import * as React from "react"

const SvgSearchBox = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill="#fff"
      stroke="#808A94"
    />
    <g clipPath="url(#a)">
      <path
        d="m19.52 18.663-3.57-3.713a6.037 6.037 0 0 0 1.422-3.894A6.063 6.063 0 0 0 11.315 5a6.063 6.063 0 0 0-6.056 6.056 6.063 6.063 0 0 0 6.056 6.057 5.991 5.991 0 0 0 3.47-1.096l3.597 3.74c.15.157.353.243.57.243a.79.79 0 0 0 .57-1.337ZM11.316 6.58a4.481 4.481 0 0 1 4.477 4.476 4.481 4.481 0 0 1-4.477 4.477 4.481 4.481 0 0 1-4.476-4.477 4.481 4.481 0 0 1 4.476-4.476Z"
        fill={props.props || "#000"}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(5 5)" d="M0 0h15v15H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgSearchBox
