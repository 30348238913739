import { Reducer } from "redux";
import moduleFunctTypes from "../actions/types/moduleFunct.types";
import { IModuleFunctState } from "../interfaces/moduleFunct.interfaces";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { rootState } from "./state/root.states";

const initState: IModuleFunctState = {
	stateGetModuleFunct: { ...rootState },
	stateGetModuleFunctDetail: { ...rootState },
	stateGetModuleFunctDetailChildren:{ ...rootState },
};

const moduleFunctReducer: Reducer<IModuleFunctState, ISyntheticAction> = (
	state: IModuleFunctState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case moduleFunctTypes.START_GET_LIST_MODULE_FUNCT: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunct: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case moduleFunctTypes.GET_LIST_MODULE_FUNCT_SUCCESS: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunct: {
					...rootState,
					isLoading: false,
					...response
					// data: response.data,
					// message: response.message,
					// success: response.success
				}
			};
			return stateReducer;
		}
		case moduleFunctTypes.GET_LIST_MODULE_FUNCT_FAILURE: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunct: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/
		case moduleFunctTypes.START_GET_MODULE_FUNCT_BY_ID: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunctDetail: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_SUCCESS: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunctDetail: {
					...rootState,
					...response,
					isLoading: false,
					// data: response.data,
					// message: response.message,
					// success: response.success
				}
			};
			return stateReducer;
		}
		case moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_FAILURE: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunctDetail: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		///////////////////////////////////////////////////////////
		case moduleFunctTypes.START_GET_MODULE_FUNCT_BY_ID_CHILDREN: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunctDetailChildren: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_CHILDREN_SUCCESS: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunctDetailChildren: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_CHILDREN_FAILURE: {
			const stateReducer: IModuleFunctState = {
				...state,
				stateGetModuleFunctDetailChildren: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		default:
			return state;
	}
};

export default moduleFunctReducer;
