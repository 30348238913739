import * as React from "react"

const Svg3Dot = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill="#fff"
      stroke="#808A94"
    />
    <circle cx={12.5} cy={6.5} r={2.5} fill="#001529" />
    <circle cx={12.5} cy={12.5} r={2.5} fill="#001529" />
    <circle cx={12.5} cy={18.5} r={2.5} fill="#001529" />
  </svg>
)

export default Svg3Dot
