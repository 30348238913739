import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { ISuppliesState } from '../interfaces/supplies.interfaces';
import suppliesTypes from '../actions/types/supplies.type';

const initState: ISuppliesState = {
    stateSuppliesManagement: { ...rootState },
    stateSuppliesManagementDetail: {...rootState},
    stateUpdateSupplies: {...rootState},
    stateCreateSupplies: {...rootState},
};

const suppliesReducer: Reducer<ISuppliesState, ISyntheticAction>
  = (state: ISuppliesState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET SUPPLIES                   */

      case suppliesTypes.START_GET_SUPLIES_MANAGEMENT: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateSuppliesManagement: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case suppliesTypes.GET_SUPLIES_SUCCESS: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateSuppliesManagement: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case suppliesTypes.GET_SUPLIES_FAILURE: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateSuppliesManagement: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

       /*************************** START *************************/
      /*                  GET SUPPLIES DETAIL                  */

      case suppliesTypes.START_GET_SUPLIES_DETAIL: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateSuppliesManagementDetail: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case suppliesTypes.GET_SUPLIES_DETAIL_SUCCESS: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateSuppliesManagementDetail: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case suppliesTypes.GET_SUPLIES_DETAIL_FAILURE: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateSuppliesManagementDetail: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

       /*************************** START *************************/
      /*                           UPDATE                 */

      case suppliesTypes.START_UPDATE_SUPLIES: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateUpdateSupplies: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case suppliesTypes.UPDATE_SUPLIES_SUCCESS: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateUpdateSupplies: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case suppliesTypes.UPDATE_SUPLIES_FAILURE: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateUpdateSupplies: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

        /*************************** START *************************/
      /*                            CREATE                         */

      case suppliesTypes.START_CREATE_SUPLIES: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateCreateSupplies: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case suppliesTypes.CREATE_SUPLIES_SUCCESS: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateCreateSupplies: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case suppliesTypes.CREATE_SUPLIES_FAILURE: {
        const stateReducer: ISuppliesState = {
          ...state,
          stateCreateSupplies: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default suppliesReducer;