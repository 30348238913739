import * as React from "react"

const SvgIcon00133 = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill="#fff"
      stroke="#808A94"
    />
    <g clipPath="url(#a)">
      <path
        d="M18.66 11.16h-4.553a.268.268 0 0 1-.268-.267V6.339a1.34 1.34 0 0 0-2.678 0v4.554c0 .148-.12.268-.268.268H6.339a1.34 1.34 0 0 0 0 2.678h4.554c.148 0 .268.12.268.268v4.554a1.34 1.34 0 0 0 2.678 0v-4.554c0-.148.12-.268.268-.268h4.554a1.34 1.34 0 0 0 0-2.678Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(5 5)" d="M0 0h15v15H0z" />
      </clipPath>
    </defs>
  </svg>
)


export default SvgIcon00133