import { Reducer } from "redux";
import transportAutoConfigsTypes from "../actions/types/transportAutoConfigs.types";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { ITransportAutoConfigsState } from "../interfaces/transportAutoConfigs.interfaces";
import { rootState } from "./state/root.states";

const initState: ITransportAutoConfigsState = {
	stateCreateTransportAutoConfig: { ...rootState },
	stateUpdateTransportAutoConfig: { ...rootState },
	stateListTransportAutoConfig: { ...rootState },
	stateDetailTransportAutoConfig: { ...rootState },
};

const transportAutoConfigsReducer: Reducer<ITransportAutoConfigsState, ISyntheticAction> = (
	state: ITransportAutoConfigsState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case transportAutoConfigsTypes.START_CREATE_TRANSPORT_AUTO_CONFIG: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateCreateTransportAutoConfig: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.CREATE_TRANSPORT_AUTO_CONFIG_SUCCESS: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateCreateTransportAutoConfig: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.CREATE_TRANSPORT_AUTO_CONFIG_FAILURE: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateCreateTransportAutoConfig: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/
		/*************************** START *************************/
		case transportAutoConfigsTypes.START_UPDATE_TRANSPORT_AUTO_CONFIG: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateUpdateTransportAutoConfig: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.UPDATE_TRANSPORT_AUTO_CONFIG_SUCCESS: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateUpdateTransportAutoConfig: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.UPDATE_TRANSPORT_AUTO_CONFIG_FAILURE: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateUpdateTransportAutoConfig: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportAutoConfigsTypes.START_GET_TRANSPORT_AUTO_CONFIG: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateListTransportAutoConfig: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.GET_TRANSPORT_AUTO_CONFIG_SUCCESS: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateListTransportAutoConfig: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.GET_TRANSPORT_AUTO_CONFIG_FAILURE: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateListTransportAutoConfig: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportAutoConfigsTypes.START_GET_DETAIL_TRANSPORT_AUTO_CONFIG: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateDetailTransportAutoConfig: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.GET_DETAIL_TRANSPORT_AUTO_CONFIG_SUCCESS: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateDetailTransportAutoConfig: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportAutoConfigsTypes.GET_DETAIL_TRANSPORT_AUTO_CONFIG_FAILURE: {
			const stateReducer: ITransportAutoConfigsState = {
				...state,
				stateDetailTransportAutoConfig: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		default:
			return state;
	}
};

export default transportAutoConfigsReducer;
