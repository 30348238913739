const routerNames = {
	SIGN_IN: "/sign-in",
	DASHBOARD: "/dashboard",
	ACTIVE_OTP: "/active-otp",
	TEST: "/test",

	SCHEDULER: "/scheduler",

	CRON: "/cron-function",
	CCTV:'/cctv',
	APPS:'/apps',

	//đội xe
	// VEHICLE_MANAGEMENT: "/vehicle-management",
	VEHICLE_MANAGEMENT_EDIT: "/vehicle/edit",
	CREATE_NEW:'/create-new',
	CATEGORY_JOB:'/category-job',

	//Cấu hình đội xe
	FLEET_CONFIGURATION:"/fleet-configuration",
	BANNER_LIST:'/banner-list',
	TARGET_JOB:'/target-job',
	LIST_NEW:'/list-new',
	VEHICLE_GROUP:"/vehicle-group",
	VEHICLE_TYPE:"/vehicle-type",
	VEHICLE:"/vehicle",
	DRIVER:"/driver",
	LIST_EMAIL:'/list-email',
	EMAIL:'/email',
	MAINTENANCELIST:"/repair-management",
	LISTOFSUPPLIES:"/supplies-management",
	REPAIRREGULATION : "/repair-regulation",
	ROUTING_GROUPS:"/routing-groups",
	ROUTES:"/routes",
	LIST_PROFILE:'/list-profile',
	LIST_PROFILE_DETAILS:'/list-profile/:id',
	LIST_PROFILE_TEMP: '/list-profile-temp',

	//Bảo trì bảo dưỡng
	MAINTENANCE:"/maintenances",

	// Danh sách chuyến xe
	TRIPS_MANAGEMENT: "/trip-management",
	TRIPS_INNER: "/trip-inner",
	// Báo tải
	WAITING_BOOK_MANAGEMENT: "/waiting-book-management",
	WAITING_BOOK_INCURRED: "/waiting-book-incurred",
	SUMARY_WAITING_BOOK: "/sumary-waiting-book",

	//Bảng kê chi phí
	LIST_OF_EXPENSES: "/lists-of-expenses",

	DASHBOARD_TRIPS: "/dashboard-trips",
	DASHBOARD_WARN: "/dashboard-warn",
	WAREHOUSE_INVENTORY: "/warehouse-inventory",
	ROAD_COST_MANAGEMENT: "/road-cost-management",
	ROAD_COST_STATION: "/road-cost-station",

	PRICE_OFF_TYPE:"/price-off-type",
	PRICE_OFF_CATEGOTY: "/price-off-category",
	VEHICLE_DIARY: "/vehicle-diary",
	// MANTENANCES:"/maintenances",
	ROLES:"/roles",
	MOUDULE_FUNCT:"/module-funct",
	USERS: "/nhan-vien",
	REPAIR_PARTNER: "/repair-partner",
	WAREHOUSES: "/warehouses",
	TRANSPORTAUTOCONFIG: '/transport_auto_config',
	MONITOR:'/monitor',
	REPORTIDENTIFIER :'/report_identifier',
	REPORTPRODUCTIVITY :'/report_productivity',
	REPORTPERFORMANCE :'/report_performance',
	REPORTONTIME :'/report_ontime',
	REPORT_ONTIME_IN_CITY :'/report-ontime-in-city',
	CONFIGKPI :'/config_kpi',
	CONFIG_OILFUEL :'/petroleum-price-config',
	CONFIG_VEHICLE_GROUP_WEIGHT :'/config-vehicle-group-weight',
	LIST_EXPORT_WAREHOUSE: '/list-export-warehouse',
	BILL_JOURNEY:"/bill-journey",
	CREATEPOST:'/create-post',
	LIST_POST:'/list-post',
	EMAIL_FIELD:'/email-field',
	YEP: '/yep',
	APPROVELIST: '/approve-list',
	APPROVEDETAILS: '/approve-list',
	XETDUYETLUONG:'/approve-salary',
	CUSTOMER: '/customer',
	LISTBILL:'/qlvd',
	CREATE_BILL:'/taovd',
	INWAREHOUSE:'/nhk',
	WAREHOUSE_TO_CUSTOMER:'/xkth',
	WAREHOUSE_TO_WAREHOUSE:'/xktk',
	CUSTOMER_TO_WAREHOUSE:'/nkkpd',
	WH_OPERATION:'/chi-tiet-xnk',
	TCVD:'/tcvd',
	DETAILED_BILL:'/chi-tiet-don',
	PRICE_LIST:'/banggia',
	DETAILED_PRICE_LIST:'/chi-tiet-bang-gia',
	WAREHOUSE: "/kho",
	DSBANGKE: '/ds-bang-ke',
	CALCULATE:'/tinh-gia',
	VUNG:'/vung',
	PARTNER: "/doi-tac",
	CREATEDEB:'/tao-bang-ke',
	SEALLIST:'/seal',
	NAVIGATIONBILL:'/assign'
};

export default routerNames;
