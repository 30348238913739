function SvgIcon00096(props) {
	return (
		<svg
			width={65}
			height={65}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#a)">
			<path
				d="M50.864 36.72c-.861 0-1.72.078-2.565.233L43.847 32.5l20.595-20.595a1.904 1.904 0 0 0 0-2.693L55.788.558a1.904 1.904 0 0 0-2.693 0l-8.651 8.651-.003.003-.002.002-11.94 11.939-4.452-4.452c.831-4.544-.616-9.27-3.906-12.56A14.044 14.044 0 0 0 14.145 0c-2.102 0-4.211.477-6.1 1.379a1.904 1.904 0 0 0-.525 3.065l6.813 6.812-3.077 3.076L4.443 7.52a1.906 1.906 0 0 0-3.065.526A14.166 14.166 0 0 0 4.141 24.14c2.67 2.67 6.22 4.14 9.996 4.14.86 0 1.72-.078 2.564-.233l4.452 4.452L6.327 47.326c-.183.182-.326.4-.422.64L.136 62.387a1.904 1.904 0 0 0 2.476 2.476l14.422-5.77c.24-.095.457-.239.64-.421L32.5 43.847l4.453 4.453a14.196 14.196 0 0 0 3.905 12.56c2.67 2.67 6.22 4.14 9.997 4.14 2.102 0 4.21-.477 6.1-1.379a1.904 1.904 0 0 0 .525-3.065l-6.813-6.812 3.076-3.076 6.813 6.813a1.906 1.906 0 0 0 3.064-.526 14.164 14.164 0 0 0-2.761-16.096 14.039 14.039 0 0 0-9.995-4.14Zm3.578-32.123 5.96 5.96-5.96 5.962-5.96-5.961 5.96-5.96Zm-8.654 8.654 5.96 5.96-10.594 10.596-5.961-5.96L45.788 13.25Zm-37.42 38.81 4.57 4.57-7.615 3.046 3.046-7.616Zm7.96 2.572-5.962-5.96 13.48-13.48 5.96 5.96-13.479 13.48Zm44.72-2.048-5.958-5.957a1.904 1.904 0 0 0-2.693 0l-5.769 5.769a1.904 1.904 0 0 0 0 2.693l5.956 5.955c-.571.097-1.15.146-1.728.146-2.76 0-5.353-1.075-7.304-3.025a10.372 10.372 0 0 1-2.672-9.982 1.904 1.904 0 0 0-.494-1.838L18.654 24.615a1.904 1.904 0 0 0-1.838-.494c-.872.233-1.774.351-2.68.351-2.759 0-5.352-1.074-7.302-3.024a10.354 10.354 0 0 1-2.881-9.033l5.957 5.957c.743.744 1.95.744 2.693 0l5.769-5.77a1.904 1.904 0 0 0 0-2.692l-5.955-5.956a10.36 10.36 0 0 1 1.728-.145 10.26 10.26 0 0 1 7.303 3.024 10.374 10.374 0 0 1 2.673 9.983 1.904 1.904 0 0 0 .494 1.838l21.731 21.732c.48.48 1.181.669 1.838.493a10.394 10.394 0 0 1 2.68-.351c2.759 0 5.352 1.074 7.302 3.024a10.354 10.354 0 0 1 2.881 9.033Z"
				fill={props.fill || "#BFC4C9"}
			/>
			</g>
			<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h65v65H0z" />
			</clipPath>
			</defs>
		</svg>
	);
}

export default SvgIcon00096;
