function SvgIcon00199(props) {
	return (
		<svg
			width={25}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
			x={0.5}
			y={0.5}
			width={24}
			height={24}
			rx={4.5}
			fill={props.fillbg || "#fff"}
			stroke="#808A94"
			/>
			<g clipPath="url(#a)">
			<path
				d="M13.581 6.536a.462.462 0 0 0-.14.333V9.31c0 .19-.154.345-.345.345H5.463a.462.462 0 0 0-.462.462v4.752c0 .255.207.462.462.462h7.635c.19 0 .344.154.344.345v2.448a.462.462 0 0 0 .793.331l5.628-5.627a.463.463 0 0 0 .138-.325.462.462 0 0 0-.138-.33l-5.627-5.628a.462.462 0 0 0-.654-.009Z"
				fill={props.fill || "#000"}
			/>
			</g>
			<defs>
			<clipPath id="a">
				<path fill={props.fillbg || "#fff"} transform="translate(5 5)" d="M0 0h15v15H0z" />
			</clipPath>
			</defs>
		</svg>
	);
}

export default SvgIcon00199;
