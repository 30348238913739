import moment from "moment";
import { Link } from "react-router-dom";
import SvgIcon00003 from "src/assets/svg/SvgIcon00003";
import SvgPhone from "src/assets/svg/SvgPhone";
import { dateFormatYMD, dateFormatYMD_hm24h, formatDateTime } from "src/utils/helpers/functions/date";
import {
	geneUniqueKey,
	numberWithCommas,
	numberWithDot
} from "src/utils/helpers/functions/textUtils";

export const userInfo = (user_code: string, full_name: string, phone: string, email?: string, extra: any = false) => {
	return (
		<div>
			<div>
				{user_code} - {full_name}
			</div>
			{email && (
				<div className="flex items-center -ml-1">
					<SvgIcon00003 className="scale-[0.6]" />
					<span>{email || "-"}</span>
				</div>
			)}
			{phone && (
				<div className="flex items-center">
					<SvgPhone fill={"#2F80ED"} className="scale-[0.55] -ml-1" />
					<a href={`tel:${phone}`} className="flex items-center gap-2 text-blue-400">
						<span className="underline">{phone}</span>
					</a>
				</div>
			)}
			{extra && extra}
		</div>
	);
};

export const createUser = (created_by: string, createdAt: string, updatedAt: string) => {
	return (
		<div>
			{/* <div>
				<span>{created_by ? created_by : "-"}</span>
			</div> */}
			<div>
				<span className="text-slate-400">Ngày tạo: </span>
				<span>{createdAt ? moment(createdAt).format("DD/MM/YYYY HH:mm:ss") : "-"}</span>
			</div>
			<div>
				<span className="text-slate-400">Ngày cập nhật: </span>
				<span>{updatedAt ? moment(updatedAt).format("DD/MM/YYYY HH:mm:ss") : "-"}</span>
			</div>
		</div>
	);
};

type typeContent = "string" | "number" | "phone" | "email" | "link" | "money" | "dateTime" | "date";

export interface IRenderCel {
	title?: string;
	content: any;
	type?: typeContent;
	link?: string;
}
export const renderCell = (data: IRenderCel[]) => {
	return (
		<div>
			{(data || []).map((e: IRenderCel) => {
				let resultContent;
				switch (e.type) {
					case "dateTime":
						resultContent = <span>{e.content ? formatDateTime(e.content, dateFormatYMD_hm24h) : "-"}</span>;
						break;
					case "date":
						resultContent = <span>{e.content ? formatDateTime(e.content, dateFormatYMD) : "-"}</span>;
						break;
					case "number":
						resultContent = <span>{numberWithDot(e.content || 0)}</span>;
						break;
					case "money":
						resultContent = <span>{numberWithCommas(e.content || 0)}</span>;
						break;
					case "phone":
						resultContent = (
							<div className="flex items-center">
								<SvgPhone fill={"#2F80ED"} className="scale-[0.55] -ml-1" />
								{
									e.content ? <a href={`tel:${e.content}`} className="flex items-center gap-2 text-blue-400">
										<span className="underline">{e.content}</span>
									</a> : "-"
								}
							</div>
						);
						break;
					case "email":
						resultContent = (
							<div className="flex items-center -ml-1">
								<SvgIcon00003 className="scale-[0.6]" />
								<span>{e.content || "-"}</span>
							</div>
						);
						break;
					case "link":
						resultContent = e.content && e.link ? (
							<Link to={e.link || ""} style={{ color: "#60A5FA", cursor: "pointer" }}>
								<span className="underline">{e.content || "-"}</span>
							</Link>
						) : (
							"-"
						);
						break;

					default:
						resultContent = <span>{e.content || "-"}</span>;
						break;
				}

				let result = <div key={geneUniqueKey()}>{resultContent}</div>;
				if (e.title) {
					result = (
						<div key={geneUniqueKey()}>
							<span className="text-slate-400 pr-[2px]">{e.title}:</span>
							{resultContent}
						</div>
					);
				}

				return result;
			})}
		</div>
	);
};

interface IRenderContentJustifyBetween {
	title: string;
	content: any;
	type?: typeContent;
}
export const renderContentJustifyBetween = (data: IRenderContentJustifyBetween[]) => {
	return (
		<div>
			{
				data.map((e: IRenderContentJustifyBetween) => {
					let resultContent;
					switch (e.type) {
						case "dateTime":
							resultContent = <span>{e.content ? formatDateTime(e.content, dateFormatYMD_hm24h) : "-"}</span>;
							break;
						case "number":
							resultContent = <span>{numberWithDot(e.content || 0)}</span>;
							break;
						case "money":
							resultContent = <span>{numberWithCommas(e.content || 0)}</span>;
							break;
						case "phone":
							resultContent = (
								<div className="flex items-center">
									<SvgPhone fill={"#2F80ED"} className="scale-[0.55] -ml-1" />
									<a href={`tel:${e.content}`} className="flex items-center gap-2 text-blue-400">
										<span className="underline">{e.content || "-"}</span>
									</a>
								</div>
							);
							break;
						case "email":
							resultContent = (
								<div className="flex items-center -ml-1">
									<SvgIcon00003 className="scale-[0.6]" />
									<span>{e.content || "-"}</span>
								</div>
							);
							break;

						default:
							resultContent = <span>{e.content || "-"}</span>;
							break;
					}

					return (
						<div className="flex justify-between items-center pb-1" key={geneUniqueKey()}>
							<div>
								<span className="text-slate-400 pr-[2px]">{e.title}:</span>
							</div>
							<div>
								{resultContent}
							</div>
							
						</div>
					);
				})
			}
		</div>
	);
};
