import { Button, Col, Dropdown, Form, Menu, Modal, Row, Select, Switch, Tabs, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsDataOrg } from "./data";
import moment from "moment";
import SvgPen from "src/assets/svg/SvgPen";
import styled from "styled-components";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import { dataOrg } from "src/constants";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [formCreateApp] = Form.useForm();
	const [formCreateGroups] = Form.useForm();
	const [formCreateErp] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [isSupper, setIsSupper] = useState<boolean>(false);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	const [orgId, setOrgId] = useState<any>("");
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [orgCodeAccess, setOrgCodeAccess] = useState<any>([]);
	const [dataDepartments, setDataDepartments] = useState<any>([]);
	const [dataRole, setDataRole] = useState<any>([]);
	const [dataApps, setDataApps] = useState<any>([]);
	const [dataGroups, setDataGroups] = useState<any>([]);
	const [dataApp, setDataApp] = useState<any>([]);
	const [dataGroup, setDataGroup] = useState<any>([]);
	const [dataERPs, setDataERPs] = useState<any>([]);
	const [action, setAction] = useState<any>("");
	const [visibleModal, setVisibleModal] = useState<any>(false);
	const [visibleModalGroups, setVisibleModalGroups] = useState<any>(false);
	const [visibleModalErp, setVisibleModalErp] = useState<any>(false);
	const [dataOrg, setDataOrg] = useState<any>([]);
	const [dataPostOffice, setDataPostOffice] = useState<any>([]);
	const [dataSubDepartments, setDataSubDepartments] = useState<any>([]);
	const [dataPosition, setDataPosition] = useState<any>([]);
	const [activeKey, setActiveKey] = useState<any>("1");
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getApps({ limit: 999, status: "A" });
				setDataApp(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
		const initRole = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getRoles({ limit: 999, status: "A" });
				setDataGroup(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initRole();
		const initOrg = async () => {
			try {
				const res: any = await _apiNotSaga.getOrg({});
				setDataOrg(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initOrg();
		const initPosition = async () => {
			try {
				const res: any = await _apiNotSaga.getPositions({});
				setDataPosition(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initPosition();
		// const initERP = async () => {
		// 	try {
		// 		// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
		// 		setLoading(true);
		// 		const res: any = await _apiNotSaga.getUsersERPs({ limit: 999, status: "A" });
		// 		setDataERP(res?.data);
		// 		// setToal(res?.paging?.total);
		// 		setLoading(false);
		// 	} catch (error: any) {
		// 		setLoading(false);
		// 		notifyError(error.message);
		// 	}
		// };
		// initERP();
	}, []); //eslint-disable-line

	useEffect(() => {
		if (isMount) return;
		const { success, data, error } = stateGetUserTypes;
		if (success) {
			setUserTypes(data?.data);
			return;
		}
		if (success === false || error) {
			return notifyError(`${error}`);
		}
	}, [stateGetUserTypes.isLoading]); //eslint-disable-line
	// useEffect(() => {
	// 	const getUsers = async () => {
	// 		const res: any = await _apiNotSaga.getUsers({});
	// 		const _users = res?.data?.data.map((e: any) => ({
	// 			id: e.id,
	// 			name: `${e?.profile?.user_code} - ${e?.profile?.full_name}`
	// 		}));
	// 		setUsers(_users);
	// 	};
	// 	getUsers();
	// }, []);
	useEffect(() => {
		if (detail?.id) {
			console.log(detail);
			loadDataUser();
			loadDataGroups();
			loadDataERPs();
		}
	}, [detail?.id]);
	const onSuccessApp = () => {
		setVisibleModal(false);
		loadDataUser();
	};
	const onSuccessGroup = () => {
		setVisibleModalGroups(false);
		loadDataGroups();
	};
	const onSuccessErp = () => {
		setVisibleModalErp(false);
		loadDataERPs();
	};
	const loadDataUser = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getUsersApp({
				user_id: detail?.id
			});
			setDataApps(res?.data);
			// setToal(res?.paging?.total);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const loadDataGroups = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getUsersGroups({
				user_id: detail?.id
			});
			setDataGroups(res?.data);
			// setToal(res?.paging?.total);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const loadDataERPs = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getUsersERPs({
				user_id: detail?.id
			});
			setDataERPs(res?.data);
			// setToal(res?.paging?.total);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.userDetail(detail.user_code);
				form.setFieldsValue({
					...res?.data[0],
					password: undefined
				});

				setStatus(res?.data[0]?.status === "A");
				// setIsSupper(res?.data?.is_supper === "Y");
				// setLock(res?.data?.locked === "Y");
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
			// loadDataOrgUser();
		}
	}, [users.length]); //eslint-disable-line

	const checkUserCodeOnERP = async (e: any) => {
		const userCode = e.target.value.trim();
		if (!userCode) return;
		setLoading(true);
		try {
			const res: any = await _apiNotSaga.getUserByCode(userCode);
			setLoading(false);
			form.setFieldsValue({
				full_name: res?.data?.name,
				phone: res?.data?.phone,
				email: res?.data?.email,
				org_code: res?.data?.orgValue
			});
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onSubmitApp = async (val: any) => {
		const params = {
			...val,
			status: "A",
			user_id: detail.id
		};
		console.log(params);
		setLoading(true);
		try {
			const res: any = await _apiNotSaga.createUserApps(params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccessApp();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onSubmitGroups = async (val: any) => {
		const params = {
			...val,
			status: "A",
			user_id: detail.id
		};
		console.log(params);
		setLoading(true);
		try {
			const res: any = await _apiNotSaga.createUserGroups(params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccessGroup();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onSubmitErp = async (val: any) => {
		let params = {
			...val,
			user_erp_code: detail?.user_erp_code,
			user_id: detail?.id,
			// status: "A",
			department_name: dataDepartments?.find((e: any) => e.value === val.department_code)?.name,
			position_name: dataPosition?.find((e: any) => e.departmentcode === val.position_code)?.departmentname,
			sub_department_name: dataSubDepartments?.find((e: any) => e.value === val.sub_department_code)?.name,
			post_name: dataPostOffice?.find((e: any) => e.id === val.post_id)?.name
		};
		console.log(params);

		setLoading(true);
		if (action === "edit") {
			try {
				const res: any = await _apiNotSaga.updateUserErp(params);
				setLoading(false);
				notifySuccess(res.message);
				onSuccessErp();
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		} else {
			try {
				const res: any = await _apiNotSaga.createUserErps(params);
				setLoading(false);
				notifySuccess(res.message);
				onSuccessErp();
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		}
	};
	const onSubmit = async (val: any) => {
		if (isCreate) {
			if (val?.password.indexOf(" ") !== -1) return notifyError("Mật khẩu không được chứa khoảng trắng");
		}
		const params = {
			...val,
			status: status ? "A" : "D"
			// is_supper: isSupper ? "Y" : "N",
			// org_name: stateGetOrgs?.data?.find((e: any) => e.org_code === val.org_code)?.org_name,
			// department_name: dataDepartments?.find((e: any) => e.id === val.department_id)?.department_name,
			// role_name: dataRole?.find((e: any) => e.id === val.role_id)?.role_name
		};
		console.log(params);
		setLoading(true);
		try {
			const res: any = isCreate
				? await _apiNotSaga.createUser(params)
				: await _apiNotSaga.updateUser(detail.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const handleChangeOrg = async (e: any) => {
		setOrgId(e);
		let params = {
			org_value: e
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.getPostOffice(params);
			notifySuccess(res.message);
			setDataPostOffice(res?.data);
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.getDepartment(params);
			notifySuccess(res.message);
			setDataDepartments(res?.data);
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const handleChangeDepartment = async (e: any) => {
		let params = {
			org_value: orgId,
			department_value: e
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.getSubDepartment(params);
			notifySuccess(res.message);
			setDataSubDepartments(res?.data);
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const handleAddOrg = async () => {
		if (!orgId) return notifyError("Vui lòng chọn chi nhánh");
		let params = {
			user_id: detail.id,
			org_id: orgId,
			org_name: stateGetOrgs?.data?.find((e: any) => e.id === orgId)?.org_name,
			status: "A"
		};
		console.log(params);
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.createUserOrg(params);
			notifySuccess(res.message);
			// loadDataOrgUser();
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const updateStatusGroups = async (r: any, status: number, type: string) => {
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateUserGroups("update-status", {
				...r,
				user_id: detail?.id,
				status: status ? "A" : "D"
			});
			notifySuccess(res.message);
			loadDataGroups();
			// loadDataOrgUser();
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D"
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateUserApps("update-status", {
				...r,
				user_id: detail?.id,
				status: status ? "A" : "D"
			});
			notifySuccess(res.message);
			loadDataUser();
			// loadDataOrgUser();
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const getDetail = async (value: any) => {
		setAction("edit");
		setVisibleModalErp(true);
		handleChangeOrg(value?.org_id);
		handleChangeDepartment(value?.department_code);
		formCreateErp.setFieldsValue({
			...value,
			post_id: Number(value.post_id)
		});
	};
	const onChange = (key: string) => {
		console.log(key);
		setActiveKey(key);
	};
	const columnsDataGroups = ({ updateStatusGroups, getDetail, onOpenImageOverlay }: any) => {
		return [
			{
				title: "Tên App",
				key: "app_name",
				fixed: "left",
				render: (record: any, index: number) => {
					return (
						<div className="flex flex-col justify-start items-start">
							<div className="font-bold leading-snug">{record?.app_name}</div>
						</div>
					);
				}
			},
			{
				title: "Tên Group",
				key: "group_name",
				fixed: "left",
				render: (record: any, index: number) => {
					return (
						<div className="flex flex-col justify-start items-start">
							<div className="font-bold leading-snug">{record?.group_name}</div>
						</div>
					);
				}
			},
			{
				title: "Ngày tạo",
				key: "created_at",
				width: 250,
				render: (record: any, index: number) => {
					return (
						<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
							{" "}
							{moment(record?.created_at).format("DD/MM/YYYY HH:mm")}
						</span>
					);
				}
			},
			{
				title: "Trạng thái",
				key: "status",
				render: (record: any, index: number) => {
					return (
						<Tag color={record?.status === "A" ? "#87d068" : "#f50"}>{record?.status === "A" ? "Hoạt động" : "Ẩn"}</Tag>
					);
				}
			},

			{
				title: "Tháo tác",
				dataIndex: "status",
				key: "status",
				align: "center",
				width: 150,
				render: (v: string, r: any) => (
					<div className="flex justify-center">
						<Switch checked={v === "A"} onChange={(status) => updateStatusGroups(r, status, "status")} />
					</div>
				)
			}
		];
	};
	const columnsData = ({ updateStatus, getDetail, onOpenImageOverlay }: any) => {
		return [
			{
				title: "Tên App",
				key: "app_name",
				fixed: "left",
				render: (record: any, index: number) => {
					return (
						<div className="flex flex-col justify-start items-start">
							<div className="font-bold leading-snug">{record?.app_name}</div>
						</div>
					);
				}
			},

			{
				title: "Ngày tạo",
				key: "user_type_name",
				width: 250,
				render: (record: any, index: number) => {
					return (
						<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
							{" "}
							{moment(record?.created_at).format("DD/MM/YYYY HH:mm")}
						</span>
					);
				}
			},
			{
				title: "Trạng thái",
				key: "status",
				render: (record: any, index: number) => {
					return (
						<Tag color={record?.status === "A" ? "#87d068" : "#f50"}>{record?.status === "A" ? "Hoạt động" : "Ẩn"}</Tag>
					);
				}
			},

			{
				title: "Tháo tác",
				dataIndex: "status",
				key: "status",
				align: "center",
				width: 150,
				render: (v: string, r: any) => (
					<div className="flex justify-center">
						<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status, "status")} />
					</div>
				)
			}
		];
	};
	const columnsDataErps = ({ updateStatusGroups, getDetail, onOpenImageOverlay }: any) => {
		const menu = (r: any) => (
			<MenuStyled
				items={[
					{
						key: "detail",
						label: (
							<Button onClick={() => getDetail(r)}>
								<SvgPen fill="black" />
								<span>Chỉnh sửa</span>
							</Button>
						)
					}
				]}
			/>
		);
		return [
			{
				title: "Mã chi nhánh",
				key: "org_id",
				fixed: "left",
				render: (record: any, index: number) => {
					return (
						<div className="flex flex-col justify-start items-start">
							<div className="font-bold leading-snug">{record?.org_id}</div>
						</div>
					);
				}
			},
			{
				title: "Bưu cục",
				key: "post_name",
				fixed: "left",
				render: (record: any, index: number) => {
					return (
						<div className="flex flex-col justify-start items-start">
							<div className="font-bold leading-snug">{record?.post_name}</div>
						</div>
					);
				}
			},
			{
				title: "Phòng ban",
				key: "department_name",
				fixed: "left",
				render: (record: any, index: number) => {
					return (
						<div className="flex flex-col justify-start items-start">
							<div className="font-bold leading-snug">{record?.department_name}</div>
						</div>
					);
				}
			},
			{
				title: "Chức danh",
				key: "position_name",
				fixed: "left",
				render: (record: any, index: number) => {
					return (
						<div className="flex flex-col justify-start items-start">
							<div className="font-bold leading-snug">{record?.position_name}</div>
						</div>
					);
				}
			},
			{
				title: "Ngày tạo",
				key: "created_at",
				width: 250,
				render: (record: any, index: number) => {
					return (
						<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
							{" "}
							{moment(record?.created_at).format("DD/MM/YYYY HH:mm")}
						</span>
					);
				}
			},
			{
				title: "Trạng thái",
				key: "status",
				render: (record: any, index: number) => {
					return (
						<Tag color={record?.status === "A" ? "#87d068" : "#f50"}>{record?.status === "A" ? "Hoạt động" : "Ẩn"}</Tag>
					);
				}
			},

			{
				title: "Tháo tác",
				dataIndex: "status",
				key: "status",
				align: "center",
				width: 150,
				render: (v: string, r: any) => {
					return (
						<div className="flex justify-center">
							<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
								{/* <Dropdown 
							menu={{ items: items(r) }} 
							overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
								<Button icon={<Svg3Dot />}></Button>
							</Dropdown>
						</div>
					);
				}
			}
		];
	};
	const handleOpenModal = () => {
		setAction("add");
		if (activeKey === "1") {
			setVisibleModal(true);
		}
		if (activeKey === "2") {
			setVisibleModalGroups(true);
		}
		if (activeKey === "3") {
			setVisibleModalErp(true);
		}
	};
	const operations = (
		<Button onClick={() => handleOpenModal()} className="bg-black hover:bg-black">
			<p className="text-[#fdd800]">Thêm mới</p>
		</Button>
	);
	return (
		<>
			<Modal
				open={visibleModal}
				width={800}
				onCancel={() => setVisibleModal(false)}
				// maskClosable={false}
				title={`Thêm mới`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm1"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Thêm mới</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={formCreateApp} name="myForm1" onFinish={onSubmitApp} scrollToFirstError>
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormSelect
									name="app_id"
									label="Nhóm ứng dụng"
									dataSource={dataApp}
									placeholder="Chọn ứng dụng"
									slOption={{
										name: "app_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
			<Modal
				open={visibleModalGroups}
				width={800}
				onCancel={() => setVisibleModalGroups(false)}
				// maskClosable={false}
				title={`Thêm mới`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm2"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Thêm mới</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={formCreateGroups} name="myForm2" onFinish={onSubmitGroups} scrollToFirstError>
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormSelect
									name="app_id"
									label="Nhóm ứng dụng"
									dataSource={dataApp}
									placeholder="Chọn ứng dụng"
									slOption={{
										name: "app_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="group_id"
									label="Nhóm group"
									dataSource={dataGroup}
									placeholder="Chọn group"
									slOption={{
										name: "group_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
			<Modal
				open={visibleModalErp}
				width={800}
				onCancel={() => setVisibleModalErp(false)}
				// maskClosable={false}
				title={`Thêm mới`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						{/* <div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch
									disabled={loading}
									size="default"
									checked={formCreateErp.getFieldValue("status") === "D" ? false : true}
									onChange={(e) =>
										formCreateErp.setFieldsValue({
											status: e ? "D" : "A"
										})
									}
								></Switch>
							</div>
						</div> */}
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm3"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" />{" "}
							<p className="text-[#fdd800]">{action === "edit" ? "Cập nhật" : "Thêm mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={formCreateErp} name="myForm3" onFinish={onSubmitErp} scrollToFirstError>
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormSelect
									name="org_id"
									label="Chi nhánh"
									dataSource={dataOrg}
									placeholder="Chọn chi nhánh"
									slOption={{
										name: "name",
										value: "value"
									}}
									showSearch
									optionFilterProp="children"
									onChange={(e) => handleChangeOrg(e)}
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="post_id"
									label="Bưu cục"
									dataSource={dataPostOffice}
									placeholder="Chọn bưu cục"
									slOption={{
										name: "name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="department_code"
									label="Phòng ban"
									dataSource={dataDepartments}
									placeholder="Chọn phòng ban"
									onChange={(e) => handleChangeDepartment(e)}
									slOption={{
										name: "name",
										value: "value"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="sub_department_code"
									label="Bộ phận"
									dataSource={dataSubDepartments}
									placeholder="Chọn bộ phận"
									slOption={{
										name: "name",
										value: "value"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="position_code"
									label="Chức danh"
									dataSource={dataPosition}
									placeholder="Chọn chức danh"
									slOption={{
										name: "departmentname",
										value: "departmentcode"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="status"
									label="Trạng thái"
									dataSource={[
										{
											name: "Hoạt động",
											value: "A"
										},
										{
											name: "Ẩn",
											value: "D"
										}
									]}
									placeholder="Chọn trạng thái"
									slOption={{
										name: "name",
										value: "value"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
			<Modal
				open={visible}
				width={1000}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} người dùng`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							<div className="flex text-[13px] mb-4 md:mb-0">
								Supper Admin
								<Switch disabled={loading} size="default" checked={isSupper} onChange={(e) => setIsSupper(e)}></Switch>
							</div>
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormInput
									name="user_code"
									label="Mã nhân viên"
									type="justNumber"
									size="large"
									placeholder="Nhập mã nhân viên"
									required
									// onBlur={checkUserCodeOnERP}
									disabled={!isCreate}
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="full_name"
									label="Họ tên"
									type="text"
									size="large"
									placeholder="Nhập họ tên"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="email"
									label="Email"
									type="email"
									size="large"
									placeholder="Nhập email"
									required
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="phone"
									label="Số điện thoại"
									type="phone"
									size="large"
									placeholder="Nhập số điện thoại"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="role_id"
									label="Vai trò"
									dataSource={dataGroup}
									placeholder="Chọn vai trò"
									slOption={{
										name: "role_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							{/* <Col xs={24} md={12}>
								<FormSelect
									name="department_id"
									label="Phòng ban"
									dataSource={dataDepartments}
									placeholder="Chọn phòng ban"
									slOption={{
										name: "department_name",
										value: "id"
									}}
									disabled={!isCreate}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="role_id"
									label="Nhóm vai trò"
									dataSource={dataRole}
									placeholder="Chọn vai trò"
									slOption={{
										name: "role_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col> */}
							{/* <Col xs={24} md={12}>
								<FormSelect
									name="org_code"
									label="Chi nhánh"
									disabled={!isCreate}
									dataSource={stateGetOrgs?.data}
									placeholder="Chọn chi nhánh"
									slOption={{
										name: "org_name",
										value: "org_code"
									}}
									showSearch
									optionFilterProp="children"
									required
									// disabled
								/>
							</Col> */}
							{isCreate && (
								<Col xs={24} md={12}>
									<FormInput
										name="password"
										label="Mật khẩu"
										type="text"
										size="large"
										placeholder="Nhập mật khẩu"
										required
										// disabled
									/>
								</Col>
							)}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
