import { Button, Col, DatePicker, Dropdown, Form, Menu, Modal, Row, Select, Switch, Tabs, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import moment from "moment";
import SvgPen from "src/assets/svg/SvgPen";
import styled from "styled-components";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import { dataOrg, INCLUDE_SOME_SPECIAL_CHAR, JUST_NUMBER, NUMBER_AND_TEXT } from "src/constants";
import { localGetAccount } from "src/utils/localStorage";
import FormItem from "antd/lib/form/FormItem";
import { debounce } from "lodash";
import { CALC_TYPE_LIST } from "src/utils/helpers/functions/data";
import FormSelectDate from "src/components/form/FormSelectDate";
import { dateFormatYMD } from "src/utils/helpers/functions/date";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
const { Item } = Form;
const { Option } = Select;
const CreateOperationModal = ({
	id,
	visible = true,
	isEdit = false,
	onCancel = () => {},
	onSuccess = () => {}
}: any) => {
	const [form] = Form.useForm();
	const [status, setStatus] = useState<boolean>(true);
	const [isSupper, setIsSupper] = useState<boolean>(false);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [user, setUser] = useState<any>([]);
	const [listUser, setListUser] = useState([]);
	const { stateWarehouses, stateProvinces, stateGoodTypes, stateServices } = useSelector(
		(e: AppState) => e.globalReducer
	);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				// let USER_INFO: any = localGetAccount();
				// USER_INFO = JSON.parse(USER_INFO);
				// console.log("USER_INFO", USER_INFO);
				// loadUser(USER_INFO.user_code);
				// form.setFieldsValue({
				// 	receive_user_by: `${USER_INFO.user.user_code}`
				// });
				// setUser(USER_INFO);
				if (isEdit) {
					setLoading(true);
					console.log("id", id);
					const res: any = await _apiNotSaga.showPriceList(id, {});
					const data = res.data;
					form.setFieldsValue({
						price_code: data.price_code,
						price_name: data.price_name,
						service_id: data.service_id,
						goods_type_id: data.goods_type_id,
						calc_type_id: data.calc_type_id,
						status: data.status,
						start_at: data.start_at ? moment(data.start_at) : "",
						end_at: data.end_at ? moment(data.end_at) : "",
						vat: data.vat
					});
					setStatus(data.status == "A");
					setLoading(false);
				}
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, [id]);

	const onSubmit = async (val: any) => {
		const params = {
			...val
		};
		setLoading(true);
		try {
			params.status = status ? "A" : "D";
			params.start_at = moment(params.start_at).format(dateFormatYMD);
			params.end_at = moment(params.end_at).format(dateFormatYMD);
			const res: any = await (isEdit ? _apiNotSaga.updatePriceList(id, params) : _apiNotSaga.createPriceList(params));
			setLoading(false);
			if (!res.success) {
				notifyError(res.message);
				return;
			}
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const loadUser = async (q: any) => {
		if (!q || q === "") return;
		const res: any = await _apiNotSaga.getUsers({ limit: 999, status: "A", q });
		setListUser(res.data);
		// dispatch(fetchUserFromTicket({ do_get_list: true, user_erp_code: q }));
	};

	return (
		<>
			<Modal
				open={visible}
				width={1000}
				onCancel={onCancel}
				// maskClosable={false}
				title={`Tạo bảng giá`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Trạng thái &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm1"
							style={{ backgroundColor: "#212E3B" }}
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<p className="text-[#fff] text-md">{isEdit ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm1" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 8]}>
							<Col xs={8} md={8}>
								<FormInput
									name="price_code"
									label="Mã bảng giá"
									type={INCLUDE_SOME_SPECIAL_CHAR}
									size="large"
									placeholder="Nhập mã bảng giá"
									required
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={16} md={16}>
								<FormInput
									name="price_name"
									label="Tên bảng giá"
									type={INCLUDE_SOME_SPECIAL_CHAR}
									size="large"
									placeholder="Nhập tên bảng giá"
									required
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormSelect
									name="service_id"
									label="Dịch vụ"
									dataSource={stateServices.data || []}
									placeholder="Chọn dịch vụ"
									slOption={{
										name: "service_name",
										value: "id"
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormSelect
									name="goods_type_id"
									label="Loại hàng hóa"
									dataSource={stateGoodTypes.data || []}
									placeholder="Chọn loại hàng hóa"
									slOption={{
										name: "gst_name",
										value: "id"
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormSelect
									name="calc_type_id"
									label="Quy tắc áp giá"
									dataSource={CALC_TYPE_LIST}
									placeholder="Chọn quy tắc"
									slOption={{
										name: "calc_type_name",
										value: "id"
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormInput
									name="round_weight"
									label="Làm tròn khối lượng"
									type={JUST_NUMBER}
									size="large"
									placeholder="1"
									// required
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormInput
									name="round_price"
									label="Làm tròn giá"
									type={JUST_NUMBER}
									size="large"
									placeholder="1"
									// required
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={8} md={8}></Col>
							<Col md={8} lg={8} xs={8} sm={8}>
								<Form.Item
									name="start_at"
									label="Áp dụng từ ngày"
									rules={[{ required: true, message: "Không được bỏ trống!" }]}
								>
									<DatePicker placeholder="Chọn ngày" format={"DD/MM/YYYY"} />
								</Form.Item>
							</Col>
							<Col md={8} lg={8} xs={8} sm={8}>
								<Form.Item name="end_at" label="Áp dụng đến ngày" rules={[{ required: false }]}>
									<DatePicker placeholder="Chọn ngày" format={"DD/MM/YYYY"} />
								</Form.Item>
							</Col>
							{/* <Col xs={24} md={12}></Col> */}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default CreateOperationModal;
