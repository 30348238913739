
import { IAction } from '../interfaces/root.interfaces';
import userTypesTypes from './types/userTypes.types';

export const getUserTypes = (params?: any): IAction => {
  const action: IAction = {
    type: userTypesTypes.START_GET_USER_TYPES,
    payload: { params },
  }
  return action;
}