/* eslint-disable */
import { call, put, takeLeading } from "redux-saga/effects";
import { API_URL } from "../../api/config";
import { IAction, IRootResponse, ICatchError } from "../../interfaces/root.interfaces";
import { api } from "../../api/api.index";
import { actionFailure, actionSuccess } from "../root.actions";
import { API_DOWNLOAD, API_EXPORT_IDENTIFIER_TEMPLATE, API_EXPORT_TEMPLATE, API_TRANSPORT_TRIPS, API_USERS, API_VEHICLES, TRANSPORT_TRIP_EXPORTED } from "src/services/api/url.index";
import exportTypes from "../types/export.types";
import { exportExcel } from "src/utils/helpers/functions/textUtils";

function* getExportTemplate(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_EXPORT_TEMPLATE}`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.GET_EXPORT_TEMPLATE_SUCCESS, res));
		exportExcel(_res, payload.params?.name);
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.GET_EXPORT_TEMPLATE_FAILURE, err));
	}
}
function* getExportIdentifierTemplate(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_EXPORT_IDENTIFIER_TEMPLATE}`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.GET_EXPORT_TEMPLATE_SUCCESS, res));
		exportExcel(_res, payload.params?.name);
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.GET_EXPORT_TEMPLATE_FAILURE, err));
	}
}
function* exportReportOntime(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_TRANSPORT_TRIPS}/export-report-ontime`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_REPORT_ONTIME_SUCCESS, res));
		exportExcel(_res, 'REPORT_ONTIME_CX_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_REPORT_ONTIME_FAILURE, err));
	}
}
function* exportReportOntimeSynthesize (action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_TRANSPORT_TRIPS}/export-report-ontime-th`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_REPORT_ONTIME_SYNTHESIZE_SUCCESS, res));
		exportExcel(_res, 'REPORT_ONTIME_CX_TH_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_REPORT_ONTIME_SYNTHESIZE_FAILURE, err));
	}
}

function* exportReportPerformance(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_VEHICLES}/export-report-performance`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_REPORT_PERFORMANCE_SUCCESS, res));
		exportExcel(_res, 'REPORT_PERFORMANCE_CX_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_REPORT_PERFORMANCE_FAILURE, err));
	}
}

function* exportListTrip(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_TRANSPORT_TRIPS}/export-list-trip`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_LIST_TRIP_SUCCESS, res));
		exportExcel(_res, 'LIST_TRIP_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_LIST_TRIP_FAILURE, err));
	}
}

function* exportReportProductivity(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_TRANSPORT_TRIPS}/export-report-productivity`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_REPORT_PRODUCTIVITY_SUCCESS, res));
		exportExcel(_res, 'REPORT_PRODUCTIVITY_CX_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_REPORT_PRODUCTIVITY_FAILURE, err));
	}
}

function* exportReportProductivityDetails(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_TRANSPORT_TRIPS}/export-report-productivity-details`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_REPORT_PRODUCTIVITY_DETAILS_SUCCESS, res));
		exportExcel(_res, 'REPORT_PRODUCTIVITY_DETAIL_CX_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_REPORT_PRODUCTIVITY_DETAILS_FAILURE, err));
	}
}
function* exportReportUser(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${API_USERS}/export-report-user`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_REPORT_USER_SUCCESS, res));
		exportExcel(_res, 'REPORT_USER_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_REPORT_USER_FAILURE, err));
	}
}

// function* exportReportProblems(action: IAction) {
// 	try {
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return api.getDowload(`${API_URL}/${API_TRANSPORT_TRIP_PROBLEMS}/export-report-problem`, payload.params);
// 		});

// 		const _res: any = res;
// 		yield put(actionSuccess(exportTypes.EXPORT_REPORT_PROBLEMS_SUCCESS, res));
// 		exportExcel(_res, 'REPORT_PROBLEM_CX_');
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(exportTypes.EXPORT_REPORT_PROBLEMS_FAILURE, err));
// 	}
// }

function* exportReportExport(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${TRANSPORT_TRIP_EXPORTED}/export-report-exports`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_REPORT_EXPORT_SUCCESS, res));
		exportExcel(_res, 'REPORT_EXPORT_CX_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_REPORT_EXPORT_FAILURE, err));
	}
}


// function* exportReportEvaluationWH(action: IAction) {
// 	try {
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return api.getDowload(`${API_URL}/${TRANSPORT_TRIP_EVALUATION}/export-evaluation-wh`, payload.params);
// 		});

// 		const _res: any = res;
// 		yield put(actionSuccess(exportTypes.EXPORT_REPORT_EVALUATION_WH_SUCCESS, res));
// 		exportExcel(_res, 'REPORT_EVALUTION_CX_');
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(exportTypes.EXPORT_REPORT_EVALUATION_WH_FAILURE, err));
// 	}
// }


// function* exportReportEvaluationDriver(action: IAction) {
// 	try {
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return api.getDowload(`${API_URL}/${TRANSPORT_TRIP_EVALUATION}/export-evaluation-driver`, payload.params);
// 		});

// 		const _res: any = res;
// 		yield put(actionSuccess(exportTypes.EXPORT_REPORT_EVALUATION_DRIVER_SUCCESS, res));
// 		exportExcel(_res, 'REPORT_EVALUTION_DRIVER_CX_');
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(exportTypes.EXPORT_REPORT_EVALUATION_DRIVER_FAILURE, err));
// 	}
// }

function* exportWarehouseInventory(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.getDowload(`${API_URL}/${TRANSPORT_TRIP_EXPORTED}/export-report-wh-details`, payload.params);
		});

		const _res: any = res;
		yield put(actionSuccess(exportTypes.EXPORT_WH_INVENTORY_SUCCESS, res));
		exportExcel(_res, 'BAO_CAO_SAN_LUONG_HANG_');
	} catch (err: ICatchError | any) {
		yield put(actionFailure(exportTypes.EXPORT_WH_INVENTORY_FAILURE, err));
	}
}

function getLinkDowload(action: IAction) {
	const { payload } = action;
	const _fileName = payload.params.file_name.split("/").pop();
	window.open(`${API_URL}/${API_DOWNLOAD}?file=${_fileName}`, "_parent");
}

const exportSagas = [
	takeLeading(exportTypes.START_EXPORT_TEMPLATE, getExportTemplate),
	takeLeading(exportTypes.START_EXPORT_IDENTIFIER_TEMPLATE, getExportIdentifierTemplate),
	takeLeading(exportTypes.START_EXPORT_REPORT_ONTIME, exportReportOntime),
	takeLeading(exportTypes.START_EXPORT_REPORT_ONTIME_SYNTHESIZE, exportReportOntimeSynthesize),
	takeLeading(exportTypes.START_EXPORT_REPORT_PERFORMANCE, exportReportPerformance),
	takeLeading(exportTypes.START_EXPORT_LIST_TRIP, exportListTrip),
	takeLeading(exportTypes.START_EXPORT_REPORT_PRODUCTIVITY, exportReportProductivity),
	takeLeading(exportTypes.START_EXPORT_REPORT_PRODUCTIVITY_DETAILS, exportReportProductivityDetails),
	takeLeading(exportTypes.START_EXPORT_REPORT_USER, exportReportUser),
	takeLeading(exportTypes.START_LINK_DOWNLOAD, getLinkDowload),
	// takeLeading(exportTypes.START_EXPORT_REPORT_PROBLEMS, exportReportProblems),
	takeLeading(exportTypes.START_EXPORT_REPORT_EXPORT, exportReportExport),
	// takeLeading(exportTypes.START_EXPORT_REPORT_EVALUATION_WH, exportReportEvaluationWH),
	// takeLeading(exportTypes.START_EXPORT_REPORT_EVALUATION_DRIVER, exportReportEvaluationDriver),
	takeLeading(exportTypes.START_EXPORT_WH_INVENTORY, exportWarehouseInventory),


];

export default exportSagas;
