const driversTypes = {

    START_GET_DRIVERS: 'START_GET_DRIVERS',
    GET_DRIVERS_SUCCESS: 'GET_DRIVERS_SUCCESS',
    GET_DRIVERS_FAILURE: 'GET_DRIVERS_FAILURE',
  
    START_GET_DRIVER_DETAIL: 'START_GET_DRIVER_DETAIL',
    GET_DRIVER_DETAIL_SUCCESS: 'GET_DRIVER_DETAIL_SUCCESS',
    GET_DRIVER_DETAIL_FAILURE: 'GET_DRIVER_DETAIL_FAILURE',
  }
  
  export default driversTypes;