const pageHeaderType = {

    START_GET_PAGE_HEADER: 'START_GET_PAGE_HEADER',
    GET_PAGE_HEADER_SUCCESS: 'GET_PAGE_HEADER_SUCCESS',
    GET_PAGE_HEADER_FAILURE: 'GET_LPAGE_HEADER_FAILURE',
  
    START_UPDATE_PAGE_HEADER: 'START_UPDATE_PAGE_HEADER',
    UPDATE_PAGE_HEADER_SUCCESS: 'UPDATE_PAGE_HEADER_SUCCESS',
    UPDATE_PAGE_HEADER_FAILURE: 'UPDATE_PAGE_HEADER_FAILURE',
  
  }
  
  export default pageHeaderType;