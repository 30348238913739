const routesTypes = {

    START_GET_ROUTES: 'START_GET_ROUTES',
    GET_ROUTES_SUCCESS: 'GET_ROUTES_SUCCESS',
    GET_ROUTES_FAILURE: 'GET_ROUTES_FAILURE',

    START_GET_ROUTE_DETAIL: 'START_GET_ROUTE_DETAIL',
    GET_ROUTE_DETAIL_SUCCESS: 'GET_ROUTE_DETAIL_SUCCESS',
    GET_ROUTE_DETAIL_FAILURE: 'GET_ROUTE_DETAIL_FAILURE',  
  }
  
  export default routesTypes;