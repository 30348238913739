import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { IVehicleTypeState } from '../interfaces/vehicleType.interfaces';
import vehicleTypeTypes from '../actions/types/vehicleType.types';

const initState: IVehicleTypeState = {
  stateGetVehicleType: { ...rootState },
  stateCreateVehicleType: { ...rootState },
  stateUpdateVehicleType: { ...rootState },
  stateGetVehicleTypeDetail: {...rootState},
};

const vehicleTypeReducer: Reducer<IVehicleTypeState, ISyntheticAction>
  = (state: IVehicleTypeState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET_ALL_VEHICLE_TYPE                   */

      case vehicleTypeTypes.START_GET_VEHICLE_TYPE: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateGetVehicleType: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.GET_VEHICLE_TYPE_SUCCESS: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateGetVehicleType: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.GET_VEHICLE_TYPE_FAILURE: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateGetVehicleType: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      

      /*************************** START *************************/
      /*                  GET_VEHICLE_TYPE_DETAIL                */

      case vehicleTypeTypes.START_GET_VEHICLE_TYPE_DETAIL: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateGetVehicleTypeDetail: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.GET_VEHICLE_TYPE_DETAIL_SUCCESS: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateGetVehicleTypeDetail: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.GET_VEHICLE_TYPE_DETAIL_FAILURE: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateGetVehicleTypeDetail: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 CREATE_VEHICLE_GROUP                   */

      case vehicleTypeTypes.START_CREATE_VEHECLE_TYPE: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateCreateVehicleType: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.CREATE_VEHECLE_TYPE_SUCCESS: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateCreateVehicleType: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.CREATE_VEHECLE_TYPE_FAILURE: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateCreateVehicleType: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 UPDATE_VEHICLE_GROUP                   */

      case vehicleTypeTypes.START_UPDATE_VEHICLE_TYPE: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateUpdateVehicleType: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.UPDATE_VEHICLE_TYPE_SUCCESS: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateUpdateVehicleType: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleTypeTypes.UPDATE_VEHICLE_TYPE_FAILURE: {
        const stateReducer: IVehicleTypeState = {
          ...state,
          stateUpdateVehicleType: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default vehicleTypeReducer;