import * as React from "react"

const SvgIcon00194 = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill="#fff"
      stroke="#808A94"
    />
    <path
      d="m18.61 13.925-1.194-.935c.015-.144.028-.312.028-.49 0-.179-.013-.347-.028-.491l1.195-.935a.586.586 0 0 0 .142-.747l-1.243-2.15a.576.576 0 0 0-.719-.258l-1.41.566a4.836 4.836 0 0 0-.846-.491l-.215-1.496A.575.575 0 0 0 13.746 6h-2.491a.574.574 0 0 0-.574.494l-.214 1.501c-.284.128-.563.29-.845.491L8.208 7.92a.588.588 0 0 0-.714.254L6.25 10.325a.582.582 0 0 0 .141.75l1.195.935a4.714 4.714 0 0 0-.028.49c0 .149.009.308.028.49l-1.196.936a.586.586 0 0 0-.14.746l1.242 2.15c.132.242.42.368.718.258l1.41-.566c.27.195.554.36.847.492l.214 1.495c.035.284.282.499.574.499h2.492a.574.574 0 0 0 .573-.494l.216-1.5c.283-.129.562-.29.845-.492l1.413.567a.572.572 0 0 0 .713-.253l1.249-2.161a.585.585 0 0 0-.146-.742Zm-6.11.742a2.169 2.169 0 0 1-2.166-2.167c0-1.195.972-2.167 2.167-2.167 1.194 0 2.166.972 2.166 2.167a2.169 2.169 0 0 1-2.166 2.167Z"
      fill={props.fill?props.fill:"#000"}
    />
  </svg>
)

export default SvgIcon00194
