import * as React from "react"

const SvgConfigRoute = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
<rect x="0.5" y="0.5" width="24" height="24" rx="4.5" fill="white" stroke="#808A94"/>
<path d="M5.2475 15.0703C5.27756 17.7701 7.32699 19.9935 9.95332 20.2981C10.1095 20.3123 10.2677 20.32 10.4276 20.32C13.2889 20.32 15.6085 18.0004 15.6085 15.1391C15.6085 11.5125 10.4276 6.3316 10.4276 6.3316C10.4276 6.3316 5.31197 11.4474 5.2475 15.0703ZM12.2794 13.0189L11.3535 13.0189C11.3535 12.5935 11.0074 12.2473 10.5819 12.2473H10.0676C9.75561 12.2473 9.50173 12.5012 9.50173 12.8132C9.5018 12.9227 9.53357 13.0298 9.59321 13.1215C9.65285 13.2133 9.73781 13.2858 9.8378 13.3304L11.3935 14.0218C11.9317 14.261 12.2794 14.7961 12.2794 15.385C12.2794 16.173 11.6653 16.8197 10.8906 16.8729V17.8027H9.96467V16.8691C9.18597 16.7914 8.57584 16.1325 8.57584 15.3337H9.50173C9.50173 15.674 9.77863 15.9509 10.119 15.9509H10.7876C11.0996 15.9509 11.3535 15.697 11.3535 15.385C11.3534 15.2756 11.3217 15.1685 11.262 15.0767C11.2024 14.9849 11.1174 14.9124 11.0174 14.8679L9.46176 14.1764C8.92357 13.9373 8.57584 13.4022 8.57584 12.8132C8.57584 12.0253 9.18998 11.3785 9.96467 11.3254V10.3956H10.8906V11.3501C11.6796 11.4957 12.2794 12.1884 12.2794 13.0189Z" fill="black"/>
<path d="M15.7818 5.00733L15.4544 4.67999L15.1271 5.00733C15.068 5.06646 13.9287 6.21222 12.8852 7.69924C13.1672 8.04422 13.4642 8.42214 13.7621 8.82426C15.6016 11.3077 16.5344 13.4323 16.5344 15.1391C16.5344 15.4399 16.5126 15.7375 16.4698 16.0308C18.3513 15.5733 19.7524 13.8744 19.7524 11.8542C19.7524 9.01061 15.9439 5.16945 15.7818 5.00733Z" fill="black"/>
</svg>
)

export default SvgConfigRoute