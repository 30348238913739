import { Reducer } from "redux";
import routesTypes from "../actions/types/routes.types";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { IRoutesState } from "../interfaces/routes.interfaces";
import { rootState } from "./state/root.states";

const initState: IRoutesState = {
	stateGetRoutes: { ...rootState },
	stateGetRoutesDetail: { ...rootState },
};

const routesReducer: Reducer<IRoutesState, ISyntheticAction> = (
	state: IRoutesState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case routesTypes.START_GET_ROUTES: {
			const stateReducer: IRoutesState = {
				...state,
				stateGetRoutes: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case routesTypes.GET_ROUTES_SUCCESS: {
			const stateReducer: IRoutesState = {
				...state,
				stateGetRoutes: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case routesTypes.GET_ROUTES_FAILURE: {
			const stateReducer: IRoutesState = {
				...state,
				stateGetRoutes: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/
		case routesTypes.START_GET_ROUTE_DETAIL: {
			const stateReducer: IRoutesState = {
				...state,
				stateGetRoutesDetail: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case routesTypes.GET_ROUTE_DETAIL_SUCCESS: {
			const stateReducer: IRoutesState = {
				...state,
				stateGetRoutesDetail: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case routesTypes.GET_ROUTE_DETAIL_FAILURE: {
			const stateReducer: IRoutesState = {
				...state,
				stateGetRoutesDetail: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		default:
			return state;
	}
};

export default routesReducer;
