/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import { useLocation, useParams } from "react-router-dom";
import FormSelect from "src/components/form/FormSelect";
import { Row, Col, Input, Form, Button, Select, InputNumber, Divider, Card } from "antd";
import { localGetAccount } from "src/utils/localStorage";
import moment from "moment";
import { WH_OPERATION_TYPE } from "src/utils/helpers/functions/data";
import TagStatusWH from "src/components/TagStatusWH/tagStatusWH";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/types";
import { getGoodTypes, getProvinces } from "src/services/actions/global.actions";
import { currencyFormat } from "src/utils/helpers/functions/utils";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const CalculatePrice = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const urlParams = new URLSearchParams(window.location.search);
	const type_operation = urlParams.get("type");
	const { id } = useParams<any>();
	const [data, setData] = useState<any>([]);
	const [visible, setVisible] = useState<any>(false);
	const [user, setUser] = useState<any>(false);
	const [params, setParams] = useState<any>({
		page: 1,
		limit: 10
	});
	const [loading, setLoading] = useState<any>(false);
	const [total, setToal] = useState<number>(0);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [districts, setDistricts] = useState<any[]>([]);
	const [districtsReceiver, setDistrictsReceiver] = useState<any[]>([]);
	const [dataBills, setDataBills] = useState<any>([]);
	const [scanBill, setScanBill] = useState<any>([]);
	const [fees, setFees] = useState<any>({});
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		dispatch(getProvinces());
		dispatch(getGoodTypes());
	}, []);

	const getDetailedOperationWH = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getDetailedOperationWH(id, params);
			console.log("res", res);
			form.setFieldsValue(res?.data);
			setDataBills(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onChangeProvince = async (e: any, type: any = "sender") => {
		const obj =
			type == "sender"
				? {
						s_district_id: undefined
				  }
				: {
						r_district_id: undefined
				  };
		form.setFieldsValue(obj);
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			type == "sender" ? setDistricts(district.data) : setDistrictsReceiver(district.data);
		}
	};
	const handleSubmit = async (values: any) => {
		const { weight, length, width, height, goodsValue } = values;
		setLoading(true);
		try {
			values.service_id = 1;
			const completeRes: any = await _apiNotSaga.calculatePrice(values);
			if (!completeRes.success) {
				setFees(null);
				return notifyError(completeRes.message);
			}
			setFees(completeRes.data);
			notifySuccess("Tính giá thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Loading spinning={loading}>
			<Form form={form} layout="vertical" className="space-y-4" onFinish={handleSubmit}>
				<div className="contentSection">
					<div className="contentSection-main">
						<h3 style={{ fontWeight: 600 }}>Nhập thông tin bưu kiện</h3>
						<Row gutter={[12, 12]}>
							<Col md={6} xs={6} sm={6}>
								<FormSelect
									name="s_province_id"
									label="Từ tỉnh"
									dataSource={stateProvinces?.data || []}
									placeholder="Chọn tỉnh thành"
									slOption={{
										name: "province_name",
										value: "id"
									}}
									required={true}
									// disabled={disableAddress}
									onChange={(e) => onChangeProvince(e)}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<FormSelect
									name="s_district_id"
									label="Từ quận"
									dataSource={districts}
									placeholder="Chọn quận huyện"
									slOption={{
										name: "district_name",
										value: "id"
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<FormSelect
									name="r_province_id"
									label="Đến tỉnh"
									dataSource={stateProvinces?.data || []}
									placeholder="Chọn tỉnh thành"
									slOption={{
										name: "province_name",
										value: "id"
									}}
									required={true}
									// disabled={disableAddress}
									onChange={(e) => onChangeProvince(e, "receiver")}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<FormSelect
									name="r_district_id"
									label="Đến huyện"
									dataSource={districtsReceiver}
									placeholder="Chọn quận huyện"
									slOption={{
										name: "district_name",
										value: "id"
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
						</Row>

						<Row gutter={[12, 12]}>
							<Col md={6} xs={6} sm={6}>
								<Form.Item
									label="Trọng lượng (kg)"
									name="weight"
									rules={[{ required: true, message: "Vui lòng nhập số kg" }]}
								>
									<InputNumber placeholder="Nhập số kg" min={0} style={{ width: "100%" }} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<FormSelect
									name="goods_type_id"
									label="Loại hàng hóa"
									dataSource={stateGoodTypes?.data || []}
									placeholder="Chọn loại hàng hóa"
									slOption={{
										name: "gst_name",
										value: "id"
									}}
									required={true}
									// disabled={disableAddress}
									onChange={(e) => {
										form.setFieldsValue({
											goods_type_name: (stateGoodTypes?.data || []).find((item: any) => item.id === e)?.gst_name
										});
									}}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Số kiện" name="package_number">
									<InputNumber placeholder="Số kiện" min={0} style={{ width: "100%" }} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Giá trị hàng hóa (VND)" name="cargo_amt">
									<InputNumber placeholder="Nhập giá trị hàng hóa" min={0} style={{ width: "100%" }} />
								</Form.Item>
							</Col>
							<Col md={18} xs={18} sm={18}></Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item>
									<Button
										id="filter_button_onSearch"
										size="large"
										block={true}
										htmlType="submit"
										className="bg-[#001529] flex tms-btn-search justify-center rounded-lg items-center text-[#FFFFFF]"
									>
										{"Tính giá"}
									</Button>
								</Form.Item>
							</Col>
						</Row>
						<Divider></Divider>
						{fees && (
							<Row gutter={16}>
								<Col span={6}>
									<Card title="Cước chính (Vnđ)" bordered={false}>
										<b>{currencyFormat(fees.main_fee)}</b>
									</Card>
								</Col>
								<Col span={6}>
									<Card title="Phí NTX (Vnđ)" bordered={false}>
									<b>{currencyFormat(fees.remote_fee)}</b>
									</Card>
								</Col>
								<Col span={6}>
									<Card title="Phí bảo hiểm (Vnđ)" bordered={false}>
									<b>{currencyFormat(fees.insurance_fee)}</b>
									</Card>
								</Col>
								<Col span={6}>
									<Card title="Tổng cước (Vnđ)" bordered={false}>
									<b>{currencyFormat(fees.total_fee)}</b>
									</Card>
								</Col>
							</Row>
						)}
						{/* <Row gutter={16}>
							<Col span={8}>
								<Form.Item label="Dài (cm)" name="length">
									<InputNumber placeholder="Nhập chiều dài" min={0} style={{ width: "100%" }} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Rộng (cm)" name="width">
									<InputNumber placeholder="Nhập chiều rộng" min={0} style={{ width: "100%" }} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Cao (cm)" name="height">
									<InputNumber placeholder="Nhập chiều cao" min={0} style={{ width: "100%" }} />
								</Form.Item>
							</Col>
						</Row> */}
					</div>
				</div>
			</Form>
		</Loading>
	);
};

export default CalculatePrice;
