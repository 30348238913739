/* eslint-disable */
import { Reducer } from "redux";
import { rootState } from "./state/root.states";
import { ISyntheticAction } from "../interfaces/root.interfaces";
import { IExportState } from "../interfaces/export.interfaces";
import exportTypes from "../actions/types/export.types";

const initState: IExportState = {
  stateExportTemplate: { ...rootState },
  stateLinkDown: { ...rootState },
  stateExportReportOntime: { ...rootState },
  stateExportReportOntimeSynthesize: { ...rootState },
  stateExportReportPerformances: {...rootState},
  stateExportReportProductivity:{...rootState},
  stateExportReportProductivityDetails:{...rootState},
  stateExportReportUser: { ...rootState },
  stateExportListTrip: { ...rootState },
  stateExportReportProblems: { ...rootState },
  stateExportReportExport: { ...rootState },
  stateExportReportEvaluationWh: { ...rootState },
  stateExportReportEvaluationDriver: { ...rootState },
  stateExportWarehouseInventory: { ...rootState },
};

const exportReducer: Reducer<IExportState, ISyntheticAction> = (
  state: IExportState = initState,
  action: ISyntheticAction
) => {
  const { type, response } = action;

  switch (type) {
    /*************************** START *************************/
    /*                        GET TEMPLATE                   */

    case exportTypes.START_EXPORT_TEMPLATE: {
      const stateReducer: IExportState = {
        ...state,
        stateExportTemplate: {
          ...rootState,
          isLoading: true,
        },
      };
      return stateReducer;
    }
    case exportTypes.GET_EXPORT_TEMPLATE_SUCCESS: {
      const stateReducer: IExportState = {
        ...state,
        stateExportTemplate: {
          ...rootState,
          isLoading: false,
          data: [],
          message: "Thành công",
          success: true,
        },
      };
      return stateReducer;
    }
    case exportTypes.GET_EXPORT_TEMPLATE_FAILURE: {
      const stateReducer: IExportState = {
        ...state,
        stateExportTemplate: {
          ...rootState,
          isLoading: false,
          message: response.message,
          error: true,
        },
      };
      return stateReducer;
    }

    /**************************** END **************************/

    case exportTypes.START_EXPORT_IDENTIFIER_TEMPLATE: {
      const stateReducer: IExportState = {
        ...state,
        stateExportTemplate: {
          ...rootState,
          isLoading: true,
        },
      };
      return stateReducer;
    }
    case exportTypes.GET_EXPORT_IDENTIFIER_TEMPLATE_SUCCESS: {
      const stateReducer: IExportState = {
        ...state,
        stateExportTemplate: {
          ...rootState,
          isLoading: false,
          data: [],
          message: "Thành công",
          success: true,
        },
      };
      return stateReducer;
    }
    case exportTypes.GET_EXPORT_IDENTIFIER_TEMPLATE_SUCCESS: {
      const stateReducer: IExportState = {
        ...state,
        stateExportTemplate: {
          ...rootState,
          isLoading: false,
          message: response.message,
          error: true,
        },
      };
      return stateReducer;
    }


    /*************************** START *************************/
    /*                        GET LINK DOWNLOAD                   */

    case exportTypes.START_LINK_DOWNLOAD: {
      const stateReducer: IExportState = {
        ...state,
        stateLinkDown: {
          ...rootState,
          isLoading: true,
        },
      };
      return stateReducer;
    }
    case exportTypes.GET_LINK_DOWNLOAD_SUCCESS: {
      const stateReducer: IExportState = {
        ...state,
        stateLinkDown: {
          ...rootState,
          isLoading: false,
          data: [],
          message: "Thành công",
          success: true,
        },
      };
      return stateReducer;
    }
    case exportTypes.GET_LINK_DOWNLOAD_FAILURE: {
      const stateReducer: IExportState = {
        ...state,
        stateLinkDown: {
          ...rootState,
          isLoading: false,
          message: response.message,
          error: true,
        },
      };
      return stateReducer;
    }

    /**************************** END **************************/
    /*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_ONTIME: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportOntime: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_ONTIME_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportOntime: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_ONTIME_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportOntime: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
		/*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_ONTIME_SYNTHESIZE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportOntimeSynthesize: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_ONTIME_SYNTHESIZE_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportOntimeSynthesize: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_ONTIME_SYNTHESIZE_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportOntimeSynthesize: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/

		 /*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_PERFORMANCE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportPerformances: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PERFORMANCE_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportPerformances: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PERFORMANCE_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportPerformances: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
    /*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_LIST_TRIP: {
			const stateReducer: IExportState = {
			...state,
			stateExportListTrip: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_LIST_TRIP_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportListTrip: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_LIST_TRIP_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportListTrip: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
      /*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_PRODUCTIVITY: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProductivity: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PRODUCTIVITY_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProductivity: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PRODUCTIVITY_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProductivity: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/

		  /*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_PRODUCTIVITY_DETAILS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProductivityDetails: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PRODUCTIVITY_DETAILS_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProductivityDetails: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PRODUCTIVITY_DETAILS_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProductivityDetails: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
		/*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_PROBLEMS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProblems: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PROBLEMS_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProblems: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_PROBLEMS_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportProblems: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
		/*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_EXPORT: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportExport: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_EXPORT_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportExport: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_EXPORT_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportExport: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
    /*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_USER: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportUser: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_USER_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportUser: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_USER_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportUser: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/

		/*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_EVALUATION_WH: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportEvaluationWh: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_EVALUATION_WH_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportEvaluationWh: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_EVALUATION_WH_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportEvaluationWh: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
			/*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_REPORT_EVALUATION_DRIVER: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportEvaluationDriver: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_EVALUATION_DRIVER_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportEvaluationDriver: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_REPORT_EVALUATION_DRIVER_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportReportEvaluationDriver: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/
		/*************************** START *************************/
		/*                        EXPORT REPORT                   */

		case exportTypes.START_EXPORT_WH_INVENTORY: {
			const stateReducer: IExportState = {
			...state,
			stateExportWarehouseInventory: {
				...rootState,
				isLoading: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_WH_INVENTORY_SUCCESS: {
			const stateReducer: IExportState = {
			...state,
			stateExportWarehouseInventory: {
				...rootState,
				isLoading: false,
				data: [],
				message: "Thành công",
				success: true,
			},
			};
			return stateReducer;
		}
		case exportTypes.EXPORT_WH_INVENTORY_FAILURE: {
			const stateReducer: IExportState = {
			...state,
			stateExportWarehouseInventory: {
				...rootState,
				isLoading: false,
				message: response.message,
				error: true,
			},
			};
			return stateReducer;
		}
	
		/**************************** END **************************/

    default:
      return state;
  }
};

export default exportReducer;
