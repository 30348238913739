import CONFIG_ICON from "./configIcon";
import { typeIconUI } from "./typesIcon";

const renderIcon = (icon: typeIconUI, fill?: string, fillbg?: string) => {
  return CONFIG_ICON(icon, fill, fillbg)[icon];
};

export function IconUI(
  this: any,
  data: { icon: typeIconUI, fill?: string, fillbg?: string }
) {
  this.icon = data.icon;
  this.fill = data.fill;
  this.fillbg = data.fillbg;
  this.renderIcon = function () {
    let _icon: typeIconUI = this?.icon || 'default';
    let _fill: string | undefined = this?.fill || undefined;
    let _fillbg: string | undefined = this?.fillbg || undefined;
    
    return renderIcon(_icon, _fill, _fillbg);
  }
}