import { IActionResponse, ICatchError, IRootResponse, IStateResponse } from '../interfaces/root.interfaces';
const statusCodeSuccess = [200, 201];

export const actionSuccess = (type: string, res: IRootResponse) => {
  
  const result: IStateResponse = {
      data: res.data,
      message: res.message,
      success: res.success
    };
  const action: IActionResponse = {
    type,
    response: result
  }
  return action;
}

export const actionSuccessPageHeader = (type: string, res: IRootResponse) => {
  
  const result: IStateResponse = {
      data: res,
      message: null,
      success: true
    };
    
    
  const action: IActionResponse = {
    type,
    response: result
  }
  return action;
}


export const actionFailure = (type: string, err: ICatchError) => {
  const result: IStateResponse = {
    message: err.message,
    success: false
  };
  const action: IActionResponse = {
    type,
    response: result
  }
  return action;
}