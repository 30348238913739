import { Avatar, Button, Dropdown, Menu, Popconfirm, Popover, Progress, Switch, Tag } from "antd";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgIcon00060 from "src/assets/svg/SvgIcon00060";
import SvgIcon00061 from "src/assets/svg/SvgIcon00061";
import SvgIcon00139 from "src/assets/svg/SvgIcon00139";
import SvgIcon00178 from "src/assets/svg/SvgIcon00178";
import { renderCell } from "src/components/commonRender/renderData";
import DIcon from "src/components/icons/DIcon";
import { CND_URL, TICKET_URL } from "src/services/api/config";
import colors from "src/utils/colors";
import { numberWithCommas } from "src/utils/helpers/functions/textUtils";
import "./style.scss";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SvgPen from "src/assets/svg/SvgPen";
import moment from "moment";
import routerNames from "src/utils/data/routerName";
import SvgDel from "src/assets/svg/SvgDel";
import SvgView from "src/assets/svg/SvgView";
import { STATUS_WH_OPERATION_COLOR, STATUS_WH_OPERATION_MAPPING } from "src/constants";
import TagStatusWH from "src/components/TagStatusWH/tagStatusWH";
import SvgPhone from "src/assets/svg/SvgPhone";
import SvgEditBox from "src/assets/svg/SvgEditBox";
import IconSeal from "src/assets/svg/IconSeal";
import IconEdit from "src/assets/svg/IconEdit";
import TagStatus from "src/components/TagStatus/tagStatus";
import TagStatusSeal from "src/components/TagStatus/tagStatusSeal";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({
	updateStatus,
	setItems,
	onEdit,
	onCancel,
	pushUpApproval,
	onCreateTicket,
	getDetail,
	user,
	getDetailSeal
}: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Link to={`/list-profile/${r?.candidate_id}`}>
							<Button>
								<SvgPen fill="black" />
								<span>Chỉnh sửa</span>
							</Button>
						</Link>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Nhân viên",
			align: "left",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="flex flex-col justify-start items-start">
							<div className="text-[#1A90FF] text-opacity-90 text-[13px] font-normal  leading-snug">
								{record?.user_code} - <span className="text-black">{record?.full_name}</span>
							</div>
							<div className="text-[#A6A6A6] text-opacity-90 text-[13px] font-normal  leading-snug">
								Chi nhánh - <span className="text-black">{record?.org_name}</span>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Seal cuối",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								<p className="text-black  text-opacity-90 text-[13px]">{record?.last_seal}</p>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Tổng Seals",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-end items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								<p className="text-black  text-opacity-90 text-[13px]">{record?.total_seal}</p>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Đã sử dụng",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-end items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								<p className="text-black  text-opacity-90 text-[13px]">{record?.used_count}</p>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Còn lại",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-end items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								<p className="text-black  text-opacity-90 text-[13px]">{record?.unused_count}</p>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Ngày phát",
			// align: "right",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px]">
									Ngày tạo: {moment(record?.created_at).format("DD/MM/YYYY")}
								</p>
							</div>
							<div className="flex items-center">
								<p className="text-black  text-opacity-90 text-[13px]">Người tạo: {record?.created_by}</p>
							</div>
						</div>
					</div>
				);
			}
		},
		// {
		// 	title: "TT hoạt động",
		// 	dataIndex: "status",
		// 	key: "status",
		// 	align: "center",
		// 	width: 150,
		// 	render: (v: string, r: any) => (
		// 		<div className="flex justify-center">
		// 			<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status, "status")} />
		// 		</div>
		// 	)
		// },

		{
			title: "Thao tác",
			align: "center",
			fixed: "right",
			width: "13%",
			render: (text: string, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button onClick={() => getDetailSeal(record)} icon={<IconSeal />} />
						<Button onClick={() => getDetail(record)} icon={<IconEdit />} />
						{/* <Link to={`/chi-tiet-xnk/${record?.id}`}>
							<Button icon={<SvgView />}></Button>
						</Link> */}
						{/* <Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter"> */}
						{/* {["C", "D"].includes(record.status) ? <></> : <Button icon={<SvgDel />}></Button>} */}
						{/* </Dropdown> */}
						{/* <Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter">
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown> */}
					</div>
				);
			}
		}
	];
};

export const columnsDataSeal = ({
	getDetail,
}: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Link to={`/list-profile/${r?.candidate_id}`}>
							<Button>
								<SvgPen fill="black" />
								<span>Chỉnh sửa</span>
							</Button>
						</Link>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Mã Seal",
			align: "left",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="flex flex-col justify-start items-start">
							<div className="text-[#1A90FF] text-opacity-90 text-[13px] font-normal  leading-snug">
								{record?.seal_number}
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Trạng thái sử dụng",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
							<TagStatusSeal status={record?.is_used} />
								{/* <p className="text-black  text-opacity-90 text-[13px]">{record?.is_used === 1 ? 'Đã sử dụng': 'Chưa sử dụng'}</p> */}
							</div>
						</div>
					</div>
				);
			}
		},
		
		{
			title: "Ngày phát",
			// align: "right",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px]">
									Ngày tạo: {moment(record?.created_at).format("DD/MM/YYYY")}
								</p>
							</div>
							{/* <div className="flex items-center">
								<p className="text-black  text-opacity-90 text-[13px]">Người tạo: {record?.created_by}</p>
							</div> */}
						</div>
					</div>
				);
			}
		},
		// {
		// 	title: "TT hoạt động",
		// 	dataIndex: "status",
		// 	key: "status",
		// 	align: "center",
		// 	width: 150,
		// 	render: (v: string, r: any) => (
		// 		<div className="flex justify-center">
		// 			<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status, "status")} />
		// 		</div>
		// 	)
		// },

		// {
		// 	title: "Thao tác",
		// 	align: "center",
		// 	fixed: "right",
		// 	width: "13%",
		// 	render: (text: string, record: any, index: number) => {
		// 		return (
		// 			<div style={{ display: "flex", justifyContent: "center" }}>
		// 				<Button onClick={() => getDetailSeal(record)} icon={<IconSeal />} />
		// 				<Button onClick={() => getDetail(record)} icon={<IconEdit />} />
		// 				{/* <Link to={`/chi-tiet-xnk/${record?.id}`}>
		// 					<Button icon={<SvgView />}></Button>
		// 				</Link> */}
		// 				{/* <Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter"> */}
		// 				{/* {["C", "D"].includes(record.status) ? <></> : <Button icon={<SvgDel />}></Button>} */}
		// 				{/* </Dropdown> */}
		// 				{/* <Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter">
		// 					<Button icon={<Svg3Dot />}></Button>
		// 				</Dropdown> */}
		// 			</div>
		// 		);
		// 	}
		// }
	];
};
export const columnsDataApprove = ({
	setOpenModalLoadReport,
	setItems,
	onEdit,
	onCancel,
	pushUpApproval,
	onCreateTicket,
	getDetail,
	user
}: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Link to={`${routerNames.APPROVEDETAILS}/${r?.candidate_id}`}>
							<Button>
								<SvgPen fill="black" />
								<span>Chỉnh sửa</span>
							</Button>
						</Link>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Mã hồ sơ",
			align: "left",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<Link to={`${routerNames.APPROVEDETAILS}/${record?.candidate_id}`}>
							<p className="text-sky-500 text-[13px] font-normal   underline leading-snug">{record?.candidate_code}</p>
						</Link>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal  ">Ngày ứng tuyển:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal  ">
								{moment(record?.created_at).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
						<Tag color={record.status_color}>{record?.candidate_status}</Tag>
					</div>
				);
			}
		},
		{
			title: "Tên ứng viên",
			width: "30%",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<Avatar
							style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }}
							src={record?.portrait_url ? CND_URL + record?.portrait_url : undefined}
							size={60}
						>
							{record?.candidate_name?.charAt(0)}
						</Avatar>
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px] font-bold  ">{record?.candidate_name}</p>
							</div>
							<div>
								<span className="text-black text-opacity-25 text-[13px] font-normal  ">Email:</span>
								<span className="text-black text-opacity-90 text-[13px] font-normal  "> {record?.candidate_email}</span>
							</div>
							<div>
								<span className="text-black text-opacity-25 text-[13px] font-normal  ">Sđt:</span>
								<span className="text-black text-opacity-90 text-[13px] font-normal  "> {record?.candidate_phone}</span>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Vị trí",
			// align: "right",
			render: (record: any, index: number) => {
				return <p className="text-black text-opacity-90 text-[13px] font-normal  ">{record?.position_name}</p>;
			}
		},
		{
			title: "Tin đăng",
			render: (record: any, index: number) => {
				return (
					<Link
						to={`/create-post/${record.recruitment_news_id}`}
						className="text-link text-opacity-90 text-[13px] font-normal  "
					>
						{record?.thread}
					</Link>
				);
			}
		},

		// {
		// 	title: "Đánh giá",
		// 	render: (record: any, index: number) => {
		// 		return <Tag color="processing">{record?.rate}</Tag>;
		// 	}
		// },

		{
			title: "Thao tác",
			align: "center",
			fixed: "right",
			width: "13%",
			render: (text: string, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter">
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
export const columnsTransportTripExpenseData = ({ setOpenModalLoadReport, setItems }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.transport_trip_code}</div>;
			}
		},
		{
			title: "Tuyến",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.routing_road?.routing_road_name}</div>;
			}
		},
		{
			title: "Biển số xe",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.vehicle?.license_plate_short}</div>;
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Ngày bắt đầu",
						content: record?.transport_trip?.start_at || "",
						type: "dateTime"
					},
					{
						title: "Ngày kết thúc",
						content: record?.transport_trip?.end_at || "",
						type: "dateTime"
					}
				]);
			}
		},
		{
			title: "Tài xế phụ trách",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Tài xế chính",
						content: record?.transport_trip?.main_user?.profile?.full_name || ""
					},
					{
						title: "Tài xế phụ",
						content: record?.transport_trip?.sub_user?.profile?.full_name || ""
					}
				]);
			}
		},
		// {
		// 	title: "Lương chuyến (Vnđ)",
		// 	width: "10%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				content: record?.transport_trip_cost || 0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		// {
		// 	title: "Chi phí thưởng / phạt (Vnđ)",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				title: "Thưởng",
		// 				content: record?.transport_bobus || 0,
		// 				type: "money"
		// 			},
		// 			{
		// 				title: "Phạt",
		// 				content: record?.transport_penalty || 0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		{
			title: "Tổng chi phí phát sinh (Vnđ)",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.costs_incurred || 0,
						type: "money"
					}
				]);
			}
		}
	];
};

export const columnsDataRow = ({ setOpenModalLoadReport, setItems, _handleImgBillClick }: any) => {
	return [
		{
			title: "Số HĐ/PT",
			align: "center",
			render: (record: any, index: number) => {
				return <div>{record?.invoice_number || "-"}</div>;
			}
		},
		{
			title: "MST NCC",
			render: (record: any, index: number) => {
				return <div>{record?.tax_code || "-"}</div>;
			}
		},
		{
			title: "Nhóm phí",
			render: (record: any, index: number) => {
				return <div>{record?.cost_category_name}</div>;
			}
		},
		{
			title: "Loại phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Type?.cost_type_name}</div>;
			}
		},
		{
			title: "Chi phí (Vnđ)",
			align: "center",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.amount || 0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Hình ảnh hóa đơn",

			render: (record: any, index: number) => {
				return (
					<div className="grid-container-add">
						<u
							style={{ color: "#2F80ED", cursor: "pointer" }}
							onClick={() => {
								_handleImgBillClick(record?.imgs);
							}}
						>
							Xem ảnh
						</u>
						{/* {record?.imgs &&
							record?.imgs.length > 0 &&
							record?.imgs.map((img: any) => (
								<Image
									src={`${CND_URL}/${img.image_url}`}
									style={{ cursor: "pointer", height: "100px", width: "100px" }}
								/>
							))} */}
					</div>
				);
			}
		}
	];
};
export const columnsDataDetail = () => {
	return [
		{
			title: "Nhân viên",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal  ">
								{record?.updated_by_name}
								<br />
							</span>
							<span className="text-black text-opacity-25 text-[13px] font-normal  ">Chức vụ:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal  "> {record?.role_name}</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return <div>{moment(record?.created_at).format("DD-MM-YYYY HH:mm:mm")}</div>;
			}
		},

		{
			title: "Nội dung cập nhật",
			render: (record: any, index: number) => {
				return (
					<div>
						{record?.action_name} : {record?.candidate_status}
					</div>
				);
			}
		}
	];
};
export const columnsDataDetailEdit = ({ setOpenModalLoadReport, setItems, onDelete }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.transport_trip_code}</div>;
			}
		},
		{
			title: "Tuyến",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.routing_road?.routing_road_name}</div>;
			}
		},
		{
			title: "Biển số xe",
			width: "7%",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.vehicle?.license_plate_short}</div>;
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Ngày bắt đầu",
						content: record?.transportTrip?.start_at || "",
						type: "dateTime"
					},
					{
						title: "Ngày kết thúc",
						content: record?.transportTrip?.end_at || "",
						type: "dateTime"
					}
				]);
			}
		},
		{
			title: "Tài xế phụ trách",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Tài xế chính",
						content: record?.transportTrip?.main_user?.profile?.full_name || ""
					},
					{
						title: "Tài xế phụ",
						content: record?.transportTrip?.sub_user?.profile?.full_name || ""
					}
				]);
			}
		},
		// {
		// 	title: "Lương chuyến (Vnđ)",
		// 	width: "10%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_trip_cost) ||
		// 					0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		// {
		// 	title: "Chi phí thưởng / phạt (Vnđ)",
		// 	width: "13%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				title: "Thưởng",
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_bobus) ||
		// 					0,
		// 				type: "money"
		// 			},
		// 			{
		// 				title: "Phạt",
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense?.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_penalty) ||
		// 					0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		{
			title: "Tổng chi phí phát sinh (Vnđ)",
			width: "13%",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content:
							(record?.transportTrip?.expense &&
								record?.transportTrip?.expense?.length > 0 &&
								record?.transportTrip?.expense[0]?.costs_incurred) ||
							0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Thao tác",
			dataIndex: "tt",
			key: "action",
			align: "center",
			fixed: "right",
			width: 120,
			render: (text: string, record: any, index: number) => {
				return (
					<div>
						<Popconfirm
							title="Bạn có muốn xóa hay không?"
							style={{ borderRadius: "10px" }}
							okButtonProps={{
								style: {
									background: "black",
									color: "#fdd800"
								}
							}}
							cancelButtonProps={{
								style: {
									background: "#dfe2e4",
									color: "black"
								}
							}}
							onConfirm={() => {
								onDelete(record.id);
							}}
							okText="Đồng ý"
							cancelText="Hủy"
						>
							<DIcon
								// onClick={() => {
								// 	onDelete(record.id);
								// }}
								icon="recycle_bin"
								className="flex align-center justify-center"
							/>
						</Popconfirm>
					</div>
				);
			}
		}
	];
};
export const columnsCostIncurred = ({ setOpenModalLoadReport, setItems, _handleImgBillClick }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.Transport_Trip?.transport_trip_code || "-"}</div>;
			}
		},
		{
			title: "Chi phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Category?.cost_name || "-"}</div>;
			}
		},
		{
			title: "Loại chi phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Type?.cost_type_name || "-"}</div>;
			}
		},
		{
			title: "Mã số thuế",
			render: (record: any, index: number) => {
				return <div>{record?.tax_code || "-"}</div>;
			}
		},
		{
			title: "Số hóa đơn",
			render: (record: any, index: number) => {
				return <div>{record?.invoice_number || "-"}</div>;
			}
		},
		{
			title: "Chi phí phát sinh (Vnđ)",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.amount || 0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Hình ảnh hóa đơn",
			render: (record: any, index: number) => {
				return (
					<div className="grid-container">
						<u
							style={{ color: "#2F80ED", cursor: "pointer" }}
							onClick={() => {
								_handleImgBillClick(record?.imgs);
							}}
						>
							Xem ảnh
						</u>

						{/* {record?.imgs &&
							record?.imgs.length > 0 &&
							record?.imgs.map((img: any) => (
								<Image
									src={`${CND_URL}/${img.image_url}`}
									style={{ cursor: "pointer", height: "100px", width: "100px" }}
								/>
							))} */}
					</div>
				);
			}
		}
	];
};
export const _paramsFilter: any = {
	q: undefined,
	status: null,
	created_at_start: undefined,
	created_at_end: undefined,
	page: 1,
	limit: 10
};
