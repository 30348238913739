const globalTypes = {
  CHANGE_THEME: 'CHANGE_THEME',
  OTHER_ACTION: 'OTHER_ACTION',
  START_PUT_SIGN_IN_APP: 'START_PUT_SIGN_IN_APP',
  PUT_SIGN_IN_APP_SUCCESS: 'PUT_SIGN_IN_APP_SUCCESS',
  START_GET_ENUMS: 'START_GET_ENUMS',
  GET_ENUMS_SUCCESS: 'GET_ENUMS_SUCCESS',
  GET_ENUMS_FAILURE: 'GET_ENUMS_FAILURE',
  START_GET_PROVINCES: 'START_GET_PROVINCES',
  START_PUT_ACTIONS: 'START_PUT_ACTIONS',
  PUT_ACTIONS_SUCCESS: 'PUT_ACTIONS_SUCCESS',
  GET_PROVINCES_SUCCESS: 'GET_PROVINCES_SUCCESS',
  GET_PROVINCES_FAILURE: 'GET_PROVINCES_FAILURE',

  START_GET_SERVICES: 'START_GET_SERVICES',
  GET_SERVICES_SUCCESS: 'GET_SERVICES_SUCCESS',
  GET_SERVICES_FAILURE: 'GET_SERVICES_FAILURE',

  START_GET_GOOD_TYPES: 'START_GET_GOOD_TYPES',
  GET_GOOD_TYPES_SUCCESS: 'GET_GOOD_TYPES_SUCCESS',
  GET_GOOD_TYPES_FAILURE: 'GET_GOOD_TYPES_FAILURE',
  
  START_GET_PAYMENT_METHODS: 'START_GET_PAYMENT_METHODS',
  GET_PAYMENT_METHODS_SUCCESS: 'GET_PAYMENT_METHODS_SUCCESS',
  GET_PAYMENT_METHODS_FAILURE: 'GET_PAYMENT_METHODS_FAILURE',

  START_GET_WAREHOUSES: 'START_GET_WAREHOUSES',
  GET_WAREHOUSES_SUCCESS: 'GET_WAREHOUSES_SUCCESS',
  GET_WAREHOUSES_FAILURE: 'GET_WAREHOUSES_FAILURE',
}

export default globalTypes;