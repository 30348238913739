function SvgIcon00003(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
			<path
				fill="#001529"
				d="M16.246 12.618L24 17.52V7.509l-7.754 5.109zM1 7.509v10.01l7.754-4.901L1 7.509zM22.563 4.594H2.438c-.718 0-1.287.534-1.395 1.223L12.5 13.365l11.457-7.548c-.108-.689-.677-1.223-1.395-1.223zM14.93 13.486l-2.035 1.34a.718.718 0 01-.79 0l-2.034-1.341-9.025 5.708c.11.683.677 1.213 1.392 1.213h20.125c.714 0 1.28-.53 1.391-1.213l-9.024-5.707z"
			></path>
		</svg>
	);
}

export default SvgIcon00003;
