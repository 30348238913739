import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Popover, Switch, Table, TableColumnsType } from "antd";
import { SortableHandle } from "react-sortable-hoc";
import Svg00114 from "src/assets/svg/Svg00114";
import DIcon from "src/components/icons/DIcon";
import TableStyled from "src/components/table/TableStyled";
import { API_URL_CDN, CND_URL } from "src/services/api/config";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
interface ExpandedDataType {
	key: React.Key;
	funct_name: string;
	funct_code: string;
	api_route: string;
	ui_route: string;
	ui_icon: string;
	status: boolean;
}
const DragHandle = SortableHandle(({ active }: any) => <Svg00114 />);

export const columnsData = ({
	onViewFunctDetail,
	onAddActions,
	onOpenImageOverlay,
	handleChangeStatusWebsite,
	onChangeStatus
}: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => onViewFunctDetail(r)}>
							<EditOutlined />
							<span>Chỉnh sửa</span>
						</Button>
					)
				},
				{
					key: "confirm",
					label: r.level === 1 && (
						<Button onClick={() => onAddActions(r)}>
							<PlusOutlined />
							<span>Thêm action </span>
						</Button>
					)
				}
			]}
		/>
	);
	// const items = (record: any) => [
	// 	{
	// 		key: "detail",
	// 		label: (
	// 			<Button onClick={() => onViewFunctDetail(record)}>
	// 				<EditOutlined />
	// 				<span>Chỉnh sửa</span>
	// 			</Button>
	// 		)
	// 	},
	// 	{
	// 		key: "confirm",
	// 		label: record.level === 1 && (
	// 			<Button onClick={() => onAddActions(record)}>
	// 				<PlusOutlined />
	// 				<span>Thêm action </span>
	// 			</Button>
	// 		)
	// 	}
	// ];
	return [
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: "4%",
			align: "center",

			render: (index: string, record: any, i: number) =>
				record.level <= 2 ? (
					<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<DragHandle />{" "}
					</div>
				) : null
		},

		{
			title: "",
			dataIndex: "",
			key: "",
			width: "4%",
			align: "center"
		},
		Table.EXPAND_COLUMN,
		{
			title: "Display name",
			dataIndex: "funct_name",
			key: "funct_name",
			width: "16%",
			render: (funct_name: string, record: any, index: number) => {
				return <div>{funct_name}</div>;
			}
		},
		{
			title: "Function code",
			dataIndex: "funct_code",
			key: "funct_code",
			width: "14%",
			render: (funct_code: string, record: any, index: number) => {
				return <div>{funct_code}</div>;
			}
		},
		{
			title: "API Route",
			dataIndex: "api_route",
			key: "api_route",
			width: "14%",
			render: (api_route: string, record: any, index: number) => {
				return <div>{api_route}</div>;
			}
		},
		{
			title: "UI Route",
			dataIndex: "web_route",
			key: "web_route",
			width: "15%",
			render: (web_route: string, record: any, index: number) => {
				return <div>{web_route}</div>;
			}
		},
		// {
		// 	title: "Icon",
		// 	dataIndex: "ui_icon",
		// 	key: "ui_icon",
		// 	width: "5%",
		// 	render: (ui_icon: string, record: any, index: number) => {
		// 		return (
		// 			<div style={{ textAlign: "center" }}>
		// 				{record.level !== 2 && ui_icon !== "undefined" && ui_icon !== "" ? (
		// 					<img
		// 						src={`${CND_URL}/${ui_icon}`}
		// 						alt=""
		// 						style={{ maxWidth: "36px", objectFit: "cover", cursor: "pointer" }}
		// 						onClick={() => onOpenImageOverlay(ui_icon)}
		// 					/>
		// 				) : null}
		// 			</div>
		// 		);
		// 	}
		// },
		// {
		// 	title: "Mobile Route",
		// 	dataIndex: "mobile_route",
		// 	key: "mobile_route",
		// 	width: "15%",
		// 	render: (mobile_route: string, record: any, index: number) => {
		// 		return <div>{mobile_route}</div>;
		// 	}
		// },
		// {
		// 	title: "Mobile Icon",
		// 	dataIndex: "mobile_icon",
		// 	key: "mobile_icon",
		// 	width: "5%",
		// 	render: (mobile_icon: string, record: any, index: number) => {
		// 		return (
		// 			<div style={{ textAlign: "center" }}>
		// 				{record.level !== 2 && mobile_icon !== "undefined" && mobile_icon !== "" ? (
		// 					<img
		// 						src={`${CND_URL}/${mobile_icon}`}
		// 						alt=""
		// 						style={{ maxWidth: "36px", objectFit: "cover", cursor: "pointer" }}
		// 						onClick={() => onOpenImageOverlay(mobile_icon)}
		// 					/>
		// 				) : null}
		// 			</div>
		// 		);
		// 	}
		// },
		{
			title: "Trạng thái",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: "10%",
			render: (status: any, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Switch
							checked={status === "A" ? true : false}
							onChange={(value: boolean) => {
								onChangeStatus(record, value);
							}}
						></Switch>
					</div>
				);
			}
		},

		{
			title: "Thiết lập",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: "5%",
			render: (action: string, record: any, index: number) => {
				return (
					// <Popover
					// 	placement="left"
					// 	content={
					// 		<div className="moduleFunctions_edit__list__item__popover">
					// 			<div
					// 				onClick={() => onViewFunctDetail(record)}
					// 				className="moduleFunctions_edit__list__item__popover__item"
					// 			>
					// 				<EditOutlined />
					// 				<span>Chỉnh sửa</span>
					// 			</div>
					// 			{record.level === 1 && (
					// 				<div onClick={() => onAddActions(record)} className="moduleFunctions_edit__list__item__popover__item">
					// 					<PlusOutlined />
					// 					<span>Thêm action </span>
					// 				</div>
					// 			)}
					// 		</div>
					// 	}
					// 	trigger="click"
					// >
					// 	<div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
					// 		<SvgIcon00091 style={{ transform: "scale(0.7)" }} />
					// 	</div>
					// </Popover>
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(record)} trigger={["click"]} placement="bottomRight" arrow>
						{/* <Dropdown menu={{ items: items(record) }} 
						overlayClassName="dropdown-css" trigger={["click"]} placement="bottomRight" arrow> */}
							<DIcon icon="setting" className="flex align-center justify-center" />
						</Dropdown>
					</div>
				);
			}
		}
	];
};

export const expandedRowRender = ({ record, onOpenEditActionModal }: any) => {
	const columns: TableColumnsType<ExpandedDataType> = [
		{
			dataIndex: "funct_name",
			key: "funct_name",
			width: "16%",
			render: (funct_name: string, record: any, index: number) => {
				return <div>{funct_name}</div>;
			}
		},
		{
			dataIndex: "funct_code",
			key: "funct_code",
			width: "16%",
			render: (funct_code: string, record: any, index: number) => {
				return <div>{funct_code}</div>;
			}
		},
		{
			dataIndex: "api_route",
			key: "api_route",
			width: "16%",
			render: (api_route: string, record: any, index: number) => {
				return <div>{api_route}</div>;
			}
		},
		{
			dataIndex: "ui_route",
			key: "ui_route",
			width: "16%",
			render: (ui_route: string, record: any, index: number) => {
				return <div>{ui_route}</div>;
			}
		},
		{
			dataIndex: "ui_icon",
			key: "ui_icon",
			width: "5%",
			render: (ui_icon: string, record: any, index: number) => {
				return (
					<div style={{ textAlign: "center" }}>
						{ui_icon ? (
							<img src={`${API_URL_CDN}/${ui_icon}`} alt="" style={{ maxWidth: "30px", objectFit: "cover" }} />
						) : null}
					</div>
				);
			}
		},
		{
			dataIndex: "status",
			key: "status",
			align: "center",
			width: "5%",
			render: (status: boolean, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Switch checked={status}></Switch>
					</div>
				);
			}
		},
		{
			dataIndex: "display_on_website",
			key: "display_on_website",
			align: "center",
			width: "5%",
			render: (display_on_website: boolean, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Switch checked={display_on_website}></Switch>
					</div>
				);
			}
		},
		{
			dataIndex: "createdAt",
			key: "createdAt",
			width: "10%",
			render: (createdAt: any, record: any, index: number) => {
				return <div style={{ textAlign: "center" }}>{createdAt}</div>;
			}
		},
		{
			dataIndex: "action",
			key: "action",
			align: "center",
			width: "5.1%",
			render: (action: string, record: any, index: number) => {
				return (
					<Popover
						placement="left"
						content={
							<div className="moduleFunctions_edit__list__item__popover">
								<div
									onClick={() => onOpenEditActionModal(record)}
									className="moduleFunctions_edit__list__item__popover__item"
								>
									<EditOutlined />
									<span>Chỉnh sửa</span>
								</div>
							</div>
						}
						trigger="click"
					>
						<div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
							{/* <SvgConfig style={{ transform: "scale(0.7)" }} /> */}
						</div>
					</Popover>
				);
			}
		}
	];

	return (
		<TableStyled
			rowKey="index"
			bordered
			columns={[...columns]}
			isRowLight={true}
			dataSource={record ? [...record] : []}
			pagination={false}
			showHeader={false}
			style={{ marginLeft: "58px" }}
			sticky={false}
		/>
	);
};

export enum TypeModalEnum {
	"CREATE" = "CREATE",
	"EDIT" = "EDIT"
}
