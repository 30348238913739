import { call, put, takeLatest } from 'redux-saga/effects';
import { _apiNotSaga } from 'src/services/api/apiNotSaga';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces'; //eslint-disable-line
import { actionFailure, actionSuccess } from '../root.actions';
import transportAutoConfigsTypes from '../types/transportAutoConfigs.types';

function* createTransportAutoConfig(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return _apiNotSaga.createTransportAutoConfig(payload?.params)
    });
    yield put(actionSuccess(transportAutoConfigsTypes.CREATE_TRANSPORT_AUTO_CONFIG_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(transportAutoConfigsTypes.CREATE_TRANSPORT_AUTO_CONFIG_FAILURE, err));
  }
};

function* updateTransportAutoConfig(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return _apiNotSaga.updateTransportAutoConfig(payload.id, payload?.params)
    });
    yield put(actionSuccess(transportAutoConfigsTypes.UPDATE_TRANSPORT_AUTO_CONFIG_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(transportAutoConfigsTypes.UPDATE_TRANSPORT_AUTO_CONFIG_FAILURE, err));
  }
};

function* getTransportAutoConfigs(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return _apiNotSaga.getTransportAutoConfigs(payload?.params)
    });
    yield put(actionSuccess(transportAutoConfigsTypes.GET_TRANSPORT_AUTO_CONFIG_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(transportAutoConfigsTypes.GET_TRANSPORT_AUTO_CONFIG_FAILURE, err));
  }
};

function* getTransportAutoConfigById(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return _apiNotSaga.getTransportAutoConfigById(payload.id)
    });
    yield put(actionSuccess(transportAutoConfigsTypes.GET_DETAIL_TRANSPORT_AUTO_CONFIG_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(transportAutoConfigsTypes.GET_DETAIL_TRANSPORT_AUTO_CONFIG_FAILURE, err));
  }
};

const transportAutoConfigsSagas = [
    takeLatest(transportAutoConfigsTypes.START_CREATE_TRANSPORT_AUTO_CONFIG, createTransportAutoConfig),
    takeLatest(transportAutoConfigsTypes.START_UPDATE_TRANSPORT_AUTO_CONFIG, updateTransportAutoConfig),
    takeLatest(transportAutoConfigsTypes.START_GET_TRANSPORT_AUTO_CONFIG, getTransportAutoConfigs),
    takeLatest(transportAutoConfigsTypes.START_GET_DETAIL_TRANSPORT_AUTO_CONFIG, getTransportAutoConfigById),
  ]

export default transportAutoConfigsSagas

