import * as React from "react"

const SvgMapBox = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill={props.fillbg || "#fff"}
      stroke="#808A94"
    />
    <path
      d="M17.56 8.295a5.594 5.594 0 0 0-4.425-3.036 6.357 6.357 0 0 0-1.27 0A5.596 5.596 0 0 0 7.44 8.294a6.045 6.045 0 0 0 .829 6.654l3.8 4.62a.56.56 0 0 0 .864 0l3.8-4.62a6.044 6.044 0 0 0 .829-6.653Zm-5.06 4.764a2.238 2.238 0 1 1 0-4.475 2.238 2.238 0 0 1 0 4.475Z"
      fill={props.fill || "#000"}
    />
  </svg>
)

export default SvgMapBox