import * as React from "react"

const SvgIcon00150 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <rect
      width={24}
      height={24}
      x={0.5}
      y={0.5}
      fill="#fff"
      stroke="#808A94"
      rx={4.5}
    />
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          fill="#000"
          d="M19.03 8.537a.431.431 0 0 0-.268-.4l-6.098-2.505a.432.432 0 0 0-.329 0L6.237 8.138a.432.432 0 0 0-.267.399v7.926a.432.432 0 0 0 .267.4l6.098 2.505a.43.43 0 0 0 .328 0l6.099-2.505a.432.432 0 0 0 .267-.4V8.537Zm-6.53 2.075-2.247-.923 4.855-2.119 2.398.985-5.007 2.057ZM6.832 9.217l1.939.797v2.247a.432.432 0 0 0 .863 0v-1.893l2.433 1v6.956l-5.235-2.15V9.217ZM12.5 6.5l1.497.614L9.14 9.232l-1.647-.677L12.5 6.5Zm.432 4.869 5.235-2.15v6.956l-5.235 2.15v-6.956Z"
        />
      </g>
    </g>
  </svg>
)

export default SvgIcon00150