
import { IAction } from '../interfaces/root.interfaces';
import orgTypes from './types/org.types';

export const getOrgs = (params?: any): IAction => {
  const action: IAction = {
    type: orgTypes.START_ORG,
    payload: { params },
  }
  return action;
}