/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import CreateOpertaionModal from "./CreateModal";
import { getGoodTypes, getServices, getWarehouses } from "src/services/actions/global.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/types";
import moment from "moment";
import { CALC_TYPE_LIST } from "src/utils/helpers/functions/data";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const PriceList = () => {
	const dispatch = useDispatch();
	const [data, setData] = useState<any>([]);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter });
	const [visible, setVisible] = useState<any>(false);
	const [params, setParams] = useState<any>({
		page: 1,
		limit: 10
	});
	const [loading, setLoading] = useState<any>(false);
	const [callback, setCallback] = useState<boolean>(false);
	const [total, setToal] = useState<number>(0);
	const [dataSyscTicket, setDataSyscTicket] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [idCostsIncurred, setIdCostsIncurred] = useState<any>();
	const [visibleCostsIncurred, setVisibleCostsIncurred] = useState<any>(false);
	const [statusStatements, setStatusStatements] = useState<any>([]);
	const [statusPayment, setStatusPayment] = useState<any>([]);
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
	const [dataBills, setDataBills] = useState<any>([]);
	const [dataStatus, setDataStatus] = useState<any>([]);
	const { stateWarehouses, stateProvinces, stateGoodTypes, stateServices } = useSelector(
		(e: AppState) => e.globalReducer
	);
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		dispatch(getWarehouses());
		dispatch(getServices());
		dispatch(getGoodTypes());
	}, []);
	useEffect(() => {
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPriceList(params);
				setDataBills(res?.data);
				setToal(res?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
	}, [params, callback]); //eslint-disable-line
	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
		console.log("id", id);
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
	const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const costsIncurredDetail = (id: any) => {
		setIdCostsIncurred(id);
		setVisibleCostsIncurred(true);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	const actionsFilter = (v: any) => {
		console.log("v", v);
		const { idBtn } = v;
		handleActions[idBtn](v);
	};
	const handleActions: any = {
		btnSearch: onSearch,
		// changeSelect: changeSelect,
		btnAdd: () => {
			setVisible(true);
			setIsEdit(false);
		}
	};
	const onSuccessModal = () => {
		setCallback(!callback);
		setVisible(false);
	};
	const onChangeStatus = async (id: any, status: any, record: any) => {
		const params = {
			...record,
			status: status ? "A" : "D"
		};
		setLoading(true);
		const res: any = await _apiNotSaga.updatePriceList(id, params);
		setLoading(false);
		setCallback(!callback);
	};
	return (
		<Loading spinning={loading}>
			{visible && (
				<CreateOpertaionModal
					visible={visible}
					id={idEdit}
					onCancel={() => setVisible(false)}
					onSuccess={onSuccessModal}
					isEdit={isEdit}
				/>
			)}
			<div className="contentSection">
				<div className="top-0 contentSection-filter">
					<FlexFilter
						onSearch={(v) => actionsFilter(v)}
						onAdd={(v) => {
							setIsEdit(false);
							setVisible(true);
						}}
						// manipulation={[
						// 	{
						// 		paramsName: "btnAdd",
						// 		icon: <PlusOutlined color='white' />,
						// 		text: "Tạo mới"
						// 	}
						// 	// {
						// 	// 	paramsName: "btnSysc",
						// 	// 	icon: <TransactionOutlined />,
						// 	// 	text: "Tạo ticket thanh toán"
						// 	// }
						// ]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.input,
								placeholder: "Mã/Tên bảng giá",
								label: "Mã/Tên bảng giá",
								paramsName: "q",
								width: 260
							},
							{
								...FLEX_FILTER_ELEMENTS.datpickerShort,
								paramsName: "startDate",
								placeholder: "Chọn ngày",
								initValue: moment(),
								label: "Ngày tạo",
								hasSelectDate: false,
								width: 260
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Dịch vụ",
								placeholder: "Dịch vụ",
								width: 260,
								options: {
									data: stateServices?.data || [],
									keyOption: "id",
									valueOption: "id",
									nameOption: "service_name"
								},
								paramsName: "service_id"
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Loại hàng hóa",
								placeholder: "Loại hàng hóa",
								width: 260,
								options: {
									data: stateGoodTypes?.data || [],
									keyOption: "id",
									valueOption: "id",
									nameOption: "gst_name"
								},
								paramsName: "goods_type_id"
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Loại tính giá",
								placeholder: "Loại tính giá",
								width: 260,
								options: {
									data: CALC_TYPE_LIST,
									keyOption: "id",
									valueOption: "id",
									nameOption: "calc_type_name"
								},
								paramsName: "calc_type_id"
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Trạng thái hoạt động",
								placeholder: "Trạng thái hoạt động",
								width: 260,
								options: {
									data: [
										{
											name: "Hoạt động",
											value: "A"
										},
										{
											name: "Ngưng hoạt động",
											value: "D"
										}
									],
									keyOption: "value",
									valueOption: "value",
									nameOption: "name"
								},
								paramsName: "status"
							},
							// {
							// 	...FLEX_FILTER_ELEMENTS.createdRange,
							// 	label: "Ngày tạo",
							// 	initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
							// 	initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
							// 	width: 250
							// },
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined color="white" />,
								width: 130
							},
							// {
							// 	...FLEX_FILTER_ELEMENTS.btnFilterMore
							// },
							{
								...FLEX_FILTER_ELEMENTS.btnAdd,
								icon: <PlusOutlined color="red" />,
								width: 130
							}
							// { ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
						]}
					/>
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={dataBills}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: params.page,
							showSizeChanger: true,
							defaultPageSize: params.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						scroll={{
							x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
							y: "calc(70vh - 80px)"
						}}
						isRowLight={true}
						columns={
							columnsData({
								onEdit,
								onCancel,
								pushUpApproval,
								onCreateTicket,
								user: account,
								onSearch: onSuccessModal,
								onChangeStatus
							}) as any
						}
					/>
				</div>
			</div>
		</Loading>
	);
};

export default PriceList;
