
import { IAction } from '../interfaces/root.interfaces';
import moduleFunctTypes from './types/moduleFunct.types';

export const getModuleFunct = (params?: any): IAction => {
  const action: IAction = {
    type: moduleFunctTypes.START_GET_LIST_MODULE_FUNCT,
    payload: { params },
  }
  return action;
}

export const getModuleFunctDetail = (id?: any): IAction => {
  const action: IAction = {
    type: moduleFunctTypes.START_GET_MODULE_FUNCT_BY_ID,
    payload: { id },
  }
  return action;
}
export const getModuleFunctDetailChildren = (id?: any): IAction => {
  const action: IAction = {
    type: moduleFunctTypes.START_GET_MODULE_FUNCT_BY_ID_CHILDREN,
    payload: { id },
  }
  return action;
}