import { Form, Input, Select, Button, InputNumber, Col, Row } from "antd";
import "./style.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import { AppState } from "src/types";
import { getGoodTypes, getProvinces, getServices } from "src/services/actions/global.actions";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "src/components/form/FormSelect";
const { Option } = Select;
const ServiceInfo = ({form}: any) => {
	// const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	useEffect(() => {
		dispatch(getServices());
	}, []);
	return (
		<div >
			<h3 style={{fontWeight:600}}>Dịch vụ</h3>
			<Form.Item style={{ display: "none" }} name="service_name" />
			<FormSelect
				name="service_id"
				label="Dịch vụ"
				dataSource={stateServices?.data || []}
				placeholder="Chọn dịch vụ"
				slOption={{
					name: "service_name",
					value: "id"
				}}
				required={true}
				// disabled={disableAddress}
				onChange={(e) => {
					form.setFieldsValue({
						service_name: (stateServices?.data || []).find((item:any) => item.id === e)?.service_name
					})
				}}
				showSearch
				optionFilterProp="children"
			/>
		</div>
	);
};

export default ServiceInfo;
