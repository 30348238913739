import {
    call,
    put, takeLatest
} from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import {
    IAction, ICatchError, IRootResponse //eslint-disable-line
} from "../../interfaces/root.interfaces";
import { actionFailure, actionSuccess } from "../root.actions";
import vehicleGroupTypes from "../types/vehicleGroup.types";
import { API_VEHICLE_GROUP } from './../../api/url.index';

function* getAllVehicleGroup(action: IAction) {
    try {
        // yield delay(500, true);
        const { payload } = action;
        const res: IRootResponse = yield call(() => {
          return _apiNotSaga.getAllVehicleGroup(payload?.params)
        });
        yield put(actionSuccess(vehicleGroupTypes.GET_ALL_VEHICLE_GROUP_SUCCESS, res));
      } catch (err: ICatchError | any) {
        yield put(actionFailure(vehicleGroupTypes.GET_ALL_VEHICLE_GROUP_FAILURE, err));
      }
}

function* getVehicleGroupById(action: IAction) {
    try {
        // yield delay(500, true);
        const { payload } = action;
        const res: IRootResponse = yield call(() => {
          return api.get(`${API_URL}/${API_VEHICLE_GROUP}/${payload.id}`)
        });
        yield put(actionSuccess(vehicleGroupTypes.GET_VEHICLE_GROUP_BY_ID_SUCCESS, res));
      } catch (err: ICatchError | any) {
        yield put(actionFailure(vehicleGroupTypes.GET_VEHICLE_GROUP_BY_ID_FAILURE, err));
      }
}
function* createVehicleGroup(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_VEHICLE_GROUP}`, payload.params)
    });
    yield put(actionSuccess(vehicleGroupTypes.CREATE_VEHICLE_GROUP_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(vehicleGroupTypes.CREATE_VEHICLE_GROUP_FAILURE, err));
  }
};

function* updateVehicleGroup(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.put(`${API_URL}/${API_VEHICLE_GROUP}/${payload.id}`, payload.params);
		});
		yield put(actionSuccess(vehicleGroupTypes.UPDATE_VEHICLE_GROUP_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleGroupTypes.UPDATE_VEHICLE_GROUP_FAILURE, err));
	}
}

const vehicleGroupSagas = [
  takeLatest(vehicleGroupTypes.START_GET_ALL_VEHICLE_GROUP, getAllVehicleGroup),
  takeLatest(vehicleGroupTypes.START_GET_VEHICLE_GROUP_BY_ID, getVehicleGroupById),
  takeLatest(vehicleGroupTypes.START_CREATE_VEHICLE_GROUP, createVehicleGroup),
  takeLatest(vehicleGroupTypes.START_UPDATE_VEHICLE_GROUP, updateVehicleGroup),

];

export default vehicleGroupSagas;
