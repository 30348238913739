import * as React from "react"

const Svg00114 = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.417 5.833a1.417 1.417 0 1 0 0-2.833 1.417 1.417 0 0 0 0 2.833Zm0 8.5a1.417 1.417 0 1 0 0-2.833 1.417 1.417 0 0 0 0 2.833Zm1.416 7.084a1.417 1.417 0 1 1-2.833 0 1.417 1.417 0 0 1 2.833 0Zm7.084-15.584a1.417 1.417 0 1 0 0-2.833 1.417 1.417 0 0 0 0 2.833Zm1.416 7.084a1.417 1.417 0 1 1-2.833 0 1.417 1.417 0 0 1 2.833 0Zm-1.416 9.916a1.417 1.417 0 1 0 0-2.833 1.417 1.417 0 0 0 0 2.833Z"
      fill={props.fill || "#000"}
    />
  </svg>
)

export default Svg00114
