
import NotFoundImg from '../../assets/images/NotFound.png';
const NotFound = () => {
  return (
    <div className='p-8 flex justify-center'>
        <div className="w-[621px] h-auto bg-white rounded-[36px] shadow-sm drop-shadow-sm flex flex-col items-center px-32 pb-32">
            <img src={NotFoundImg} alt="" className='object-cover'/>
            <p className='font-semibold text-[20px] text-center'>Tính năng đang trong giai đoạn phát triển.</p>
            <p className='font-semibold text-[20px] text-center'>Vui lòng quay lại sau!!!</p>
        </div>
    </div>
  )
}

export default NotFound