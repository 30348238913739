import { Button, Dropdown, Menu, Switch, Tag } from "antd";
import moment from "moment";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgPen from "src/assets/svg/SvgPen";
import { createUser, userInfo } from "src/components/commonRender/renderData";
import { CND_URL } from "src/services/api/config";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ updateStatus, getDetail, onOpenImageOverlay }: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Tên App",
			key: "app_name",
			fixed: "left",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="font-bold leading-snug">{record?.app_name}</div>
					</div>
				);
			}
		},

		{
			title: "App key",
			dataIndex: "app_key",
			key: "app_key"
		},
		{
			title: "Mobile version",
			dataIndex: "mobile_app_version",
			key: "mobile_app_version"
		},
		{
			title: "Ngày tạo",
			key: "user_type_name",
			width: 250,
			render: (record: any, index: number) => {
				return (
					<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
						{" "}
						{moment(record?.created_at).format("DD/MM/YYYY HH:mm")}
					</span>
				);
			}
		},
		{
			title: "Trạng thái",
			key: "status",
			render: (record: any, index: number) => {
				return <Tag color={record?.status === "A" ? '#87d068' : '#f50'}>{record?.status === "A" ? "Hoạt động" : "Ẩn"}</Tag>;
			}
		},
		{
			title: "Thao tác",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: 100,
			fixed: "right",
			render: (v: string, r: any) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
							{/* <Dropdown 
						menu={{ items: items(r) }} 
						overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
