import { IAction } from '../interfaces/root.interfaces';
import exportTypes from './types/export.types';

export const getExportTemplate = (params?: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_TEMPLATE,
    payload: { params },
  }
  return action;
}

export const getExportIdentifierTemplate = (params?: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_IDENTIFIER_TEMPLATE,
    payload: { params },
  }
  return action;
}
export const getLinkDownload = (params?: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_LINK_DOWNLOAD,
    payload: { params },
  }
  return action;
}

export const exportReportOntime = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_ONTIME,
    payload: { params },
  }
  return action;
}
export const exportReportOntimeSynthesize  = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_ONTIME_SYNTHESIZE,
    payload: { params },
  }
  return action;
}
export const exportReportPerformance = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_PERFORMANCE,
    payload: { params },
  }
  return action;
}
export const exportReportProductivity = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_PRODUCTIVITY,
    payload: { params },
  }
  return action;
}
export const exportReportProductivityDetails = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_PRODUCTIVITY_DETAILS,
    payload: { params },
  }
  return action;
}
export const exportListTrip = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_LIST_TRIP,
    payload: { params },
  }
  return action;
}

export const exportReportUser = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_USER,
    payload: { params },
  }
  return action;
}

export const exportReportProblems = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_PROBLEMS,
    payload: { params },
  }
  return action;
}


export const exportReportExport = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_EXPORT,
    payload: { params },
  }
  return action;
}


export const exportReportEvaluationWH = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_EVALUATION_WH,
    payload: { params },
  }
  return action;
}

export const exportReportEvaluationDriver = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_REPORT_EVALUATION_DRIVER,
    payload: { params },
  }
  return action;
}

export const exportReportWarehouseInventory = (params: any): IAction => {
  const action: IAction = {
    type: exportTypes.START_EXPORT_WH_INVENTORY,
    payload: { params },
  }
  return action;
}