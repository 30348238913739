import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "src/services/api/api.index";
import { API_URL } from "src/services/api/config";
import { API_USER_TYPES } from "src/services/api/url.index";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import userTypesTypes from "../types/userTypes.types";

function* getUserTypes(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_USER_TYPES}`, payload?.params);
		});
		yield put(actionSuccess(userTypesTypes.GET_USER_TYPES_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(userTypesTypes.GET_USER_TYPES_FAILURE, err));
	}
}

const userTypesSagas = [
	takeLatest(userTypesTypes.START_GET_USER_TYPES, getUserTypes),
];
export default userTypesSagas;
