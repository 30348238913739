import useWindowDimensions from "../layouts/dimensions";

export const useScrollYTable = (offset = 0) => {
	/*
    header + footer = 96;
    filter -> table = 10
    pagination		= 43
    margin-table	= 32
    header-bottom	= 16
    border-table	=  2
  */
	const { height } = useWindowDimensions();
	// console.log({ width, height });
	const filterHeight = document.getElementById("flexFilter")?.offsetHeight + 32;
	const theadHeihgt = document.querySelector("thead")?.offsetHeight;
	// console.log({ theadHeihgt });
	// console.log({ filterHeight });
	if (!filterHeight) return null;
	const scrollY = `calc(${height}px - ${filterHeight}px - 199px - ${theadHeihgt}px + ${offset}px)`;
	return scrollY;
};
