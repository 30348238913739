/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData, columnsDetailData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import { useLocation, useParams } from "react-router-dom";
import FormSelect from "src/components/form/FormSelect";
import { Row, Col, Input, Form, Button, Dropdown, Tooltip, Popconfirm, Select } from "antd";
import { localGetAccount } from "src/utils/localStorage";
import moment from "moment";
import { WH_OPERATION_TYPE } from "src/utils/helpers/functions/data";
import TagStatusWH from "src/components/TagStatusWH/tagStatusWH";
import FormInput from "src/components/form/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/types";
import { getProvinces } from "src/services/actions/global.actions";
import CreateDetailModal from "./CreateDetailModal";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const DetailsPartner = () => {
	const [form] = Form.useForm();
	const urlParams = new URLSearchParams(window.location.search);
	const { id } = useParams<any>();
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	const [districts, setDistricts] = useState<any[]>([]);
	const [wards, setWards] = useState<any[]>([]);
	const [data, setData] = useState<any>([]);
	const [visible, setVisible] = useState<any>(false);
	const [user, setUser] = useState<any>(false);
	const [params, setParams] = useState<any>({});
	const [loading, setLoading] = useState<any>(false);
	const [total, setToal] = useState<number>(0);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const dispatch = useDispatch();
	const [empSelect, setEmpSelect] = useState<any>("");
	const [account, setAccount] = useState<any>({});
	const [dataBills, setDataBills] = useState<any>([]);
	const [addressList, setAddressList] = useState<any>([]);
	const [dataUserWh, setDataUserWh] = useState<any>([]);
	const [items, setItems] = useState<any>([]);
	const [callback, setCallback] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>({});
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		let USER_INFO: any = localGetAccount();
		USER_INFO = JSON.parse(USER_INFO);
		console.log("USER_INFO", USER_INFO);
		setUser(USER_INFO);
		getDetailedPartner();
		getPartnerAddress();
		// getUserWH();
		dispatch(getProvinces());
		loadUser();
	}, [callback]);

	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setDetail(id);
	};
	const getDetailedPartner = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getDetailsPartner(id, params);
			console.log("res", res);
			onChangeProvince(res?.data?.province_id);
			onChangeDistrict(res?.data?.district_id);
			form.setFieldsValue(res?.data);
			setDataBills(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const getPartnerAddress = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getPartnerAddresses(id, params);
			console.log("res", res);
			setAddressList(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const loadUser = async () => {
		const res: any = await _apiNotSaga.getUsers({ limit: 999, status: "A" });
		let data = res?.data;
		let districts: any = [];
		res.data.map((item: any) => {
			districts.push({
				label: (
					<a target="_blank" rel="noopener noreferrer" onClick={() => handleSetEmp(item.id)}>
						{`${item.user_code} - ${item.full_name}`}
					</a>
				),
				value: item.id.toString()
			});
		});
		setItems(districts);
		// dispatch(fetchUserFromTicket({ do_get_list: true, user_erp_code: q }));
	};
	const handleSetEmp = (id: any) => {};
	const getUserWH = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getUsersByWh({
				warehouse_id: id
			});
			console.log("res", res);

			setDataUserWh(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	
	const handleAddAddress = async () => {
		setIsEdit(false);
		setVisible(true);
		setDetail(null);
	};
	const onComplete = async (val: any) => {
		setLoading(true);
		try {
			let paramss = {
				...val
			};
			const completeRes: any = await _apiNotSaga.updatePartner(id, paramss);
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getDetailedPartner();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	// const onDelete = async () => {
	// 	setLoading(true);
	// 	try {
	// 		params.status = "D";
	// 		const completeRes: any = await _apiNotSaga.updateOperationWH(id, params);
	// 		if (!completeRes.success) {
	// 			return notifyError(completeRes.message);
	// 		}
	// 		getDetailedPartner();
	// 		notifySuccess("Cập nhật thành công");
	// 	} catch (error) {
	// 		setLoading(false);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };
	// const _handleKeyDown = (e: any) => {
	// 	if (e.key === "Enter") {
	// 		onScanBill(scanBill);
	// 	}
	// };
	// const onCancel = (id: any, isCancel: boolean) => {
	// 	if (isCancel) {
	// 		// updateStatementTripCost(id, { status_cancel: "C" });
	// 	} else {
	// 		setVisibleCancel(true);
	// 		paramsCancel.id = id;
	// 		paramsCancel.status = "R";
	// 		setParamsCancel({ ...paramsCancel });
	// 	}
	// };
	const onChangeProvince = async (e: any) => {
		form.setFieldsValue({
			province_name: (stateProvinces?.data || []).find((item: any) => item.id === e)?.province_name,
			district_id: undefined,
			ward_id: undefined,
			district_name: undefined,
			ward_name: undefined
		});
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistricts(district.data);
		}
	};
	const onChangeDistrict = async (e: any) => {
		form.setFieldsValue({
			ward_id: undefined,
			ward_name: undefined,
			district_name: districts.find((item: any) => item.id === e)?.district_name
		});
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWards(ward.data);
		}
	};
	const onChange = (value: any) => {
		console.log(`selected ${value}`);
		setEmpSelect(value);
	};
	const onDeleteBill = async (bill: any) => {
		const params = {
			warehouse_id: id,
			status: "D"
		};
		const res: any = await _apiNotSaga.updateUserByWH(bill.id, params);
		if (!res.success) {
			return notifyError(res.message);
		}
		getUserWH();
		return notifySuccess(res.message);
	};

	const updateStatus = async (r: any, status: string) => {
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updatePartnerAddress(r?.id, { status: status ? "A" : "D", });
			notifySuccess(res.message);
			setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const onSuccessModal = () => {
		setCallback(!callback);
		setVisible(false);
	};

	return (
		<Loading spinning={loading}>
			{visible && <CreateDetailModal partnerId={dataBills?.id} visible={visible} onCancel={()=> setVisible(false)} onSuccess={onSuccessModal} detail={detail}/>}
			<Form form={form} layout="vertical" name="myForm1" onFinish={onComplete} className="space-y-4">
				<div className="contentSection">
					<div className="contentSection-main">
						{/* <div className="flex">
							<h3>
								{WH_OPERATION_TYPE[dataBills.operation_type]} - {dataBills.operation_code} 
							</h3>
							<div className="w-2"></div>
							<TagStatusWH status={dataBills?.status} />
						</div> */}
						<Row gutter={[8, 8]}>
							<Col xs={12} md={12}>
								<FormInput
									name="partner_code"
									label="Mã đối tác"
									type="text"
									size="large"
									placeholder="Nhập đối tác"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col xs={16} md={12}>
								<FormInput
									name="partner_name"
									label="Tên đối tác"
									type="text"
									size="large"
									placeholder="Nhập tên đối tác"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="partner_type"
									label="Loại đối tác"
									dataSource={[
										{
											partner_type: 1,
											partner_name: "Đối tác"
										},
										{
											partner_type: 2,
											partner_name: "Khách lẻ"
										}
									]}
									placeholder="Chọn loại đối tác"
									slOption={{
										name: "partner_name",
										value: "partner_type"
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormInput
									name="contact_name"
									label="Người liên hệ"
									type="text"
									size="large"
									placeholder="Nhập người liên hệ"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormInput name="email" label="Email" type="text" size="large" placeholder="Nhập Email" />
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="province_id"
									label="Tỉnh thành"
									dataSource={stateProvinces?.data || []}
									placeholder="Chọn tỉnh thành"
									slOption={{
										name: "province_name",
										value: "id"
									}}
									required={true}
									// disabled={disableAddress}
									onChange={onChangeProvince}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="district_id"
									label="Quận huyện"
									dataSource={districts}
									placeholder="Chọn quận huyện"
									slOption={{
										name: "district_name",
										value: "id"
									}}
									required={true}
									// required={!disableAddress}
									// disabled={disableAddress}
									onChange={onChangeDistrict}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="ward_id"
									label="Phường xã"
									dataSource={wards}
									placeholder="Chọn phường xã"
									slOption={{
										name: "ward_name",
										value: "id"
									}}
									required={true}
									// required={!disableAddress}
									// disabled={disableAddress}
									onChange={(e) => {
										form.setFieldsValue({
											r_ward_name: (wards || []).find((item: any) => item.id === e)?.ward_name
										});
									}}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col xs={12} md={12}>
								<FormInput
									name="address"
									label="Địa chỉ"
									type="text"
									size="large"
									placeholder="Nhập địa chỉ"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col xs={12} md={12}>
								<Form.Item label="Trạng thái" name="status" style={{ margin: "0" }}>
									<Select
										className="defaultSelect"
										options={[
											{ label: "Hoạt động", value: "A" },
											{ label: "Ẩn", value: "D" }
										]}
									/>
								</Form.Item>
							</Col>

							<Col md={6} xs={6} sm={6}>
								{!["C", "D"].includes(dataBills.status) ? (
									<div
										style={{ alignItems: "end", height: "100%", paddingBottom: 7 }}
										className="flex justify-between "
									>
										<Button
											id="filter_button_onSearch"
											size="large"
											block={true}
											htmlType="submit"
											form="myForm1"
											// onClick={onComplete}
											className="bg-[#001529] flex tms-btn-search justify-center rounded-lg items-center text-[#FFFFFF]"
										>
											{"Cập nhật"}
										</Button>
										{/* <Button
											id="filter_button_onSearch"
											size="large"
											block={true}
											onClick={onDelete}
											className="bg-[#fff] flex tms-btn-search justify-center rounded-lg items-center text-[#000] border-1px"
										>
											{"Hủy"}
										</Button> */}
									</div>
								) : (
									<></>
								)}
							</Col>
						</Row>
					</div>
					<hr className=" mb-[10px] border-neutral_color_1_2" />
					<div className="contentSection-main">
						<div className="flex justify-between items-center my-[10px]">
							<p className="text-[16px] font-bold">Danh sách địa chỉ</p>
							<div className="w-[550px] flex justify-end items-center">
								{/* <Select
									showSearch
									placeholder="Tìm kiếm nhân viên"
									optionFilterProp="label"
									className="w-[350px]"
									onChange={onChange}
									// onSearch={onSearch}
									options={items}
								/> */}
								<Button
									onClick={() => handleAddAddress()}
									className="bg-[#001529] mx-2 flex tms-btn-search justify-center rounded-lg items-center text-[#FFFFFF]"
								>
									Thêm địa chỉ
								</Button>
							</div>
						</div>
						<TableStyled
							rowKey="id"
							dataSource={addressList}
							bordered
							pagination={{
								locale: {
									items_per_page: "/Trang"
								},
								current: params.page,
								showSizeChanger: true,
								defaultPageSize: params.limit,
								total: addressList?.length || 0,
								showTotal: (total: any, range: any) => (
									<div className="flex gap-2">
										<strong>
											{range[0]} - {range[1]}
										</strong>
										trong tổng
										<strong>{total}</strong>
									</div>
								),
								onChange: onChangePaging
							}}
							scroll={{
								x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
								y: "calc(70vh - 80px)"
							}}
							isRowLight={true}
							columns={columnsDetailData({ onEdit, user: account, updateStatus }) as any}
						/>
					</div>
				</div>
			</Form>
		</Loading>
	);
};

export default DetailsPartner;
