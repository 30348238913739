import * as React from "react"
import { SVGProps } from "react"
const IconAssign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#212E3B"
      d="m23.54 17.945-6.292-4.722-.608-.453v3.208c-2.057-.22-3.002-1.448-3.972-2.755-.013-.026-.039-.039-.052-.065l-1.94-2.6c-2.549-3.416-4.17-4.904-8.57-4.904H1.46v3.882h.647c2.988 0 4.377 1.837 5.67 3.61.014.025.04.05.053.077h.012c.402.556.803 1.1 1.23 1.526 1.94 3.196 4.476 4.904 7.568 5.11v3.26l6.9-5.174Z"
    />
    <path
      fill="#000"
      d="M16.64 23.12v-3.26c-3.092-.207-5.628-1.915-7.568-5.11-.427-.428-.828-.97-1.23-1.527h4.826c.97 1.306 1.915 2.536 3.972 2.755V12.77l.608.453 6.292 4.722-6.9 5.175Z"
    />
    <path
      fill="#212E3B"
      d="M12.604 13.223c-1.773 2.859-4.559 6.662-10.497 6.662H1.46v-3.88h.647c2.73 0 4.442-1.204 5.723-2.782h4.774Z"
    />
    <path
      fill="#001529"
      d="m23.54 7.595-6.9-5.175v3.26c-2.691.194-4.942 1.617-6.546 4.153l-.466.764c-.57.892-1.126 1.81-1.798 2.626-1.281 1.578-2.993 2.781-5.723 2.781H1.46v3.881h.647c5.938 0 8.724-3.803 10.497-6.662.142-.22.271-.44.4-.647l.428-.699c.866-1.358 1.914-2.109 3.208-2.303v3.196l6.9-5.175Z"
    />
    <path
      fill="#001529"
      d="M12.604 13.223c-1.773 2.859-4.559 6.662-10.497 6.662H1.46v-3.88h.647c2.73 0 4.442-1.204 5.723-2.782h4.774Z"
    />
  </svg>
)
export default IconAssign
