const vehicleGroupTypes = {

    //VEHICLE

	// START_GET_ALL_VEHICLES: "START_GET_ALL_VEHICLES",
	// GET_ALL_VEHICLES_SUCCESS: "GET_ALL_VEHICLES_SUCCESS",
	// GET_ALL_VEHICLES_FAILURE: "GET_ALL_VEHICLES_FAILURE",

    // START_GET_VEHICLE_BY_ID: "START_GET_VEHICLE_BY_ID",
	// GET_VEHICLE_BY_ID_SUCCESS: "GET_VEHICLE_BY_ID_SUCCESS",
	// GET_VEHICLE_BY_ID_FAILURE: "GET_VEHICLE_BY_ID_FAILURE",

    // START_CREATE_VEHICLE: "START_CREATE_VEHICLE",
	// CREATE_VEHICLE_SUCCESS: "CREATE_VEHICLE_SUCCESS",
	// CREATE_VEHICLE_FAILURE: "CREATE_VEHICLE_FAILURE",

    // START_UPDATE_VEHICLE: "START_UPDATE_VEHICLE",
	// UPDATE_VEHICLE_SUCCESS: "UPDATE_VEHICLE_SUCCESS",
	// UPDATE_VEHICLE_FAILURE: "UPDATE_VEHICLE_FAILURE",

    // START_DELETE_VEHICLE: "START_DELETE_VEHICLE",
	// DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
	// DELETE_VEHICLE_FAILURE: "DELETE_VEHICLE_FAILURE",

    //VEHICLE- GROUP

	START_GET_ALL_VEHICLE_GROUP: "START_GET_ALL_VEHICLE_GROUP",
	GET_ALL_VEHICLE_GROUP_SUCCESS: "GET_ALL_VEHICLE_GROUP_SUCCESS",
	GET_ALL_VEHICLE_GROUP_FAILURE: "GET_ALL_VEHICLE_GROUP_FAILURE",

    START_GET_VEHICLE_GROUP_BY_ID: "START_GET_VEHICLE_GROUP_BY_ID",
	GET_VEHICLE_GROUP_BY_ID_SUCCESS: "GET_VEHICLE_GROUP_BY_ID_SUCCESS",
	GET_VEHICLE_GROUP_BY_ID_FAILURE: "GET_VEHICLE_GROUP_BY_ID_FAILURE",

    START_CREATE_VEHICLE_GROUP: "START_CREATE_VEHICLE_GROUP",
	CREATE_VEHICLE_GROUP_SUCCESS: "CREATE_VEHICLE_GROUP_SUCCESS",
	CREATE_VEHICLE_GROUP_FAILURE: "CREATE_VEHICLE_GROUP_FAILURE",

    START_UPDATE_VEHICLE_GROUP: "START_UPDATE_VEHICLE_GROUP",
	UPDATE_VEHICLE_GROUP_SUCCESS: "UPDATE_VEHICLE_GROUP_SUCCESS",
	UPDATE_VEHICLE_GROUP_FAILURE: "UPDATE_VEHICLE_GROUP_FAILURE",

    START_DELETE_VEHICLE_GROUP: "START_DELETE_VEHICLE_GROUP",
	DELETE_VEHICLE_GROUP_SUCCESS: "DELETE_VEHICLE_GROUP_SUCCESS",
	DELETE_VEHICLE_GROUP_FAILURE: "DELETE_VEHICLE_GROUP_FAILURE",

    //VEHICLE- TYPES

	// START_GET_ALL_VEHICLE_TYPES: "START_GET_ALL_VEHICLE_TYPES",
	// GET_ALL_VEHICLE_TYPES_SUCCESS: "GET_ALL_VEHICLE_TYPES_SUCCESS",
	// GET_ALL_VEHICLE_TYPES_FAILURE: "GET_ALL_VEHICLE_TYPES_FAILURE",
};

export default vehicleGroupTypes;
