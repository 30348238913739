function SvgCancelStatement(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
			<g clip-path="url(#clip0_150_5803)">
				<g clip-path="url(#clip1_150_5803)">
					<path
						d="M21.3428 3.6572C16.4655 -1.21907 8.53368 -1.21907 3.65639 3.6572C-1.2188 8.53346 -1.2188 16.4674 3.65639 21.3436C6.09503 23.7812 9.29756 24.9995 12.5001 24.9995C15.7027 24.9995 18.9042 23.7812 21.3428 21.3436C26.2191 16.4674 26.2191 8.53346 21.3428 3.6572ZM17.6577 16.1849C18.0652 16.5924 18.0652 17.2511 17.6577 17.6585C17.4545 17.8618 17.1877 17.9639 16.9209 17.9639C16.6541 17.9639 16.3873 17.8618 16.1841 17.6585L12.5001 13.9735L8.81711 17.6575C8.61286 17.8607 8.34604 17.9628 8.08029 17.9628C7.81351 17.9628 7.54669 17.8607 7.34347 17.6575C6.936 17.25 6.936 16.5903 7.34347 16.1839L11.0264 12.4999L7.34244 8.81586C6.93498 8.4084 6.93498 7.74871 7.34244 7.34227C7.74888 6.93481 8.40857 6.93481 8.81603 7.34227L12.5 11.0263L16.184 7.34227C16.5915 6.93481 17.2502 6.93481 17.6576 7.34227C18.0651 7.74871 18.0651 8.4084 17.6576 8.81586L13.9736 12.4999L17.6577 16.1849Z"
						fill="#DC3030"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_150_5803">
					<rect width="25" height="25" fill="white" />
				</clipPath>
				<clipPath id="clip1_150_5803">
					<rect width="25" height="25" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgCancelStatement;
