const SvgIconMoveRight = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 25 25" {...props}>
		<path
			fill="#001529"
			d="M17.345 12.5c0-.305-.116-.61-.348-.842l-7.31-7.31a1.19 1.19 0 10-1.684 1.684l6.468 6.468-6.467 6.468a1.19 1.19 0 001.683 1.683l7.31-7.31c.232-.232.348-.536.348-.841z"
		></path>
	</svg>
);

export default SvgIconMoveRight;
