const vehicleTypes = {
    START_GET_VEHICLES: 'START_GET_VEHICLES',
    GET_VEHICLES_SUCCESS: 'GET_VEHICLES_SUCCESS',
    GET_VEHICLES_FAILURE: 'GET_VEHICLES_FAILURE',

    START_GET_VEHICLE_DETAIL: 'START_GET_VEHICLES_DETAIL',
    GET_VEHICLE_DETAIL_SUCCESS: 'GET_VEHICLES_DETAIL_SUCCESS',
    GET_VEHICLE_DETAIL_FAILURE: 'GET_VEHICLES_DETAIL_FAILURE',

    START_UPDATE_VEHICLE: 'START_UPDATE_VEHICLE',
    UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
    UPDATE_VEHICLE_FAILURE: 'UPDATE_VEHICLE_FAILURE', 
}

export default vehicleTypes;