import { call, put, takeLatest } from "redux-saga/effects";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccessPageHeader } from "../root.actions";
import pageHeaderType from "../types/pageHeader.type";

function* getPageHeader(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return payload?.params;
		});
		yield put(actionSuccessPageHeader(pageHeaderType.GET_PAGE_HEADER_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(pageHeaderType.GET_PAGE_HEADER_FAILURE, err));
	}
}

function* updatePageHeader(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return payload;
		});

		yield put(actionSuccessPageHeader(pageHeaderType.UPDATE_PAGE_HEADER_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(pageHeaderType.UPDATE_PAGE_HEADER_FAILURE, err));
	}
}

const pageHeaderSagas = [
	takeLatest(pageHeaderType.START_GET_PAGE_HEADER, getPageHeader),
	takeLatest(pageHeaderType.START_UPDATE_PAGE_HEADER, updatePageHeader)
];
export default pageHeaderSagas;
