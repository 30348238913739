import { Avatar, Button, Dropdown, Menu, Popconfirm, Popover, Progress, Switch, Tag } from "antd";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgIcon00060 from "src/assets/svg/SvgIcon00060";
import SvgIcon00061 from "src/assets/svg/SvgIcon00061";
import SvgIcon00139 from "src/assets/svg/SvgIcon00139";
import SvgIcon00178 from "src/assets/svg/SvgIcon00178";
import { renderCell } from "src/components/commonRender/renderData";
import DIcon from "src/components/icons/DIcon";
import { CND_URL, TICKET_URL } from "src/services/api/config";
import colors from "src/utils/colors";
import { numberWithCommas } from "src/utils/helpers/functions/textUtils";
import "./style.scss";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SvgPen from "src/assets/svg/SvgPen";
import moment from "moment";
import routerNames from "src/utils/data/routerName";
import SvgDel from "src/assets/svg/SvgDel";
import SvgView from "src/assets/svg/SvgView";
import { STATUS_WH_OPERATION_COLOR, STATUS_WH_OPERATION_MAPPING } from "src/constants";
import TagStatusWH from "src/components/TagStatusWH/tagStatusWH";
import SvgPhone from "src/assets/svg/SvgPhone";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({
	setOpenModalLoadReport,
	setItems,
	onEdit,
	onCancel,
	pushUpApproval,
	onCreateTicket,
	getDetail,
	user,
	onChangeStatus
}: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Link to={`/list-profile/${r?.candidate_id}`}>
							<Button>
								<SvgPen fill="black" />
								<span>Chỉnh sửa</span>
							</Button>
						</Link>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Bảng giá",
			align: "left",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<Link to={`/chi-tiet-bang-gia/${record?.id}`}>
							<p className="text-sky-500 text-[13px] font-normal   underline leading-snug">{record?.price_code}</p>
						</Link>
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal  ">{record?.price_name}</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Điều kiện áp dụng",
			width: "22%",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<p className="text-14 text-[#212E3B]">
							<span className="text-[#A6A6A6]">Dịch vụ: </span>
							{record?.service_name}
						</p>
						<p className="text-14 text-[#212E3B]">
							<span className="text-[#A6A6A6]">Loại hàng hóa: </span>
							{record?.goods_type_name}
						</p>
						<p className="text-14 text-[#212E3B]">
							<span className="text-[#A6A6A6]">Áp giá theo: </span>
							{record?.calc_type_name}
						</p>
					</div>
				);
			}
		},
		{
			title: "Nhân viên tạo",
			// align: "right",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px]">
									{record?.created_by}
								</p>
							</div>
							<p className="text-14 text-[#212E3B]">
								<span className="text-[#A6A6A6]">Ngày tạo: </span>
								{record.created_at ? moment(record?.created_at).format("YYYY-MM-DD") : "-"}
							</p>
							<p className="text-14 text-[#212E3B]">
								<span className="text-[#A6A6A6]">Ngày cập nhật: </span>
								{record.updated_at ? moment(record?.updated_at).format("YYYY-MM-DD") : "-"}
							</p>
						</div>
					</div>
				);
			}
		},
		{
			title: "Thời gian áp dụng",
			// align: "right",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<p className="text-14 text-[#212E3B]">
								<span className="text-[#A6A6A6]">Từ ngày: </span>
								{record.start_at ? moment(record?.start_at).format("YYYY-MM-DD") : "-"}
							</p>
							<p className="text-14 text-[#212E3B]">
								<span className="text-[#A6A6A6]">Đến ngày: </span>
								{record.end_at ? moment(record?.end_at).format("YYYY-MM-DD") : "-"}
							</p>
						</div>
					</div>
				);
			}
		},
		{
			title: "Trạng thái",
			// align: "right",
			render: (record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Switch checked={record?.status =="A"} onClick={(e) => onChangeStatus(record.id,e ,record)} ></Switch>
					</div>
				);
			}
		},
		{
			title: "Thao tác",
			align: "center",
			fixed: "right",
			width: "13%",
			render: (text: string, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button icon={<SvgPen fill="black" />} onClick={() => onEdit(record.id)}></Button>
					</div>
				);
			}
		}
	];
};

export const _paramsFilter: any = {
	q: undefined,
	status: null,
	created_at_start: undefined,
	created_at_end: undefined,
	page: 1,
	limit: 10
};
