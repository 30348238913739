import { call, put, takeLatest } from "redux-saga/effects";
import { API_REPAIR_REGULATION } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import repairRegulationTypes from "../types/repairRegulation.types";

function* getListrepairRegulation(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_REPAIR_REGULATION}`, payload?.params);
		});
		yield put(actionSuccess(repairRegulationTypes.GET_LIST_REPAIR_REGULATION_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairRegulationTypes.GET_LIST_REPAIR_REGULATION_FAILURE, err));
	}
}

function* getrepairRegulationById(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_REPAIR_REGULATION}/${payload.id}`);
		});
		yield put(actionSuccess(repairRegulationTypes.GET_REPAIR_REGULATION_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairRegulationTypes.GET_REPAIR_REGULATION_BY_ID_FAILURE, err));
	}
}

function* genCoderepairRegulationById(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.post(`${API_URL}/${API_REPAIR_REGULATION}/${payload.id}`);
		});
		yield put(actionSuccess(repairRegulationTypes.GEN_CODE_REPAIR_REGULATION_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairRegulationTypes.GEN_CODE_REPAIR_REGULATION_BY_ID_FAILURE, err));
	}
}

function* updateOnerepairRegulation(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.put(`${API_URL}/${API_REPAIR_REGULATION}/${payload.id}`, payload.params);
		});
		yield put(actionSuccess(repairRegulationTypes.UPDATE_ONE_REPAIR_REGULATION_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairRegulationTypes.UPDATE_ONE_REPAIR_REGULATION_FAILURE, err));
	}
}

function* createOnerepairRegulation(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.post(`${API_URL}/${API_REPAIR_REGULATION}`, payload.params);
		});
		yield put(actionSuccess(repairRegulationTypes.CREATE_ONE_REPAIR_REGULATION_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairRegulationTypes.CREATE_ONE_REPAIR_REGULATION_FAILURE, err));
	}
}
const repairRegulationSagas = [
	takeLatest(repairRegulationTypes.START_GET_LIST_REPAIR_REGULATION, getListrepairRegulation),
	takeLatest(repairRegulationTypes.START_GET_REPAIR_REGULATION_BY_ID, getrepairRegulationById),
	takeLatest(repairRegulationTypes.START_GEN_CODE_REPAIR_REGULATION_BY_ID, genCoderepairRegulationById),
	takeLatest(repairRegulationTypes.START_UPDATE_ONE_REPAIR_REGULATION, updateOnerepairRegulation),
	takeLatest(repairRegulationTypes.START_CREATE_ONE_REPAIR_REGULATION, createOnerepairRegulation)
];
export default repairRegulationSagas;
