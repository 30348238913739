const transportTripsTypes = {
    START_CREATE_TRANSPORT_TRIP: 'START_CREATE_TRANSPORT_TRIP',
    CREATE_TRANSPORT_TRIP_SUCCESS: 'CREATE_TRANSPORT_TRIP_SUCCESS',
    CREATE_TRANSPORT_TRIP_FAILURE: 'CREATE_TRANSPORT_TRIP_FAILURE',
    
    START_UPDATE_TRANSPORT_TRIP: 'START_UPDATE_TRANSPORT_TRIP',
    UPDATE_TRANSPORT_TRIP_SUCCESS: 'UPDATE_TRANSPORT_TRIP_SUCCESS',
    UPDATE_TRANSPORT_TRIP_FAILURE: 'UPDATE_TRANSPORT_TRIP_FAILURE',
    
    START_GET_TRANSPORT_TRIP: 'START_GET_TRANSPORT_TRIP',
    GET_TRANSPORT_TRIP_SUCCESS: 'GET_TRANSPORT_TRIP_SUCCESS',
    GET_TRANSPORT_TRIP_FAILURE: 'GET_TRANSPORT_TRIP_FAILURE',
    
    START_GET_REPORT_ONTIME: 'START_GET_REPORT_ONTIME',
    GET_REPORT_ONTIME_SUCCESS: 'GET_REPORT_ONTIME_SUCCESS',
    GET_REPORT_ONTIME_FAILURE: 'GET_REPORT_ONTIME_FAILURE',

    START_GET_REPORT_PERFORMANCE: 'START_GET_REPORT_PERFORMANCE',
    GET_REPORT_PERFORMANCE_SUCCESS: 'GET_REPORT_PERFORMANCE_SUCCESS',
    GET_REPORT_PERFORMANCE_FAILURE: 'GET_REPORT_PERFORMANCE_FAILURE',

    START_GET_REPORT_PRODUCTIVITY: 'START_GET_REPORT_PRODUCTIVITY',
    GET_REPORT_PRODUCTIVITY_SUCCESS: 'GET_REPORT_PRODUCTIVITY_SUCCESS',
    GET_REPORT_PRODUCTIVITY_FAILURE: 'GET_REPORT_PRODUCTIVITY_FAILURE',
    
    START_GET_DETAIL_TRANSPORT_TRIP: 'START_GET_DETAIL_TRANSPORT_TRIP',
    GET_DETAIL_TRANSPORT_TRIP_SUCCESS: 'GET_DETAIL_TRANSPORT_TRIP_SUCCESS',
    GET_DETAIL_TRANSPORT_TRIP_FAILURE: 'GET_DETAIL_TRANSPORT_TRIP_FAILURE',

    
    START_GET_REPORT_PROBLEM: 'START_GET_REPORT_PROBLEM',
    GET_REPORT_PROBLEM_SUCCESS: 'GET_REPORT_PROBLEM_SUCCESS',
    GET_REPORT_PROBLEM_FAILURE: 'GET_REPORT_PROBLEM_FAILURE',


     
    START_GET_REPORT_EXPORT: 'START_GET_REPORT_EXPORT',
    GET_REPORT_EXPORT_SUCCESS: 'GET_REPORT_EXPORT_SUCCESS',
    GET_REPORT_EXPORT_FAILURE: 'GET_REPORT_EXPORT_FAILURE',

      
    START_GET_REPORT_EVALUATION: 'START_GET_REPORT_EVALUATION',
    GET_REPORT_EVALUATION_SUCCESS: 'GET_REPORT_EVALUATION_SUCCESS',
    GET_REPORT_EVALUATION_FAILURE: 'GET_REPORT_EVALUATION_FAILURE',

    START_GET_REPORT_EVALUATION_DRIVER: 'START_GET_REPORT_EVALUATION_DRIVER',
    GET_REPORT_EVALUATION_DRIVER_SUCCESS: 'GET_REPORT_EVALUATION_DRIVER_SUCCESS',
    GET_REPORT_EVALUATION_DRIVER_FAILURE: 'GET_REPORT_EVALUATION_DRIVER_FAILURE',
  }
  
  export default transportTripsTypes;