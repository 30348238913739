const routingGroupTypes={
    START_GET_LIST_ROUTING_GROUP: 'START_GET_LIST_ROUTING_GROUP',
  GET_LIST_ROUTING_GROUP_SUCCESS: 'GET_LIST_REGULATION_SUCCESS',
  GET_LIST_ROUTING_GROUP_FAILURE: 'GET_LIST_ROUTING_GROUP_FAILURE',

  START_GET_ROUTING_GROUP_BY_ID: 'START_GET_ROUTING_GROUP_BY_ID',
  GET_ROUTING_GROUP_BY_ID_SUCCESS: 'GET_ROUTING_GROUP_BY_ID_SUCCESS',
  GET_ROUTING_GROUP_BY_ID_FAILURE: 'GET_ROUTING_GROUP_BY_ID_FAILURE',


}
export default routingGroupTypes