// import imageLogo from "../../../assets/images/LogoTransportNew.svg";

// import imageLogoTransport from "../../../assets/images/LogoTransport.svg";

const LogoSignIn = () => {
  return (
    <div className="contents">
      <img src={""} alt="" />
    </div>
  );
};

export default LogoSignIn;
