import { Switch } from "antd";
import { Link } from "react-router-dom";
import { createUser } from "src/components/commonRender/renderData";
import DIcon from "src/components/icons/DIcon";
import routerNames from "src/utils/data/routerName";
export const columnsData = ({ updateStatus, getDetail }: any) => {
	return [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 80,
			render: (v: number, r: any) => (
				<span className="text-blue-500 cursor-pointer pr-8" onClick={() => getDetail(v)}>
					{v}
				</span>
			)
		},
		{
			title: "Tên nhóm vai trò",
			dataIndex: "role_name",
			key: "role_name"
		},
		{
			title: "Ngày tạo",
			dataIndex: "created_at",
			key: "created_at",
			render: (created_at: any, record: any) => createUser(record?.created_by, created_at, record?.updated_at)
		},
		{
			title: "Trạng thái",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 100,
			render: (v: string, r: any) => (
				<div className="flex justify-center">
					<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status)} />
				</div>
			)
		},
		{
			title: "Thao tác",
			dataIndex: "action",
			kye: "action",
			align: "center",
			width: 100,
			render: (v: string, r: any) => {
				return (
					<Link to={`/roles/${r?.id}`}>
						<DIcon
							icon="pen_box"
							className="flex align-center justify-center"
							// onClick={() => getDetail(r?.id)}
						/>
					</Link>
				);
			}
		}
	];
};
