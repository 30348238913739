import { Button, Col, Dropdown, Form, Menu, Modal, Row, Select, Switch, Tabs, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import moment from "moment";
import SvgPen from "src/assets/svg/SvgPen";
import styled from "styled-components";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import { dataOrg } from "src/constants";
import { localGetAccount } from "src/utils/localStorage";
import FormItem from "antd/lib/form/FormItem";
import { debounce } from "lodash";
import { getGoodTypes, getProvinces, getServices } from "src/services/actions/global.actions";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
const { Item } = Form;
const { Option } = Select;
const CreateDetailModal = ({partnerId, visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} } :any) => {
	const [form] = Form.useForm();
	const isCreate = detail?.id ? false : true;
	const [status, setStatus] = useState<boolean>(true);
	const [isSupper, setIsSupper] = useState<boolean>(false);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [user, setUser] = useState<any>([]);
	const [listUser, setListUser] = useState([]);
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	const { stateWarehouses } = useSelector((e: AppState) => e.globalReducer);
	const [districts, setDistricts] = useState<any[]>([]);
	const [wards, setWards] = useState<any[]>([]);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				// let USER_INFO: any = localGetAccount();
				// USER_INFO = JSON.parse(USER_INFO);
				// console.log("USER_INFO", USER_INFO);
				// loadUser(USER_INFO.user_code);
				// form.setFieldsValue({
				// 	receive_user_by: `${USER_INFO.user.user_code}`
				// });
				// setUser(USER_INFO);
				if (detail?.id) {
					onChangeProvince(detail.province_id, true);
					onChangeDistrict(detail.district_id, true);
					form.setFieldsValue(detail);
				}
				setLoading(true);
				// const res: any = await _apiNotSaga.getApps({ limit: 999, status: "A" });
				// setDataApp(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []); //eslint-disable-line
	useEffect(() => {
		dispatch(getProvinces());
	}, []);

	const onSubmit = async (val: any) => {
		const params = {
			...val
		};
		setLoading(true);
		try {
			// params.operation_type = type;
			// params.wh_to_id = params.wh_to_id ? params.wh_to_id : params.wh_from_id;
			// params.wh_from_id = val.wh_from_id ? val.wh_from_id : val.wh_to_id;
			const res: any = detail?.id ? await _apiNotSaga.updatePartnerAddress(detail.id, params) : await _apiNotSaga.createPartnerAddress(partnerId, params);
			if (!res.success) {
				setLoading(false);
				notifyError(res.message);
				return;
			}
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
			console.log("first", window.location);
			// const currentUrl = window.location.origin; // Get the current URL
			// const newPath = "/chi-tiet-xnk/" + res.data.insertId; // Define the new relative path
			// const newUrl = `${currentUrl}${newPath}`; // Combine the current URL with the new path
			// window.open(newUrl, "_blank", "noopener,noreferrer");
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const onChangeProvince = async (e: any, isInit = false) => {
		if (!isInit) {
			form.setFieldsValue({
				province_name: (stateProvinces?.data || []).find((item: any) => item.id === e)?.province_name,
				district_id: undefined,
				ward_id: undefined,
				district_name: undefined,
				ward_name: undefined
			});
		}
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistricts(district.data);
		}
	};
	const onChangeDistrict = async (e: any, isInit = false) => {
		if (!isInit) {
			form.setFieldsValue({
				ward_id: undefined,
				ward_name: undefined,
				district_name: districts.find((item: any) => item.id === e)?.district_name
			});
		}
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWards(ward.data);
		}
	};
	return (
		<>
			<Modal
				open={visible}
				width={1000}
				onCancel={onCancel}
				// maskClosable={false}
				title={detail?.id ? `Chỉnh sửa địa chỉ` : `Thêm địa chỉ`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="formDetail"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="formDetail" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[12, 12]}>
							<Col xs={4} md={12}>
								<FormInput
									name="name"
									label="Tên địa chỉ"
									type="text"
									size="large"
									placeholder="Nhập tên địa chỉ"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col md={12} xs={12} sm={12}>
								<FormInput
									name="phone"
									label="Số điện thoại"
									type="phone"
									size="large"
									placeholder="Nhập số điện thoại"
									required
									// disabled
								/>
							</Col>
							<Col xs={24} md={24}>
								<FormInput
									name="address"
									label="Địa chỉ"
									type="text"
									size="large"
									placeholder="Nhập địa chỉ"
									required
									// disabled={!isCreate}
									// disabled
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="province_id"
									label="Tỉnh thành"
									dataSource={stateProvinces?.data || []}
									placeholder="Chọn tỉnh thành"
									slOption={{
										name: "province_name",
										value: "id"
									}}
									required={true}
									// disabled={disableAddress}
									onChange={onChangeProvince}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="district_id"
									label="Quận huyện"
									dataSource={districts}
									placeholder="Chọn quận huyện"
									slOption={{
										name: "district_name",
										value: "id"
									}}
									required={true}
									// required={!disableAddress}
									// disabled={disableAddress}
									onChange={onChangeDistrict}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="ward_id"
									label="Phường xã"
									dataSource={wards}
									placeholder="Chọn phường xã"
									slOption={{
										name: "ward_name",
										value: "id"
									}}
									required={true}
									// required={!disableAddress}
									// disabled={disableAddress}
									onChange={(e) => {
										form.setFieldsValue({
											r_ward_name: (wards || []).find((item: any) => item.id === e)?.ward_name
										});
									}}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default CreateDetailModal;
