import { Button, Card, Col, Image, Input, Popconfirm, Row, Table, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import TagStatus from "src/components/TagStatus/tagStatus";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { CND_URL } from "src/services/api/config";
import { replaceSpecialCharacters } from "src/utils/functions";
import { columnsData } from "./data";

const Tracuuvandon = () => {
	const [searchTxt, setSearchTxt] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [billInfo, setBillInfo] = useState<any>({});
	const handleSearch = () => {
		// router.push({
		//   pathname: `/tra-van-don`,
		//   query: {q: searchTxt},
		// })

		setIsLoading(true);
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setIsLoading(true);
				const res: any = await _apiNotSaga.fetchSearchEs(searchTxt, {});
				setBillInfo(res?.data);
				setIsLoading(false);
			} catch (error: any) {
				setIsLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
	};
	const columns = [
		{
			title: "Thời gian",
			dataIndex: "created_at",
			key: "created_at",
			render: (text: any) => <p className="text-left font-medium">{moment(text).format("DD/MM/YYYY HH:mm")}</p>
		},

		{
			title: "Hành trình",
			dataIndex: "bill_status_name",
			key: "bill_status_name",
			render: (text: any, record: any) => (
				<div className="flex flex-col">
					<p className="hidden text-left text-black font-medium">{text}</p>
					{/* <p className="text-left font-medium">
                {record?.district} - {record?.city}
              </p> */}
					<p className="text-left font-medium">{record?.desc_history}</p>
				</div>
			)
		}
	];
	return (
		<>
			<div className="relative flex h-[350px] w-full flex-col items-center justify-start">
				{/* <div
					style={{
						backgroundImage: "url(/tracuu.png)",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "cover"
					}}
					className="absolute left-0 top-0 flex h-[350px] w-full items-center justify-center max-md:h-[350px] "
				></div> */}
				<div className="w-full flex flex-col items-center justify-center md:px-5">
					<div className=" z-10 flex w-full bg-white flex-col items-center justify-center p-5 my-6 max-md:p-2">
						<div className="flex w-full items-center space-x-4 max-md:space-x-2">
							<Input
								type="text"
								value={searchTxt}
								className="h-[55px] rounded-md bg-bginput text-[20px] max-md:h-[44px] max-md:text-15"
								onChange={(e) => setSearchTxt(replaceSpecialCharacters(e.target.value))}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleSearch();
									}
								}}
								placeholder={"Nhập mã vận đơn"}
							/>
							<Button
								onClick={() => handleSearch()}
								// type="submit"
								// size={"search"}
								className="inline-flex h-[55px] w-[137px] items-center justify-center gap-2.5  rounded-md  bg-black px-[13px] py-2 text-white max-md:h-[44px]"
							>
								<p className="text-center text-[20px] font-medium leading-normal">Tìm kiếm</p>
							</Button>
						</div>
						{/* <div className="flex w-full pt-2 justify-between">
							<p className="text-black max-md:text-13">
								Hotline:{" "}
								<a href="tel:(028) 3848 0642">
									<span className="font-medium text-black">(028) 3848 0642</span>
								</a>{" "}
								hoặc Email:{" "}
								<a href="mailto:info@lsc.com">
									<span className="font-medium text-black">info@lsc.com</span>
								</a>
							</p>
						</div> */}
					</div>
					<div className="flex w-full flex-col items-center justify-center   ">
						<div className="w-full flex items-center justify-center">
							{!billInfo.bill_code ? (
								<Card bordered={false}></Card>
							) : (
								<Card className="w-full"
									title={
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<div style={{ display: "flex" }}>
												<div>
													<QRCode value={billInfo.bill_code} style={{ height: 50, width: 50 }} />
												</div>
												<div style={{ marginLeft: 10 }}>
													<h1>
														<strong>{billInfo.bill_code}</strong>
													</h1>
													<TagStatus status={billInfo.bill_status_id} />
												</div>
											</div>
											<div style={{ display: "flex" }}>
												{/* <Button onClick={() => returnBill()}>Hoàn đơn</Button> */}
												{/* <Popconfirm
													title="Bạn có chắc muốn huỷ đơn hay không?"
													style={{ borderRadius: "10px" }}
													okButtonProps={{
														style: {
															background: "black",
															color: "#fdd800"
														}
													}}
													cancelButtonProps={{
														style: {
															background: "#dfe2e4",
															color: "black"
														}
													}}
													onConfirm={() => {
														cancelBill();
													}}
													okText="Đồng ý"
													cancelText="Hủy"
												>
													<Button>Hủy đơn</Button>
												</Popconfirm> */}

												{/* <Button onClick={() => printBill()}>In đơn</Button> */}
											</div>
										</div>
									}
									bordered={false}
								>
									<Row gutter={[16, 16]}></Row>
									<Row gutter={[16, 16]}>
										<Col span={12}>
											<Card title="Thông tin người gửi" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
												<p>
													Sân bay: <strong>{billInfo.s_name}</strong>
												</p>
												<p>
													Hãng bay: <strong>{billInfo.partner_name}</strong>
												</p>
												<p>
													Ngày tạo: <strong>{moment(billInfo.created_at).format("DD-MM-YYYY HH:mm:ss")}</strong>
												</p>
											</Card>
										</Col>

										<Col span={12}>
											<Card title="Thông tin người nhận" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
												<p>
													Họ tên: <strong>{billInfo.r_name}</strong>
												</p>
												<p>
													SDT: <strong>{billInfo.r_phone}</strong>
												</p>
												<p>
													Địa chỉ:{" "}
													<strong>
														{billInfo.r_address}, {billInfo.r_ward_name ? `${billInfo.r_ward_name},` : ""} {billInfo.r_district_name},{" "}
														{billInfo.r_province_name}
													</strong>
												</p>
											</Card>
										</Col>
									</Row>
									<Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
										<Col span={24}>
											<Card title="Thông tin vận đơn" bordered={false} size="small" headStyle={{ fontWeight: 600 }}>
												<div style={{ display: "flex", gap: 40 }}>
													<div style={{ width: "50%" }}>
														Dịch vụ: <strong>{billInfo.service_name}</strong>
													</div>
													<div style={{ width: "50%" }}>
														Số kiện: <strong>{billInfo.package_number}</strong>
													</div>
												</div>
												<div style={{ display: "flex", gap: 40 }}>
													<div style={{ width: "50%" }}>
														Loại hàng hoá: <strong>{billInfo.goods_type_name}</strong>
													</div>
													<div style={{ width: "50%" }}>
														Kích thước hàng hoá (Cm):{" "}
														<strong>
															{billInfo.length}x{billInfo.width}x{billInfo.height}
														</strong>
													</div>
												</div>
												<div style={{ display: "flex", gap: 40 }}>
													<div style={{ width: "50%" }}>
														Số SEAL:{" "}
														<strong>{billInfo.seal_number ? JSON.parse(billInfo.seal_number).join(",") : ""}</strong>
														{/* <TextArea
												style={{ borderRadius: 8 }}
												rows={3}
												disabled={true}
												defaultValue={billInfo.seal_number ? JSON.parse(billInfo.seal_number).join(",") : ""}
											></TextArea> */}
													</div>
													<div style={{ width: "50%" }}>
														Trọng lượng (Kg): <strong>{billInfo.weight}</strong>
													</div>
												</div>

												<div style={{ display: "flex", gap: 40 }}>
													<div style={{ width: "50%" }}>
														<div>Ghi chú:</div>
														<TextArea
															style={{ borderRadius: 8 }}
															rows={3}
															disabled={true}
															defaultValue={billInfo.note}
														></TextArea>
													</div>
													<div style={{ width: "50%" }}>
														<div>Nội dung hàng hóa:</div>
														<TextArea
															style={{ borderRadius: 8 }}
															rows={3}
															disabled={true}
															defaultValue={billInfo.cargo_content}
														></TextArea>
													</div>
												</div>
												<div style={{ display: "flex", gap: 40, marginTop: 10 }}>
													<div style={{ width: "50%" }}>
														<div className="font-bold">Hình ảnh vận đơn lấy hàng:</div>
														{billInfo?.image_send_list?.length > 0 && (
															<Row>
																<Col span={24}>
																	<Image
																		src={`${CND_URL}${billInfo?.image_send_list}`}
																		style={{ cursor: "pointer", maxHeight: "100px" }}
																	/>
																</Col>
															</Row>
														)}
													</div>
													<div style={{ width: "50%" }}>
														<div className="font-bold">Hình ảnh vận đơn trả hàng:</div>
														{billInfo?.image_receive_list?.length > 0 && (
															<Row>
																<Col span={24}>
																	<Image
																		src={`${CND_URL}${billInfo?.image_receive_list}`}
																		style={{ cursor: "pointer", maxHeight: "100px" }}
																	/>
																</Col>
															</Row>
														)}
													</div>
												</div>
												{/* <p>
										Số SEAL: <strong>{billInfo.seal_number ? JSON.parse(billInfo.seal_number).join(",") : ""}.</strong>
									</p> */}
												{/* <div style={{ marginBottom: 8 }}>
										<div>Số SEAL:</div>
										<TextArea
											style={{ borderRadius: 8 }}
											rows={3}
											disabled={true}
											defaultValue={billInfo.seal_number ? JSON.parse(billInfo.seal_number).join(",") : ""}
										></TextArea>
									</div> */}

												<div></div>
											</Card>
										</Col>

										{/* <Col span={12}>
								<Card title="Hành trình vận đơn" bordered={false} size="small" headStyle={{ fontWeight: 600 }}>
									<div className="timeline_container">
										<Timeline mode="left" style={{ alignContent: "start" }}>
											{(billInfo.log || []).map((item: any) => (
												<Timeline.Item position="left" label={moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}>
													<div className="flex justify-start">
														
														<div style={{ marginLeft: 10 }}>
															<p style={{ fontWeight: 600 }}>{item.bill_status_name}</p>
															<p>{item.desc_history}</p>
															{item.bill_status_id == 10 && billInfo.image_receive_list.length ? (
																<>
																	<Image
																		preview={{ visible: false }}
																		width={100}
																		src={`${CND_URL}${billInfo.image_receive_list[0]}`}
																		onClick={() => setVisible(true)}
																	/>
																	<div style={{ display: "none" }}>
																		<Image.PreviewGroup
																			preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
																		>
																			{billInfo.image_receive_list.map((item: any) => (
																				<Image src={`${CND_URL}${item}`} />
																			))}
																		</Image.PreviewGroup>
																	</div>
																</>
															) : (
																<></>
															)}
															{item.bill_status_id == 6 && billInfo.image_report_list.length ? (
																<>
																	<Image
																		preview={{ visible: false }}
																		width={100}
																		src={`${CND_URL}${billInfo.image_report_list[0]}`}
																		onClick={() => setVisible(true)}
																	/>
																	<div style={{ display: "none" }}>
																		<Image.PreviewGroup
																			preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
																		>
																			{billInfo.image_report_list.map((item: any) => (
																				<Image src={`${CND_URL}${item}`} />
																			))}
																		</Image.PreviewGroup>
																	</div>
																</>
															) : (
																<></>
															)}
															{item.bill_status_id == 2 && billInfo.image_send_list.length ? (
																<>
																	<Image
																		preview={{ visible: false }}
																		width={100}
																		src={`${CND_URL}${billInfo.image_send_list[0]}`}
																		onClick={() => setVisible(true)}
																	/>
																	<div style={{ display: "none" }}>
																		<Image.PreviewGroup
																			preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
																		>
																			{billInfo.image_send_list.map((item: any) => (
																				<Image src={`${CND_URL}${item}`} />
																			))}
																		</Image.PreviewGroup>
																	</div>
																</>
															) : (
																<></>
															)}
														</div>
													</div>
												</Timeline.Item>
											))}
										</Timeline>
									</div>
								</Card>
							</Col> */}
									</Row>
									<hr className=" mb-[10px] border-neutral_color_1_2" />
									<div className="contentSection-main">
										<Card title="Hành trình vận đơn" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
											<TableStyled
												rowKey="id"
												dataSource={billInfo.log_erp}
												bordered
												// pagination={{
												// 	locale: {
												// 		items_per_page: "/Trang"
												// 	},
												// 	current: params.page,
												// 	showSizeChanger: true,
												// 	defaultPageSize: params.limit,
												// 	total: dataBills?.child?.length || 0,
												// 	showTotal: (total: any, range: any) => (
												// 		<div className="flex gap-2">
												// 			<strong>
												// 				{range[0]} - {range[1]}
												// 			</strong>
												// 			trong tổng
												// 			<strong>{total}</strong>
												// 		</div>
												// 	),
												// 	onChange: onChangePaging
												// }}
												// scroll={{
												// 	x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
												// 	y: "calc(70vh - 80px)"
												// }}
												isRowLight={true}
												columns={columnsData({}) as any}
											/>
										</Card>
									</div>
								</Card>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Tracuuvandon;
