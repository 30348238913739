const accountTypes = {
  START_SIGNIN: "START_SIGNIN",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",

  START_REGISTER: "START_REGISTER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

	SIGNIN_GOOGLE_START : "SIGNIN_GOOGLE_START",
	SIGNIN_GOOGLE_SUCCESS : "SIGNIN_GOOGLE_SUCCESS",
	SIGNIN_GOOGLE_FAILURE : "SIGNIN_GOOGLE_FAILURE",

  SIGN_OUT: "SIGN_OUT",
};

export default accountTypes;
