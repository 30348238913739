import { call, put, takeLatest } from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { IAction, IRootResponse } from "../../interfaces/root.interfaces";
import { actionFailure, actionSuccess } from "../root.actions";
import userTypes from "../types/user.types";

function* getListUser(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getUsers(payload?.params)
		});
		yield put(actionSuccess(userTypes.GET_USERS_SUCCESS, res));
	} catch (err: any) {
		yield put(actionFailure(userTypes.GET_USERS_FAILURE, err));
	}
}

const userSagas = [
	takeLatest(userTypes.START_GET_USERS, getListUser),
];

export default userSagas;
