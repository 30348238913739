import * as React from "react"
import { SVGProps } from "react"
const SvgView = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <rect width={26} height={26} x={2} y={2} stroke="#D3D3D3" rx={4.5} />
    <path
      fill="#001529"
      fillRule="evenodd"
      d="M15 10.09a7.092 7.092 0 0 0-6.73 4.851.19.19 0 0 0 0 .121 7.095 7.095 0 0 0 13.458-.003v-.002a.18.18 0 0 0 0-.116v-.003A7.094 7.094 0 0 0 15 10.091Zm-7.764 4.507a8.183 8.183 0 0 1 15.527-.004c.088.264.088.548 0 .81a8.185 8.185 0 0 1-15.527.003 1.282 1.282 0 0 1 0-.809"
      clipRule="evenodd"
    />
    <path
      fill="#001529"
      fillRule="evenodd"
      d="M15 13.364a1.636 1.636 0 1 0 0 3.272 1.636 1.636 0 0 0 0-3.272Zm-1.929-.293a2.727 2.727 0 1 1 3.857 3.857 2.727 2.727 0 0 1-3.857-3.857Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgView
