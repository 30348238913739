import { call, put, takeLatest } from 'redux-saga/effects';
import { API_CUSTOMER } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';/*eslint-disable-line*/
import { actionFailure, actionSuccess } from '../root.actions';
import customerTypes from '../types/customer.types';

function* getListCustomer(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.get(`${API_URL}/${API_CUSTOMER}`, payload?.params)
    });
    yield put(actionSuccess(customerTypes.GET_LIST_CUSTOMER_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(customerTypes.GET_LIST_CUSTOMER_FAILURE, err));
  }
};

function* getCustomerById(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.get(`${API_URL}/${API_CUSTOMER}/${payload.id}`)
    });
    yield put(actionSuccess(customerTypes.GET_CUSTOMER_BY_ID_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(customerTypes.GET_CUSTOMER_BY_ID_FAILURE, err));
  }
};

function* genCodeCustomerById(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_CUSTOMER}/${payload.id}`)
    });
    yield put(actionSuccess(customerTypes.GEN_CODE_CUSTOMER_BY_ID_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(customerTypes.GEN_CODE_CUSTOMER_BY_ID_FAILURE, err));
  }
};

function* updateOneCustomer(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.put(`${API_URL}/${API_CUSTOMER}/${payload.id}`, payload.params)
    });
    yield put(actionSuccess(customerTypes.UPDATE_ONE_CUSTOMER_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(customerTypes.UPDATE_ONE_CUSTOMER_FAILURE, err));
  }
};

function* createOneCustomer(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_CUSTOMER}`, payload.params)
    });
    yield put(actionSuccess(customerTypes.CREATE_ONE_CUSTOMER_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(customerTypes.CREATE_ONE_CUSTOMER_FAILURE, err));
  }
};
const customerSagas=[
  takeLatest(customerTypes.START_GET_LIST_CUSTOMER, getListCustomer),
  takeLatest(customerTypes.START_GET_CUSTOMER_BY_ID, getCustomerById),
  takeLatest(customerTypes.START_GEN_CODE_CUSTOMER_BY_ID, genCodeCustomerById),
  takeLatest(customerTypes.START_UPDATE_ONE_CUSTOMER, updateOneCustomer),
  takeLatest(customerTypes.START_CREATE_ONE_CUSTOMER, createOneCustomer),
]
export default customerSagas

