import { Button, Col, DatePicker, Dropdown, Form, Menu, Modal, Row, Select, Switch, Tabs, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import moment from "moment";
import SvgPen from "src/assets/svg/SvgPen";
import styled from "styled-components";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import { dataOrg, JUST_NUMBER } from "src/constants";
import { localGetAccount } from "src/utils/localStorage";
import FormItem from "antd/lib/form/FormItem";
import { debounce } from "lodash";
import { APPLY_TYPE_LIST, CALC_TYPE_LIST } from "src/utils/helpers/functions/data";
import FormSelectDate from "src/components/form/FormSelectDate";
import { dateFormatYMD } from "src/utils/helpers/functions/date";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
const { Item } = Form;
const { Option } = Select;
const CreateOperationModal = ({
	id,
	priceListId,
	priceDetail,
	record,
	visible = true,
	isEdit = false,
	onCancel = () => {},
	onSuccess = () => {}
}: any) => {
	const [form] = Form.useForm();
	const [status, setStatus] = useState<boolean>(true);
	const [applyType, setApplyType] = useState<number>(1);
	const [zones, setZones] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [user, setUser] = useState<any>([]);
	const [listUser, setListUser] = useState([]);
	const { stateWarehouses, stateProvinces, stateGoodTypes, stateServices } = useSelector(
		(e: AppState) => e.globalReducer
	);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				// let USER_INFO: any = localGetAccount();
				// USER_INFO = JSON.parse(USER_INFO);
				// console.log("USER_INFO", USER_INFO);
				// loadUser(USER_INFO.user_code);
				// form.setFieldsValue({
				// 	receive_user_by: `${USER_INFO.user.user_code}`
				// });
				// setUser(USER_INFO);
				getZones({});
				if (isEdit) {
					form.setFieldsValue({ ...record });
					setApplyType(record.apply_type_id)
				}
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, [id]);

	const getZones = async (val: any) => {
		const res: any = await _apiNotSaga.getZones({});
		const data = res?.data;
		setZones(data || []);
	};

	const onSubmit = async (val: any) => {
		const params = {
			...val
		};
		setLoading(true);
		try {
			params.status = status ? "A" : "D";
			// params.start_at = moment(params.start_at).format(dateFormatYMD);
			// params.end_at = moment(params.end_at).format(dateFormatYMD);
			const res: any = await (isEdit
				? _apiNotSaga.updatePriceDetail(id, params)
				: _apiNotSaga.createPriceDetail(priceListId, params));
			setLoading(false);
			if (!res.success) {
				notifyError(res.message);
				return;
			}
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const loadUser = async (q: any) => {
		if (!q || q === "") return;
		const res: any = await _apiNotSaga.getUsers({ limit: 999, status: "A", q });
		setListUser(res.data);
		// dispatch(fetchUserFromTicket({ do_get_list: true, user_erp_code: q }));
	};

	return (
		<>
			<Modal
				open={visible}
				width={1000}
				onCancel={onCancel}
				// maskClosable={false}
				title={isEdit ? "Chỉnh sửa quy tắc áp giá" : "Tạo quy tắc áp giá"}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Trạng thái &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm1"
							style={{ backgroundColor: "#212E3B" }}
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<p className="text-[#fff] text-md">{isEdit ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm1" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 8]}>
							<Col xs={8} md={8}>
								<FormSelect
									name="zone_id"
									label="Vùng"
									dataSource={zones}
									placeholder="Chọn vùng"
									slOption={{
										name: "zone_name",
										value: "id"
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormInput
									name="fr_weight"
									label="Trọng lượng từ (kg)"
									type={JUST_NUMBER}
									size="large"
									placeholder="Nhập trọng lượng"
									required
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormInput
									name="to_weight"
									label="Trọng lượng đến (kg)"
									type={JUST_NUMBER}
									size="large"
									placeholder="Nhập trọng lượng"
									required
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormSelect
									name="apply_type_id"
									label="Kiểu đơn giá"
									dataSource={APPLY_TYPE_LIST}
									placeholder="Chọn kiểu đơn giá"
									slOption={{
										name: "apply_type_name",
										value: "id"
									}}
									onChange={(e) => {
										setApplyType(e);
									}}
									required={true}
									showSearch
									optionFilterProp="children"
								/>
							</Col>

							<Col xs={8} md={8}>
								<FormInput
									name="main_fee"
									label="Đơn giá (Vnđ)"
									type={JUST_NUMBER}
									size="large"
									placeholder="Nhập số tiền"
									required
									// disabled={!isCreate}
								/>
							</Col>
							{/* <Col xs={8} md={8}>
							</Col> */}
							{applyType === 2 && (
								<>
									<Col xs={8} md={8}>
										<FormInput
											name="per_increase"
											label="Bước trọng lượng (kg)"
											type={JUST_NUMBER}
											size="large"
											placeholder="0.5"
											required
											// disabled={!isCreate}
										/>
									</Col>
									<Col xs={8} md={8}>
										<FormInput
											name="init_fee"
											label="Trọng lượng ban đầu"
											type={JUST_NUMBER}
											size="large"
											placeholder="Nhập trọng lượng ban đầu"
											required
											// disabled={!isCreate}
										/>
									</Col>
									<Col xs={8} md={8}>
										<FormInput
											name="init_weight"
											label="Giá ban đầu"
											type={JUST_NUMBER}
											size="large"
											placeholder="Nhập giá bắt đầu"
											required
											// disabled={!isCreate}
										/>
									</Col>
								</>
							)}

							{/* <Col xs={24} md={12}></Col> */}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default CreateOperationModal;
