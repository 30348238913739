import {
	Button,
	Col,
	Dropdown,
	Form,
	InputNumber,
	Menu,
	Modal,
	Radio,
	Row,
	Select,
	Switch,
	Tabs,
	Tag,
	Typography
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import moment from "moment";
import SvgPen from "src/assets/svg/SvgPen";
import styled from "styled-components";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import { dataOrg } from "src/constants";
import { localGetAccount } from "src/utils/localStorage";
import FormItem from "antd/lib/form/FormItem";
import { debounce } from "lodash";
import { getGoodTypes, getProvinces, getServices } from "src/services/actions/global.actions";
import { FormatInputMoney } from "src/utils/helpers/functions/utils";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
const { Item } = Form;
const { Option } = Select;
const CreateModalBill = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} ,id = ''}) => {
	const [form] = Form.useForm();
	const isCreate = detail?.id ? false : true;
	const [status, setStatus] = useState<boolean>(true);
	const [isSupper, setIsSupper] = useState<boolean>(false);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [user, setUser] = useState<any>([]);
	const [listUser, setListUser] = useState([]);
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	const { stateWarehouses } = useSelector((e: AppState) => e.globalReducer);
	const [districts, setDistricts] = useState<any[]>([]);
	const [wards, setWards] = useState<any[]>([]);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				let USER_INFO: any = localGetAccount();
				USER_INFO = JSON.parse(USER_INFO);
				console.log("USER_INFO", USER_INFO);
				loadUser(USER_INFO.user.user_code);
				form.setFieldsValue({
					// receive_user_by: `${USER_INFO.user.user_code}`,
					...detail
				});
				setUser(USER_INFO);
				setLoading(true);
				// const res: any = await _apiNotSaga.getApps({ limit: 999, status: "A" });
				// setDataApp(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, [detail]); //eslint-disable-line
	useEffect(() => {
		dispatch(getGoodTypes());
	}, []);

	const onSubmit = async (val: any) => {
		const params = {
			...val,
            bill_code: detail?.bill_code,
            total_fee: Number(val?.total_fee) + Number(val?.other_fee)
		};
		setLoading(true);
		try {
			// params.operation_type = type;
			// params.wh_to_id = params.wh_to_id ? params.wh_to_id : params.wh_from_id;
			// params.wh_from_id = val.wh_from_id ? val.wh_from_id : val.wh_to_id;
			const res: any = await _apiNotSaga.updateDebBill(id, params);
			if (!res.success) {
				setLoading(false);
				notifyError(res.message);
				return;
			}
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
			console.log("first", window.location);
			// const currentUrl = window.location.origin; // Get the current URL
			// const newPath = "/chi-tiet-xnk/" + res.data.insertId; // Define the new relative path
			// const newUrl = `${currentUrl}${newPath}`; // Combine the current URL with the new path
			// window.open(newUrl, "_blank", "noopener,noreferrer");
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const loadUser = async (q: any) => {
		if (!q || q === "") return;
		const res: any = await _apiNotSaga.getUsers({ limit: 999, status: "A", q });
		setListUser(res.data);
		// dispatch(fetchUserFromTicket({ do_get_list: true, user_erp_code: q }));
	};
	const onChangeProvince = async (e: any) => {
		form.setFieldsValue({
			province_name: (stateProvinces?.data || []).find((item: any) => item.id === e)?.province_name,
			district_id: undefined,
			ward_id: undefined,
			district_name: undefined,
			ward_name: undefined
		});
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistricts(district.data);
		}
	};
	const onChangeDistrict = async (e: any) => {
		form.setFieldsValue({
			ward_id: undefined,
			ward_name: undefined,
			district_name: districts.find((item: any) => item.id === e)?.district_name
		});
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWards(ward.data);
		}
	};
	return (
		<>
			<Modal
				open={visible}
				width={1000}
				onCancel={onCancel}
				// maskClosable={false}
				title={`Chỉnh sửa vận đơn`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						{/* <div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							
						</div> */}
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm1"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{"Hoàn tất"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm1" onFinish={onSubmit} scrollToFirstError>
						<Row gutter={[16, 16]}>
							<Col md={8} xs={8} sm={8}>
								<FormSelect
									name="goods_type_id"
									label="Loại hàng hóa"
									dataSource={stateGoodTypes?.data || []}
									placeholder="Chọn loại hàng hóa"
									slOption={{
										name: "gst_name",
										value: "id"
									}}
									required={true}
									// disabled={disableAddress}
									onChange={(e) => {
										form.setFieldsValue({
											goods_type_name: (stateGoodTypes?.data || []).find((item: any) => item.id === e)?.gst_name
										});
									}}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col md={8} xs={8} sm={8} >
								<Form.Item
									label="TL hàng hoá (Kg)"
									name="weight"
									rules={[{ required: true, message: "Vui lòng nhập trọng lượng" }]}
								>
									<InputNumber formatter={FormatInputMoney} placeholder="TL hàng hoá (Kg)" min={0} className="w-full" />
								</Form.Item>
							</Col>
							<Col md={8} xs={8} sm={8} >
								<Form.Item
									label="Số kiện"
									name="package_number"
									rules={[{ required: true, message: "Vui lòng nhập số kiện" }]}
								>
									<InputNumber formatter={FormatInputMoney} placeholder="Số kiện" min={0} className="w-full" />
								</Form.Item>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<Form.Item
									label="TL quy đổi (Kg)"
									name="dm_weight"
									rules={[{ required: true, message: "Vui lòng nhập TL quy đổi (Kg)" }]}
								>
									<InputNumber formatter={FormatInputMoney} placeholder="TL quy đổi (Kg)" min={0} className="w-full" />
								</Form.Item>
							</Col>
							<Col md={8} xs={8} sm={8}>
								<Form.Item
									label="Cước (vnđ)"
									name="total_fee"
									rules={[{ required: true, message: "Vui lòng nhập Cước (vnđ)" }]}
								>
									<InputNumber disabled formatter={FormatInputMoney} placeholder="Cước (vnđ)" min={0} className="w-full" />
								</Form.Item>
							</Col>
                            <Col md={8} xs={8} sm={8}>
								<Form.Item
									label="Phí COD (Vnđ)"
									name="other_fee"
									rules={[{ required: true, message: "Vui lòng nhập Phí COD (Vnđ)" }]}
								>
									<InputNumber formatter={FormatInputMoney} placeholder="Phí COD (Vnđ)" min={0} className="w-full" />
								</Form.Item>
							</Col>
						</Row>
						
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default CreateModalBill;
