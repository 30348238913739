import { call, put, takeLatest } from "redux-saga/effects";
import { API_MODULE_FUNT } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import moduleFunctTypes from "../types/moduleFunct.types";

function* getModuleFunct(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}${API_MODULE_FUNT}/list-by-parent`, payload?.params);
		});
		yield put(actionSuccess(moduleFunctTypes.GET_LIST_MODULE_FUNCT_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(moduleFunctTypes.GET_LIST_MODULE_FUNCT_FAILURE, err));
	}
}

function* getModuleFunctDetail(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}${API_MODULE_FUNT}?parent_id=${payload.id}`);
		});
		
		yield put(actionSuccess(moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_FAILURE, err));
	}
}

function* getModuleFunctDetailChildren(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_MODULE_FUNT}/${payload.id}/children`);
		});
		yield put(actionSuccess(moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_CHILDREN_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(moduleFunctTypes.GET_MODULE_FUNCT_BY_ID_CHILDREN_FAILURE, err));
	}
}

const mouduleFuntSagas = [
	takeLatest(moduleFunctTypes.START_GET_LIST_MODULE_FUNCT, getModuleFunct),
	takeLatest(moduleFunctTypes.START_GET_MODULE_FUNCT_BY_ID, getModuleFunctDetail),
	takeLatest(moduleFunctTypes.START_GET_MODULE_FUNCT_BY_ID_CHILDREN, getModuleFunctDetailChildren)

];
export default mouduleFuntSagas;
