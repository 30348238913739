/* eslint-disable */
import { call, put, takeLeading } from "redux-saga/effects";
import { API_IMPORT_TEMPLATE, API_YEP_IMPORT_TEMPLATE } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import {
  IAction, ICatchError, IRootResponse
} from "../../interfaces/root.interfaces";
import { actionFailure, actionSuccess } from "../root.actions";
import importTypes from "../types/import.types";

function* importFileBooking(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.postMultipart(
        `${API_URL}/${API_YEP_IMPORT_TEMPLATE}`,
        payload.params
      );
    });
    yield put(actionSuccess(importTypes.GET_IMPORT_BY_TEMPLATE_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(importTypes.GET_IMPORT_BY_TEMPLATE_FAILURE, err));
  }
}

const importSagas = [
  takeLeading(importTypes.START_IMPORT_BY_TEMPLATE, importFileBooking),
];

export default importSagas;