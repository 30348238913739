import * as React from "react"

const SvgIconArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={11}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#2F80ED"
        d="m6.521 9.022 4.183-6.11c.194-.282.296-.567.296-.803 0-.457-.367-.74-.98-.74H.979c-.613 0-.979.283-.979.739 0 .236.102.516.296.799L4.48 9.02c.27.393.632.61 1.021.61.39 0 .752-.214 1.021-.608Z"
      />
    </g>
    {/* <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h11v11H0z" />
      </clipPath>
    </defs> */}
  </svg>
)

export default SvgIconArrowDown