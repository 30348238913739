import { call, put, takeLatest } from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces";//eslint-disable-line
import { actionFailure, actionSuccess } from "../root.actions";
import vehicleTypes from "../types/vehicle.types";

function* getVehicles(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getVehicles(payload?.params)
		});
		yield put(actionSuccess(vehicleTypes.GET_VEHICLES_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleTypes.GET_VEHICLES_FAILURE, err));
	}
}

function* getVehicleDetail(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getVehicleDetail(payload?.id)
		});
		yield put(actionSuccess(vehicleTypes.GET_VEHICLE_DETAIL_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleTypes.GET_VEHICLE_DETAIL_FAILURE, err));
	}
}

function* updateVehicle(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		console.log(payload)
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.updateVehicle(payload?.id, payload?.params)
		});
		yield put(actionSuccess(vehicleTypes.UPDATE_VEHICLE_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleTypes.UPDATE_VEHICLE_FAILURE, err));
	}
}
const vehicleSagas = [
    takeLatest(vehicleTypes.START_GET_VEHICLE_DETAIL, getVehicleDetail),
	takeLatest(vehicleTypes.START_UPDATE_VEHICLE, updateVehicle),
    takeLatest(vehicleTypes.START_GET_VEHICLES, getVehicles),
];

export default vehicleSagas;
