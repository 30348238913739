import { call, put, takeLatest } from "redux-saga/effects";
import { API_SUPPLIES_MANAGEMENT } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces";//eslint-disable-line
import { actionFailure, actionSuccess } from "../root.actions";
import suppliesTypes from "../types/supplies.type";
function* getSupliesManagement(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_SUPPLIES_MANAGEMENT}`, payload?.params);
		});
		yield put(actionSuccess(suppliesTypes.GET_SUPLIES_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(suppliesTypes.GET_SUPLIES_FAILURE, err));
	}
}

function* getSupliesManagementDetail(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_SUPPLIES_MANAGEMENT}/${payload.id}`,);
		});
		yield put(actionSuccess(suppliesTypes.GET_SUPLIES_DETAIL_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(suppliesTypes.GET_SUPLIES_DETAIL_FAILURE, err));
	}
}

function* updateSuplies(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.put(`${API_URL}/${API_SUPPLIES_MANAGEMENT}/${payload.id}`, payload.params);
		});
		yield put(actionSuccess(suppliesTypes.UPDATE_SUPLIES_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(suppliesTypes.UPDATE_SUPLIES_FAILURE, err));
	}
}

function* createSuplies(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.post(`${API_URL}/${API_SUPPLIES_MANAGEMENT}`, payload.params);
		});
		yield put(actionSuccess(suppliesTypes.CREATE_SUPLIES_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(suppliesTypes.CREATE_SUPLIES_FAILURE, err));
	}
}

const suppliesSagas = [
	takeLatest(suppliesTypes.START_GET_SUPLIES_MANAGEMENT, getSupliesManagement),
	takeLatest(suppliesTypes.START_GET_SUPLIES_DETAIL, getSupliesManagementDetail),
	takeLatest(suppliesTypes.START_UPDATE_SUPLIES, updateSuplies),
	takeLatest(suppliesTypes.START_CREATE_SUPLIES, createSuplies),
];

export default suppliesSagas;
