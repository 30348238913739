import { call, put, takeLatest } from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces";//eslint-disable-line
import { actionFailure, actionSuccess } from "../root.actions";
import vehicleTypeTypes from "../types/vehicleType.types";
import { API_VEHICLE_TYPE } from "./../../api/url.index";

function* getVehicleType(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getVehicleType(payload?.params)
		});
		yield put(actionSuccess(vehicleTypeTypes.GET_VEHICLE_TYPE_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleTypeTypes.GET_VEHICLE_TYPE_FAILURE, err));
	}
}

function* getVehicleTypeDetail(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getVehicleTypeDetail(payload?.id)
		});
		yield put(actionSuccess(vehicleTypeTypes.GET_VEHICLE_TYPE_DETAIL_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleTypeTypes.GET_VEHICLE_TYPE_DETAIL_FAILURE, err));
	}
}

function* createVehicleType(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.post(`${API_URL}/${API_VEHICLE_TYPE}`, payload?.params);
		});
		yield put(actionSuccess(vehicleTypeTypes.CREATE_VEHECLE_TYPE_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleTypeTypes.CREATE_VEHECLE_TYPE_FAILURE, err));
	}
}
function* updateVehicleType(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.put(`${API_URL}/${API_VEHICLE_TYPE}/${payload.id}`, payload?.params);
		});
		yield put(actionSuccess(vehicleTypeTypes.UPDATE_VEHICLE_TYPE_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(vehicleTypeTypes.UPDATE_VEHICLE_TYPE_FAILURE, err));
	}
}

const vehicleTypeSagas = [
	takeLatest(vehicleTypeTypes.START_GET_VEHICLE_TYPE, getVehicleType),
	takeLatest(vehicleTypeTypes.START_CREATE_VEHECLE_TYPE, createVehicleType),
	takeLatest(vehicleTypeTypes.START_UPDATE_VEHICLE_TYPE, updateVehicleType),
    takeLatest(vehicleTypeTypes.START_GET_VEHICLE_TYPE_DETAIL, getVehicleTypeDetail)
];

export default vehicleTypeSagas;
