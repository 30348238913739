import { call, put, takeLatest } from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import rolesTypes from "../types/roles.types";

function* getRoles(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getRoles(payload?.params)
		});
		yield put(actionSuccess(rolesTypes.GET_ROLES_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(rolesTypes.GET_ROLES_FAILURE, err));
	}
}

function* getRoleDetail(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.roleDetail(payload?.params)
		});
		yield put(actionSuccess(rolesTypes.GET_ROLE_DETAIL_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(rolesTypes.GET_ROLE_DETAIL_FAILURE, err));
	}
}

const rolesSagas = [
	takeLatest(rolesTypes.START_GET_ROLES, getRoles),
	takeLatest(rolesTypes.START_GET_ROLE_DETAIL, getRoleDetail),
];
export default rolesSagas;
