import React from 'react';
import { Row, Col, Timeline } from 'antd';
import { useParams } from "react-router-dom";
import DetailedBill from './detailedBill';

const TrackingInfo = () => {
	const { bill_code } = useParams<any>();
  return (
 	<DetailedBill billCode={bill_code}/>
  );
};

export default TrackingInfo;
