import { Reducer } from "redux";
import transportTripsTypes from "../actions/types/transportTrips.types";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { ITransportTripsState } from "../interfaces/transportTrips.interfaces";
import { rootState } from "./state/root.states";

const initState: ITransportTripsState = {
	stateCreateTransportTrip: { ...rootState },
	stateUpdateTransportTrip: { ...rootState },
	stateListTransportTrip: { ...rootState },
	stateReportOntime: { ...rootState },
	stateReportPerformances: { ...rootState },
	stateReportProductivity: { ...rootState },
	stateReportProblem: { ...rootState },
	stateDetailTransportTrip: { ...rootState },
	stateExportReportOntime: { ...rootState },
	stateReportExport: { ...rootState },
	stateReportEvaluation: { ...rootState },
	stateReportEvaluationDriver: { ...rootState },

};

const transportTripsReducer: Reducer<ITransportTripsState, ISyntheticAction> = (
	state: ITransportTripsState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case transportTripsTypes.START_CREATE_TRANSPORT_TRIP: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateCreateTransportTrip: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.CREATE_TRANSPORT_TRIP_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateCreateTransportTrip: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.CREATE_TRANSPORT_TRIP_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateCreateTransportTrip: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_UPDATE_TRANSPORT_TRIP: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateUpdateTransportTrip: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.UPDATE_TRANSPORT_TRIP_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateUpdateTransportTrip: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.UPDATE_TRANSPORT_TRIP_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateUpdateTransportTrip: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_TRANSPORT_TRIP: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateListTransportTrip: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_TRANSPORT_TRIP_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateListTransportTrip: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_TRANSPORT_TRIP_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateListTransportTrip: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_REPORT_ONTIME: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportOntime: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_ONTIME_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportOntime: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_ONTIME_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportOntime: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_REPORT_PERFORMANCE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportPerformances: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_PERFORMANCE_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportPerformances: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_PERFORMANCE_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportPerformances: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_REPORT_PRODUCTIVITY: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportProductivity: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_PRODUCTIVITY_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportProductivity: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_PRODUCTIVITY_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportProductivity: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_REPORT_PROBLEM: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportProblem: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_PROBLEM_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportProblem: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_PROBLEM_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportProblem: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_DETAIL_TRANSPORT_TRIP: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateDetailTransportTrip: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_DETAIL_TRANSPORT_TRIP_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateDetailTransportTrip: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_DETAIL_TRANSPORT_TRIP_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateDetailTransportTrip: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_REPORT_EXPORT: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportExport: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_EXPORT_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportExport: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_EXPORT_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportExport: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_REPORT_EVALUATION: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportEvaluation: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_EVALUATION_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportEvaluation: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_EVALUATION_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportEvaluation: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		/*************************** START *************************/
		case transportTripsTypes.START_GET_REPORT_EVALUATION_DRIVER: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportEvaluationDriver: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_EVALUATION_DRIVER_SUCCESS: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportEvaluationDriver: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case transportTripsTypes.GET_REPORT_EVALUATION_DRIVER_FAILURE: {
			const stateReducer: ITransportTripsState = {
				...state,
				stateReportEvaluationDriver: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		/**************************** END **************************/
		default:
			return state;
	}
};

export default transportTripsReducer;
