const exportTypes = {
  START_EXPORT_TEMPLATE: 'START_EXPORT_TEMPLATE',
  GET_EXPORT_TEMPLATE_SUCCESS: 'GET_EXPORT_TEMPLATE_SUCCESS',
  GET_EXPORT_TEMPLATE_FAILURE: 'GET_EXPORT_TEMPLATE_FAILURE',

  START_EXPORT_IDENTIFIER_TEMPLATE: 'START_EXPORT_IDENTIFIER_TEMPLATE',
  GET_EXPORT_IDENTIFIER_TEMPLATE_SUCCESS: 'GET_EXPORT_IDENTIFIER_TEMPLATE_SUCCESS',
  GET_EXPORT_IDENTIFIER_TEMPLATE_FAILURE: 'GET_EXPORT_IDENTIFIER_TEMPLATE_FAILURE',
  
  START_LINK_DOWNLOAD: 'START_LINK_DOWNLOAD',
  GET_LINK_DOWNLOAD_SUCCESS: 'GET_LINK_DOWNLOAD_SUCCESS',
  GET_LINK_DOWNLOAD_FAILURE: 'GET_LINK_DOWNLOAD_FAILURE',

  START_EXPORT_REPORT_ONTIME: 'START_EXPORT_REPORT_ONTIME',
  EXPORT_REPORT_ONTIME_SUCCESS: 'EXPORT_REPORT_ONTIME_SUCCESS',
  EXPORT_REPORT_ONTIME_FAILURE: 'EXPORT_REPORT_ONTIME_FAILURE',
  
  START_EXPORT_LIST_TRIP: 'START_EXPORT_LIST_TRIP',
  EXPORT_LIST_TRIP_SUCCESS: 'EXPORT_LIST_TRIP_SUCCESS',
  EXPORT_LIST_TRIP_FAILURE: 'EXPORT_LIST_TRIP_FAILURE',

  START_EXPORT_REPORT_PRODUCTIVITY: 'START_EXPORT_REPORT_PRODUCTIVITY',
  EXPORT_REPORT_PRODUCTIVITY_SUCCESS: 'EXPORT_REPORT_PRODUCTIVITY_SUCCESS',
  EXPORT_REPORT_PRODUCTIVITY_FAILURE: 'EXPORT_REPORT_PRODUCTIVITY_FAILURE',

  START_EXPORT_REPORT_PRODUCTIVITY_DETAILS: 'START_EXPORT_REPORT_PRODUCTIVITY_DETAILS',
  EXPORT_REPORT_PRODUCTIVITY_DETAILS_SUCCESS: 'EXPORT_REPORT_PRODUCTIVITY_DETAILS_SUCCESS',
  EXPORT_REPORT_PRODUCTIVITY_DETAILS_FAILURE: 'EXPORT_REPORT_PRODUCTIVITY_DETAILS_FAILURE',

  START_EXPORT_REPORT_USER: 'START_EXPORT_REPORT_USER',
  EXPORT_REPORT_USER_SUCCESS: 'EXPORT_REPORT_USER_SUCCESS',
  EXPORT_REPORT_USER_FAILURE: 'EXPORT_REPORT_USER_FAILURE',


  START_EXPORT_REPORT_PERFORMANCE: 'START_EXPORT_REPORT_PERFORMANCE',
  EXPORT_REPORT_PERFORMANCE_SUCCESS: 'EXPORT_REPORT_PERFORMANCE_SUCCESS',
  EXPORT_REPORT_PERFORMANCE_FAILURE: 'EXPORT_REPORT_PERFORMANCE_FAILURE',

  START_EXPORT_REPORT_PROBLEMS: 'START_EXPORT_REPORT_PROBLEMS',
  EXPORT_REPORT_PROBLEMS_SUCCESS: 'EXPORT_REPORT_PROBLEMS_SUCCESS',
  EXPORT_REPORT_PROBLEMS_FAILURE: 'EXPORT_REPORT_PROBLEMS_FAILURE',

  START_EXPORT_REPORT_EXPORT: 'START_EXPORT_REPORT_EXPORT',
  EXPORT_REPORT_EXPORT_SUCCESS: 'EXPORT_REPORT_EXPORT_SUCCESS',
  EXPORT_REPORT_EXPORT_FAILURE: 'EXPORT_REPORT_EXPORT_FAILURE',

  START_EXPORT_REPORT_EVALUATION_WH: 'START_EXPORT_REPORT_EVALUATION_WH',
  EXPORT_REPORT_EVALUATION_WH_SUCCESS: 'EXPORT_REPORT_EVALUATION_WH_SUCCESS',
  EXPORT_REPORT_EVALUATION_WH_FAILURE: 'EXPORT_REPORT_EVALUATION_WH_FAILURE',


  START_EXPORT_REPORT_ONTIME_SYNTHESIZE: 'START_EXPORT_REPORT_ONTIME_SYNTHESIZE',
  EXPORT_REPORT_ONTIME_SYNTHESIZE_SUCCESS: 'EXPORT_REPORT_ONTIME_SYNTHESIZE_SUCCESS',
  EXPORT_REPORT_ONTIME_SYNTHESIZE_FAILURE: 'EXPORT_REPORT_ONTIME_SYNTHESIZE_FAILURE',

  START_EXPORT_REPORT_EVALUATION_DRIVER: 'START_EXPORT_REPORT_EVALUATION_DRIVER',
  EXPORT_REPORT_EVALUATION_DRIVER_SUCCESS: 'EXPORT_REPORT_EVALUATION_DRIVER_SUCCESS',
  EXPORT_REPORT_EVALUATION_DRIVER_FAILURE: 'EXPORT_REPORT_EVALUATION_DRIVER_FAILURE',

  START_EXPORT_WH_INVENTORY: 'START_EXPORT_WH_INVENTORY',
  EXPORT_WH_INVENTORY_SUCCESS: 'EXPORT_WH_INVENTORY_SUCCESS',
  EXPORT_WH_INVENTORY_FAILURE: 'EXPORT_WH_INVENTORY_FAILURE',
}

export default exportTypes;