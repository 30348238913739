/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS } from "src/constants";
import "./style.scss";
import { _paramsFilter, columnsData, columnsDataDetail, columnsDataField } from "./data";
import TableStyled from "src/components/table/TableStyled";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { notifyError } from "src/components/notification";
import UserModal from "./UserModal";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const EmailField = () => {
    const rawData = [
        {
            id:11213,
            status:'Hồ sơ mới',
			name:'Xác nhận ứng tuyển',
			create_user:'5001043 - Lê Văn B',
			create_at:'20/10/2023 13:40',
			update_at:'12/12/2021 14:00',
        },  {
            id:11213,
            status:'Hồ sơ mới',
			name:'Xác nhận ứng tuyển',
			create_user:'5001043 - Lê Văn B',
			create_at:'20/10/2023 13:40',
			update_at:'12/12/2021 14:00',
        }
    ];
	const [data, setData] = useState<any>([]);
	const [paramsFilter, setParamsFilter] = useState<any>(DEFAULT_FILTER);
	const [visible, setVisible] = useState<any>(false);
	const [detail, setDetail] = useState<any>({});
	const [callback, setCallback] = useState<boolean>(false);
	const [loading, setLoading] = useState<any>(false);
	const [total, setTotal] = useState(0);
	const [dataSyscTicket, setDataSyscTicket] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [idCostsIncurred, setIdCostsIncurred] = useState<any>();
	const [visibleCostsIncurred, setVisibleCostsIncurred] = useState<any>(false);
	const [statusStatements, setStatusStatements] = useState<any>([]);
	const [statusPayment, setStatusPayment] = useState<any>([]);
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
    const onChangePaging = (page: number, pageSize: number) => {
		setParamsFilter((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
    const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
    useEffect(() => {
		const initpage = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const ress: any = await _apiNotSaga.getEmailField(paramsFilter);
				setData(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initpage();
	}, [callback,paramsFilter]);//eslint-disable-line
    const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
    const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
    const costsIncurredDetail = (id: any) => {
		setIdCostsIncurred(id);
		setVisibleCostsIncurred(true);
	};
	const onCreate: () => void = () => {
		setDetail({});
		setVisible(true);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...paramsFilter,
			...flexParams,
			page: 1
		});
		setParamsFilter(newParams);
	};
	const handleActions: any = {
		btnAdd: onCreate,
		btnSearch: onSearch,
		// btnImport: btnImport,
	};

	const actionsFilter = (val: any) => {
		const { idBtn } = val;
		handleActions[idBtn](val);
	};
	const cancelModal = () => {
		setVisible(false);
	};
	const onSuccessModal = () => {
		setCallback(!callback);
		setVisible(false);
	};
	const getDetail = async (id: number) => {
		setDetail({ id });
		setVisible(true);
	};
	return (
		<Loading spinning={loading}>
			{visible && <UserModal visible={visible} detail={detail} onCancel={cancelModal} onSuccess={onSuccessModal} />}
			<div className="contentSection">
				<div className="top-0 contentSection-filter">
					<FlexFilter
						onSearch={(v) => actionsFilter(v)}
						manipulation={[
							{
								paramsName: "btnAdd",
								icon: <PlusOutlined />,
								text: "Tạo mới"
							}
							// {
							// 	paramsName: "btnSysc",
							// 	icon: <TransactionOutlined />,
							// 	text: "Tạo ticket thanh toán"
							// }
						]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.input,
								placeholder: "Nhập tên trường email",
                                label: "Tên mẫu",
								paramsName: "search"
							},
							
							
                            // {
							// 	...FLEX_FILTER_ELEMENTS.select,
							// 	label: "Trạng thái",
							// 	placeholder: "Chọn trạng thái",
							// 	width: 160,
							// 	options: {
							// 		data: statusPayment,
							// 		keyOption: "id",
							// 		valueOption: "id",
							// 		nameOption: "name"
							// 	},
							// 	paramsName: "payment_status"
							// },
							// {
							// 	...FLEX_FILTER_ELEMENTS.createdRange,
							// 	initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
							// 	initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
							// 	width: 250
							// },
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined />
							},
							{ ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
						]}
					/>
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={data}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: paramsFilter.page,
							showSizeChanger: true,
							defaultPageSize: paramsFilter.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
					
						isRowLight={true}
						columns={columnsDataField({ onEdit, onCancel,getDetail, pushUpApproval, onCreateTicket, user: account }) as any}
						
					/>
				</div>
			</div>
		</Loading>
	);
};

export default EmailField;
