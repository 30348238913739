import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgBoxPen from "src/assets/svg/SvgBoxPen";
import SvgDashboard from "src/assets/svg/SvgDashboard";
import SvgEditBox from "src/assets/svg/SvgEditBox";
import SvgIcon00004 from "src/assets/svg/SvgIcon00004";
import SvgIcon00055 from "src/assets/svg/SvgIcon00055";
import SvgIcon00096 from "src/assets/svg/SvgIcon00096";
import SvgIcon00101_1 from "src/assets/svg/SvgIcon00101_1";
import SvgIcon00112_1 from "src/assets/svg/SvgIcon00112_1";
import SvgIcon00133 from "src/assets/svg/SvgIcon00133";
import SvgIcon00142 from "src/assets/svg/SvgIcon00142";
import SvgIcon00151 from "src/assets/svg/SvgIcon00151";
import SvgIcon00192 from "src/assets/svg/SvgIcon00192";
import SvgIcon00194 from "src/assets/svg/SvgIcon00194";
import SvgIcon00195 from "src/assets/svg/SvgIcon00195";
import SvgIcon00197 from "src/assets/svg/SvgIcon00197";
import SvgIcon00198 from "src/assets/svg/SvgIcon00198";
import SvgIcon00199 from "src/assets/svg/SvgIcon00199";
import SvgIconDown from "src/assets/svg/SvgIconDown";
import SvgIconMoveRight from "src/assets/svg/SvgIconMoveRight";
import SvgIconSearch from "src/assets/svg/SvgIconSearch";
import SvgMapBox from "src/assets/svg/SvgMapBox";
import SvgPrint from "src/assets/svg/SvgPrint";
import SvgRecycleBin from "src/assets/svg/SvgRecycleBin";
import SvgSearchBox from "src/assets/svg/SvgSearchBox";
import { typeIconUI } from "./typesIcon";
import SvgIconLocationVehicle from "src/assets/svg/SvgIconLocationVehicle";
import SvgIconPushUp from "src/assets/svg/SvgIconPushUp";
import SvgCancelStatement from "src/assets/svg/SvgCancelStatement";
import SvgAddBox from "src/assets/svg/SvgAddBox";
import SvgIcon00150 from "src/assets/svg/SvgIcon00150";
import SvgIconArrowDown from "src/assets/svg/SvgIconArrowDown";
import Svg00242 from "src/assets/svg/Svg00242";
import SvgConfigRoute from "src/assets/svg/SvgConfigRoute";
const CONFIG_ICON = (icon: typeIconUI, fill?: string, fillbg?: string) => {
	return {
		default: null,
		move_right: <SvgIconMoveRight fill={fill} />,
		down: <SvgIconDown fill={fill} />,
		menu_dashboard: <SvgDashboard fill={fill} />,
		search: <SvgIconSearch fill={fill} />,
		edit_box: <SvgEditBox fill={fill} />,
		map_box: <SvgMapBox fill={fill} fillbg={fillbg} />,
		search_box: <SvgSearchBox fill={fill} />,
		add_box: <SvgAddBox fill={fill} />,
		pen_box: <SvgBoxPen fill={fill} />,
		time_box: <Svg00242 fill={fill} />,
		config_route: <SvgConfigRoute fill={fill} />,
		recycle_bin: <SvgRecycleBin fill={fill} />,
		trash_box: <SvgIcon00151 fill={fill} fillbg={fillbg} />,
		three_dot: <Svg3Dot fill={fill} />,
		sub: <SvgIcon00133 fill={fill} />,
		print: <SvgPrint fill={fill} />,
		setting: <SvgIcon00194 fill={fill} />,
		hourglass: <SvgIcon00195 fill={fill} />,
		truck_box: <SvgIcon00142 fill={fill} fillbg={fillbg} />,
		truck: <SvgIcon00112_1 fill={fill} />,
		repair: <SvgIcon00096 fill={fill} />,
		cancel: <SvgIcon00101_1 fill={fill} />,
		list: <SvgIcon00192 fill={fill} fillbg={fillbg} />,
		user_box: <SvgIcon00197 fill={fill} fillbg={fillbg} />,
		right_box: <SvgIcon00199 fill={fill} fillbg={fillbg} />,
		lock: <SvgIcon00004 fill={fill} fillbg={fillbg} />,
		swap: <SvgIcon00198 fill={fill} fillbg={fillbg} />,
		trash: <SvgIcon00055 fill={fill} fillbg={fillbg} />,
		push_up: <SvgIconPushUp />,
		location_vehicle: <SvgIconLocationVehicle />,
		cancel_statement : <SvgCancelStatement />,
		split_box: <SvgIcon00150 fill={fill} />,
		arrow_down: <SvgIconArrowDown fill={fill} />,
	};
};

export default CONFIG_ICON;
