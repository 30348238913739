const repairManagementTypes={
    START_GET_LIST_REPAIR_MANAGEMENT: 'START_GET_LIST_REPAIR_MANAGEMENT',
  GET_LIST_REPAIR_MANAGEMENT_SUCCESS: 'GET_LIST_MAINTENANCE_SUCCESS',
  GET_LIST_REPAIR_MANAGEMENT_FAILURE: 'GET_LIST_REPAIR_MANAGEMENT_FAILURE',

  START_UPDATE_ONE_REPAIR_MANAGEMENT: 'START_UPDATE_ONE_REPAIR_MANAGEMENT',
  UPDATE_ONE_REPAIR_MANAGEMENT_SUCCESS: 'UPDATE_ONE_REPAIR_MANAGEMENT_SUCCESS',
  UPDATE_ONE_REPAIR_MANAGEMENT_FAILURE: 'UPDATE_ONE_REPAIR_MANAGEMENT_FAILURE',

  START_CREATE_ONE_REPAIR_MANAGEMENT: 'START_CREATE_ONE_REPAIR_MANAGEMENT',
  CREATE_ONE_REPAIR_MANAGEMENT_SUCCESS: 'CREATE_ONE_REPAIR_MANAGEMENT_SUCCESS',
  CREATE_ONE_REPAIR_MANAGEMENT_FAILURE: 'CREATE_ONE_REPAIR_MANAGEMENT_FAILURE',

  START_GET_REPAIR_MANAGEMENT_BY_ID: 'START_GET_REPAIR_MANAGEMENT_BY_ID',
  GET_REPAIR_MANAGEMENT_BY_ID_SUCCESS: 'GET_REPAIR_MANAGEMENT_BY_ID_SUCCESS',
  GET_REPAIR_MANAGEMENT_BY_ID_FAILURE: 'GET_REPAIR_MANAGEMENT_BY_ID_FAILURE',

  START_GEN_CODE_REPAIR_MANAGEMENT_BY_ID: 'START_GEN_CODE_REPAIR_MANAGEMENT_BY_ID',
  GEN_CODE_REPAIR_MANAGEMENT_BY_ID_SUCCESS: 'GEN_CODE_REPAIR_MANAGEMENT_BY_ID_SUCCESS',
  GEN_CODE_REPAIR_MANAGEMENT_BY_ID_FAILURE: 'GEN_CODE_REPAIR_MANAGEMENT_BY_ID_FAILURE',

}
export default repairManagementTypes