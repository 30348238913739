import * as React from "react"
import { SVGProps } from "react"
const IconSeal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31}
    fill="none"
    {...props}
  >
    <rect width={26} height={26} x={2.002} y={2.5} stroke="#D3D3D3" rx={4.5} />
    <path
      fill="#212E3B"
      d="M21.003 8.3h-4.988c-.33 0-.791.19-1.024.424l-6.838 6.838a1.203 1.203 0 0 0 0 1.697l5.091 5.091c.466.466 1.23.466 1.697 0l6.838-6.838c.233-.232.424-.694.424-1.024V9.5c0-.66-.54-1.2-1.2-1.2Zm-2.4 4.8a1.2 1.2 0 1 1 0-2.401 1.2 1.2 0 0 1 0 2.4Z"
    />
  </svg>
)
export default IconSeal
