function SvgIconPushUp(props) {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M23.3437 2.67185L15.5703 22.9375C15.5137 23.0843 15.414 23.2106 15.2843 23.2998C15.1547 23.389 15.0011 23.437 14.8437 23.4375H14.7891C14.6231 23.4252 14.4653 23.3602 14.3388 23.252C14.2124 23.1438 14.1238 22.998 14.0859 22.8359L11.8516 13.1484L2.16406 10.9375C2.00339 10.8978 1.85947 10.8081 1.75305 10.6814C1.64662 10.5547 1.58319 10.3974 1.57189 10.2323C1.56059 10.0672 1.60202 9.9028 1.69019 9.76276C1.77836 9.62271 1.90873 9.51428 2.06249 9.4531L22.3281 1.65623C22.4701 1.5994 22.6256 1.58549 22.7754 1.61622C22.9252 1.64695 23.0627 1.72097 23.1709 1.8291C23.279 1.93724 23.353 2.07473 23.3838 2.22454C23.4145 2.37435 23.4006 2.52988 23.3437 2.67185Z"
				fill="#40BAFF"
			/>
		</svg>
	);
}

export default SvgIconPushUp;
