/* eslint-disable */
import { Empty, Table } from "antd";
import { memo } from "react";
import styled from "styled-components";

interface TableStyledAntdInterfaces {
	widthCheckbox?: string;
	paddingItemHeader?: string;
	paddingItemBody?: string;
	heightHeader?: string;
	borderstyled?: string;
	backgroundHeader?: string;
	isRowLight?: boolean;
	rowClassName?: any;
	margin?: string;
	width?: string;
	height?: string;
	widthCol1?: string;
	widthCol2?: string;
	widthCol3?: string;
	widthCol4?: string;
	widthCol5?: string;
	widthCol6?: string;
	widthCol7?: string;
	widthCol8?: string;
	widthCol9?: string;
	widthCol10?: string;
	widthCol11?: string;
	widthCol12?: string;
	widthCol13?: string;

	className?: string;
	minheight?: string;
}

const TableStyledAntd = ({ widthCheckbox, className, isRowLight,rowClassName, ...rest }: any) => {
	const LOCALE_CONFIG = {
		emptyText: <Empty className="my-[5px] mx-[0px]" image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Trống</span>} />
	};
	return (
		<TableStyled
			{...rest}
			widthCheckbox={widthCheckbox}
			className={className}
			locale={LOCALE_CONFIG}
			rowClassName={rowClassName ? rowClassName : (r: any, idx: number) => (isRowLight && idx % 2 !== 0 ? "bg-neutral_color_1_9" : "bg-white")}
		/>
	);
};

TableStyledAntd.defaultProps = {
	widthCheckbox: "16px",
	paddingItemHeader: "4px 12px",
	heightHeader: "35px",
	paddingItemBody: "4px 12px",
	backgroundHeader: "#fdf4d7"
};
export default memo(TableStyledAntd);

const TableStyled = styled(Table)<TableStyledAntdInterfaces>`
	border: ${(props) => props.borderstyled};
	border-radius: 3px;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	margin: ${(props) => props.margin};
	overflow: hidden;
	.ant-table-container {
		font-size: 13px;
	}
	.ant-checkbox-inner {
		width: ${(props) => props.widthCheckbox}!important;
		height: ${(props) => props.widthCheckbox}!important;
	}
	.ant-spin-nested-loading {
		min-height: ${(props) => props.minheight}!important;
	}
	.ant-table-thead {
		height: ${(props) => props.heightHeader}!important;
		tr {
			${(props) =>
				props.widthCol1 &&
				`th:nth-child(1) {
            width:${props.widthCol1}!important;
            white-space:unset!important;
        }`}
			${(props) =>
				props.widthCol2 &&
				`th:nth-child(2) {
            width:${props.widthCol2}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol3 &&
				`th:nth-child(3) {
           width:${props.widthCol3}!important;
           white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol4 &&
				`th:nth-child(4) {
            width:${props.widthCol4}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol5 &&
				`th:nth-child(5) {
            width:${props.widthCol5}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol6 &&
				`th:nth-child(6) {
            width:${props.widthCol6}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol7 &&
				`th:nth-child(7) {
            width:${props.widthCol7}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol8 &&
				`th:nth-child(8) {
            width:${props.widthCol8}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol9 &&
				`th:nth-child(9) {
            width:${props.widthCol9}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol10 &&
				`th:nth-child(10) {
            width:${props.widthCol10}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol11 &&
				`th:nth-child(11) {
              width:${props.widthCol11}!important;
            white-space:unset!important;

          }`}
        ${(props) =>
				props.widthCol12 &&
				`th:nth-child(12) {
                width:${props.widthCol12}!important;
            white-space:unset!important;
                
            }`}
		}
		tr th.ant-table-cell {
			font-weight: 900;
			padding: ${(props) => props.paddingItemHeader}!important;
		}
		tr > th {
			background: ${(props) => props.backgroundHeader};
		}
	}

	.ant-table-tbody {
		tr {
			${(props) =>
				props.widthCol1 &&
				`td:nth-child(1) {
            width:${props.widthCol1}!important;
            white-space:unset!important;

        }`}
			${(props) =>
				props.widthCol2 &&
				`td:nth-child(2) {
            width:${props.widthCol2}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol3 &&
				`td:nth-child(3) {
           width:${props.widthCol3}!important;
           white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol4 &&
				`td:nth-child(4) {
            width:${props.widthCol4}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol5 &&
				`td:nth-child(5) {
            width:${props.widthCol5}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol6 &&
				`td:nth-child(6) {
            width:${props.widthCol6}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol7 &&
				`td:nth-child(7) {
            width:${props.widthCol7}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol8 &&
				`td:nth-child(8) {
            width:${props.widthCol8}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol9 &&
				`td:nth-child(9) {
            width:${props.widthCol9}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol10 &&
				`td:nth-child(10) {
            width:${props.widthCol10}!important;
            white-space:unset!important;

        }`}
        ${(props) =>
				props.widthCol11 &&
				`td:nth-child(11) {
              width:${props.widthCol11}!important;
            white-space:unset!important;

          }`}
        ${(props) =>
				props.widthCol12 &&
				`td:nth-child(12) {
                width:${props.widthCol12}!important;
            white-space:unset!important;

            }`}
            ${(props) =>
				props.widthCol13 &&
				`td:nth-child(13) {
                      width:${props.widthCol13}!important;
                  white-space:unset!important;
      
                  }`}
		}
		td.ant-table-cell.ant-table-cell-fix-left,
		td.ant-table-cell.ant-table-cell-fix-right {
			background: inherit;
		}
	}
	.ant-table-tbody tr.ant-table-row {
		height: ${(props) => props.heightHeader}!important;
		td.ant-table-cell {
			padding: ${(props) => props.paddingItemBody}!important;
		}
	}
	.ant-table-tbody tr:not(.ant-table-row) {
		.ant-table-cell {
			/* padding: ${(props) => props.paddingItemBody}!important; */
			padding: 2px 16px !important;
		}
	}
`;
