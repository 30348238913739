import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { IVehicleState } from '../interfaces/vehicle.interfaces';
import vehicleTypes from '../actions/types/vehicle.types';

const initState: IVehicleState = {
  stateGetVehicleDetail: {...rootState},
  statetUpdateVehicle: {...rootState},
  stateGetVehicles:{...rootState},
};

const vehicleReducer: Reducer<IVehicleState, ISyntheticAction>
  = (state: IVehicleState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {
      /*************************** START *************************/
      /*                  GET_VEHICLES                */

      case vehicleTypes.START_GET_VEHICLES: {
        const stateReducer: IVehicleState = {
          ...state,
          stateGetVehicles: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleTypes.GET_VEHICLES_SUCCESS: {
        const stateReducer: IVehicleState = {
          ...state,
          stateGetVehicles: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleTypes.GET_VEHICLES_FAILURE: {
        const stateReducer: IVehicleState = {
          ...state,
          stateGetVehicles: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/
      /*************************** START *************************/
      /*                  GET_VEHICLE_TYPE_DETAIL                */

      case vehicleTypes.START_GET_VEHICLE_DETAIL: {
        const stateReducer: IVehicleState = {
          ...state,
          stateGetVehicleDetail: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleTypes.GET_VEHICLE_DETAIL_SUCCESS: {
        const stateReducer: IVehicleState = {
          ...state,
          stateGetVehicleDetail: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleTypes.GET_VEHICLE_DETAIL_FAILURE: {
        const stateReducer: IVehicleState = {
          ...state,
          stateGetVehicleDetail: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                     UPDATE_VEHICLE                */

      case vehicleTypes.START_UPDATE_VEHICLE: {
        const stateReducer: IVehicleState = {
          ...state,
          statetUpdateVehicle: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleTypes.UPDATE_VEHICLE_SUCCESS: {
        const stateReducer: IVehicleState = {
          ...state,
          statetUpdateVehicle: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleTypes.UPDATE_VEHICLE_FAILURE: {
        const stateReducer: IVehicleState = {
          ...state,
          statetUpdateVehicle: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/
      default:
        return state;
    }

  };

export default vehicleReducer;