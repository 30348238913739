/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import { useLocation, useParams } from "react-router-dom";
import FormSelect from "src/components/form/FormSelect";
import { Row, Col, Input, Form, Button } from "antd";
import { localGetAccount } from "src/utils/localStorage";
import moment from "moment";
import { WH_OPERATION_TYPE } from "src/utils/helpers/functions/data";
import CreateOperationModal from "./CreateModal";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const DetailedPriceList = () => {
	const [form] = Form.useForm();
	const urlParams = new URLSearchParams(window.location.search);
	const type_operation = urlParams.get("type");
	const { id } = useParams<any>();
	const [data, setData] = useState<any>([]);
	const [visible, setVisible] = useState<any>(false);
	const [user, setUser] = useState<any>(false);
	const [params, setParams] = useState<any>({
		page: 1,
		limit: 10
	});
	const [loading, setLoading] = useState<any>(false);
	const [total, setToal] = useState<number>(0);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [recordEdit, setRecordEdit] = useState<any>();
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
	const [detailedPrice, setDetailedPrice] = useState<any>({});
	const [priceDetailList, setPriceDetailList] = useState<any>([]);
	const [scanBill, setScanBill] = useState<any>([]);
	const [scanBillNewList, setScanBillNewList] = useState<any>([]);
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		let USER_INFO: any = localGetAccount();
		USER_INFO = JSON.parse(USER_INFO);
		console.log("USER_INFO", USER_INFO);
		setUser(USER_INFO);
		getInit();
	}, []);

	const onEdit = (record: any) => {
		setIsEdit(true);
		setVisible(true);
		setRecordEdit(record)
	};
	const getInit = async () => {
		getDetailedPriceList();
		getPriceDetailList();
	};

	const getDetailedPriceList = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.showPriceList(id, {});
			console.log("res", res);
			form.setFieldsValue(res?.data);
			setDetailedPrice(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const getPriceDetailList = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getPriceDetailList(id, {});
			console.log("res", res);
			form.setFieldsValue(res?.data);
			setPriceDetailList(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const onComplete = async () => {
		setLoading(true);
		try {
			const completeRes: any = await _apiNotSaga.completeOperationWH(id, {});
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getDetailedPriceList();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const onDelete = async () => {
		setLoading(true);
		try {
			params.status = "D";
			const completeRes: any = await _apiNotSaga.updateOperationWH(id, params);
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getDetailedPriceList();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const _handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			// onScanBill(scanBill);
		}
	};
	const onChangeStatus = async (id: any, status: any, record: any) => {
		const params = {
			...record,
			status: status ? "A" : "D"
		};
		setLoading(true);
		const res: any = await _apiNotSaga.updatePriceList(id, params);
		setLoading(false);
		getInit()
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
	const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const onSuccessModal = () => {
		getInit();
		setVisible(false);
	};
	return (
		<Loading spinning={loading}>
			{visible && (
				<CreateOperationModal
					visible={visible}
					record={recordEdit}
					priceListId={detailedPrice.id}
					onCancel={() => setVisible(false)}
					onSuccess={onSuccessModal}
					isEdit={isEdit}
				/>
			)}
			<Form form={form} layout="vertical" className="space-y-4">
				<div className="contentSection">
					<div className="contentSection-main">
						<Row gutter={[8, 8]}>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Mã bảng giá">
									<Input className="w-full" value={`${detailedPrice.price_code}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Tên bảng giá">
									<Input className="w-full" value={`${detailedPrice.price_name}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Dịch vụ">
									<Input className="w-full" value={`${detailedPrice.service_name}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Loại hàng hóa">
									<Input className="w-full" value={`${detailedPrice.goods_type_name}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Quy tắc áp giá">
									<Input className="w-full" value={`${detailedPrice.calc_type_name}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Làm tròn trọng lượng">
									<Input className="w-full" value={`${detailedPrice.round_weight}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Làm tròn giá">
									<Input className="w-full" value={`${detailedPrice.round_price}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}></Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Áp dụng từ ngày">
									<Input
										className="w-full"
										value={`${moment(detailedPrice.start_at).format("DD-MM-YYYY HH:mm:ss")}`}
										disabled={true}
									/>
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Hết hạn vào ngày">
									<Input
										className="w-full"
										value={`${moment(detailedPrice.end_at).format("DD-MM-YYYY HH:mm:ss")}`}
										disabled={true}
									/>
								</Form.Item>
							</Col>
							{/* <Col md={6} xs={6} sm={6}>
								{!["C", "D"].includes(detailedPrice.status) ? (
									<div
										style={{ alignItems: "end", height: "100%", paddingBottom: 7 }}
										className="flex justify-between "
									>
										<Button
											id="filter_button_onSearch"
											size="large"
											block={true}
											onClick={onComplete}
											className="bg-[#001529] flex tms-btn-search justify-center rounded-lg items-center text-[#FFFFFF]"
										>
											{"Hoàn thành"}
										</Button>
										<Button
											id="filter_button_onSearch"
											size="large"
											block={true}
											onClick={onDelete}
											className="bg-[#fff] flex tms-btn-search justify-center rounded-lg items-center text-[#000] border-1px"
										>
											{"Hủy"}
										</Button>
									</div>
								) : (
									<></>
								)}
							</Col> */}
						</Row>
					</div>
					<hr className=" mb-[10px] border-neutral_color_1_2" />
					<div className="contentSection-main">
						<div className="flex justify-between mb-2">
							<p className="text-6xl font-bold">Quy tắc áp giá</p>
							<Button
								icon={<PlusOutlined />}
								style={{ border: "1px solid black", color: "#001529" }}
								onClick={() => {
									setIsEdit(false);
									setVisible(true);
								}}
							>
								Thêm
							</Button>
						</div>

						<TableStyled
							rowKey="id"
							dataSource={priceDetailList}
							bordered
							pagination={{
								locale: {
									items_per_page: "/Trang"
								},
								current: params.page,
								showSizeChanger: true,
								defaultPageSize: params.limit,
								total: priceDetailList?.length || 0,
								showTotal: (total: any, range: any) => (
									<div className="flex gap-2">
										<strong>
											{range[0]} - {range[1]}
										</strong>
										trong tổng
										<strong>{total}</strong>
									</div>
								),
								onChange: onChangePaging
							}}
							scroll={{
								x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
								y: "calc(70vh - 80px)"
							}}
							isRowLight={true}
							columns={columnsData({onEdit, onChangeStatus, detailedPrice, onDelete }) as any}
						/>
					</div>
				</div>
			</Form>
		</Loading>
	);
};

export default DetailedPriceList;
