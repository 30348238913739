/*eslint-disable*/
import { Button, Card, Col, Form, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TagStatusWH from "src/components/TagStatusWH/tagStatusWH";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getProvinces } from "src/services/actions/global.actions";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { localGetAccount } from "src/utils/localStorage";
import { columnsData, columnsDataBill } from "./data";
import "./style.scss";
import TagStatus from "src/components/TagStatus/tagStatus";
import TagStatusDeb from "src/components/TagStatusWH/TagStatusDeb";
import moment from "moment";
import { formatVND } from "src/utils/helpers/functions/textUtils";
import { Calculator, MailCheck } from "lucide-react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CreateModalBill from "./CreateModalBill";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const { confirm } = Modal;
const DebDetails = () => {
	const [form] = Form.useForm();
	const urlParams = new URLSearchParams(window.location.search);
	const type_operation = urlParams.get("type");
	const { id } = useParams<any>();
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	const [districts, setDistricts] = useState<any[]>([]);
	const [wards, setWards] = useState<any[]>([]);
	const [data, setData] = useState<any>([]);
	const [visible, setVisible] = useState<any>(false);
	const [user, setUser] = useState<any>(false);
	const [params, setParams] = useState<any>({
		page: 1,
		limit: 10
	});
	const [loading, setLoading] = useState<any>(false);
	const [total, setToal] = useState<number>(0);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const dispatch = useDispatch();
	const [empSelect, setEmpSelect] = useState<any>("");
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
	const [dataBills, setDataBills] = useState<any>([]);
	const [dataUserWh, setDataUserWh] = useState<any>([]);
	const [items, setItems] = useState<any>([]);
	const [scanBill, setScanBill] = useState<any>([]);
	const [detail, setDetail] = useState<any>({});
	const [scanBillNewList, setScanBillNewList] = useState<any>([]);
	const [callback, setCallback] = useState<boolean>(false);
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		let USER_INFO: any = localGetAccount();
		USER_INFO = JSON.parse(USER_INFO);
		console.log("USER_INFO", USER_INFO);
		setUser(USER_INFO);
		getDetailedOperationWH();
		// getUserWH();
		// dispatch(getProvinces());
		// loadUser();
	}, []);

	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
	const getDetailedOperationWH = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getDetailsDeb(id, {});
			console.log("res", res);

			setDataBills(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const loadUser = async () => {
		const res: any = await _apiNotSaga.getUsers({ limit: 999, status: "A" });
		let data = res?.data;
		let districts: any = [];
		res.data.map((item: any) => {
			districts.push({
				label: (
					<a target="_blank" rel="noopener noreferrer" onClick={() => handleSetEmp(item.id)}>
						{`${item.user_code} - ${item.full_name}`}
					</a>
				),
				value: item.id.toString()
			});
		});
		setItems(districts);
		// dispatch(fetchUserFromTicket({ do_get_list: true, user_erp_code: q }));
	};
	const handleSetEmp = (id: any) => {};
	const getUserWH = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getUsersByWh({
				warehouse_id: id
			});
			console.log("res", res);

			setDataUserWh(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onScanBill = async (bill_code: any) => {
		console.log("bill_code", bill_code);
		if (!bill_code) {
			return;
		}
		if (dataBills.child.some((item: any) => item.bill_code == bill_code)) {
			return notifyError("Đơn đã quét!");
		}
		setLoading(true);
		try {
			params.bill_code = bill_code;
			params.wh_operation_id = id;
			const scanBillRes: any = await _apiNotSaga.scanBillInOut(params);
			setScanBill("");
			if (!scanBillRes.success) {
				return notifyError(scanBillRes.message);
			}
			dataBills.child = [scanBillRes.data, ...dataBills.child];
			dataBills.total_bill = (dataBills.total_bill || 0) + 1;
			dataBills.total_weight = (dataBills.total_weight || 0) + scanBillRes.data.weight;
			setDataBills({ ...dataBills });
			notifySuccess("Quét thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const handleAddemp = async () => {
		let paramss = {
			warehouse_id: id
		};
		setLoading(true);
		try {
			const completeRes: any = await _apiNotSaga.postUserByWH(empSelect, paramss);
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getUserWH();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const onComplete = async (val: any) => {
		setLoading(true);
		try {
			let paramss = {
				...val
			};
			const completeRes: any = await _apiNotSaga.updateWarehouse(id, paramss);
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getDetailedOperationWH();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const onDelete = async () => {
		setLoading(true);
		try {
			params.status = "D";
			const completeRes: any = await _apiNotSaga.updateOperationWH(id, params);
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getDetailedOperationWH();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const _handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			onScanBill(scanBill);
		}
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
	const onChangeProvince = async (e: any) => {
		form.setFieldsValue({
			province_name: (stateProvinces?.data || []).find((item: any) => item.id === e)?.province_name,
			district_id: undefined,
			ward_id: undefined,
			district_name: undefined,
			ward_name: undefined
		});
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistricts(district.data);
		}
	};
	const onChangeDistrict = async (e: any) => {
		form.setFieldsValue({
			ward_id: undefined,
			ward_name: undefined,
			district_name: districts.find((item: any) => item.id === e)?.district_name
		});
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWards(ward.data);
		}
	};
	const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const onChange = (value: any) => {
		console.log(`selected ${value}`);
		setEmpSelect(value);
	};
	const handleEditBill = async (bill: any) => {
		setDetail(bill);
		setVisible(true);
	};
	const onSuccessModal = () => {
		getDetailedOperationWH();
		setVisible(false);
	};
	const onDeleteBill = async (bill: any) => {
		confirm({
			title: "Chú ý?",
			icon: <ExclamationCircleOutlined />,
			className: "tms-modal-confirm",
			content: "Bạn có muốn xoá đơn này khỏi bảng kê không?",
			async onOk() {
				const params = {
					delete_bill_code_list: [bill]
				};
				const res: any = await _apiNotSaga.deleteBillDeb(id, params);
				if (!res.success) {
					return notifyError(res.message);
				}
				getDetailedOperationWH();
				return notifySuccess(res.message);
			},
			onCancel() {
				console.log("Cancel");
			}
		});
	};

	const col_weight = ["PTW", "DTW"].includes(dataBills.operation_type) ? 6 : 3;
	console.log("dataBills", dataBills);
	return (
		<Loading spinning={loading}>
			{visible && (
				<CreateModalBill
					visible={visible}
					onCancel={() => setVisible(false)}
					onSuccess={onSuccessModal}
					detail={detail}
					id={id}
				/>
			)}
			<div className="contentSection">
				<div className="contentSection-main">
					<div className="flex justify-between items-center">
						<div className="flex">
							<h3>{dataBills?.statement_code}</h3>
							<div className="w-2"></div>
							<TagStatusDeb status={dataBills?.statement_status_id} />
						</div>
						<div className="flex justify-end items-center">
							<Button color="#212E3B" type="primary" icon={<Calculator />} size={"large"}>
								Tải bảng kê
							</Button>
							<Button className="bg-[#FF3039] mx-[10px]" type="primary" icon={<MailCheck />} size={"large"}>
								Gửi đối soát
							</Button>
						</div>
					</div>
					<Card title="" size="small" bordered={false} headStyle={{ fontWeight: 600, marginLeft: -10 }}>
						<div className="grid grid-cols-2 gap-[10px]">
						<div className="col-span-1">
								Cước (chưa chiết khấu): <strong>{formatVND(dataBills?.total_amt)}</strong>
							</div>
							<div className="col-span-1">
								Số lượng vận đơn: <strong>{dataBills?.total_bill || 0}</strong>
							</div>
							<div className="col-span-1">
								Chiết khấu: <strong>{formatVND(dataBills?.discount_amt || 0)}</strong>
							</div>
							
							<div className="col-span-1">
								Ngày tạo: <strong>{moment(dataBills?.created_at).format("DD/MM/YYYY")}</strong>
							</div>
							<div className="col-span-1">
								VAT:<strong>{formatVND(dataBills?.total_paid_vat_amt)}</strong>
							</div>
							<div className="col-span-1">
								Ngày thanh toán:{" "}
								<strong>
									{dataBills?.paid_at ? moment(dataBills?.paid_at).format("DD/MM/YYYY") : "Chưa thanh toán"}
								</strong>
							</div>
							<div className="col-span-1">
								Số tiền thanh toán (Vnđ): <strong>{formatVND(dataBills?.remain_paid_vat_amt)}</strong>
							</div>
							
							<div className="col-span-1">
								Ghi chú: <strong>{dataBills?.note}</strong>
							</div>
						</div>
					</Card>

					<Card title="Người thanh toán" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
						<div style={{ display: "flex", gap: 40, marginTop: 10 }}>
							<div style={{ width: "50%" }}>
								Loại khách hàng: <strong>{dataBills?.partner_type === 1 ? "Đối tác" : "Khách lẻ"}</strong>
							</div>
							<div style={{ width: "50%" }}>
								Liên hệ:{" "}
								<strong>
									{dataBills?.contact_phone} - {dataBills?.contact_name}
								</strong>
							</div>
						</div>
						<div style={{ display: "flex", gap: 40, marginTop: 10 }}>
							<div style={{ width: "50%" }}>
								Tên đối tác: <strong>{dataBills?.partner_name}</strong>
							</div>
							<div style={{ width: "50%" }}>
								Email: <strong>{dataBills?.contact_email}</strong>
							</div>
						</div>
					</Card>
					{/* <Card title="Thông tin thanh toán" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
						<div style={{ display: "flex", gap: 40, marginTop: 10 }}>
							<div style={{ width: "50%" }}>
								Mã giao dịch: <strong>{dataBills?.partner_name}</strong>
							</div>
							<div style={{ width: "50%" }}>Ảnh chụp màn hình chuyển khoản: Xem ảnh</div>
						</div>
					</Card> */}
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={dataBills?.child}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: params.page,
							showSizeChanger: true,
							defaultPageSize: params.limit,
							total: dataBills?.child?.length || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						scroll={{
							x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
							y: "calc(70vh - 80px)"
						}}
						isRowLight={true}
						columns={columnsDataBill({ dataBills, onDelete: onDeleteBill, handleEditBill }) as any}
					/>
				</div>
			</div>
		</Loading>
	);
};

export default DebDetails;
