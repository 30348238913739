function SvgIcon00151(props) {
	return (
		<svg
			width={25}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
			x={0.5}
			y={0.5}
			width={24}
			height={24}
			rx={4.5}
			fill={props.fillbg || "#fff"}
			stroke="#808A94"
			/>
			<path
			d="M14.14 16.133c.26 0 .47-.21.47-.469v-4.687a.469.469 0 0 0-.938 0v4.687c0 .259.21.469.469.469ZM10.86 16.133c.258 0 .468-.21.468-.469v-4.687a.469.469 0 1 0-.937 0v4.687c0 .259.21.469.468.469ZM14.375 6.992a.469.469 0 0 0 0-.937h-3.75a.469.469 0 0 0 0 .937h3.75Z"
			fill={props.fill || "#000"}
			/>
			<path
			d="M7.344 7.46a.469.469 0 0 0 0 .938h.468v8.625c0 1.06.863 1.922 1.922 1.922h5.532c1.059 0 1.921-.862 1.921-1.922V8.398h.47a.469.469 0 0 0 0-.937H7.343Zm8.906.938v8.625c0 .544-.44.985-.984.985H9.734a.984.984 0 0 1-.984-.985V8.398h7.5Z"
			fill={props.fill || "#000"}
			/>
		</svg>
	);
}

export default SvgIcon00151;
