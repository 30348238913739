import * as React from "react"
import { SVGProps } from "react"
const SvgDel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <rect width={26} height={26} x={2} y={2} stroke="#D3D3D3" rx={4.5} />
    <path
      fill="#000"
      d="M20.25 11.25a.75.75 0 0 0-.75.75v8.393a1.44 1.44 0 0 1-1.508 1.357h-5.985a1.44 1.44 0 0 1-1.507-1.357V12A.75.75 0 1 0 9 12v8.393a2.939 2.939 0 0 0 3.008 2.857h5.985A2.939 2.939 0 0 0 21 20.393V12a.75.75 0 0 0-.75-.75ZM21 9h-3V7.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75V9H9a.75.75 0 0 0 0 1.5h12A.75.75 0 1 0 21 9Zm-7.5 0v-.75h3V9h-3Z"
    />
    <path
      fill="#000"
      d="M14.25 18.75V13.5a.75.75 0 1 0-1.5 0v5.25a.75.75 0 1 0 1.5 0Zm3 0V13.5a.75.75 0 1 0-1.5 0v5.25a.75.75 0 1 0 1.5 0Z"
    />
  </svg>
)
export default SvgDel
