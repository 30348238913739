import { API_URL } from 'src/services/api/config';
import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "src/services/api/api.index";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import repairPartnerTypes from "../types/repairPartner.types";
import { API_PARTNER } from "src/services/api/url.index";

function* getListRepairPartner(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_PARTNER}`, payload?.params);
		});
		yield put(actionSuccess(repairPartnerTypes.GET_LIST_REPAIR_PARTNER_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairPartnerTypes.GET_LIST_REPAIR_PARTNER_FAILURE, err));
	}
}

function* getRepairPartnerById(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_PARTNER}/${payload.id}`);
		});
		yield put(actionSuccess(repairPartnerTypes.GET_REPAIR_PARTNER_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairPartnerTypes.GET_REPAIR_PARTNER_BY_ID_FAILURE, err));
	}
}
const repairPartnerSagas = [
	takeLatest(repairPartnerTypes.START_GET_LIST_REPAIR_PARTNER, getListRepairPartner),
	takeLatest(repairPartnerTypes.START_GET_REPAIR_PARTNER_BY_ID, getRepairPartnerById)
];
export default repairPartnerSagas;
