import { call, put, takeLatest } from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import routingGroupTypes from "../types/routingGroups.type";

function* getListRoutingGroup(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getAllRoutingGroups(payload?.params);
		});
		yield put(actionSuccess(routingGroupTypes.GET_LIST_ROUTING_GROUP_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(routingGroupTypes.GET_LIST_ROUTING_GROUP_FAILURE, err));
	}
}

function* getRoutingGroupById(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getRoutingGroupsDetail(payload?.id);
		});
		yield put(actionSuccess(routingGroupTypes.GET_ROUTING_GROUP_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(routingGroupTypes.GET_ROUTING_GROUP_BY_ID_FAILURE, err));
	}
}
const routingGroupSagas = [
	takeLatest(routingGroupTypes.START_GET_LIST_ROUTING_GROUP, getListRoutingGroup),
	takeLatest(routingGroupTypes.START_GET_ROUTING_GROUP_BY_ID, getRoutingGroupById)
];
export default routingGroupSagas;
