/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import { useLocation, useParams } from "react-router-dom";
import FormSelect from "src/components/form/FormSelect";
import { localGetAccount } from "src/utils/localStorage";
import moment from "moment";
import { Row, Col, Timeline, Card, Tag, Button, Image, Popconfirm } from "antd";
import TextArea from "antd/lib/input/TextArea";
import QRCode from "react-qr-code";
import TagStatus from "src/components/TagStatus/tagStatus";
import { CND_URL } from "src/services/api/config";
import { exportPdf } from "src/utils/helpers/functions/utils";
const DetailedBill = ({ billCode }: any) => {
	const [loading, setLoading] = useState<any>(false);
	const [billInfo, setBillInfo] = useState<any>({});
	const [visible, setVisible] = useState(false);
	useEffect(() => {
		// let USER_INFO: any = localGetAccount();
		// USER_INFO = JSON.parse(USER_INFO);
		// console.log("USER_INFO", USER_INFO);
		// setUser(USER_INFO);
		getDetailedBill(billCode);
	}, [billCode]);

	const getDetailedBill = async (billCode: any) => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.showByCode(billCode, {});
			setLoading(false);
			setBillInfo(res?.data);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const printBill = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.printBills({ bill_code_list: billCode });
			exportPdf(res);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const returnBill = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.returnBill({ bill_code_list: [billCode], is_return_bill: "Y" });
			setLoading(false);
			if (!res.success) {
				return notifyError(res.message);
			}
			getDetailedBill(billCode);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const cancelBill = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.cancelBill({ bill_code: billCode });
			setLoading(false);
			if (!res.success) {
				return notifyError(res.message);
			}
			getDetailedBill(billCode);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	return (
		<div style={{ padding: "20px" }}>
			<Loading spinning={loading}>
				{!billInfo.bill_code ? (
					<Card bordered={false}></Card>
				) : (
					<Card
						title={
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div style={{ display: "flex" }}>
									<div>
										<QRCode value={billInfo.bill_code} style={{ height: 50, width: 50 }} />
									</div>
									<div style={{ marginLeft: 10 }}>
										<h1>
											<strong>{billInfo.bill_code}</strong>
										</h1>
										<TagStatus status={billInfo.bill_status_id} />
									</div>
								</div>
								<div style={{ display: "flex" }}>
									<Button onClick={() => returnBill()}>Hoàn đơn</Button>
									<Popconfirm
										title="Bạn có chắc muốn huỷ đơn hay không?"
										style={{ borderRadius: "10px" }}
										okButtonProps={{
											style: {
												background: "black",
												color: "#fdd800"
											}
										}}
										cancelButtonProps={{
											style: {
												background: "#dfe2e4",
												color: "black"
											}
										}}
										onConfirm={() => {
											cancelBill();
										}}
										okText="Đồng ý"
										cancelText="Hủy"
									>
										<Button>Hủy đơn</Button>
									</Popconfirm>

									<Button onClick={() => printBill()}>In đơn</Button>
								</div>
							</div>
						}
						bordered={false}
					>
						<Row gutter={[16, 16]}></Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								{billInfo.partner_type == 1 ? (
									<Card title="Thông tin người gửi" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
										<p>
											Sân bay: <strong>{billInfo.s_name}</strong>
										</p>
										<p>
											Hãng bay: <strong>{billInfo.partner_name}</strong>
										</p>
										<p>
											Ngày tạo: <strong>{moment(billInfo.created_at).format("DD-MM-YYYY HH:mm:ss")}</strong>
										</p>
										<p>
											Người tạo:{" "}
											<strong>
												{billInfo.created_user_by} - {billInfo.created_user_name_by}
											</strong>
										</p>
										<p>
											Ngày tạo:{" "}
											<strong>
												{moment(billInfo.created_at).format("DD/MM/YYYY HH:mm")}
											</strong>
										</p>
										<p>
											Số hành lý:{" "}
											<strong>
												{billInfo.package_note}
											</strong>
										</p>
									</Card>
								) : (
									<Card title="Thông tin người nhận" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
										<p>
											Họ tên: <strong>{billInfo.s_name}</strong>
										</p>
										<Row>
											<Col span={12}>
												SĐT 1: <strong>{billInfo.s_phone}</strong>
											</Col>
											{billInfo.r_phone_2 && (
												<Col span={12}>
													SĐT 2: <strong>{billInfo.s_phone_2}</strong>
												</Col>
											)}
										</Row>
										<p>
											Địa chỉ:{" "}
											<strong>
												{billInfo.s_address}, {billInfo.s_ward_name ? `${billInfo.s_ward_name}, ` : ""}{" "}
												{billInfo.s_district_name}, {billInfo.s_province_name}
											</strong>
										</p>
										<p>
											Người tạo:{" "}
											<strong>
												{billInfo.created_user_by} - {billInfo.created_user_name_by}
											</strong>
										</p>
										<p>
											Ngày tạo:{" "}
											<strong>
												{moment(billInfo.created_at).format("DD/MM/YYYY HH:mm")}
											</strong>
										</p>
										<p>
											Số hành lý:{" "}
											<strong>
												{billInfo.package_note}
											</strong>
										</p>
									</Card>
								)}
							</Col>

							<Col span={12}>
								<Card title="Thông tin người nhận" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
									<p>
										Họ tên: <strong>{billInfo.r_name}</strong>
									</p>
									<Row>
										<Col span={12}>
											SĐT 1: <strong>{billInfo.r_phone}</strong>
										</Col>
										{billInfo.r_phone_2 && (
											<Col span={12}>
												SĐT 2: <strong>{billInfo.r_phone_2}</strong>
											</Col>
										)}
									</Row>
									<p>
										Địa chỉ:{" "}
										<strong>
											{billInfo.r_address}, {billInfo.r_ward_name ? `${billInfo.r_ward_name},` : ""}{" "}
											{billInfo.r_district_name}, {billInfo.r_province_name}
										</strong>
									</p>
									<p>
										Ngày giao:{" "}
										<strong>
											{billInfo.delivery_at ? moment(billInfo.delivery_at).format("DD-MM-YYYY HH:mm:ss") : "-"}
										</strong>
									</p>
								</Card>
							</Col>
						</Row>
						<Row gutter={[16, 16]} style={{}}>
							<Col span={24}>
								<Card title="Thông tin vận đơn" bordered={false} size="small" headStyle={{ fontWeight: 600 }}>
									<div style={{ display: "flex", gap: 40 }}>
										<div style={{ width: "50%" }}>
											Dịch vụ: <strong>{billInfo.service_name}</strong>
										</div>
										<div style={{ width: "50%" }}>
											Số kiện: <strong>{billInfo.package_number}</strong>
										</div>
									</div>
									<div style={{ display: "flex", gap: 40 }}>
										<div style={{ width: "50%" }}>
											Loại hàng hoá: <strong>{billInfo.goods_type_name}</strong>
										</div>
										<div style={{ width: "50%" }}>
											Kích thước hàng hoá (Cm):{" "}
											<strong>
												{billInfo.length}x{billInfo.width}x{billInfo.height}
											</strong>
										</div>
									</div>
									<div style={{ display: "flex", gap: 40 }}>
										<div style={{ width: "50%" }}>
											Số SEAL: <strong>{billInfo.seal_number ? JSON.parse(billInfo.seal_number).join(",") : ""}</strong>
											{/* <TextArea
												style={{ borderRadius: 8 }}
												rows={3}
												disabled={true}
												defaultValue={billInfo.seal_number ? JSON.parse(billInfo.seal_number).join(",") : ""}
											></TextArea> */}
										</div>
										<div style={{ width: "50%" }}>
											Trọng lượng (Kg): <strong>{billInfo.weight}</strong>
										</div>
									</div>

									<div className="mt-2" style={{ display: "flex", gap: 40 }}>
										<div style={{ width: "50%" }}>
											<div>Ghi chú:</div>
											<TextArea
												style={{ borderRadius: 8 }}
												rows={3}
												disabled={true}
												defaultValue={billInfo.note}
											></TextArea>
										</div>
										<div style={{ width: "50%" }}>
											<div>Nội dung hàng hóa:</div>
											<TextArea
												style={{ borderRadius: 8 }}
												rows={3}
												disabled={true}
												defaultValue={billInfo.cargo_content}
											></TextArea>
										</div>
									</div>
									<div className="mt-2 " style={{ display: "flex", gap: 40 }}>
										<div className="flex flex-col" style={{ width: "50%" }}>
											<div className="flex flex-col">
												<div>Ghi chú giao hàng:</div>
												<TextArea
													style={{ borderRadius: 8 }}
													rows={3}
													disabled={true}
													defaultValue={billInfo.recipient_name}
												></TextArea>
											</div>
											<div className="flex flex-col mt-2">
												<div className="font-medium">Hình ảnh vận đơn lấy hàng:</div>
												<Row>
													{billInfo.image_send_list.map((imagePath: any, index: any) => (
														<Col span={6} key={index}>
															<Image
																src={`${CND_URL}${imagePath}`}
																style={{ cursor: "pointer", maxHeight: "100px", marginBottom: "10px" }}
																alt={`Image ${index + 1}`}
															/>
														</Col>
													))}
												</Row>
											</div>
											<div className="flex flex-col mt-2">
												<div className="font-medium">Hình ảnh vận đơn trả hàng:</div>
												<Row>
													{billInfo.image_receive_list.map((imagePath: any, index: any) => (
														<Col span={6} key={index}>
															<Image
																src={`${CND_URL}${imagePath}`}
																style={{ cursor: "pointer", maxHeight: "100px", marginBottom: "10px" }}
																alt={`Image ${index + 1}`}
															/>
														</Col>
													))}
												</Row>
											</div>
										</div>
										<div className="flex flex-col" style={{ width: "50%" }}>
											{billInfo.log.length && billInfo.log.filter((item: any) => item.bill_status_id == 6) ? (
												<Col span={24}>
													<Card title="Log sự cố vận đơn" bordered={false} size="small" headStyle={{ fontWeight: 600 }}>
														<div className="timeline_container">
															<Timeline mode="left" style={{ alignContent: "start" }}>
																{(billInfo.log.filter((item: any) => item.bill_status_id == 6) || []).map(
																	(item: any) => (
																		<Timeline.Item
																			position="left"
																			label={moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}
																		>
																			<div className="flex justify-start">
																				<div style={{ marginLeft: 10 }}>
																					<p style={{ fontWeight: 600 }}>{item.bill_status_name}</p>
																					<p>{item.desc_history}</p>
																					{item.bill_status_id == 6 && billInfo.image_report_list.length ? (
																						<>
																							<Image
																								preview={{ visible: false }}
																								style={{ cursor: "pointer", maxHeight: "100px", marginBottom: "10px" }}
																								src={`${CND_URL}${billInfo.image_report_list[0]}`}
																								onClick={() => setVisible(true)}
																							/>
																							<div style={{ display: "none" }}>
																								<Image.PreviewGroup
																									preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
																								>
																									{billInfo.image_report_list.map((item: any) => (
																										<Image src={`${CND_URL}${item}`} />
																									))}
																								</Image.PreviewGroup>
																							</div>
																						</>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																		</Timeline.Item>
																	)
																)}
															</Timeline>
														</div>
													</Card>
												</Col>
											) : (
												<></>
											)}
										</div>
									</div>
									<div style={{ display: "flex", gap: 40, marginTop: 10 }}></div>

									<div></div>
								</Card>
							</Col>
						</Row>
						<hr className=" mb-[10px] border-neutral_color_1_2" />
						<div className="contentSection-main">
							<Card title="Hành trình vận đơn" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
								<TableStyled
									rowKey="id"
									dataSource={billInfo.log_erp}
									bordered
									// pagination={{
									// 	locale: {
									// 		items_per_page: "/Trang"
									// 	},
									// 	current: params.page,
									// 	showSizeChanger: true,
									// 	defaultPageSize: params.limit,
									// 	total: dataBills?.child?.length || 0,
									// 	showTotal: (total: any, range: any) => (
									// 		<div className="flex gap-2">
									// 			<strong>
									// 				{range[0]} - {range[1]}
									// 			</strong>
									// 			trong tổng
									// 			<strong>{total}</strong>
									// 		</div>
									// 	),
									// 	onChange: onChangePaging
									// }}
									// scroll={{
									// 	x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
									// 	y: "calc(70vh - 80px)"
									// }}
									isRowLight={true}
									columns={columnsData({}) as any}
								/>
							</Card>
						</div>
					</Card>
				)}
			</Loading>
		</div>
	);
};

export default DetailedBill;
