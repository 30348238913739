function SvgIcon00055(props) {
	return (
		<svg
			width={19}
			height={19}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
			d="M11.578 14.102a.594.594 0 0 0 .594-.594V7.57a.594.594 0 0 0-1.188 0v5.938c0 .328.266.594.594.594ZM7.422 14.102a.594.594 0 0 0 .594-.594V7.57a.594.594 0 0 0-1.188 0v5.938c0 .328.266.594.594.594ZM11.875 2.523a.594.594 0 0 0 0-1.187h-4.75a.594.594 0 0 0 0 1.187h4.75Z"
			fill={props.fill || "#E46463"}
			/>
			<path
			d="M2.969 3.117a.594.594 0 0 0 0 1.188h.594V15.23a2.438 2.438 0 0 0 2.434 2.434h7.006a2.438 2.438 0 0 0 2.434-2.434V4.305h.594a.594.594 0 0 0 0-1.188H2.97ZM14.25 4.305V15.23c0 .688-.558 1.247-1.247 1.247H5.997A1.247 1.247 0 0 1 4.75 15.23V4.305h9.5Z"
			fill={props.fill || "#E46463"}
			/>
		</svg>
	);
}

export default SvgIcon00055;
