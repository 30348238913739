import { Button, Col, Form, Modal, Row, Switch, UploadFile } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import FormSelectDate from "src/components/form/FormSelectDate";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { POS_BASE, SITE_BASE } from "src/constants";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { CND_URL } from "src/services/api/config";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [fileListMobie, setFileListMobie] = useState<UploadFile[]>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [dataPage, setDataPage] = useState<any>([]);

	useEffect(() => {}, []); //eslint-disable-line

	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getAppsDetails(detail.id);
				
				form.setFieldsValue({
					...res?.data,
				});
				
				// onGetRoles(res?.data?.profile?.user_type_id);
				setStatus(res?.data?.status === "A");
				// setLock(res?.data?.locked === "Y");
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, []); //eslint-disable-line

	const onChangeSite = (val: number) => {
		form.setFieldsValue({
			page_code: undefined
		});
		const initpage = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPage({ site: val });
				setDataPage(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initpage();
	};

	const onSubmit = async (val: any) => {
		// setLoading(true);
		let date = val?.date;

		delete val.date;
		const params = {
			...val,
			status: status ? "A" : "D",
			company_id:1
			// start_at: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
			// end_at: moment(date[1]).format("YYYY-MM-DD HH:mm:ss")
		};
		try {
			const res: any = isCreate
				? await _apiNotSaga.createApps(params)
				: await _apiNotSaga.updateBanner(detail.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onChangeDate = (dates: any) => {
		console.log(dates[0].format("YYYY-MM-DD HH:mm:ss"));
		return form.setFieldsValue({
			start_at: dates[0].format("YYYY-MM-DD HH:mm:ss"),
			end_at: dates[1].format("YYYY-MM-DD HH:mm:ss")
		});
	};
	const uploadImg = (info: any) => {
		console.log(info);
		if (info.file) {
			let file = info.file;
			uploadFile(file, (response: any) => {
				if (response) {
					console.log(response);
					form.setFieldsValue({
						link: response.data[0]
					});
				}
			});
		}
	};
	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} banner`}
				style={{
					maxWidth: "80vw"
				}}
				width={"60%"}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[8, 8]}>
							{/* <Col xs={8} md={8}>
								<FormSelect
									name="site"
									label="Chọn site"
									dataSource={SITE_BASE}
									placeholder="Chọn site"
									slOption={{
										name: "name",
										value: "id"
									}}
									onChange={onChangeSite}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col> */}
							<Col xs={12} md={12}>
								<FormInput
									name="app_name"
									label="Tên ứng dụng"
									type="text"
									size="large"
									placeholder="Nhập tên ứng dụng"
									required
									// disabled
								/>
							</Col>
							<Col xs={12} md={12}>
								<FormInput
									name="mobile_app_version"
									label="Số version"
									type="text"
									size="large"
									placeholder="Nhập version"
									required
									// disabled
								/>
							</Col>
							<Col xs={24} md={24}>
								<FormInput
									name="url"
									label="Nhập URL"
									type="text"
									size="large"
									placeholder="Nhập url"
									
									// disabled
								/>
							</Col>
							<Col xs={24} md={24}>
								<FormInput
									name="app_key"
									label="Nhập APP KEY"
									type="text"
									size="large"
									placeholder="Nhập APP KEY"
									
									// disabled
								/>
							</Col>
							{/* <Col xs={24} md={24}>
								<FormSelectDate name="date" label="Thời gian hiển thị" required onChange={onChangeDate} />
							</Col> */}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
