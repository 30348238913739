import { call, put, takeLatest } from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; //eslint-disable-line
import { actionFailure, actionSuccess } from "../root.actions";
import transportTripsTypes from "../types/transportTrips.types";

function* createTransportTrip(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.createTransportTrip(payload?.params);
		});
		yield put(actionSuccess(transportTripsTypes.CREATE_TRANSPORT_TRIP_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(transportTripsTypes.CREATE_TRANSPORT_TRIP_FAILURE, err));
	}
}

function* updateTransportTrip(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.updateTransportTrip(payload.id, payload?.params);
		});
		yield put(actionSuccess(transportTripsTypes.UPDATE_TRANSPORT_TRIP_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(transportTripsTypes.UPDATE_TRANSPORT_TRIP_FAILURE, err));
	}
}

function* getTransportTrips(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getTransportTrips(payload?.params);
		});
		yield put(actionSuccess(transportTripsTypes.GET_TRANSPORT_TRIP_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(transportTripsTypes.GET_TRANSPORT_TRIP_FAILURE, err));
	}
}

function* getReportOntime(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getReportOntime(payload?.params);
		});
		yield put(actionSuccess(transportTripsTypes.GET_REPORT_ONTIME_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(transportTripsTypes.GET_REPORT_ONTIME_FAILURE, err));
	}
}

function* getReportPerformances(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getReportPerformance(payload?.params);
		});
		yield put(actionSuccess(transportTripsTypes.GET_REPORT_PERFORMANCE_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(transportTripsTypes.GET_REPORT_PERFORMANCE_FAILURE, err));
	}
}

function* getReportProductivity(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getReportProductivity(payload?.params);
		});
		yield put(actionSuccess(transportTripsTypes.GET_REPORT_PRODUCTIVITY_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(transportTripsTypes.GET_REPORT_PRODUCTIVITY_FAILURE, err));
	}
}

// function* getReportProblem(action: IAction) {
// 	try {
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return _apiNotSaga.getReportProblem(payload?.params);
// 		});
// 		yield put(actionSuccess(transportTripsTypes.GET_REPORT_PROBLEM_SUCCESS, res));
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(transportTripsTypes.GET_REPORT_PROBLEM_FAILURE, err));
// 	}
// }

// function* getReportExport(action: IAction) {
// 	try {
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return _apiNotSaga.getReportExport(payload?.params);
// 		});
// 		yield put(actionSuccess(transportTripsTypes.GET_REPORT_EXPORT_SUCCESS, res));
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(transportTripsTypes.GET_REPORT_EXPORT_FAILURE, err));
// 	}
// }

// function* getReportEvaluation(action: IAction) {
// 	try {
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return _apiNotSaga.getReportEvaluation(payload?.params);
// 		});
// 		yield put(actionSuccess(transportTripsTypes.GET_REPORT_EVALUATION_SUCCESS, res));
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(transportTripsTypes.GET_REPORT_EVALUATION_FAILURE, err));
// 	}
// }
// function* getReportEvaluationDriver(action: IAction) {
// 	try {
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return _apiNotSaga.getReportEvaluationDriver(payload?.params);
// 		});
// 		yield put(actionSuccess(transportTripsTypes.GET_REPORT_EVALUATION_DRIVER_SUCCESS, res));
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(transportTripsTypes.GET_REPORT_EVALUATION_DRIVER_FAILURE, err));
// 	}
// }

function* getTransportTripById(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getTransportTripById(payload.id);
		});
		yield put(actionSuccess(transportTripsTypes.GET_DETAIL_TRANSPORT_TRIP_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(transportTripsTypes.GET_DETAIL_TRANSPORT_TRIP_FAILURE, err));
	}
}

const transportTripsSagas = [
	takeLatest(transportTripsTypes.START_CREATE_TRANSPORT_TRIP, createTransportTrip),
	takeLatest(transportTripsTypes.START_UPDATE_TRANSPORT_TRIP, updateTransportTrip),
	takeLatest(transportTripsTypes.START_GET_TRANSPORT_TRIP, getTransportTrips),
	takeLatest(transportTripsTypes.START_GET_REPORT_ONTIME, getReportOntime),
	takeLatest(transportTripsTypes.START_GET_REPORT_PERFORMANCE, getReportPerformances),
	takeLatest(transportTripsTypes.START_GET_REPORT_PRODUCTIVITY, getReportProductivity),
	// takeLatest(transportTripsTypes.START_GET_REPORT_PROBLEM, getReportProblem),
	takeLatest(transportTripsTypes.START_GET_DETAIL_TRANSPORT_TRIP, getTransportTripById),
	// takeLatest(transportTripsTypes.START_GET_REPORT_EXPORT, getReportExport),
	// takeLatest(transportTripsTypes.START_GET_REPORT_EVALUATION, getReportEvaluation),
	// takeLatest(transportTripsTypes.START_GET_REPORT_EVALUATION_DRIVER, getReportEvaluationDriver)
];

export default transportTripsSagas;
