import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { IVehicleGroupState } from '../interfaces/vehicleGroup.interfaces';
import vehicleGroupTypes from '../actions/types/vehicleGroup.types';

const initState: IVehicleGroupState = {
  stateGetAllVehicleGroup: { ...rootState },
  stateGetVehicleGroupById: { ...rootState },
  stateCreateVehicleGruop: { ...rootState },
  stateUpdateVehicleGroup: { ...rootState },
};

const vehicleGroupReducer: Reducer<IVehicleGroupState, ISyntheticAction>
  = (state: IVehicleGroupState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET_ALL_VEHICLE_GROUP                   */

      case vehicleGroupTypes.START_GET_ALL_VEHICLE_GROUP: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateGetAllVehicleGroup: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.GET_ALL_VEHICLE_GROUP_SUCCESS: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateGetAllVehicleGroup: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.GET_ALL_VEHICLE_GROUP_FAILURE: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateGetAllVehicleGroup: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 GET_VEHICLE_GROUP_BY_ID                   */

      case vehicleGroupTypes.START_GET_VEHICLE_GROUP_BY_ID: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateGetVehicleGroupById: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.GET_VEHICLE_GROUP_BY_ID_SUCCESS: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateGetVehicleGroupById: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.GET_VEHICLE_GROUP_BY_ID_FAILURE: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateGetVehicleGroupById: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/
      /*************************** START *************************/
      /*                 CREATE_VEHICLE_GROUP                   */

      case vehicleGroupTypes.START_CREATE_VEHICLE_GROUP: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateCreateVehicleGruop: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.CREATE_VEHICLE_GROUP_SUCCESS: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateCreateVehicleGruop: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.CREATE_VEHICLE_GROUP_FAILURE: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateCreateVehicleGruop: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 UPDATE_VEHICLE_GROUP                   */

      case vehicleGroupTypes.START_UPDATE_VEHICLE_GROUP: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateUpdateVehicleGroup: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.UPDATE_VEHICLE_GROUP_SUCCESS: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateUpdateVehicleGroup: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case vehicleGroupTypes.UPDATE_VEHICLE_GROUP_FAILURE: {
        const stateReducer: IVehicleGroupState = {
          ...state,
          stateUpdateVehicleGroup: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default vehicleGroupReducer;