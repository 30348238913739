const priceOffTypes = {
    START_GET_PRICE_OF_TYPE: "START_GET_PRICE_OF_TYPE",
    GET_PRICE_OF_TYPE_SUCCESS: "GET_PRICE_OF_TYPE_SUCCESS",
    GET_PRICE_OF_TYPE_FAILURE: "GET_PRICE_OF_TYPE_FAILURE",

    START_GET_PRICE_OF_TYPE_BY_ID: "START_GET_PRICE_OF_TYPE_BY_ID",
    GET_PRICE_OF_TYPE_BY_ID_SUCCESS: "GET_PRICE_OF_TYPE_BY_ID_SUCCESS",
    GET_PRICE_OF_TYPE_BY_ID_FAILURE: "GET_PRICE_OF_TYPE_BY_ID_FAILURE",
}

export default priceOffTypes