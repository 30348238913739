/* eslint-disable */
import { Reducer } from "redux";
import { rootState } from "./state/root.states";
import { ISyntheticAction } from "../interfaces/root.interfaces";
import { IImportState } from "../interfaces/import.interfaces";
import importTypes from "../actions/types/import.types";

const initState: IImportState = {
  stateImportByTemplate: { ...rootState },
};

const importReducer: Reducer<IImportState, ISyntheticAction> = (
  state: IImportState = initState,
  action: ISyntheticAction
) => {
  const { type, response } = action;

  switch (type) {
    /*************************** START *************************/
    /*                        GET TEMPLATE                   */

    case importTypes.START_IMPORT_BY_TEMPLATE: {
      const stateReducer: IImportState = {
        ...state,
        stateImportByTemplate: {
          ...rootState,
          isLoading: true,
        },
      };
      return stateReducer;
    }
    case importTypes.GET_IMPORT_BY_TEMPLATE_SUCCESS: {
      const stateReducer: IImportState = {
        ...state,
        stateImportByTemplate: {
          ...rootState,
          isLoading: false,
          data: response.data,
          message: "Thành công",
          success: response.success,
        },
      };
      return stateReducer;
    }
    case importTypes.GET_IMPORT_BY_TEMPLATE_FAILURE: {
      const stateReducer: IImportState = {
        ...state,
        stateImportByTemplate: {
          ...rootState,
          isLoading: false,
          message: response.message,
          error: true,
        },
      };
      return stateReducer;
    }

    /**************************** END **************************/

    default:
      return state;
  }
};

export default importReducer;
