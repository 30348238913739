import { Reducer } from "redux";
import waitingBookTypes from "../actions/types/waitingBook.types";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { IWaitingBookState } from "../interfaces/waitingBook.interfaces";
import { rootState } from "./state/root.states";

const initState: IWaitingBookState = {
  stateGetWaitingBook: { ...rootState },
};

const waitingBookReducer: Reducer<IWaitingBookState, ISyntheticAction> = (
  state: IWaitingBookState = initState,
  action: ISyntheticAction
) => {
  const { type, response } = action;

  switch (type) {
    /*************************** START *************************/
    /*                       LOGIN ACCOUNT                     */

    case waitingBookTypes.START_GET_WAITING_BOOK: {
      const stateReducer: IWaitingBookState = {
        ...state,
        stateGetWaitingBook: {
          ...rootState,
          isLoading: true,
        },
      };
      return stateReducer;
    }
    case waitingBookTypes.GET_WAITING_BOOK_SUCCESS: {
      const stateReducer: IWaitingBookState = {
        ...state,
        stateGetWaitingBook: {
          ...rootState,
          isLoading: false,
          data: response.data,
          message: response.message,
          success: response.success,
        },
      };
      return stateReducer;
    }
    case waitingBookTypes.GET_WAITING_BOOK_FAILURE: {
      const stateReducer: IWaitingBookState = {
        ...state,
        stateGetWaitingBook: {
          ...rootState,
          isLoading: false,
          message: response.message,
          error: true,
        },
      };
      return stateReducer;
    }

    /**************************** END **************************/
    default:
      return state;
  }
};

export default waitingBookReducer;
