import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { IRepairPartnerState } from '../interfaces/repairPartner.interfaces';
import repairPartnerTypes from '../actions/types/repairPartner.types';

const initState: IRepairPartnerState = {
    stateGetListRepairPartner: { ...rootState },
    stateGetRepairPartnerById: { ...rootState },
//   stateGenCodeRepairManagementById: { ...rootState },
};

const repairPartnerReducer: Reducer<IRepairPartnerState, ISyntheticAction>
  = (state: IRepairPartnerState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET LIST USER SYSTEM                   */

      case repairPartnerTypes.START_GET_LIST_REPAIR_PARTNER: {
        const stateReducer: IRepairPartnerState = {
          ...state,
          stateGetListRepairPartner: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case repairPartnerTypes.GET_LIST_REPAIR_PARTNER_SUCCESS: {
        const stateReducer: IRepairPartnerState = {
          ...state,
          stateGetListRepairPartner: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case repairPartnerTypes.GET_LIST_REPAIR_PARTNER_FAILURE: {
        const stateReducer: IRepairPartnerState = {
          ...state,
          stateGetListRepairPartner: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 GET USER SYSTEM BY ID                   */

      case repairPartnerTypes.START_GET_REPAIR_PARTNER_BY_ID: {
        const stateReducer: IRepairPartnerState = {
          ...state,
          stateGetRepairPartnerById: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case repairPartnerTypes.GET_REPAIR_PARTNER_BY_ID_SUCCESS: {
        const stateReducer: IRepairPartnerState = {
          ...state,
          stateGetRepairPartnerById: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case repairPartnerTypes.GET_REPAIR_PARTNER_BY_ID_FAILURE: {
        const stateReducer: IRepairPartnerState = {
          ...state,
          stateGetRepairPartnerById: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default repairPartnerReducer;