import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { IPriceOffTypeState } from '../interfaces/priceOffType.interfaces';
import priceOffTypes from '../actions/types/priceOffType.types';

const initState: IPriceOffTypeState = {
    stateGetListPriceOffType: { ...rootState },
    stateGetPriceOffTypeById: { ...rootState },
//   stateGenCodeRepairManagementById: { ...rootState },
};

const priceOffTypeReducer: Reducer<IPriceOffTypeState, ISyntheticAction>
  = (state: IPriceOffTypeState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET LIST USER SYSTEM                   */

      case priceOffTypes.START_GET_PRICE_OF_TYPE: {
        const stateReducer: IPriceOffTypeState = {
          ...state,
          stateGetListPriceOffType: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case priceOffTypes.GET_PRICE_OF_TYPE_SUCCESS: {
        const stateReducer: IPriceOffTypeState = {
          ...state,
          stateGetListPriceOffType: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case priceOffTypes.GET_PRICE_OF_TYPE_FAILURE: {
        const stateReducer: IPriceOffTypeState = {
          ...state,
          stateGetListPriceOffType: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 GET USER SYSTEM BY ID                   */

      case priceOffTypes.START_GET_PRICE_OF_TYPE_BY_ID: {
        const stateReducer: IPriceOffTypeState = {
          ...state,
          stateGetPriceOffTypeById: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case priceOffTypes.GET_PRICE_OF_TYPE_BY_ID_SUCCESS: {
        const stateReducer: IPriceOffTypeState = {
          ...state,
          stateGetPriceOffTypeById: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case priceOffTypes.GET_PRICE_OF_TYPE_BY_ID_FAILURE: {
        const stateReducer: IPriceOffTypeState = {
          ...state,
          stateGetPriceOffTypeById: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default priceOffTypeReducer;