const customerTypes = {

  START_GET_LIST_CUSTOMER: 'START_GET_LIST_CUSTOMER',
  GET_LIST_CUSTOMER_SUCCESS: 'GET_LIST_CUSTOMER_SUCCESS',
  GET_LIST_CUSTOMER_FAILURE: 'GET_LIST_CUSTOMER_FAILURE',

  START_UPDATE_ONE_CUSTOMER: 'START_UPDATE_ONE_CUSTOMER',
  UPDATE_ONE_CUSTOMER_SUCCESS: 'UPDATE_ONE_CUSTOMER_SUCCESS',
  UPDATE_ONE_CUSTOMER_FAILURE: 'UPDATE_ONE_CUSTOMER_FAILURE',

  START_CREATE_ONE_CUSTOMER: 'START_CREATE_ONE_CUSTOMER',
  CREATE_ONE_CUSTOMER_SUCCESS: 'CREATE_ONE_CUSTOMER_SUCCESS',
  CREATE_ONE_CUSTOMER_FAILURE: 'CREATE_ONE_CUSTOMER_FAILURE',

  START_GET_CUSTOMER_BY_ID: 'START_GET_CUSTOMER_BY_ID',
  GET_CUSTOMER_BY_ID_SUCCESS: 'GET_CUSTOMER_BY_ID_SUCCESS',
  GET_CUSTOMER_BY_ID_FAILURE: 'GET_CUSTOMER_BY_ID_FAILURE',

  START_GEN_CODE_CUSTOMER_BY_ID: 'START_GEN_CODE_CUSTOMER_BY_ID',
  GEN_CODE_CUSTOMER_BY_ID_SUCCESS: 'GEN_CODE_CUSTOMER_BY_ID_SUCCESS',
  GEN_CODE_CUSTOMER_BY_ID_FAILURE: 'GEN_CODE_CUSTOMER_BY_ID_FAILURE',

}

export default customerTypes;