import React from "react";

const SvgMoreOutLined = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 7 25" fill="none">
			<path
				d="M3.5 6.25C5.21875 6.25 6.625 4.84375 6.625 3.125C6.625 1.40625 5.21875 0 3.5 0C1.78125 0 0.375 1.40625 0.375 3.125C0.375 4.84375 1.78125 6.25 3.5 6.25ZM3.5 9.375C1.78125 9.375 0.375 10.7812 0.375 12.5C0.375 14.2188 1.78125 15.625 3.5 15.625C5.21875 15.625 6.625 14.2188 6.625 12.5C6.625 10.7812 5.21875 9.375 3.5 9.375ZM3.5 18.75C1.78125 18.75 0.375 20.1562 0.375 21.875C0.375 23.5938 1.78125 25 3.5 25C5.21875 25 6.625 23.5938 6.625 21.875C6.625 20.1562 5.21875 18.75 3.5 18.75Z"
				fill="black"
			/>
		</svg>
	);
};

export default SvgMoreOutLined;
