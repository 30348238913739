import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { IRepairManagementState } from '../interfaces/repairManagement.interfaces';
import RepairManagementTypes from '../actions/types/repairManagement.types';

const initState: IRepairManagementState = {
  stateGetListRepairManagement: { ...rootState },
  stateGetRepairManagementById: { ...rootState },
  stateUpdateOneRepairManagement: { ...rootState },
  stateCreateOneRepairManagement: { ...rootState },
//   stateGenCodeRepairManagementById: { ...rootState },
};

const repairManagementReducer: Reducer<IRepairManagementState, ISyntheticAction>
  = (state: IRepairManagementState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET LIST USER SYSTEM                   */

      case RepairManagementTypes.START_GET_LIST_REPAIR_MANAGEMENT: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateGetListRepairManagement: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.GET_LIST_REPAIR_MANAGEMENT_SUCCESS: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateGetListRepairManagement: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.GET_LIST_REPAIR_MANAGEMENT_FAILURE: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateGetListRepairManagement: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 GET USER SYSTEM BY ID                   */

      case RepairManagementTypes.START_GET_REPAIR_MANAGEMENT_BY_ID: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateGetRepairManagementById: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.GET_REPAIR_MANAGEMENT_BY_ID_SUCCESS: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateGetRepairManagementById: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.GET_REPAIR_MANAGEMENT_BY_ID_FAILURE: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateGetRepairManagementById: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 GEN CODE CUSTOMER BY ID                   */

    //   case RepairManagementTypes.START_GEN_CODE_CUSTOMER_BY_ID: {
    //     const stateReducer: IRepairManagementState = {
    //       ...state,
    //       stateGenCodeCustomerById: {
    //         ...rootState,
    //         isLoading: true
    //       }
    //     };
    //     return stateReducer;
    //   }
    //   case RepairManagementTypes.GEN_CODE_CUSTOMER_BY_ID_SUCCESS: {
    //     const stateReducer: IRepairManagementState = {
    //       ...state,
    //       stateGenCodeCustomerById: {
    //         ...rootState,
    //         isLoading: false,
    //         data: response.data,
    //         message: response.message,
    //         success: response.success,
    //       }
    //     };
    //     return stateReducer;
    //   }
    //   case RepairManagementTypes.GEN_CODE_CUSTOMER_BY_ID_FAILURE: {
    //     const stateReducer: IRepairManagementState = {
    //       ...state,
    //       stateGenCodeCustomerById: {
    //         ...rootState,
    //         isLoading: false,
    //         message: response.message,
    //         error: true,
    //       }
    //     };
    //     return stateReducer;
    //   }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 UPDATE ONE USER SYSTEM                  */

      case RepairManagementTypes.START_UPDATE_ONE_REPAIR_MANAGEMENT: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateUpdateOneRepairManagement: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.UPDATE_ONE_REPAIR_MANAGEMENT_SUCCESS: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateUpdateOneRepairManagement: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.UPDATE_ONE_REPAIR_MANAGEMENT_FAILURE: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateUpdateOneRepairManagement: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 CREATE ONE USER SYSTEM                  */

      case RepairManagementTypes.START_CREATE_ONE_REPAIR_MANAGEMENT: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateCreateOneRepairManagement: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.CREATE_ONE_REPAIR_MANAGEMENT_SUCCESS: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateCreateOneRepairManagement: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case RepairManagementTypes.CREATE_ONE_REPAIR_MANAGEMENT_FAILURE: {
        const stateReducer: IRepairManagementState = {
          ...state,
          stateCreateOneRepairManagement: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default repairManagementReducer;