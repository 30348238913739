import React from "react";

function SvgIcon00016(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
			<path
				fill="#000"
				d="M22.725 14.346l-2.012-2.841a1.042 1.042 0 00-1.452-.248l-2.841 2.011a1.041 1.041 0 101.204 1.7l.952-.674a6.285 6.285 0 01-.953 1.946 6.29 6.29 0 01-5.123 2.604 6.275 6.275 0 01-4.797-2.214 1.042 1.042 0 00-1.589 1.348 8.355 8.355 0 006.386 2.95 8.463 8.463 0 006.803-3.457 8.358 8.358 0 001.263-2.569l.459.648a1.041 1.041 0 101.7-1.204zM8.829 10.28a1.041 1.041 0 00-1.452-.248l-.952.673c.204-.692.516-1.35.952-1.944A6.293 6.293 0 0112.5 6.156c1.855 0 3.604.807 4.797 2.215a1.042 1.042 0 001.59-1.348 8.355 8.355 0 00-6.387-2.95A8.466 8.466 0 005.696 7.53a8.36 8.36 0 00-1.262 2.568l-.459-.648a1.041 1.041 0 10-1.7 1.204l2.011 2.841a1.041 1.041 0 001.452.25l2.842-2.013c.47-.332.58-.982.249-1.452z"
			></path>
		</svg>
	);
}

export default SvgIcon00016;
