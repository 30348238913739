const transportAutoConfigTypes = {
    START_CREATE_TRANSPORT_AUTO_CONFIG: 'START_CREATE_TRANSPORT_AUTO_CONFIG',
    CREATE_TRANSPORT_AUTO_CONFIG_SUCCESS: 'CREATE_TRANSPORT_AUTO_CONFIG_SUCCESS',
    CREATE_TRANSPORT_AUTO_CONFIG_FAILURE: 'CREATE_TRANSPORT_AUTO_CONFIG_FAILURE',
    
    START_UPDATE_TRANSPORT_AUTO_CONFIG: 'START_UPDATE_TRANSPORT_AUTO_CONFIG',
    UPDATE_TRANSPORT_AUTO_CONFIG_SUCCESS: 'UPDATE_TRANSPORT_AUTO_CONFIG_SUCCESS',
    UPDATE_TRANSPORT_AUTO_CONFIG_FAILURE: 'UPDATE_TRANSPORT_AUTO_CONFIG_FAILURE',
    
    START_GET_TRANSPORT_AUTO_CONFIG: 'START_GET_TRANSPORT_AUTO_CONFIG',
    GET_TRANSPORT_AUTO_CONFIG_SUCCESS: 'GET_TRANSPORT_AUTO_CONFIG_SUCCESS',
    GET_TRANSPORT_AUTO_CONFIG_FAILURE: 'GET_TRANSPORT_AUTO_CONFIG_FAILURE',
    
    START_GET_DETAIL_TRANSPORT_AUTO_CONFIG: 'START_GET_DETAIL_TRANSPORT_AUTO_CONFIG',
    GET_DETAIL_TRANSPORT_AUTO_CONFIG_SUCCESS: 'GET_DETAIL_TRANSPORT_AUTO_CONFIG_SUCCESS',
    GET_DETAIL_TRANSPORT_AUTO_CONFIG_FAILURE: 'GET_DETAIL_TRANSPORT_AUTO_CONFIG_FAILURE',
  }
  
  export default transportAutoConfigTypes;