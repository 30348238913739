import * as React from "react"

const SvgBoxPen = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill="#fff"
      stroke="#808A94"
    />
    <path
      d="M18.188 6.813a1.875 1.875 0 0 0-2.67 0l-1.2 1.218 2.65 2.65 1.2-1.2a1.876 1.876 0 0 0 .02-2.668ZM13.431 8.919l-5.244 5.243a.717.717 0 0 0-.118.163L6.33 17.831a.625.625 0 0 0 .544.919c.095 0 .19-.021.275-.063l3.506-1.737a.716.716 0 0 0 .163-.119l5.243-5.243-2.63-2.67ZM18.125 18.75H12.5a.624.624 0 1 1 0-1.25h5.625a.624.624 0 1 1 0 1.25Z"
      fill="#000"
    />
  </svg>
)

export default SvgBoxPen