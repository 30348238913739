import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "src/services/api/api.index";
import { API_URL } from "src/services/api/config";
import { API_PRICE_OFF_TYPE } from "src/services/api/url.index";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import priceOffTypes from "../types/priceOffType.types";

function* getListPriceOffType(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_PRICE_OFF_TYPE}`, payload?.params);
		});
		yield put(actionSuccess(priceOffTypes.GET_PRICE_OF_TYPE_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(priceOffTypes.GET_PRICE_OF_TYPE_FAILURE, err));
	}
}

function* getPriceOffTypeById(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_PRICE_OFF_TYPE}/${payload.id}`);
		});
		yield put(actionSuccess(priceOffTypes.GET_PRICE_OF_TYPE_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(priceOffTypes.GET_PRICE_OF_TYPE_BY_ID_FAILURE, err));
	}
}
const priceOffTypesSagas = [
	takeLatest(priceOffTypes.START_GET_PRICE_OF_TYPE, getListPriceOffType),
	takeLatest(priceOffTypes.START_GET_PRICE_OF_TYPE_BY_ID, getPriceOffTypeById)
];
export default priceOffTypesSagas;
