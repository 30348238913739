import * as React from "react"

const SvgRecycleBin = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill="#fff"
      stroke="#808A94"
    />
    <path
      d="M19.25 7.25H5.75a.75.75 0 0 0 0 1.5h1.5v9A2.25 2.25 0 0 0 9.5 20h6a2.25 2.25 0 0 0 2.25-2.25v-9h1.5a.75.75 0 1 0 0-1.5Zm-7.5 8.25a.75.75 0 1 1-1.5 0v-3.75a.75.75 0 1 1 1.5 0v3.75Zm3 0a.75.75 0 1 1-1.5 0v-3.75a.75.75 0 1 1 1.5 0v3.75ZM11 6.5h3A.75.75 0 1 0 14 5h-3a.75.75 0 1 0 0 1.5Z"
      fill={props?props.fill:"#000"}
    />
  </svg>
)

export default SvgRecycleBin
