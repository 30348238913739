/*eslint-disable*/
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoBackgroundOMS from "src/assets/images/bg.png";
import logoNTL from "src/assets/images/logoNTL.png";
import { registerAccount, signInAccount } from "../../services/actions/account.actions";
import { AppState } from "../../types";

import { notifyError, notifySuccess } from "src/components/notification";
import { useAuth } from "src/services/authorRouter";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { localGetFormLogin } from "src/utils/localStorage";
// import { GoogleLogin, googleLogout } from "@react-oauth/google";
// import { useGoogleLogin } from "@react-oauth/google";
// import { IGoogleAuthentication } from "src/services/interfaces/auth.interface";
import ActiveAccountSuccess from "./components/activeAccountSuccess";
import ChangeAccountSuccess from "./components/changeAccountSuccess";
import ChangePassword from "./components/changePassword";
import CreateAccountSuccess from "./components/createAccountSuccess";
import ForgetPassword from "./components/forgetPassword";
import Otp from "./components/otp";
import Register from "./components/register";
import SignInPage from "./components/signIn";

export const typeScreenManagerSignIn = {
	SIGN_IN: 1,
	REGISTER: 2,
	FORGET_PASSWORD: 3,
	CREATE_ACCOUNT_SUCCESS: 4,
	ACTIVE_ACCOUNT_SUCCESS: 5,
	OTP: 6,
	CHANGE_PASSWORD: 7,
	CHANGE_PASSWORD_SUCCESS: 8
};

const SignIn = () => {
	// const [formLogin] = Form.useForm();
	const auth = useAuth();
	const dispatch = useDispatch();
	const isMount = useIsMount();
	const [type, setType] = useState(1);
	// let history = useHistory();
	const { stateSignIn, stateRegister } = useSelector((state: AppState) => state.accountReducer); //eslint-disable-line
	const [renderType, setRenderType] = useState(typeScreenManagerSignIn.SIGN_IN);
	/****************************START**************************/
	/*                         Life Cycle                      */

	useEffect(() => {
		let _formLogin = localGetFormLogin();
		if (_formLogin) {
			let _dataUser = JSON?.parse(_formLogin);
			// formLogin.setFieldsValue({
			// 	username: _dataUser.username
			// });
		}
	}, []);

	useEffect(() => {
		if (isMount) return;
		const { isLoading, success, message, error, data } = stateRegister;
		if (!isLoading) {
			if (success) {
				notifySuccess(message + "");
				// formLogin.resetFields();
				setType(1);
			} else if (!success || error) {
				return notifyError(message + "");
			}
		}
	}, [stateRegister.isLoading]);

	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { isLoading, success, message, error, data } = stateSignIn;
	// 	if (!isLoading) {
	// 		if (success) {
	// 			localSaveAccount(JSON.stringify(data));
	// 			localSaveToken(data.token || "");
	// 			localSaveFeatures(data.features || "");
	// 			localSaveAuthUUID(data.uuid || "");
	// 			localSaveFormLogin(
	// 				JSON.stringify({
	// 					username: data?.user?.email
	// 				})
	// 			);
	// 			auth.signIn(data.token || "", JSON.stringify(data), () => {
	// 				notifySuccess("Đăng nhập thành công");
	// 				history.push({
	// 					pathname: "dashboard"
	// 				});
	// 			});
	// 		} else if (!success || error) {
	// 			return notifyError(message + "");
	// 		}
	// 	}
	// }, [stateSignIn.isLoading]);

	/**************************** END **************************/

	/****************************START**************************/
	/*                          Function                       */

	const btnSignIn = (values: any) => {
		let params;
		// email: 'maivthang95@gmail.com',
		// password: 'Aa@123456'
		if (values?.username?.search("@") !== -1) {
			params = {
				username: values?.username?.trim(),
				password: values?.password?.trim()
			};
		} else {
			params = {
				username: values.username.trim(),
				password: values.password.trim()
			};
		}

		dispatch(signInAccount(params));
	};

	// const btnRegister = (values: any) => {
	// 	let params;
	// 	params = {
	// 		fullname: values.fullname.trim(),
	// 		email: values.email.trim(),
	// 		phone: values.phone.trim(),
	// 		password: values.password.trim()
	// 	};
	// 	dispatch(registerAccount(params));
	// };

	// const onFinishFailed = (errorInfo: any) => {
	// 	console.log("Failed:", errorInfo);
	// };

	/**************************** END **************************/

	/****************************START**************************/
	/*                         Component                       */

	/**************************** END **************************/

	/****************************START**************************/
	/*                        Return Page                      */
	const responseFacebook = (response: any) => {
		console.log(response);
		if (response?.name) {
			notifySuccess(`Login thành công ${response?.name}`);
		}
	};

	const createOrGetUser = async (res: any) => {
		console.log(res);
		// let userInfo = await axios.get(
		//   "https://www.googleapis.com/oauth2/v3/userinfo",
		//   {
		//     headers: {
		//       Authorization: `Bearer ${res.access_token}`,
		//       Accept: "application/json",
		//     },
		//   }
		// );
		// if (userInfo.data) {

		//   notifySuccess(`Login thành công ${userInfo.data?.name}`);
		// }
	};

	// const login = useGoogleLogin({
	//   onSuccess: (credentialResponse) => createOrGetUser(credentialResponse),
	//   scope: "email",
	// });
	const changeType = (values: number) => {
		setRenderType(values);
	};

	// const responseGoogle = (response: any) => {
	// 	const { profileObj } = response;
	// 	const dataRequest: any = {
	// 		providerId: profileObj["googleId"],
	// 		familyName: profileObj["familyName"],
	// 		givenName: profileObj["givenName"],
	// 		imageUrl: profileObj["imageUrl"],
	// 		email: profileObj["email"],
	// 		extra_data: profileObj["tokenObj"]
	// 	};
	// 	dispatch(signInGoogle(dataRequest));
	// };
	const _renderForm = () => {
		switch (renderType) {
			case typeScreenManagerSignIn.REGISTER:
				return <Register changeType={changeType} />;
			case typeScreenManagerSignIn.FORGET_PASSWORD:
				return <ForgetPassword changeType={changeType} />;
			case typeScreenManagerSignIn.CREATE_ACCOUNT_SUCCESS:
				return <CreateAccountSuccess />;
			case typeScreenManagerSignIn.OTP:
				return <Otp />;
			case typeScreenManagerSignIn.CHANGE_PASSWORD:
				return <ChangePassword changeType={changeType} />;
			case typeScreenManagerSignIn.ACTIVE_ACCOUNT_SUCCESS:
				return <ActiveAccountSuccess changeType={changeType} />;
			case typeScreenManagerSignIn.CHANGE_PASSWORD_SUCCESS:
				return <ChangeAccountSuccess changeType={changeType} />;

			default:
				return <SignInPage changeType={changeType} />;
		}
	};
	return (
		<div className="flex justify-between items-center " style={{ height: "100vh", overflow: "hidden" }}>
			<Col xs={0} sm={0} md={12} lg={12} xl={12} className="">
			<img src={"/bglogin.png"} alt="" style={{  objectFit: "cover",maxHeight:'100vh',width:'100%' }} />
			</Col>

			<Col xs={24} sm={24} md={12} lg={12} xl={12} className="flex items-center justify-center p-20">
				{_renderForm()}
			</Col>
		</div>
	);

	/**************************** END **************************/
};

export default SignIn;
