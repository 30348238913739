
import { IAction } from '../interfaces/root.interfaces';
import pageHeaderTypes from './types/pageHeader.type';

export const getPageHeader = (params?: any): IAction => {
  const action: IAction = {
    type: pageHeaderTypes.START_GET_PAGE_HEADER,
    payload: { params },
  }
  return action;
}


export const updatePageHeader = (params: any): IAction => {
  const action: IAction = {
    type: pageHeaderTypes.START_UPDATE_PAGE_HEADER,
    payload: {  params },
  }
  return action;
}
