import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import pageHeaderType from '../actions/types/pageHeader.type';
import { IPageHeaderState } from '../interfaces/pageHeader.interfaces';

const initState: IPageHeaderState= {
  stateGetPageHeader: { ...rootState },
  stateUpdatePageHeader: { ...rootState },
};

const pageHeaderReducer: Reducer<IPageHeaderState, ISyntheticAction>
  = (state: IPageHeaderState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET LIST USER SYSTEM                   */

      case pageHeaderType.START_GET_PAGE_HEADER: {
        const stateReducer: IPageHeaderState = {
          ...state,
          stateGetPageHeader: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case pageHeaderType.GET_PAGE_HEADER_SUCCESS: {
        const stateReducer: IPageHeaderState = {
          ...state,
          stateGetPageHeader: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case pageHeaderType.GET_PAGE_HEADER_FAILURE: {
        const stateReducer: IPageHeaderState = {
          ...state,
          stateGetPageHeader: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

   

      /*************************** START *************************/
      /*                 UPDATE ONE USER SYSTEM                  */

      case pageHeaderType.START_UPDATE_PAGE_HEADER: {
        const stateReducer: IPageHeaderState = {
          ...state,
          stateUpdatePageHeader: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case pageHeaderType.UPDATE_PAGE_HEADER_SUCCESS: {
        const stateReducer: IPageHeaderState = {
          ...state,
          stateUpdatePageHeader: {
            ...rootState,
            isLoading: false,
            data: response.data.params,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case pageHeaderType.UPDATE_PAGE_HEADER_FAILURE: {
        const stateReducer: IPageHeaderState = {
          ...state,
          stateUpdatePageHeader: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

    

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default pageHeaderReducer;