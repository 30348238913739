function SvgIcon00004(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
			<path
				fill={props.fill || "#001529"}
				d="M20.52 9.625h-1.437V7.708A6.716 6.716 0 0012.375 1a6.716 6.716 0 00-6.708 6.708v1.917H4.229a.479.479 0 00-.479.48v11.978C3.75 23.14 4.61 24 5.667 24h13.416C20.14 24 21 23.14 21 22.083V10.104a.479.479 0 00-.48-.479zm-6.71 10.01a.48.48 0 01-.477.532h-1.916a.48.48 0 01-.477-.532l.303-2.719a1.895 1.895 0 01-.785-1.541c0-1.057.86-1.917 1.917-1.917s1.917.86 1.917 1.917c0 .62-.294 1.184-.784 1.541l.302 2.719zm2.398-10.01H8.542V7.708a3.838 3.838 0 013.833-3.833 3.838 3.838 0 013.833 3.833v1.917z"
			></path>
		</svg>
	);
}

export default SvgIcon00004;
