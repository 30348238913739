import moment from "moment";
export const dateFormatYMD = "YYYY/MM/DD";
export const dateFormatDMY = "DD/MM/YYYY";
export const dateFormatYMD_hms = "YYYY/MM/DD hh:mm:ss";
export const dateFormatYMD_hmsA = "YYYY/MM/DD hh:mm:ss a";
export const dateFormatYMD_hm24h = "YYYY/MM/DD HH:mm";
export const dateFormatYMD_hms24h = "YYYY/MM/DD HH:mm:ss";
export const dateFormatDMY_hms24h = "DD/MM/YYYY HH:mm:ss";
export const dateFormat_hms24h = "HH:mm:ss";
export const dateFormat_hm24h = "HH:mm";
export const dateFormatDM_hms = "DD/MM hh:mm:ss";
export const dateFormatDM_hm = "DD/MM hh:mm";

export const _startOfMonth = moment().startOf("month");
export const startOfMonth = (dateFormat) => _startOfMonth.format(dateFormat ? dateFormat : dateFormatYMD);
export const _endOfMonth = moment().endOf("month");
export const endOfMonth = (dateFormat) => _endOfMonth.format(dateFormat ? dateFormat : dateFormatYMD);

export const _startOfLastMonth = moment().subtract(1, "months").startOf("month");
export const startOfLastMonth = (dateFormat) => _startOfLastMonth.format(dateFormat ? dateFormat : dateFormatYMD);
export const _endOfLastMonth = moment().subtract(1, "months").endOf("month");
export const endOfLastMonth = (dateFormat) => _endOfLastMonth.format(dateFormat ? dateFormat : dateFormatYMD);

export const _startOfWeek = moment().startOf("week");
export const startOfWeek = (dateFormat) => _startOfWeek.format(dateFormat ? dateFormat : dateFormatYMD);
export const _endOfWeek = moment().endOf("week");
export const endOfWeek = (dateFormat) => _endOfWeek.format(dateFormat ? dateFormat : dateFormatYMD);
export const beforeThirtyDays = (dateFormat) =>
	moment()
		.subtract(30, "days")
		.format(dateFormat ? dateFormat : dateFormatYMD);
export const _start7Day = moment().subtract(7, "days");
export const start7Day = (dateFormat) => _start7Day.format(dateFormat ? dateFormat : dateFormatYMD);
export const _start14Day = moment().subtract(14, "days");
export const start14Day = (dateFormat) => _start14Day.format(dateFormat ? dateFormat : dateFormatYMD);
export const _start30Day = moment().subtract(30, "days");
export const start30Day = (dateFormat) => _start30Day.format(dateFormat ? dateFormat : dateFormatYMD);
export const _yesterday = moment().subtract(1, "days");
export const yesterday = (dateFormat) => _yesterday.format(dateFormat ? dateFormat : dateFormatYMD);
export const _nowDate = moment();
export const nowDate = (dateFormat) => _nowDate.format(dateFormat ? dateFormat : dateFormatYMD);
export const fromDate = (dateFormat) => moment(beforeThirtyDays, dateFormat ? dateFormat : dateFormatYMD);
export const toDate = (dateFormat) => moment(nowDate, dateFormat ? dateFormat : dateFormatYMD);
export const _start15Day = moment().subtract(15, "days");
export const _before15Day = moment().add(15, "days");

export const formatDateTime = (timestamp = moment(), format = dateFormatYMD) => moment(timestamp).format(format);

export const formatAddDateTime = (value, type = "days", timestamp = new Date()) => +moment(timestamp).add(value, type);

export const diffTime = (fromTime = new Date(), toTime = new Date(), type = "minutes") =>
	moment(fromTime).diff(toTime, type);

const padTo2Digits = (num) => {
	return num.toString().padStart(2, "0");
};

export const formatDate = (date) => {
	return (
		[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
		" " +
		[padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
	);
};

export const optionsTime = (value) => {
	switch (value) {
		case "last_1_days":
			return [_yesterday, _nowDate];
		case "last_7_days":
			return [_start7Day, _nowDate];

		case "week_now":
			return [_startOfWeek, _nowDate];

		case "last_month":
			return [_startOfLastMonth, _endOfLastMonth];

		case "month_now":
			return [_startOfMonth, _endOfMonth];

		case "thirty_date_before":
			return [_start30Day, _nowDate];
		case "15_date_before":
			return [_start15Day, _nowDate];
		case "15_days_before_after":
			return [_start15Day, _before15Day];

		default:
			return [_nowDate, _nowDate];
	}
};
