import axios from "axios";
import { localGetAuthUUID, localGetToken } from "src/utils/localStorage";
import { postMethod } from "../../utils/helpers/functions/textUtils";
import { ICatchError } from "../interfaces/root.interfaces";
import routerNames from "src/utils/data/routerName";
import { APP_ID } from "./config";

const addHeaders = async (url: string, options: object, dataHeaders: any, isAuth: boolean) => {
	let headers: any = {
		"Content-Type": "application/json"
	};
	let token = localGetToken();
	let authUUID = localGetAuthUUID();
	if (isAuth && token) {
		headers.Authorization = token;
		headers["app_id"] = APP_ID;
	}
	if (isAuth && authUUID) {
		headers["x-auth-uuid"] = authUUID;
	}
	if (dataHeaders) {
		headers = { ...headers, ...dataHeaders };
	}
	return axios({ url: `${url}`, headers: headers, ...options });
};

const apiDownloadBlob = (method: string, isAuth: boolean) => (url: string, payload?: object, token?: string) => {
	return new Promise((resolve, reject) => {
		addHeaders(
			url,
			{
				method,
				responseType: "blob",
				data: method !== "GET" ? payload : postMethod(payload),
				params: method === "GET" ? payload || "" : ""
			},
			undefined,
			isAuth || false
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				let catchError: ICatchError = handleError({
					...err.response,
					url: url
				});
				reject(catchError);
			});
	});
};

const apiWithPayload = (method: string, isAuth: boolean) => (url: string, payload?: object, token?: string) => {
	return new Promise((resolve, reject) => {
		addHeaders(
			url,
			{
				method,
				data: method !== "GET" ? payload : postMethod(payload),
				params: method === "GET" ? payload || "" : ""
			},

			token
				? {
						Authorization: token || ""
				  }
				: undefined,
			isAuth || false
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				let catchError: ICatchError = handleError({
					...err.response,
					url: url
				});
				reject(catchError);
			});
	});
};
const apiWithPayloadPrivate = (method: string, isAuth: boolean) => (url: string, payload?: object, token?: string) => {
	return new Promise((resolve, reject) => {
		addHeaders(
			url,
			{
				method,
				data: method !== "GET" ? payload : postMethod(payload),
				params: method === "GET" ? payload || "" : ""
			},
			{
				Authorization: "56gXx2Y{+D6/4m#kZ394jNTL2=34s3bT2eHq24TAu>r8zAT>4yEn:;T2M#9*Vg"
			},
			isAuth || false
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				let catchError: ICatchError = handleError({
					...err.response,
					url: url
				});
				reject(catchError);
			});
	});
};
const apiWithFormData = (method: string, isAuth: boolean) => async (url: string, formData: object) => {
	let _data = new FormData();
	Object.entries(formData).forEach(([key, value]) => {
		if (Array.isArray(value) && value?.length > 0) {
			value.forEach((item: any) => {
				_data.append(key, item);
			});
		} else _data.append(key, value);
	});

	return new Promise((resolve, reject) => {
		addHeaders(
			url,
			{
				method,
				data: _data
			},
			{
				// 'Accept': 'multipart/form-data',
				"Content-type": "multipart/form-data"
				// 'accept-encoding': 'gzip',
			},
			isAuth || false
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				let catchError: ICatchError = handleError(err.response);
				// notifyError(catchError.message || 'Xảy ra lỗi');
				reject(catchError);
			});
	});
};

const handleError = (err: any) => {
	let resultError;
	console.log(err);
	if (err?.data && err?.status === 408) {
		localStorage.clear();
		window.location.href = routerNames.SIGN_IN;

		resultError = {
			message: "Token đã hết hạn.",
			statusCode: err?.data?.statusCode,
			data: err?.data?.data || "Lỗi"
		};
	} else if (err?.data) {
		resultError = {
			message: err?.data?.message,
			statusCode: err?.data?.statusCode,
			data: err?.data?.data || "Lỗi"
		};
	} else {
		resultError = {
			message: `${err?.url ? err?.url + ", lỗi hệ thống !" : "Lỗi hệ thống !"}`,
			statusCode: 500
		};
	}
	// //token exprired
	// if (([408, 500]).includes(err?.status))
	//   configureStore().dispatch(putSignIn(false));
	return resultError;
};

export const api = {
	get: apiWithPayload("GET", true),
	getDowload: apiDownloadBlob("GET", true),
	getNoAuth: apiWithPayload("GET", false),
	delete: apiWithPayload("DELETE", true),
	post: apiWithPayload("POST", true),
	put: apiWithPayload("PUT", true),
	postMultipart: apiWithFormData("POST", true),
	putMultipart: apiWithFormData("PUT", true),
	getPrivate: apiWithPayloadPrivate("GET", true)
};
