import { Reducer } from 'redux';
import { localGetTheme } from '../../utils/localStorage';
import globalTypes from '../actions/types/global.types';
import { IGlobalState } from '../interfaces/global.interfaces';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { rootState } from './state/root.states';

// type Action =
//   | ReturnType<typeof changeTheme>
//   | ReturnType<typeof otherAction>
//   | ReturnType<typeof putSignIn>;

const initState: IGlobalState = {
  theme: localGetTheme(),
  otherState: null,
  isSignIn: null,
  stateGetEnum: { ...rootState },
  stateProvinces: { ...rootState },
  stateGoodTypes: { ...rootState },
  stateWarehouses: { ...rootState },
  stateServices: { ...rootState },
  stateActions: [],
}

const globalReducer: Reducer<IGlobalState, ISyntheticAction>
  = (state: IGlobalState = initState, action: ISyntheticAction) => {
    const { type, payload, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                       CHANGE THEME                      */

      case globalTypes.CHANGE_THEME: {
        const stateReducer: IGlobalState = {
          ...state,
          theme: payload.data
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                      SET SIGN IN APP                    */

      case globalTypes.START_PUT_SIGN_IN_APP: {
        const stateReducer: IGlobalState = {
          ...state,
          isSignIn: null
        };
        return stateReducer;
      }
      case globalTypes.PUT_SIGN_IN_APP_SUCCESS: {
        const stateReducer: IGlobalState = {
          ...state,
          isSignIn: payload.data
        };
        return stateReducer;
      }

      /**************************** END **************************/
      /*************************** START *************************/

      case globalTypes.START_GET_ENUMS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateGetEnum: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case globalTypes.GET_ENUMS_SUCCESS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateGetEnum: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success
          }
        };
        return stateReducer;
      }
      case globalTypes.GET_ENUMS_FAILURE: {
        const stateReducer: IGlobalState = {
          ...state,
          stateGetEnum: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true
          }
        };
        return stateReducer;
      }
      /**************************** END **************************/
      /*************************** START *************************/

      case globalTypes.START_GET_PROVINCES: {
        const stateReducer: IGlobalState = {
          ...state,
          stateProvinces: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case globalTypes.GET_PROVINCES_SUCCESS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateProvinces: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success
          }
        };
        return stateReducer;
      }
      case globalTypes.GET_PROVINCES_FAILURE: {
        const stateReducer: IGlobalState = {
          ...state,
          stateProvinces: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true
          }
        };
        return stateReducer;
      }

      case globalTypes.START_GET_GOOD_TYPES: {
        const stateReducer: IGlobalState = {
          ...state,
          stateGoodTypes: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case globalTypes.GET_GOOD_TYPES_SUCCESS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateGoodTypes: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success
          }
        };
        return stateReducer;
      }
      case globalTypes.GET_GOOD_TYPES_FAILURE: {
        const stateReducer: IGlobalState = {
          ...state,
          stateGoodTypes: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true
          }
        };
        return stateReducer;
      }

      case globalTypes.START_GET_SERVICES: {
        const stateReducer: IGlobalState = {
          ...state,
          stateServices: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case globalTypes.GET_SERVICES_SUCCESS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateServices: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success
          }
        };
        return stateReducer;
      }
      case globalTypes.GET_SERVICES_FAILURE: {
        const stateReducer: IGlobalState = {
          ...state,
          stateServices: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true
          }
        };
        return stateReducer;
      }

      case globalTypes.START_GET_WAREHOUSES: {
        const stateReducer: IGlobalState = {
          ...state,
          stateWarehouses: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case globalTypes.GET_WAREHOUSES_SUCCESS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateWarehouses: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success
          }
        };
        return stateReducer;
      }
      case globalTypes.GET_WAREHOUSES_FAILURE: {
        const stateReducer: IGlobalState = {
          ...state,
          stateWarehouses: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true
          }
        };
        return stateReducer;
      }
      /**************************** END **************************/
      /*************************** START *************************/
      /*                      SET SIGN IN APP                    */

      case globalTypes.START_PUT_ACTIONS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateActions: []
        };
        return stateReducer;
      }
      case globalTypes.PUT_ACTIONS_SUCCESS: {
        const stateReducer: IGlobalState = {
          ...state,
          stateActions: payload.data
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  }

export default globalReducer;