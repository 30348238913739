/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import CreateOpertaionModal from "./CreateModal";
import { getWarehouses } from "src/services/actions/global.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/types";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const WarehouseToCustomer = () => {
	const dispatch = useDispatch();
	const [data, setData] = useState<any>([]);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter });
	const [visible, setVisible] = useState<any>(false);
	const [params, setParams] = useState<any>({
		page: 1,
		limit: 10,
	});
	const [loading, setLoading] = useState<any>(false);
	const [callback, setCallback] = useState<boolean>(false);
	const [total, setToal] = useState<number>(0);
	const [dataSyscTicket, setDataSyscTicket] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [idCostsIncurred, setIdCostsIncurred] = useState<any>();
	const [visibleCostsIncurred, setVisibleCostsIncurred] = useState<any>(false);
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
	const [dataBills, setDataBills] = useState<any>([]);
	const [dataStatus, setDataStatus] = useState<any>([]);
	const { stateWarehouses } = useSelector((e: AppState) => e.globalReducer);
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		dispatch(getWarehouses());
		
	}, []);
	useEffect(() => {
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				params.operation_type = "WTD"
				const res: any = await _apiNotSaga.getInWarehouse(params);
				setDataBills(res?.data);
				setToal(res?.total)
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
	}, [params, callback]); //eslint-disable-line
	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
	const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const costsIncurredDetail = (id: any) => {
		setIdCostsIncurred(id);
		setVisibleCostsIncurred(true);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	const actionsFilter = (v: any) => {
		console.log('v', v)
		const { idBtn } = v;
		handleActions[idBtn](v);
	};
	const handleActions: any = {
		btnSearch: onSearch,
		// changeSelect: changeSelect,
		btnAdd: () => setVisible(true),
	};
	const onSuccessModal = () => {
		setCallback(!callback);
		setVisible(false);
	};
	return (
		<Loading spinning={loading}>
			{visible && <CreateOpertaionModal visible={visible} onCancel={()=> setVisible(false)} onSuccess={onSuccessModal} type={"WTD"}/>}
			<div className="contentSection">
				<div className="top-0 contentSection-filter">
					<FlexFilter
						onSearch={(v) => actionsFilter(v)}
						onAdd={(v) => {
							setVisible(true)
							console.log("ABC")
						}}
						// manipulation={[
						// 	{
						// 		paramsName: "btnAdd",
						// 		icon: <PlusOutlined color='white' />,
						// 		text: "Tạo mới"
						// 	}
						// 	// {
						// 	// 	paramsName: "btnSysc",
						// 	// 	icon: <TransactionOutlined />,
						// 	// 	text: "Tạo ticket thanh toán"
						// 	// }
						// ]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.input,
								placeholder: "Nhập mã nhập kho",
								label: "Nhập mã nhập kho",
								paramsName: "search"
							},

							

							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Trạng thái",
								placeholder: "Chọn trạng thái",
								width: 160,
								options: {
									data: dataStatus,
									keyOption: "id",
									valueOption: "id",
									nameOption: "candidate_status"
								},
								paramsName: "candidate_status_id"
							},
							{
								...FLEX_FILTER_ELEMENTS.createdRange,
								label: "Ngày tạo",
								initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
								initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
								width: 250
							},
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined color='white' />
							},
                            // {
							// 	...FLEX_FILTER_ELEMENTS.btnFilterMore
							// },
                            {
								...FLEX_FILTER_ELEMENTS.btnAdd,
                                icon: <PlusOutlined color='white'/>
							},
							{ ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
						]}
					/>
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={dataBills}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: params.page,
							showSizeChanger: true,
							defaultPageSize: params.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						scroll={{
							x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
							y: "calc(70vh - 80px)"
						}}
						isRowLight={true}
						columns={columnsData({ onEdit, onCancel, pushUpApproval, onCreateTicket, user: account }) as any}
					/>
				</div>
			</div>
		</Loading>
	);
};

export default WarehouseToCustomer;
