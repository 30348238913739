/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import { useLocation, useParams } from "react-router-dom";
import FormSelect from "src/components/form/FormSelect";
import { Row, Col, Input, Form, Button } from "antd";
import { localGetAccount } from "src/utils/localStorage";
import moment from "moment";
import { WH_OPERATION_TYPE } from "src/utils/helpers/functions/data";
import TagStatusWH from "src/components/TagStatusWH/tagStatusWH";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const WHOperation = () => {
	const [form] = Form.useForm();
	const urlParams = new URLSearchParams(window.location.search);
	const type_operation = urlParams.get("type");
	const { id } = useParams<any>();
	const [data, setData] = useState<any>([]);
	const [visible, setVisible] = useState<any>(false);
	const [user, setUser] = useState<any>(false);
	const [params, setParams] = useState<any>({
		page: 1,
		limit: 10
	});
	const [loading, setLoading] = useState<any>(false);
	const [total, setToal] = useState<number>(0);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
	const [dataBills, setDataBills] = useState<any>([]);
	const [scanBill, setScanBill] = useState<any>([]);
	const [scanBillNewList, setScanBillNewList] = useState<any>([]);
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		let USER_INFO: any = localGetAccount();
		USER_INFO = JSON.parse(USER_INFO);
		console.log("USER_INFO", USER_INFO);
		setUser(USER_INFO);
		getDetailedOperationWH();
	}, []);

	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
	const getDetailedOperationWH = async () => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.getDetailedOperationWH(id, params);
			console.log("res", res);
			form.setFieldsValue(res?.data);
			setDataBills(res?.data);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onScanBill = async (bill_code: any) => {
		console.log("bill_code", bill_code);
		if (!bill_code) {
			return;
		}
		if (dataBills.child.some((item: any) => item.bill_code == bill_code)) {
			return notifyError("Đơn đã quét!");
		}
		setLoading(true);
		try {
			params.bill_code = bill_code;
			params.wh_operation_id = id;
			const scanBillRes: any = await _apiNotSaga.scanBillInOut(params);
			setScanBill("");
			if (!scanBillRes.success) {
				return notifyError(scanBillRes.message);
			}
			dataBills.child = [scanBillRes.data, ...dataBills.child];
			dataBills.total_bill = (dataBills.total_bill || 0) + 1;
			dataBills.total_weight = (dataBills.total_weight || 0) + scanBillRes.data.weight;
			setDataBills({ ...dataBills });
			notifySuccess("Quét thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const onComplete = async () => {
		setLoading(true);
		try {
			const completeRes: any = await _apiNotSaga.completeOperationWH(id, params);
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getDetailedOperationWH();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const onDelete = async () => {
		setLoading(true);
		try {
			params.status = "D";
			const completeRes: any = await _apiNotSaga.updateOperationWH(id, params);
			if (!completeRes.success) {
				return notifyError(completeRes.message);
			}
			getDetailedOperationWH();
			notifySuccess("Cập nhật thành công");
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const _handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			onScanBill(scanBill);
		}
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
	const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const onDeleteBill = async (bill: any) => {
		const params = {
			wh_operation_id: dataBills.id,
			bill_list: [bill]
		};
		const res: any = await _apiNotSaga.deleteInOutBills(params);
		if (!res.success) {
			return notifyError(res.message);
		}
		dataBills.child = dataBills.child?.filter((item: any) => item.bill_code != bill.bill_code);
		dataBills.total_bill = (dataBills.total_bill || 0) - 1;
		dataBills.total_weight = (dataBills.total_weight || 0) - bill.weight;
		setDataBills({ ...dataBills });
		return notifySuccess(res.message);
	};

	const col_weight = ["PTW", "DTW"].includes(dataBills.operation_type) ? 6 : 3;
	console.log("dataBills", dataBills);
	return (
		<Loading spinning={loading}>
			<Form form={form} layout="vertical" className="space-y-4">
				<div className="contentSection">
					<div className="contentSection-main">
						<div className="flex">
							<h3>
								{WH_OPERATION_TYPE[dataBills.operation_type]} - {dataBills.operation_code} 
							</h3>
							<div className="w-2"></div>
							<TagStatusWH status={dataBills?.status} />
						</div>
						<Row gutter={[8, 8]}>
							{["WTW", "WTD"].includes(dataBills.operation_type) ? (
								<Col md={6} xs={6} sm={6}>
									<Form.Item label="Kho xuất" name="wh_from_name">
										<Input className="w-full" disabled={true} />
									</Form.Item>
								</Col>
							) : (
								<></>
							)}
							{["WTW", "PTW", "DTW"].includes(dataBills.operation_type) ? (
								<Col md={6} xs={6} sm={6}>
									<Form.Item label="Kho nhập" name="wh_to_name">
										<Input className="w-full" disabled={true} />
									</Form.Item>
								</Col>
							) : (
								<></>
							)}
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Nhân viên giao">
									<Input
										className="w-full"
										value={`${dataBills.created_user_by} - ${dataBills.created_full_name}`}
										disabled={true}
									/>
								</Form.Item>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Nhân viên nhận">
									<Input
										className="w-full"
										value={`${dataBills.receive_user_by} - ${dataBills.receive_full_name}`}
										disabled={true}
									/>
								</Form.Item>
							</Col>
							{["PTW", "DTW", "WTD"].includes(dataBills.operation_type) ? (
								<Col md={6} xs={6} sm={6}>
									<Form.Item label="Ngày tạo">
										<Input
											className="w-full"
											value={`${moment(dataBills.created_at).format("DD-MM-YYYY HH:mm:ss")}`}
											disabled={true}
										/>
									</Form.Item>
								</Col>
							) : (
								<></>
							)}
							<Col md={6} xs={6} sm={6}>
								<Form.Item label="Quét mã">
									<Input
										className="w-full"
										value={`${scanBill}`}
										onChange={(e) => {
											setScanBill(e.target.value);
										}}
										onBlur={(e) => {
											onScanBill(e.target.value);
										}}
										disabled={["C", "D"].includes(dataBills.status)}
										onKeyDown={(e) => _handleKeyDown(e)}
									/>
								</Form.Item>
							</Col>
							<Col md={col_weight} xs={col_weight} sm={col_weight}>
								<Form.Item label="SL vận đơn">
									<Input className="w-full" value={`${dataBills.total_bill || 0}`} disabled={true} />
								</Form.Item>
							</Col>
							<Col md={col_weight} xs={col_weight} sm={col_weight}>
								<Form.Item label="Tổng TL (kg)">
									<Input className="w-full" value={`${dataBills.total_weight || 0}`} disabled={true} />
								</Form.Item>
							</Col>
							{!["PTW", "DTW"].includes(dataBills.operation_type) ? (
								<Col md={6} xs={6} sm={6}>
									<Form.Item label="Ngày tạo">
										<Input
											className="w-full"
											value={`${moment(dataBills.created_at).format("DD-MM-YYYY HH:mm:ss")}`}
											disabled={true}
										/>
									</Form.Item>
								</Col>
							) : (
								<></>
							)}
							<Col md={6} xs={6} sm={6}>
								{!["C", "D"].includes(dataBills.status) ? (
									<div
										style={{ alignItems: "end", height: "100%", paddingBottom: 7 }}
										className="flex justify-between "
									>
										<Button
											id="filter_button_onSearch"
											size="large"
											block={true}
											onClick={onComplete}
											className="bg-[#001529] flex tms-btn-search justify-center rounded-lg items-center text-[#FFFFFF]"
										>
											{"Hoàn thành"}
										</Button>
										<Button
											id="filter_button_onSearch"
											size="large"
											block={true}
											onClick={onDelete}
											className="bg-[#fff] flex tms-btn-search justify-center rounded-lg items-center text-[#000] border-1px"
										>
											{"Hủy"}
										</Button>
									</div>
								) : (
									<></>
								)}
							</Col>
						</Row>
					</div>
					<hr className=" mb-[10px] border-neutral_color_1_2" />
					<div className="contentSection-main">
						<TableStyled
							rowKey="id"
							dataSource={dataBills.child}
							bordered
							pagination={{
								locale: {
									items_per_page: "/Trang"
								},
								current: params.page,
								showSizeChanger: true,
								defaultPageSize: params.limit,
								total: dataBills?.child?.length || 0,
								showTotal: (total: any, range: any) => (
									<div className="flex gap-2">
										<strong>
											{range[0]} - {range[1]}
										</strong>
										trong tổng
										<strong>{total}</strong>
									</div>
								),
								onChange: onChangePaging
							}}
							scroll={{
								x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
								y: "calc(70vh - 80px)"
							}}
							isRowLight={true}
							columns={columnsData({ dataBills, onDelete: onDeleteBill }) as any}
						/>
					</div>
				</div>
			</Form>
		</Loading>
	);
};

export default WHOperation;
