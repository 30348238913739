import { Reducer } from "redux";
import userTypesTypes from "../actions/types/userTypes.types";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { IUserTypesState } from "../interfaces/userTypes.interfaces";
import { rootState } from "./state/root.states";

const initState: IUserTypesState = {
	stateGetUserTypes: {...rootState},
};

const userTypesReducer: Reducer<IUserTypesState, ISyntheticAction> = (
	state: IUserTypesState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/

		case userTypesTypes.START_GET_USER_TYPES: {
			const stateReducer: IUserTypesState = {
				...state,
				stateGetUserTypes: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case userTypesTypes.GET_USER_TYPES_SUCCESS: {
			const stateReducer: IUserTypesState = {
				...state,
				stateGetUserTypes: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case userTypesTypes.GET_USER_TYPES_FAILURE: {
			const stateReducer: IUserTypesState = {
				...state,
				stateGetUserTypes: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		default:
			return state;
	}
};

export default userTypesReducer;
