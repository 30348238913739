/* eslint-disable */
import styled from "styled-components";
import { Pagination, PaginationProps } from "antd";

interface PaginationStyledInterfaces extends PaginationProps {}
const PanigationAntStyled = ({ ...rest }: PaginationStyledInterfaces) => {
	return (
		<PaginationStyled
			{...rest}
			locale={{
				items_per_page: "/Trang"
			}}
			showTotal={(total, range) => (
				<div className="flex gap-2">
					<strong>
						{range[0]} - {range[1]}
					</strong>
					trong tổng
					<strong>{total}</strong>
				</div>
			)}
		/>
	);
};

export default PanigationAntStyled;

const PaginationStyled = styled(Pagination)`
	position: relative;
	width: 100%;
	text-align: right;
	// .ant-pagination-total-text {
	//   position: absolute;
	//   left: 0;
	// }
	.ant-pagination-item-link {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
	}
	.ant-pagination-item-active {
		background: #ffeec3 !important;
		border-color: #001529 !important;
		a {
			color: black !important;
		}
	}
`;
