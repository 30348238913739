const rolesTypes = {
    START_GET_ROLES: "START_GET_ROLES",
    GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
    GET_ROLES_FAILURE: "GET_ROLES_FAILURE",

    START_GET_ROLE_DETAIL: "START_GET_ROLE_DETAIL",
    GET_ROLE_DETAIL_SUCCESS: "GET_ROLE_DETAIL_SUCCESS",
    GET_ROLE_DETAIL_FAILURE: "GET_ROLE_DETAIL_FAILURE",
  };
  
  export default rolesTypes;
  