import * as React from "react"

const SvgIcon00101_1 = (props) => (
  <svg
    width={65}
    height={65}
    fill={props.fill || "#BFC4C9"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.446 60.467a27.922 27.922 0 0 1-19.81-8.192c-10.924-10.925-10.924-28.7 0-39.624a27.835 27.835 0 0 1 19.812-8.208c7.484 0 14.52 2.914 19.81 8.208 5.291 5.292 8.207 12.328 8.207 19.812 0 7.484-2.913 14.52-8.207 19.812a27.93 27.93 0 0 1-19.812 8.192Zm.002-51.54a23.372 23.372 0 0 0-16.642 6.894 23.382 23.382 0 0 0-6.892 16.642c0 6.287 2.447 12.195 6.892 16.642 9.176 9.176 24.108 9.179 33.282 0a23.383 23.383 0 0 0 6.895-16.642c0-6.287-2.448-12.195-6.895-16.642a23.383 23.383 0 0 0-16.64-6.895Z"
      fill={props.fill || "#BFC4C9"}
    />
    <path
      d="M22.937 44.211a2.24 2.24 0 0 1-1.584-3.826L40.37 21.367a2.243 2.243 0 0 1 3.17 0 2.242 2.242 0 0 1 0 3.17L24.521 43.555a2.227 2.227 0 0 1-1.585.656Z"
      fill={props.fill || "#BFC4C9"}
    />
    <path
      d="M41.957 44.211a2.235 2.235 0 0 1-1.585-.656L21.354 24.537a2.24 2.24 0 0 1 2.442-3.656c.272.113.52.278.727.486l19.019 19.018a2.238 2.238 0 0 1-1.585 3.826Z"
      fill={props.fill || "#BFC4C9"}
    />
  </svg>
)

export default SvgIcon00101_1