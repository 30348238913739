function SvgIcon00142(props) {
	return (
		<svg
			width={25}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
			x={0.5}
			y={0.5}
			width={24}
			height={24}
			rx={4.5}
			fill={props.fillbg || "#fff"}
			stroke="#808A94"
			/>
			<g clipPath="url(#a)" fill={props.fill || "#000"}>
			<path d="m19.185 11.42-.066-.087c-.264-.348-.478-.74-.667-1.087-.185-.339-.36-.66-.55-.88-.425-.495-.826-.736-1.224-.736h-1.965c-.002 0-.19.003-.416.003h-.32a.502.502 0 0 0-.486.378l-.003.01v4.35c0 .002.006.064-.043.117-.054.06-.158.09-.3.09H5.489c-.27 0-.489.207-.489.46v1.58c0 .254.22.46.489.46H7.17a1.975 1.975 0 0 0 1.964 1.8c1.03 0 1.877-.793 1.965-1.8h2.814a1.975 1.975 0 0 0 1.964 1.8c1.03 0 1.877-.793 1.965-1.8h1.279A.88.88 0 0 0 20 15.2V13.69c0-1.214-.49-1.85-.815-2.27Zm-2.572 4.485a.736.736 0 0 1-1.47 0 .736.736 0 0 1 1.47 0Zm1.69-3.828c-.015.026-.058.037-.125.032h-3.38V9.353h1.462c.393 0 .671.17.961.59.193.28.703 1.158.73 1.203.017.035.036.07.055.106.147.281.37.707.298.825ZM9.135 15.17a.736.736 0 0 1 0 1.47.736.736 0 0 1 0-1.47Z" />
			<path d="M6.03 12.88h2.316c.235 0 .426-.191.426-.426V10.75a.426.426 0 0 0-.426-.426H6.03a.426.426 0 0 0-.426.426v1.703c0 .235.191.426.426.426ZM9.88 12.88h2.315c.235 0 .426-.191.426-.426V10.75a.426.426 0 0 0-.426-.426H9.88a.426.426 0 0 0-.426.426v1.703c0 .235.191.426.426.426ZM6.03 9.678h2.316a.426.426 0 0 0 .426-.426V7.549a.426.426 0 0 0-.426-.426H6.03a.426.426 0 0 0-.426.426v1.703c0 .235.191.426.426.426ZM9.88 9.678h2.315a.426.426 0 0 0 .426-.426V7.549a.426.426 0 0 0-.426-.426H9.88a.426.426 0 0 0-.426.426v1.703c0 .235.191.426.426.426Z" />
			</g>
			<defs>
			<clipPath id="a">
				<path fill={props.fillbg || "#fff"} transform="translate(5 5)" d="M0 0h15v15H0z" />
			</clipPath>
			</defs>
		</svg>
	);
}

export default SvgIcon00142;
