import { call, put, takeLatest } from "redux-saga/effects";
import { API_REPAIR_MANAGEMENT } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import repairManagementTypes from "../types/repairManagement.types";

function* getListRepairManagement(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_REPAIR_MANAGEMENT}`, payload?.params);
		});
		yield put(actionSuccess(repairManagementTypes.GET_LIST_REPAIR_MANAGEMENT_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairManagementTypes.GET_LIST_REPAIR_MANAGEMENT_FAILURE, err));
	}
}

function* getRepairManagementById(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_REPAIR_MANAGEMENT}/${payload.id}`);
		});
		yield put(actionSuccess(repairManagementTypes.GET_REPAIR_MANAGEMENT_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairManagementTypes.GET_REPAIR_MANAGEMENT_BY_ID_FAILURE, err));
	}
}

function* genCodeRepairManagementById(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.post(`${API_URL}/${API_REPAIR_MANAGEMENT}/${payload.id}`);
		});
		yield put(actionSuccess(repairManagementTypes.GEN_CODE_REPAIR_MANAGEMENT_BY_ID_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairManagementTypes.GEN_CODE_REPAIR_MANAGEMENT_BY_ID_FAILURE, err));
	}
}

function* updateOneRepairManagement(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.put(`${API_URL}/${API_REPAIR_MANAGEMENT}/${payload.id}`, payload.params);
		});
		yield put(actionSuccess(repairManagementTypes.UPDATE_ONE_REPAIR_MANAGEMENT_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairManagementTypes.UPDATE_ONE_REPAIR_MANAGEMENT_FAILURE, err));
	}
}

function* createOneRepairManagement(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.post(`${API_URL}/${API_REPAIR_MANAGEMENT}`, payload.params);
		});
		yield put(actionSuccess(repairManagementTypes.CREATE_ONE_REPAIR_MANAGEMENT_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(repairManagementTypes.CREATE_ONE_REPAIR_MANAGEMENT_FAILURE, err));
	}
}
const repairManagementSagas = [
	takeLatest(repairManagementTypes.START_GET_LIST_REPAIR_MANAGEMENT, getListRepairManagement),
	takeLatest(repairManagementTypes.START_GET_REPAIR_MANAGEMENT_BY_ID, getRepairManagementById),
	takeLatest(repairManagementTypes.START_GEN_CODE_REPAIR_MANAGEMENT_BY_ID, genCodeRepairManagementById),
	takeLatest(repairManagementTypes.START_UPDATE_ONE_REPAIR_MANAGEMENT, updateOneRepairManagement),
	takeLatest(repairManagementTypes.START_CREATE_ONE_REPAIR_MANAGEMENT, createOneRepairManagement)
];
export default repairManagementSagas
