import React from "react";

function SvgIcon00193() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
			<g fill="#FFCE4D" clipPath="url(#clip0_14890_158198)">
				<path d="M23.804 7.153A12.465 12.465 0 0019.13 1.9 12.43 12.43 0 009.692.322a12.394 12.394 0 00-7.788 5.552 12.406 12.406 0 00-1.582 9.439A12.43 12.43 0 005.874 23.1a12.468 12.468 0 006.616 1.904h.152c2.329-.03 4.594-.703 6.557-1.944a1.218 1.218 0 00.376-1.684A1.218 1.218 0 0017.891 21a10.048 10.048 0 01-10.723.039A9.98 9.98 0 012.7 14.77a10.004 10.004 0 011.275-7.592c2.94-4.702 9.16-6.138 13.862-3.199a10.059 10.059 0 014.663 9.653 1.22 1.22 0 001.08 1.348 1.22 1.22 0 001.347-1.079 12.553 12.553 0 00-1.123-6.748z"></path>
				<path d="M16.538 8.262L10.65 14.15l-2.182-2.182a1.22 1.22 0 00-1.729 0 1.22 1.22 0 000 1.728l3.047 3.047c.24.24.552.357.864.357.313 0 .625-.118.865-.357l6.748-6.753a1.22 1.22 0 10-1.724-1.728z"></path>
			</g>
			<defs>
				<clipPath id="clip0_14890_158198">
					<path fill="#fff" d="M0 0H25V25H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgIcon00193;
