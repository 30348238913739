import { Reducer } from "redux";
import driversTypes from "../actions/types/drivers.types";
import { IDriversState } from "../interfaces/drivers.interfaces";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { rootState } from "./state/root.states";

const initState: IDriversState = {
	stateGetDrivers: { ...rootState },
	stateGetDriverDetail: { ...rootState }
};

const driversReducer: Reducer<IDriversState, ISyntheticAction> = (
	state: IDriversState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case driversTypes.START_GET_DRIVERS: {
			const stateReducer: IDriversState = {
				...state,
				stateGetDrivers: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case driversTypes.GET_DRIVERS_SUCCESS: {
			const stateReducer: IDriversState = {
				...state,
				stateGetDrivers: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case driversTypes.GET_DRIVERS_FAILURE: {
			const stateReducer: IDriversState = {
				...state,
				stateGetDrivers: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/
		case driversTypes.START_GET_DRIVER_DETAIL: {
			const stateReducer: IDriversState = {
				...state,
				stateGetDriverDetail: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case driversTypes.GET_DRIVER_DETAIL_SUCCESS: {
			const stateReducer: IDriversState = {
				...state,
				stateGetDriverDetail: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case driversTypes.GET_DRIVER_DETAIL_FAILURE: {
			const stateReducer: IDriversState = {
				...state,
				stateGetDriverDetail: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		default:
			return state;
	}
};

export default driversReducer;
