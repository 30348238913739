import { Reducer } from "redux";
import rolesTypes from "../actions/types/roles.types";
import { IRolesState } from "../interfaces/roles.interfaces";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { rootState } from "./state/root.states";

const initState: IRolesState = {
	stateGetRoles: {...rootState},
	stateRoleDetail: {...rootState},
};

const rolesReducer: Reducer<IRolesState, ISyntheticAction> = (
	state: IRolesState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/

		case rolesTypes.START_GET_ROLES: {
			const stateReducer: IRolesState = {
				...state,
				stateGetRoles: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case rolesTypes.GET_ROLES_SUCCESS: {
			const stateReducer: IRolesState = {
				...state,
				stateGetRoles: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case rolesTypes.GET_ROLES_FAILURE: {
			const stateReducer: IRolesState = {
				...state,
				stateGetRoles: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		// DETAIL
		case rolesTypes.START_GET_ROLE_DETAIL: {
			const stateReducer: IRolesState = {
				...state,
				stateRoleDetail: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case rolesTypes.GET_ROLE_DETAIL_SUCCESS: {
			const stateReducer: IRolesState = {
				...state,
				stateRoleDetail: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case rolesTypes.GET_ROLE_DETAIL_FAILURE: {
			const stateReducer: IRolesState = {
				...state,
				stateRoleDetail: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		default:
			return state;
	}
};

export default rolesReducer;
