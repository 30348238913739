const vehicleTypeTypes = {
    START_GET_VEHICLE_TYPE: 'START_GET_VEHICLE_TYPE',
    GET_VEHICLE_TYPE_SUCCESS: 'GET_VEHICLE_TYPE_SUCCESS',
    GET_VEHICLE_TYPE_FAILURE: 'GET_VEHICLE_TYPE_SUCCESS',

    START_GET_VEHICLE_TYPE_DETAIL: 'START_GET_VEHICLE_TYPE_DETAIL',
    GET_VEHICLE_TYPE_DETAIL_SUCCESS: 'GET_VEHICLE_TYPE_DETAIL_SUCCESS',
    GET_VEHICLE_TYPE_DETAIL_FAILURE: 'GET_VEHICLE_TYPE_DETAIL_FAILURE',

    START_CREATE_VEHECLE_TYPE: 'START_CREATE_VEHECLE_TYPE',
    CREATE_VEHECLE_TYPE_SUCCESS: 'CREATE_VEHECLE_TYPE_SUCCESS',
    CREATE_VEHECLE_TYPE_FAILURE: 'CREATE_VEHECLE_TYPE_FAILURE',

    START_UPDATE_VEHICLE_TYPE: 'START_UPDATE_VEHICLE_TYPE',
    UPDATE_VEHICLE_TYPE_SUCCESS: 'UPDATE_VEHICLE_TYPE_SUCCESS',
    UPDATE_VEHICLE_TYPE_FAILURE: 'UPDATE_VEHICLE_TYPE_FAILURE', 
}

export default vehicleTypeTypes;