import { Space, Tag } from "antd";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import { renderIcon } from "../icons/renderIcon";
import { typeIconUI } from "../icons/typesIcon";
import { STATUS_BILL_OBJ } from "src/utils/helpers/functions/data";

interface ITemplateCard {
	status: any;
}
const TagStatus = (props: ITemplateCard) => {
	const { status } = props;
	return <Tag style={{borderRadius: 5}} color={STATUS_BILL_OBJ[status]?.color} >{STATUS_BILL_OBJ[status]?.bs_name}</Tag>;
};

export default TagStatus;
