// import { useState } from "react";
/* eslint-disable */
import { Col, Form, Menu, MenuProps, Row } from "antd";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useForm } from "antd/lib/form/Form";
import { CND_URL } from "src/services/api/config";
import { change_alias } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { localGetAccount } from "src/utils/localStorage";
import FormText from "../form/FormText";
import "./style.scss";
import { ChevronRightIcon } from "lucide-react";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
	label: React.ReactNode,
	key?: React.Key | null,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group'
  ): MenuItem {
	return {
	  key,
	  icon,
	  children,
	  label,
	  type
	} as MenuItem;
}

function Sidenav(props: { isOpenMenu: boolean }) {
	const { pathname } = useLocation();
	const isMount = useIsMount();
	const page = pathname.split("/");
	const dataUser:any = localGetAccount();
	const [dataMenu, setDataMenu] = useState<any>([]);
	const [inputSearch, setInputSearch] = useState<string>("");
	
	const [formSearch] = useForm();

	/****************************START**************************/
	/*                         Life Cycle                      */
	useEffect(() => {
			let _dataUser = JSON.parse(dataUser);
			let menu = _dataUser?.menu;
			// const menu = [
			// 	{
			// 		"id": 2,
			// 		"parent_id": 0,
			// 		"status": "A",
			// 		"ui_route": "/",
			// 		"level": 0,
			// 		"index": 0,
			// 		"ui_icon": "uploads/tms/module-chuc-nang/2022/12/19/2/1671422662433_icon_start_svg.svg",
			// 		"active_icon": "uploads/tms/module-chuc-nang/2023/09/11/2/1694400065127_activate_icon_end_svg.svg",
			// 		"funct_name": "Quản lý tuyển dụng",
			// 		"funct_code": "FLEET_MANAGEMENT",
			// 		"action": null,
			// 		"relative_funct_ids": null,
			// 		"mobile_route": "FLEET_MANAGEMENT",
			// 		"mobile_icon": "undefined",
			// 		"children": [
			// 			{
			// 				"id": 84,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/create-post",
			// 				"level": 1,
			// 				"index": 0,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836134_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836135_activate_icon_end_svg.svg",
			// 				"funct_name": "Tạo tin tuyển dụng",
			// 				"funct_code": "FLEET_MANAGEMENT__MONITOR",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/create-post",
			// 				"mobile_icon": "undefined",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 24,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/list-post",
			// 				"level": 1,
			// 				"index": 1,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881884_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881885_activate_icon_end_svg.svg",
			// 				"funct_name": "Tin tuyển dụng",
			// 				"funct_code": "FLEET_MANAGEMENT__DASHBOARD",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/list-post",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/23/24/1671759851661_icon_mobie_svg.png",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 11,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/list-profile",
			// 				"level": 1,
			// 				"index": 2,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2022/12/19/11/1671436524753_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2022/12/19/11/1671436524753_activate_icon_end_svg.svg",
			// 				"funct_name": "Hồ sơ ứng viên",
			// 				"funct_code": "FLEET_MANAGEMENT__VEHICLE",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route":  "/list-profile",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/16/11/1671171872853_icon_mobie_svg.png",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 25,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/list-profile-temp",
			// 				"level": 1,
			// 				"index": 3,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2022/12/12/25/1670855698550_icon_start_svg_icon00170.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2022/12/12/25/1670855631626_activate_icon_end_svg_icon00170_a.svg",
			// 				"funct_name": "Hồ sơ tạm",
			// 				"funct_code": "FLEET_MANAGEMENT__DRIVER",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "DriverList",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/16/25/1671172031453_icon_mobie_svg.png",
			// 				"children": []
			// 			},
						
			// 		]
			// 	},
			// 	{
			// 		"id": 1,
			// 		"parent_id": 0,
			// 		"status": "A",
			// 		"ui_route": "/",
			// 		"level": 1,
			// 		"index": 1,
			// 		"ui_icon": "uploads/tms/module-chuc-nang/2023/02/09/1/1675939824753_icon_start_svg.svg",
			// 		"active_icon": "uploads/tms/module-chuc-nang/2023/09/11/1/1694399954849_activate_icon_end_svg.svg",
			// 		"funct_name": "Quản lý tin tức",
			// 		"funct_code": "TRANSPORT_MANAGEMENT",
			// 		"action": null,
			// 		"relative_funct_ids": null,
			// 		"mobile_route": "/",
			// 		"mobile_icon": "undefined",
			// 		"children": [
			// 			{
			// 				"id": 84,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/create-new",
			// 				"level": 1,
			// 				"index": 0,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836134_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836135_activate_icon_end_svg.svg",
			// 				"funct_name": "Tạo tin tức",
			// 				"funct_code": "FLEET_MANAGEMENT__MONITOR",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/create-post",
			// 				"mobile_icon": "undefined",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 84,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/list-new",
			// 				"level": 1,
			// 				"index": 0,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836134_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836135_activate_icon_end_svg.svg",
			// 				"funct_name": "Danh sách tin tức",
			// 				"funct_code": "FLEET_MANAGEMENT__MONITOR",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/create-post",
			// 				"mobile_icon": "undefined",
			// 				"children": []
			// 			},
			// 		]
			// 	},
			// 	{
			// 		"id": 3,
			// 		"parent_id": 0,
			// 		"status": "A",
			// 		"ui_route": "/",
			// 		"level": 0,
			// 		"index": 0,
			// 		"ui_icon": "uploads/tms/module-chuc-nang/2022/12/19/2/1671422662433_icon_start_svg.svg",
			// 		"active_icon": "uploads/tms/module-chuc-nang/2023/09/11/2/1694400065127_activate_icon_end_svg.svg",
			// 		"funct_name": "Danh mục hệ thống",
			// 		"funct_code": "FLEET_MANAGEMENT",
			// 		"action": null,
			// 		"relative_funct_ids": null,
			// 		"mobile_route": "FLEET_MANAGEMENT",
			// 		"mobile_icon": "undefined",
			// 		"children": [
			// 			{
			// 				"id": 81,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/module-funct",
			// 				"level": 1,
			// 				"index": 0,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836134_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836135_activate_icon_end_svg.svg",
			// 				"funct_name": "Module & function",
			// 				"funct_code": "FLEET_MANAGEMENT__MONITOR",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/Module & function",
			// 				"mobile_icon": "undefined",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 84,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/users",
			// 				"level": 1,
			// 				"index": 0,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836134_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836135_activate_icon_end_svg.svg",
			// 				"funct_name": "Người dùng",
			// 				"funct_code": "FLEET_MANAGEMENT__MONITOR",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/monitor",
			// 				"mobile_icon": "undefined",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 24,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/category-job",
			// 				"level": 1,
			// 				"index": 1,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881884_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881885_activate_icon_end_svg.svg",
			// 				"funct_name": "Ngành nghề",
			// 				"funct_code": "FLEET_MANAGEMENT__DASHBOARD",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/23/24/1671759851661_icon_mobie_svg.png",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 25,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/roles",
			// 				"level": 1,
			// 				"index": 1,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881884_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881885_activate_icon_end_svg.svg",
			// 				"funct_name": "Vai trò",
			// 				"funct_code": "FLEET_MANAGEMENT__DASHBOARD",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/23/24/1671759851661_icon_mobie_svg.png",
			// 				"children": []
			// 			},
						
			// 		]
			// 	},
			// 	{
			// 		"id": 3,
			// 		"parent_id": 0,
			// 		"status": "A",
			// 		"ui_route": "/",
			// 		"level": 0,
			// 		"index": 0,
			// 		"ui_icon": "uploads/tms/module-chuc-nang/2022/12/19/2/1671422662433_icon_start_svg.svg",
			// 		"active_icon": "uploads/tms/module-chuc-nang/2023/09/11/2/1694400065127_activate_icon_end_svg.svg",
			// 		"funct_name": "Cấu hình hệ thống",
			// 		"funct_code": "FLEET_MANAGEMENT",
			// 		"action": null,
			// 		"relative_funct_ids": null,
			// 		"mobile_route": "FLEET_MANAGEMENT",
			// 		"mobile_icon": "undefined",
			// 		"children": [
			// 			{
			// 				"id": 84,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/list-email",
			// 				"level": 1,
			// 				"index": 0,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836134_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836135_activate_icon_end_svg.svg",
			// 				"funct_name": "Mẫu Email",
			// 				"funct_code": "FLEET_MANAGEMENT__MONITOR",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/monitor",
			// 				"mobile_icon": "undefined",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 86,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/email-field",
			// 				"level": 1,
			// 				"index": 0,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836134_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/84/1688054836135_activate_icon_end_svg.svg",
			// 				"funct_name": "Trường dữ liệu Email",
			// 				"funct_code": "FLEET_MANAGEMENT__MONITOR",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "/monitor",
			// 				"mobile_icon": "undefined",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 24,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/target-job",
			// 				"level": 1,
			// 				"index": 1,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881884_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881885_activate_icon_end_svg.svg",
			// 				"funct_name": "Chỉ tiêu tuyển dụng",
			// 				"funct_code": "FLEET_MANAGEMENT__DASHBOARD",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/23/24/1671759851661_icon_mobie_svg.png",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 24,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/banner-list",
			// 				"level": 1,
			// 				"index": 1,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881884_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881885_activate_icon_end_svg.svg",
			// 				"funct_name": "Banner",
			// 				"funct_code": "FLEET_MANAGEMENT__DASHBOARD",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/23/24/1671759851661_icon_mobie_svg.png",
			// 				"children": []
			// 			},
			// 			{
			// 				"id": 24,
			// 				"parent_id": 2,
			// 				"status": "A",
			// 				"ui_route": "/yep",
			// 				"level": 1,
			// 				"index": 1,
			// 				"ui_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881884_icon_start_svg.svg",
			// 				"active_icon": "uploads/tms/module-chuc-nang/2023/06/29/24/1688054881885_activate_icon_end_svg.svg",
			// 				"funct_name": "Year End Party",
			// 				"funct_code": "FLEET_MANAGEMENT__DASHBOARD",
			// 				"action": null,
			// 				"relative_funct_ids": null,
			// 				"mobile_route": "",
			// 				"mobile_icon": "uploads/tms/module-chuc-nang/2022/12/23/24/1671759851661_icon_mobie_svg.png",
			// 				"children": []
			// 			},
			// 		]
			// 	},
			// ]
			
			if (menu) {
				const id = menu.find((e: any) => e.children?.find((x: any) => page[1] === x?.web_route?.split("/")[1]))?.id;
				const _dataMenu = menu?.map((e: any) => (e.id === id ? { ...e, showAll: true } : e));
				setDataMenu(_dataMenu);
			}
		
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	if (isMount) return;
	// 	let _dataUser = JSON.parse(dataUser || "");
	// 	let menu = _dataUser?.menu;
	// 	if (menu) {
	// 	  let resultMenu = menu.map((e: any) => ({
	// 		...e,
	// 		showAll: false,
	// 	  }));
	// 	  let arrMenu: any[] = [];
	// 	  resultMenu.forEach((e: any) => {
	// 		if (
	// 		  change_alias(e.funct_name || "")?.search(
	// 			change_alias(inputSearch)
	// 		  ) !== -1
	// 		) {
	// 		  arrMenu.push(e);
	// 		} else {
	// 		  let childrenMenu = e.children.filter(
	// 			(item: any) =>
	// 			  change_alias(item.funct_name || "")?.search(
	// 				change_alias(inputSearch)
	// 			  ) !== -1
	// 		  );
	// 		  if (childrenMenu.length > 0) {
	// 			let itemMenu = { ...e, children: [] };
	// 			itemMenu.children = childrenMenu;
	// 			arrMenu.push(itemMenu);
	// 		  }
	// 		}
	// 	  });
		  
	// 	  for (let i = 0; i < arrMenu.length; i++) {
	// 		arrMenu[i].showAll = true;
	// 	  }
	// 	  setDataMenu(arrMenu);
	// 	}
	//   }, [inputSearch]);

	/**************************** END **************************/

	/****************************START**************************/
	/*                          Function                       */

	const btnShowAll = (index: number) => {
		const _listMenu = [...dataMenu].map((e: any, i: number) => ({ ...e, showAll: index === i ? !e.showAll : false }));
		setDataMenu(_listMenu);
	};
	const handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
		  formSearch.submit();
		}
	  };
	  
	const submitSearch = (values: any) => {
		if (values?.search !== undefined) {
		setInputSearch(values.search);
		}
	};
	/**************************** END **************************/

	/****************************START**************************/
	/*                         Component                       */

	/**************************** END **************************/

	/****************************START**************************/
	/*                      Return Component                   */

	return (
		<div id="sidenav" className="h-full sidenav " style={{width:`${props.isOpenMenu  ? '245px' : '40px'}`}}>
			<div className="h-[20px]">

			</div>
			{/* {
				props.isOpenMenu &&
				<div style={{ padding: "10px 10px 0 10px" }}>
					<Form name="searchMenu" form={formSearch} onFinish={submitSearch}>
						<FormText
							name="search"
							placeholder="Tìm kiếm trang"
							classItem="mb-0"
							onChange={(e) => {
								submitSearch({ search: e.target.value });
							}}
							onKeyDown={handleKeyDown}
							classInput="inputSearch_header_menu h-[35px]"
						/>
					</Form>
				</div>
			} */}
			{props.isOpenMenu && dataMenu.map(
				(e: any, index: number) =>
					e.children.length !== 0 && (
						<Col key={index}>
							<div
								className="button_navBar"
								onClick={(e) => {
									e.preventDefault();
									btnShowAll(index);
								}}
							>
								<Row align="middle" className="parent-menu">
									<span className="icon">
										{e.showAll ? (
											<div className="float-right transition-all duration-100 ease-out rotate-90">{<ChevronRightIcon />}</div>
										) : (
											<div className="float-right transition-all duration-100">{<ChevronRightIcon />}</div>
										)}
									</span>
									<span className="titleMenu ">{e.funct_name}</span>
								</Row>
							</div>
							{e.showAll &&
								e.children.map(
									(item: any, indexItem: number) =>
										{
											const isActive = page.length > 0 && page[1] === item.web_route.replace("/", "") ? true : false
											return(
												<NavLink
													key={indexItem}
													to={`${item.web_route}`}
												>
													<Row
														align="middle"
														className={`${
															isActive
																? "child-menu-click"
																: "child-menu"
														}`}
													>
														{/* <span className="icon">{<img src={`${CND_URL}/${isActive ? item.active_icon : item.ui_icon}`} />}</span> */}
														<span className="titleMenu ">{item.funct_name}</span>
													</Row>
												</NavLink>
											)
										}
								)}
						</Col>
					)
			)}
			{
				!props.isOpenMenu && <Menu
				style={{ marginTop: 10}} 
				mode="vertical"
				id="tms-close-sidenav"
				items={
					dataMenu.map((e: any) => {
						const children = e.children.map((item: any) => {
							const isActive = page.length > 0 && page[1] === item.web_route.replace("/", "") ? true : false
							return getItem(
								<NavLink
									to={`${item.web_route}`}
								>
									<Row
										align="middle"
										style={{width: 200}}
									>
										<span className="icon">{<img src={`${CND_URL}/${isActive ? item.icon : item.icon}`} />}</span>
										<span className="titleMenu ml-[3px]">{item.funct_name}</span>
									</Row>
								</NavLink>, 
								item.id, 
								null
							)
						})
						return getItem(
							<span className="icon">{<img src={`${CND_URL}/${e.icon}`} />}</span>, 
							e.id, 
							null, 
							children
						)
					})
				} 
			/>
			}
		</div>
	);

	/**************************** END **************************/
}

export default Sidenav;
