import React from "react";

function SvgIcon00192(props) {
	return (
		<svg
			width={25}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
			x={0.5}
			y={0.5}
			width={24}
			height={24}
			rx={4.5}
			fill={props.fillbg || "#fff"}
			stroke="#808A94"
			/>
			<path
			d="M7.188 9.688h10.625a.937.937 0 1 0 0-1.876H7.188a.937.937 0 1 0 0 1.875ZM17.813 11.563H7.188a.937.937 0 1 0 0 1.874h10.625a.937.937 0 1 0 0-1.874ZM17.813 15.313H7.188a.937.937 0 1 0 0 1.874h10.625a.937.937 0 1 0 0-1.875Z"
			fill={props.fill || "#000"}
			/>
		</svg>
	);
}

export default SvgIcon00192;
