import { Button, DatePicker, Drawer, Form, Input, Popover, Select, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import SvgIcon00016 from "src/assets/svg/SvgIcon00016";
import SvgIcon00023 from "src/assets/svg/SvgIcon00023";
import SvgIcon00193 from "src/assets/svg/SvgIcon00193";
import SvgIconMoveRight from "src/assets/svg/SvgIconMoveRight";
import { arrSelectDate } from "src/constants";
import { removeSign } from "src/utils/helpers/functions/textUtils";
import "./flexfilter.scss";

export const FLEX_FILTER_CONFIGS = {
	type: {
		input: "input",
		select: "select",
		button: "button",
		datePicker: "datePicker",
		selectDatePicker: "selectDatePicker"
	},
	position: {
		left: "left",
		right: "right"
	},
	width: {
		50: 50,
		100: 100,
		150: 150,
		200: 200,
		250: 250,
		300: 300,
		350: 350,
		400: 400
	},
	btnClass: {
		red: "btn-red",
		green: "btn-green",
		blue: "btn-blue",
		light: "btn-light"
	},
	btnType: {
		primary: "primary",
		danger: "danger"
	},
	initTime: {
		now_days: arrSelectDate[0],
		seven_days_ago: arrSelectDate[1],
		two_weeks_ago: arrSelectDate[2],
		thirty_days_ago: arrSelectDate[3],
		this_month: arrSelectDate[4],
		last_month: arrSelectDate[5],
		optional: arrSelectDate[6]
	}
};
const FlexFilter = ({
	elements = [],
	elementsMore = [],
	manipulation = [],
	onSearch = (v) => { },
	onAdd = (v) => { },
	onPrint = (v) => { },
	onAssign= (v) => { },
	onResetMore = (v) => { }
}) => {
	const [disabled, setDisabled] = useState(elements?.find((e) => e.type === "datePicker")?.isDisable);
	const [params, setParams] = useState({});
	const [form] = Form.useForm();
	const [open, setOpen] = useState(false);
	const { setFieldsValue, getFieldsValue } = form;
	const [openFilterMore, setOpenFilterMore] = useState(false);
	const onChangeSelect = (value, id, child, getsValue) => {
		let newParams = {
			...params,
			[id]: value,
			change: id
		};
		if (getsValue) {
			const _fieldsValue = getFieldsValue(getsValue);
			newParams = {
				...newParams,
				..._fieldsValue
			}
		}
		if (child) {
			child.forEach((c) => setFieldsValue({ [c]: undefined }));
			onSearch({ params: newParams, idBtn: "changeSelect", id });
		}
	};
	const onClickBtns = async (idBtn) => {
		setOpen(false);
		const values = await form.validateFields();
		const {
			datePicker,
			selectDecorator,
			btnAction,
			btnFilterMore,
			btnSearch,
			createdRange,
			updatedRange,
			paymentRange,
			repairRange,
			...formParams
		} = values;
		const startDate = datePicker ? +moment(datePicker[0]) : undefined;
		const endDate = datePicker ? +moment((datePicker || [])[1]) : undefined;
		let newParams = {
			...params,
			startDate,
			endDate,
			...formParams,
			created_from: values["createdRange"] ? moment(values["createdRange"][0]).format("YYYY-MM-DD") : undefined,
			created_to: values["createdRange"] ? moment(values["createdRange"][1]).format("YYYY-MM-DD") : undefined,
			start_from: values["startRange"] ? +moment(values["startRange"][0]) : undefined,
			start_to: values["startRange"] ? +moment(values["startRange"][1]) : undefined,
			end_from: values["endRange"] ? +moment(values["endRange"][0]) : undefined,
			end_to: values["endRange"] ? +moment(values["endRange"][1]) : undefined,
			payment_from: values["paymentRange"] ? +moment(values["paymentRange"][0]) : undefined,
			payment_to: values["paymentRange"] ? +moment(values["paymentRange"][1]) : undefined,
			updated_from: values["updatedRange"] ? +moment(values["updatedRange"][0]) : undefined,
			updated_to: values["updatedRange"] ? +moment(values["updatedRange"][1]) : undefined,
			repair_from: values["repairRange"] ? +moment(values["repairRange"][0]) : undefined,
			repair_to: values["repairRange"] ? +moment(values["repairRange"][1]) : undefined,

		};
		setParams(newParams);
		onSearch({ idBtn, params: newParams });
	};
	const onChangeDate = (dates) => {
		const newParams = {
			...params,
			startDate: dates ? +moment(dates[0]) : "",
			endDate: dates ? +moment((dates || [])[1]) : ""
		};
		setParams(newParams);
	};
	const onChangeSelectDate = (v, option, formName) => {
		const { disabled, result, key } = option;
		const newPamams =
			key === "option"
				? {
					...params,
					startDate: undefined,
					endDate: undefined
				}
				: {
					...params,
					startDate: +moment(result[0]),
					endDate: +moment(result[1])
				};
		setParams(newPamams);
		setFieldsValue({ [formName]: result });
		setDisabled(disabled);
	};
	const content = () => {
		return (
			<div className="contentFlexFilter">
				{(manipulation || [])
					// .filter((e) => ["btnAdd"].includes(e.paramsName))
					.map((item) => (
						<Button
							key={item.paramsName}
							id={item.paramsName}
							size="middle"
							block={true}
							type="link"
							onClick={() => onClickBtns(item.paramsName)}
						>
							{item.icon}&nbsp;
							{item.text}
						</Button>
					))}
			</div>
		);
	};
	const handleOpenChange = (newOpen = false) => {
		setOpen(newOpen);
	};
	const onClickApply = async () => {
		onClickBtns("btnSearch");
		// form.resetFields(fields);
		setOpenFilterMore(false);
	};
	const onClickResetMore = async () => {
		const fields = elementsMore.map((e) => e.paramsName);
		form.resetFields(fields);
		onResetMore();
	};
	const decoratorElements = (_elements) => {
		const isReturn = _elements.filter((e) => e.type === FLEX_FILTER_CONFIGS.type.selectDatePicker).length > 0;
		if (isReturn) return _elements;
		const datepicker = _elements.find((e) => e.hasSelectDate);
		if (!datepicker) return _elements;
		const index = _elements.indexOf(datepicker);
		const { selectDateWidth, label, paramsName, initSelectDate } = datepicker;
		if (index === -1) return _elements;
		const selectDateElement = {
			type: FLEX_FILTER_CONFIGS.type.selectDatePicker,
			position: FLEX_FILTER_CONFIGS.position.left,
			width: selectDateWidth,
			placeholder: "Loại thời hạn",
			options: {
				data: arrSelectDate,
				keyOption: "key",
				nameOption: "title",
				valueOption: "key"
			},
			label,
			paramsName: "selectDecorator",
			initValue: initSelectDate,
			adaptName: paramsName
		};
		_elements.splice(index, 0, selectDateElement);
		return _elements;
	};
	const decoratedElements = decoratorElements(elements);
	const lefts = decoratedElements.filter((e) => e.position === "left");

	const decoratedElementsMore = decoratorElements(elementsMore);
	const leftsMore = decoratedElementsMore.filter((e) => e.position === "left");
	const _handleKeyDown = (e) => {
		if (e.key === "Enter") {
			onClickBtns("btnSearch");
		}
	};
	// const rights = decoratedElements.filter((e) => e.position === "right");
	const renderComponents = (info) => {
		const {
			type,
			paramsName,
			options,
			placeholder,
			width,
			text,
			icon,
			btnClass,
			btnType,
			pickerType,
			format,
			hasChild,
			getsValue,
			adaptName,
			isDisable,
			allowClear
		} = info;
		const { data, keyOption, valueOption, nameOption } = options || {
			data: []
		};
		switch (type) {
			case "input":
				return (
					<Input
						id={paramsName}
						placeholder={placeholder}
						style={{
							width: width || 150
						}}
						onKeyDown={(e) => _handleKeyDown(e)}
						className="bg-[#F5F5F5] border-[#D9D9D9] border-1"
						key={paramsName}
						allowClear
						disabled={isDisable}
					></Input>
				);
			case "selectDatePicker":
				return (
					<Select
						style={{
							width: width || 150
						}}
						className={paramsName}
						id={paramsName}
						key={paramsName}
						onChange={(v, props) => onChangeSelectDate(v, props.dateoption, adaptName)}
					>
						{data?.map((e) => (
							<Select.Option dateoption={e} key={e[keyOption]} value={e[valueOption]}>
								{e[nameOption]}
							</Select.Option>
						))}
					</Select>
				);
			case "select":
				return (
					<Select
						style={{
							width: width || 150
						}}
						disabled={isDisable}
						className={paramsName}
						id={paramsName}
						key={paramsName}
						placeholder={placeholder}
						allowClear={!allowClear}
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) => removeSign(option.children.toLowerCase()).indexOf(removeSign(input.toLowerCase())) >= 0}
						onChange={(e) => onChangeSelect(e, paramsName, hasChild, getsValue)}
					>
						{data?.map((e) => (
							<Select.Option key={e[keyOption]} value={e[valueOption]}>
								{e[nameOption]}
							</Select.Option>
						))}
					</Select>
				);
			case "button":
				return (
					<Button
						type={btnType || "default"}
						className={btnClass}
						key={paramsName}
						id={paramsName}
						onClick={() => onClickBtns(paramsName)}
						style={{ ...info.style, width: width || 150 }}
					>
						{icon}
						{text || "Default"}
					</Button>
				);
			case "btnSearch":
				return (
					<Button
						className="btn-search flex items-center bg-[#212E3B]"
						key={paramsName}
						id={paramsName}
						onClick={() => onClickBtns(paramsName)}
						style={{ width: width || 150 }}
					>
						{icon}
						{text}
					</Button>
				);
			case "btnAction":
				return (
					<Popover
						placement="bottomRight"
						title=""
						content={content}
						trigger="click"
						open={open}
						onOpenChange={handleOpenChange}
					>
						<Button className="btn-action" key={paramsName} id={paramsName} style={{ width: width || 150 }} icon={icon}>
							{text || "Thao tác"}
						</Button>
					</Popover>
				);
			case "datePicker":
				return (
					<DatePicker.RangePicker
						style={{ width: width || 400 }}
						format="DD/MM/YYYY"
						placeholder={["Từ ngày", "Đến ngày"]}
						onChange={onChangeDate}
						disabled={disabled}
						allowClear={!allowClear}
					/>
				);
			case "datePickerShort":
				return (
					<DatePicker
						style={{ width: width || 400 }}
						format={format || "DD/MM/YYYY"}
						picker={pickerType}
						placeholder={placeholder}
						onChange={onChangeDate}
						disabled={disabled}
					/>
				);
			case "btnFilterMore":
				return (
					<Button
						type={btnType || "default"}
						className={`${btnClass} btn-action flex items-center`}
						key={paramsName}
						id={paramsName}
						onClick={() => setOpenFilterMore(true)}
						style={{ ...info.style, width: width || 150 }}
					>
						{icon || <SvgIcon00023 className="scale-75" />}
						{text || "Default"}
					</Button>
				);
			case "btnAdd":
				return (
					<Button
						type={btnType || "default"}
						className={`${btnClass} btn-action flex items-center`}
						key={paramsName}
						id={paramsName}
						onClick={() => onAdd()}
						style={{ ...info.style, width: width || 150 }}
					>
						{icon || <SvgIcon00023 className="scale-75" />}
						{text || "Default"}
					</Button>
				);
			case "btnPrint":
				return (
					<Button
						type={btnType || "default"}
						className={`${btnClass} btn-action flex items-center`}
						key={paramsName}
						id={paramsName}
						onClick={() => onPrint()}
						style={{ ...info.style, width: width || 150 }}
					>
						{icon || <SvgIcon00023 className="scale-75" />}
						{text || "Default"}
					</Button>
				);
			case "btnAssign":
				return (
					<Button
						type={btnType || "default"}
						className={`${btnClass} btn-action flex items-center`}
						key={paramsName}
						id={paramsName}
						onClick={() => onAssign()}
						style={{ ...info.style, width: width || 150 }}
					>
						{icon || <SvgIcon00023 className="scale-75" />}
						{text || "Default"}
					</Button>
				);
			default:
				return (
					<div
						className="space"
						style={{
							width: width,
							display: "none",
							height: "40px"
						}}
					>
						&nbsp;
					</div>
				);
		}
	};
	const getInitValue = () => {
		const initResult = {};
		for (let i = 0; i < lefts.length; i++) {
			Object.assign(initResult, { [lefts[i].paramsName]: lefts[i].initValue });
		}
		return initResult;
	};
	const getInitMoreValue = () => {
		const initResult = {};
		for (let i = 0; i < leftsMore.length; i++) {
			Object.assign(initResult, { [leftsMore[i].paramsName]: leftsMore[i].initValue });
		}
		return initResult;
	};
	return (
		<>
			{elementsMore.length > 0 && (
				<Drawer
					className="filterMore"
					title={
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-4">
								<SvgIcon00023 />
								<Typography.Title level={4} style={{ marginBottom: 0 }}>
									Lọc thêm
								</Typography.Title>
							</div>
							<div
								className="pl-8 cursor-pointer hover:translate-x-6
								transition-all duration-100 ease-in-out"
								onClick={() => setOpenFilterMore(false)}
							>
								<SvgIconMoveRight className="scale-150" />
							</div>
						</div>
					}
					width={436}
					open={openFilterMore}
					onClose={() => setOpenFilterMore(false)}
					closeIcon={false}
				>
					<Form form={form} layout="vertical" initialValues={getInitMoreValue()}>
						{elementsMore?.map((e) => {
							if (e.type !== "custom")
								return (
									<Form.Item
										key={e.paramsName}
										name={e.paramsName}
										label={e.label && !e.hasSelectDate ? e.label : <></>}
									>
										{renderComponents(e)}
									</Form.Item>
								);
							return (
								<Form.Item key={e.paramsName} name={e.paramsName} label={e.label}>
									{e.children}
								</Form.Item>
							);
						})}
					</Form>
					<div className="flex gap-8 mt-2">
						<Button className="btn-reset w-1/2" onClick={onClickResetMore}>
							<SvgIcon00016 />
							<span>Đặt lại</span>
						</Button>
						<Button className="btn-apply w-1/2" onClick={onClickApply}>
							<SvgIcon00193 />
							<span>Áp dụng</span>
						</Button>
					</div>
				</Drawer>
			)}
			<div className="flexfilter" id="flexFilter">
				<Form form={form} layout="vertical" initialValues={getInitValue()}>
					<div className="lefts">
						{lefts?.map((e) => (
							<Form.Item key={e.paramsName} name={e.paramsName} label={e.label && !e.hasSelectDate ? e.label : <></>}>
								{renderComponents(e)}
							</Form.Item>
						))}
					</div>
					{/* <div className="rights"></div> */}
				</Form>
			</div>
		</>
	);
};
export default FlexFilter;
