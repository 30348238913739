import { Reducer } from 'redux';
import { rootState } from './state/root.states';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { IRoutingGroupState } from '../interfaces/routingGroup.interfaces';
import routingGroupTypes from '../actions/types/routingGroups.type';

const initState: IRoutingGroupState = {
  stateGetListRoutingGroup: { ...rootState },
  stateGetRoutingGroupById: { ...rootState },
};

const routingGroupReducer: Reducer<IRoutingGroupState, ISyntheticAction>
  = (state: IRoutingGroupState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                  GET LIST USER SYSTEM                   */

      case routingGroupTypes.START_GET_LIST_ROUTING_GROUP: {
        const stateReducer: IRoutingGroupState = {
          ...state,
          stateGetListRoutingGroup: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case routingGroupTypes.GET_LIST_ROUTING_GROUP_SUCCESS: {
        const stateReducer: IRoutingGroupState = {
          ...state,
          stateGetListRoutingGroup: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case routingGroupTypes.GET_LIST_ROUTING_GROUP_FAILURE: {
        const stateReducer: IRoutingGroupState = {
          ...state,
          stateGetListRoutingGroup: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }

      /**************************** END **************************/

      /*************************** START *************************/
      /*                 GET USER SYSTEM BY ID                   */

      case routingGroupTypes.START_GET_ROUTING_GROUP_BY_ID: {
        const stateReducer: IRoutingGroupState = {
          ...state,
          stateGetRoutingGroupById: {
            ...rootState,
            isLoading: true
          }
        };
        return stateReducer;
      }
      case routingGroupTypes.GET_ROUTING_GROUP_BY_ID_SUCCESS: {
        const stateReducer: IRoutingGroupState = {
          ...state,
          stateGetRoutingGroupById: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case routingGroupTypes.GET_ROUTING_GROUP_BY_ID_FAILURE: {
        const stateReducer: IRoutingGroupState = {
          ...state,
          stateGetRoutingGroupById: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          }
        };
        return stateReducer;
      }


      /**************************** END **************************/

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default routingGroupReducer;