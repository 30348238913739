export const Card = ({ title, value, Icon, bg }: any) => {
	return (
		<div className={`w-full p-[20px] rounded-[10px] border-[1px] relative flex justify-between items-center ${bg}`}>
			<div className="flex flex-col justify-start items-start">
				<p className="text-[16px] text-white font-medium leading-8">{title}</p>
				<p className="text-[48px] text-white font-medium leading-[48px]">{value}</p>
			</div>
			{Icon}
		</div>
	);
};
