import * as React from "react";

const SvgPen = (props) => (
	<svg width={15} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M13.188 2.313a1.875 1.875 0 0 0-2.67 0l-1.2 1.218 2.65 2.65 1.2-1.2a1.874 1.874 0 0 0 .02-2.668Z"
			fill={props.fill}
		/>
		<path
			d="M8.431 4.419 3.187 9.663a.719.719 0 0 0-.118.162L1.33 13.331a.625.625 0 0 0 .544.92c.095 0 .19-.022.275-.063l3.506-1.738a.717.717 0 0 0 .163-.119l5.243-5.243-2.63-2.67ZM13.125 14.25H7.5a.625.625 0 1 1 0-1.25h5.625a.624.624 0 1 1 0 1.25Z"
			fill={props.fill}
		/>
	</svg>
);

export default SvgPen;
