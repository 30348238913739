import * as React from "react";

const SvgPhone = (props) => (
	<svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#a)">
			<path
				d="m24.32 18.348-3.49-3.49c-1.245-1.245-3.363-.747-3.862.873-.374 1.121-1.62 1.744-2.741 1.495-2.492-.623-5.856-3.863-6.48-6.48-.373-1.12.374-2.367 1.496-2.74 1.62-.499 2.118-2.617.872-3.863L6.626.654c-.997-.872-2.492-.872-3.364 0L.895 3.022C-1.473 5.514 1.144 12.117 7 17.974c5.856 5.856 12.46 8.597 14.952 6.105l2.368-2.367c.872-.997.872-2.492 0-3.364Z"
				fill={props?.fill ? props.fill : "#000"}
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h25v25H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgPhone;
