const repairRegulationTypes={
    START_GET_LIST_REPAIR_REGULATION: 'START_GET_LIST_REPAIR_REGULATION',
  GET_LIST_REPAIR_REGULATION_SUCCESS: 'GET_LIST_REGULATION_SUCCESS',
  GET_LIST_REPAIR_REGULATION_FAILURE: 'GET_LIST_REPAIR_REGULATION_FAILURE',

  START_UPDATE_ONE_REPAIR_REGULATION: 'START_UPDATE_ONE_REPAIR_REGULATION',
  UPDATE_ONE_REPAIR_REGULATION_SUCCESS: 'UPDATE_ONE_REPAIR_REGULATION_SUCCESS',
  UPDATE_ONE_REPAIR_REGULATION_FAILURE: 'UPDATE_ONE_REPAIR_REGULATION_FAILURE',

  START_CREATE_ONE_REPAIR_REGULATION: 'START_CREATE_ONE_REPAIR_REGULATION',
  CREATE_ONE_REPAIR_REGULATION_SUCCESS: 'CREATE_ONE_REPAIR_REGULATION_SUCCESS',
  CREATE_ONE_REPAIR_REGULATION_FAILURE: 'CREATE_ONE_REPAIR_REGULATION_FAILURE',

  START_GET_REPAIR_REGULATION_BY_ID: 'START_GET_REPAIR_REGULATION_BY_ID',
  GET_REPAIR_REGULATION_BY_ID_SUCCESS: 'GET_REPAIR_REGULATION_BY_ID_SUCCESS',
  GET_REPAIR_REGULATION_BY_ID_FAILURE: 'GET_REPAIR_REGULATION_BY_ID_FAILURE',

  START_GEN_CODE_REPAIR_REGULATION_BY_ID: 'START_GEN_CODE_REPAIR_REGULATION_BY_ID',
  GEN_CODE_REPAIR_REGULATION_BY_ID_SUCCESS: 'GEN_CODE_REPAIR_REGULATION_BY_ID_SUCCESS',
  GEN_CODE_REPAIR_REGULATION_BY_ID_FAILURE: 'GEN_CODE_REPAIR_REGULATION_BY_ID_FAILURE',

}
export default repairRegulationTypes