const repairPartnerTypes={
    START_GET_LIST_REPAIR_PARTNER: 'START_GET_LIST_REPAIR_PARTNER',
  GET_LIST_REPAIR_PARTNER_SUCCESS: 'GET_LIST_REPAIR_PARTNER_SUCCESS',
  GET_LIST_REPAIR_PARTNER_FAILURE: 'GET_LIST_REPAIR_PARTNER_FAILURE',

  START_GET_REPAIR_PARTNER_BY_ID: 'START_GET_REPAIR_PARTNER_BY_ID',
  GET_REPAIR_PARTNER_BY_ID_SUCCESS: 'GET_REPAIR_PARTNER_BY_ID_SUCCESS',
  GET_REPAIR_PARTNER_BY_ID_FAILURE: 'GET_REPAIR_PARTNER_BY_ID_FAILURE',


}
export default repairPartnerTypes