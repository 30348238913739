import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Switch } from "antd";
import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import Svg00114 from "src/assets/svg/Svg00114";
import DIcon from "src/components/icons/DIcon";
import { CND_URL } from "src/services/api/config";
import routerNames from "src/utils/data/routerName";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
const DragHandle = SortableHandle(({ active }: any) => <Svg00114 />);
export const columnsData = ({
	setIsEdit,
	setOpenCreateModal,
	setUpdateStatus,
	setUpdateData,
	onOpenImageOverlay
}: any) => {
	const menu = (record: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button>
							<EyeOutlined />
							<Link to={`${routerNames.MOUDULE_FUNCT}/${record.id}`}>
								<span className="cursor-pointer">Xem chi tiết</span>
							</Link>
						</Button>
					)
				},
				{
					key: "confirm",
					label: (
						<Button
							onClick={() => {
								setOpenCreateModal(true);
								setIsEdit(true);
								setUpdateData(record);
							}}
						>
							<EditOutlined />
							<span className="cursor-pointer" style={{marginLeft:'0px'}}>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	// const items = (record: any) => [
	// 	{
	// 		key: "detail",
	// 		label: (
	// 			<Button>
	// 				<EyeOutlined />
	// 				<Link to={`${routerNames.MOUDULE_FUNCT}/${record.id}`}>
	// 					<span className="cursor-pointer">Xem chi tiết</span>
	// 				</Link>
	// 			</Button>
	// 		)
	// 	},
	// 	{
	// 		key: "confirm",
	// 		label: (
	// 			<Button
	// 				onClick={() => {
	// 					setOpenCreateModal(true);
	// 					setIsEdit(true);
	// 					setUpdateData(record);
	// 				}}
	// 			>
	// 				<EditOutlined />
	// 				<span className="cursor-pointer" style={{marginLeft:'0px'}}>Chỉnh sửa</span>
	// 			</Button>
	// 		)
	// 	}
	// ];
	return [
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: "4%",
			align: "center",

			render: (index: string, record: any, i: number) =>
				record.level < 2 ? (
					<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<DragHandle />{" "}
					</div>
				) : null
		},
		{
			title: "Display name",
			dataIndex: "funct_name",
			key: "funct_name",
			// sorter: (a: any, b: any, event: any) => {}
		},
		{
			title: "Function code",
			dataIndex: "funct_code",
			key: "funct_code",
			width:250,
			// sorter: (a: any, b: any, event: any) => {},
			render: (funct_code: string, record: any, index: number) => {
				return <div>{funct_code || "-"}</div>;
			}
		},

		{
			title: "UI Route",
			dataIndex: "web_route",
			key: "web_route",
			render: (web_route: any, record: any, index: number) => {
				return <div>{web_route || "-"}</div>;
			}
		},
		// {
		// 	title: "Icon",
		// 	dataIndex: "ui_icon",
		// 	key: "ui_icon",
		// 	render: (ui_icon: any, record: any, index: number) => {
		// 		return (
		// 			<div style={{ textAlign: "center" }}>
		// 				{ui_icon !== "undefined" && ui_icon !== "" ? (
		// 					<img
		// 						alt=""
		// 						src={`${CND_URL}/${ui_icon}`}
		// 						style={{ maxWidth: "36px", objectFit: "cover", cursor: "pointer" }}
		// 						onClick={() => onOpenImageOverlay(ui_icon)}
		// 					/>
		// 				) : (
		// 					""
		// 				)}
		// 			</div>
		// 		);
		// 	}
		// },
		{
			title: "API Route",
			dataIndex: "api_route",
			key: "api_route",
			render: (api_route: any, record: any, index: number) => {
				return <div>{api_route || "-"}</div>;
			}
		},
		// {
		// 	title: "Mobile Icon",
		// 	dataIndex: "mobile_icon",
		// 	key: "mobile_icon",
		// 	render: (mobile_icon: any, record: any, index: number) => {
		// 		return (
		// 			<div style={{ textAlign: "center" }}>
		// 				{mobile_icon !== "undefined" && mobile_icon !== "" ? (
		// 					<img
		// 						alt=""
		// 						src={`${CND_URL}/${mobile_icon}`}
		// 						style={{ maxWidth: "36px", objectFit: "cover", cursor: "pointer" }}
		// 						onClick={() => onOpenImageOverlay(mobile_icon)}
		// 					/>
		// 				) : (
		// 					""
		// 				)}
		// 			</div>
		// 		);
		// 	}
		// },
		{
			title: "TT hoạt động",
			dataIndex: "status",
			key: "status",
			align: "center",
			render: (status: string, record: any, index: number) => {
				return (
					<div
						style={{
							display: "flex",
							alignItems: "right",
							justifyContent: "center",
							width: "100%"
						}}
					>
						<Switch checked={status === "A" ? true : false} onClick={() => setUpdateStatus(record)} />
					</div>
				);
			}
		},
		{
			title: "Thao tác",
			dataIndex: "tt",
			key: null,
			align: "center",
			width: "10%",
			render: (text: string, record: any, index: number) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(record)} trigger={["click"]} placement="bottomRight" arrow>
						{/* <Dropdown menu={{ items: items(record) }} 
						overlayClassName="dropdown-css" trigger={["click"]} placement="bottomRight" arrow> */}
							<DIcon icon="setting" className="flex align-center justify-center" />
						</Dropdown>
					</div>
				);
			}
		}
	];
};
export const _paramsFilter: any = {
	q: undefined,
	status: null,
	created_at_start: undefined,
	created_at_end: undefined,
	page: 1,
	limit: 10
};

export const data = [
	{
		id: "1",
		supplier: "Chổi chà nhà làm",
		total: "800000",
		status_id: 0,
		statusPayment: "A",
		statusImport: "A",
		importBy: "Admin",
		importAt: "12/12/2022 12:12:12"
	},
	{
		id: "2",
		supplier: "Chổi chà nhà làm",
		total: "800000",
		status_id: 0,
		statusPayment: "A",
		statusImport: "A",
		importBy: "Admin",
		importAt: "12/12/2022 12:12:12"
	},
	{
		id: "3",
		supplier: "Chổi chà nhà làm",
		total: "800000",
		status_id: 0,
		statusPayment: "A",
		statusImport: "A",
		importBy: "Admin",
		importAt: "12/12/2022 12:12:12"
	}
];
