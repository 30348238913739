import { Space, Tag } from "antd";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import { renderIcon } from "../icons/renderIcon";
import { typeIconUI } from "../icons/typesIcon";
import { STATUS_BILL_OBJ, STATUS_STATEMENT_OBJ, STATUS_WH_OBJ } from "src/utils/helpers/functions/data";

interface ITemplateCard {
	status: any;
}
const TagStatusDeb = (props: ITemplateCard) => {
	const { status } = props;
	return (
		<Tag style={{ borderRadius: 5 }} color={STATUS_STATEMENT_OBJ[status]?.color}>
			{STATUS_STATEMENT_OBJ[status]?.bss_name}
		</Tag>
	);
};

export default TagStatusDeb;
