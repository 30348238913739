const suppliesTypes = {
    START_GET_SUPLIES_MANAGEMENT: "START_GET_SUPLIES_MANAGEMENT",
    GET_SUPLIES_SUCCESS: "GET_SUPLIES_SUCCESS",
    GET_SUPLIES_FAILURE: "GET_SUPLIES_FAILURE",

    START_GET_SUPLIES_DETAIL: "START_GET_SUPLIES",
    GET_SUPLIES_DETAIL_SUCCESS: "GET_SUPLIES_DETAIL_SUCCESS",
    GET_SUPLIES_DETAIL_FAILURE: "GET_SUPLIES_DETAIL_FAILURE",

    START_UPDATE_SUPLIES: "START_UPDATE_SUPLIES",
    UPDATE_SUPLIES_SUCCESS: "UPDATE_SUPLIES_SUCCESS",
    UPDATE_SUPLIES_FAILURE: "UPDATE_SUPLIES_FAILURE",

    START_CREATE_SUPLIES: "START_CREATE_SUPLIES",
    CREATE_SUPLIES_SUCCESS: "CREATE_SUPLIES_SUCCESS",
    CREATE_SUPLIES_FAILURE: "CREATE_SUPLIES_FAILURE",
}

export default suppliesTypes