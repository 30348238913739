import { call, put, takeLatest } from "redux-saga/effects";
import { API_WAREHOUSES } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces";/*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import placesTypes from "../types/places.types";

function* getPlaces(action: IAction) {
	// try {
	// 	// yield delay(500, true);
	// 	const { payload } = action;
	// 	const res: IRootResponse = yield call(() => {
	// 		return api.get(`${API_URL}/${API_WAREHOUSES}`, payload?.params);
	// 	});
	// 	yield put(actionSuccess(placesTypes.GET_PLCAES_SUCCESS, res));
	// } catch (err: ICatchError | any) {
	// 	yield put(actionFailure(placesTypes.GET_WAREHOUSES_FAILURE, err));
	// }
}

const placesSagas = [
	takeLatest(placesTypes.START_GET_WAREHOUSES, getPlaces)
];

export default placesSagas;
