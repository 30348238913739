import * as React from "react"

const SvgEditBox = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={24}
      height={24}
      rx={4.5}
      fill={props.fillbg || "#fff"}
      stroke="#808A94"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.36 7.008c0-.91.738-1.648 1.648-1.648h10.984c.91 0 1.648.738 1.648 1.648V12.5a.55.55 0 0 1-1.099 0V7.008a.55.55 0 0 0-.549-.55H7.008a.55.55 0 0 0-.55.55v10.984c0 .304.246.55.55.55H12.5a.55.55 0 0 1 0 1.098H7.008c-.91 0-1.648-.738-1.648-1.648V7.008Z"
      fill={props.fill || "#000"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.43 13.781a1.648 1.648 0 1 0 0 3.296 1.648 1.648 0 0 0 0-3.296Zm-2.747 1.648a2.746 2.746 0 1 1 5.492 0 2.746 2.746 0 0 1-5.492 0Z"
      fill={props.fill || "#000"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.831 16.55a.55.55 0 0 1 .772-.085l1.83 1.465a.55.55 0 1 1-.685.857l-1.831-1.464a.55.55 0 0 1-.086-.772ZM8.29 9.57a.55.55 0 0 1 .548-.548h7.324a.55.55 0 0 1 0 1.098H8.838a.55.55 0 0 1-.549-.55ZM8.29 12.5a.55.55 0 0 1 .548-.55h2.93a.55.55 0 0 1 0 1.1h-2.93a.55.55 0 0 1-.549-.55Z"
      fill={props.fill || "#000"}
    />
  </svg>
)

export default SvgEditBox