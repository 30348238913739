import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { notifyError } from "src/components/notification";
import { getModuleFunctDetail } from "src/services/actions/mouduleFunct.actioms";
import { updatePageHeader } from "src/services/actions/pageHeader.action";
// import { getModuleFunctionById, updateModuleFunction } from "src/services/actions/mouduleFunct.actioms";
import { AppState } from "src/types";
import routerNames from "src/utils/data/routerName";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import ImageOverlay from "../../../components/custom/ImageOverlay";
import FormFunction from "../component/FormFunctions";
import { TypeModalEnum } from "./data";

const ModuleFunctionsEdit = () => {
	const dispatch = useDispatch();
	const isMount = useIsMount();
	const [imageOverlay, setImageOverlay] = useState<string>("");
	const [openImageOverlay, setOpenImageOverlay] = useState<boolean>(false);
	const [funct, setFunct] = useState<any>(null);
	const [visibleModal, setVisibleModal] = useState<boolean>(false);
	const [typeModal, setTypeModal] = useState<TypeModalEnum>(TypeModalEnum.CREATE);
	const [callback, setCallback] = useState<boolean>(false);
	const [form] = Form.useForm();
	const [editForm] = Form.useForm();
	const paramsUrl = useParams() as any;
	useEffect(() => {
		let _params = [
			{
				path: "/",
				name: "Trang chủ"
			},
			{
				name: "Thiết lập hệ thống"
			},
			{
				path: routerNames.MOUDULE_FUNCT,
				name: "Module-fuction"
			},
			{
				name: funct?.funct_name
			}
		];
		dispatch(updatePageHeader(_params));
	}, [dispatch, funct]);
	useEffect(() => {
		dispatch(getModuleFunctDetail(paramsUrl.id));
	}, [paramsUrl.id,callback]);

	const { stateGetModuleFunctDetail } = useSelector((e: AppState) => e.moduleFunctReducer);
	console.log("stateGetModuleFunctDetail",stateGetModuleFunctDetail)
	useEffect(() => {
		if (isMount) return;
		const { success, data, message, error, isLoading } = stateGetModuleFunctDetail;
		if (!isLoading) {
			if (success) {
				setFunct(data);
				editForm.setFieldsValue({
					funct_name: data?.funct_name,
					funct_code: data?.funct_code,
					status: data?.status,
					ui_route: data?.ui_route
				});
			}
			if (success === false || error) {
				notifyError(`${message}`);
			}
		}
	}, [stateGetModuleFunctDetail.isLoading]);

	return (
		<>
			<ImageOverlay open={openImageOverlay} imgSrc={imageOverlay} onClose={() => setOpenImageOverlay(false)} />

			<div className="customers">
			<div className="mainPages customers">
				<FormFunction
					currentFunct={funct}
					setImageOverlay={setImageOverlay}
					setOpenImageOverlay={setOpenImageOverlay}
					visibleModal={visibleModal}
					setVisibleModal={setVisibleModal}
					typeModal={typeModal}
					setTypeModal={setTypeModal}
					form={form}
					editForm={editForm}
					setCallback={setCallback}
				/>
			</div>
			</div>
		</>
	);
};

export default ModuleFunctionsEdit;
