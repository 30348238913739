import * as React from "react"
import { SVGProps } from "react"
const SvgIcon00023 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#212E3B"
      d="M21.025 6.245a.78.78 0 0 1-.08.111l-5.768 6.706v5.596c0 .642-.367 1.216-.959 1.5l-1.928.92a1.738 1.738 0 0 1-2.26-.698 1.655 1.655 0 0 1-.209-.802v-6.516L4.055 6.356a.78.78 0 0 1-.08-.111 1.62 1.62 0 0 1-.005-1.641 1.724 1.724 0 0 1 1.495-.854h14.07c.619 0 1.192.327 1.495.854a1.62 1.62 0 0 1-.005 1.641Z"
    />
  </svg>
)
export default SvgIcon00023
