import {
  call,
  put, takeLeading
} from "redux-saga/effects";
import { API_LOGIN } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import {
  IAction, IRootResponse
} from "../../interfaces/root.interfaces";
import { actionFailure, actionSuccess } from "../root.actions";
import waitingBookTypes from "../types/waitingBook.types";

function* getWaitingBook(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_LOGIN}`, payload.params);
    });
    yield put(actionSuccess(waitingBookTypes.GET_WAITING_BOOK_SUCCESS, res));
  } catch (err: any) {
    yield put(actionFailure(waitingBookTypes.GET_WAITING_BOOK_FAILURE, err));
  }
}

const waitingBookSagas = [
  takeLeading(waitingBookTypes.START_GET_WAITING_BOOK, getWaitingBook),
];

export default waitingBookSagas;
