import * as React from "react"
import { SVGProps } from "react"
const IconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31}
    fill="none"
    {...props}
  >
    <rect width={26} height={26} x={2.002} y={2.5} stroke="#D3D3D3" rx={4.5} />
    <path
      fill="#212E3B"
      d="M9.984 20.23h.076l2.031-.186c.284-.028.548-.153.749-.354l7.667-7.667a1.894 1.894 0 0 0 0-2.69l-.492-.492c-.72-.721-1.976-.721-2.697 0l-.977.977-6.683 6.683a1.18 1.18 0 0 0-.347.749l-.187 2.031a.89.89 0 0 0 .25.693.855.855 0 0 0 .61.257ZM18.67 9.32a.84.84 0 0 1 .61.256l.492.493a.856.856 0 0 1 0 1.22l-.61.61-1.712-1.713.61-.61a.867.867 0 0 1 .61-.256Zm-8.326 8.028c0-.042.021-.076.049-.104l6.315-6.323 1.713 1.713-6.316 6.315s-.07.049-.104.049l-1.823.166.166-1.823v.007Z"
    />
    <rect width={12} height={1.2} x={9.002} y={21.5} fill="#D9D9D9" rx={0.6} />
  </svg>
)
export default IconEdit
