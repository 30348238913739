import * as React from "react";
import { memo } from "react";

const Icon2103 = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 29 29" fill="none">
		<path
			d="M10.3568 15.1905C13.0261 15.1905 15.1901 13.0265 15.1901 10.3571C15.1901 7.68775 13.0261 5.5238 10.3568 5.5238C7.68739 5.5238 5.52344 7.68775 5.52344 10.3571C5.52344 13.0265 7.68739 15.1905 10.3568 15.1905Z"
			fill="#BFC4C9"
		/>
		<path
			d="M20.7143 10.3571C20.6787 11.5312 20.4741 12.6939 20.1067 13.8095H16.5714C16.1759 13.8068 15.7823 13.865 15.4045 13.9821C16.2821 12.7589 16.6852 11.2585 16.5389 9.76014C16.3926 8.26181 15.7068 6.86769 14.6093 5.8373C13.5117 4.80691 12.0771 4.21046 10.5725 4.15898C9.06794 4.1075 7.59592 4.6045 6.43048 5.55746C5.26504 6.51042 4.48558 7.8544 4.23722 9.33922C3.98885 10.824 4.28849 12.3485 5.08036 13.6289C5.87224 14.9093 7.10238 15.8582 8.5418 16.2992C9.98123 16.7402 11.5318 16.6431 12.905 16.026C12.5909 16.6194 12.4273 17.2809 12.4286 17.9524V25.5062C11.8202 25.9392 11.1025 26.1928 10.3571 26.2381C6.29714 26.2381 0 15.6876 0 10.3571C4.09318e-08 7.61026 1.0912 4.97588 3.03354 3.03354C4.97588 1.0912 7.61026 0 10.3571 0C13.104 0 15.7384 1.0912 17.6807 3.03354C19.6231 4.97588 20.7143 7.61026 20.7143 10.3571Z"
			fill="#BFC4C9"
		/>
		<path
			d="M21.3672 17.9524C21.4729 17.9513 21.5774 17.975 21.6723 18.0214L23.4759 18.9135V15.1905H19.333V18.8997L21.0551 18.027C21.1518 17.9779 21.2587 17.9524 21.3672 17.9524Z"
			fill="#BFC4C9"
		/>
		<path
			d="M26.2381 15.1905H24.8572V20.0238C24.8572 20.207 24.7844 20.3826 24.655 20.5121C24.5255 20.6416 24.3498 20.7143 24.1667 20.7143C24.061 20.7154 23.9565 20.6917 23.8615 20.6453L21.3717 19.4176L18.955 20.6425C18.8495 20.6959 18.7321 20.7214 18.614 20.7164C18.4958 20.7115 18.3809 20.6763 18.2803 20.6142C18.1797 20.5521 18.0967 20.4652 18.0393 20.3618C17.9819 20.2584 17.952 20.1421 17.9524 20.0238V15.1905H16.5715C15.839 15.1905 15.1365 15.4815 14.6185 15.9994C14.1006 16.5174 13.8096 17.2199 13.8096 17.9524V26.2381C13.8096 26.9706 14.1006 27.6731 14.6185 28.1911C15.1365 28.709 15.839 29 16.5715 29H26.2381C26.9706 29 27.6731 28.709 28.1911 28.1911C28.7091 27.6731 29 26.9706 29 26.2381V17.9524C29 17.2199 28.7091 16.5174 28.1911 15.9994C27.6731 15.4815 26.9706 15.1905 26.2381 15.1905ZM19.3334 25.5476H17.262C17.0788 25.5476 16.9032 25.4749 16.7737 25.3454C16.6442 25.2159 16.5715 25.0403 16.5715 24.8572C16.5715 24.674 16.6442 24.4984 16.7737 24.3689C16.9032 24.2394 17.0788 24.1667 17.262 24.1667H19.3334C19.5165 24.1667 19.6921 24.2394 19.8216 24.3689C19.9511 24.4984 20.0239 24.674 20.0239 24.8572C20.0239 25.0403 19.9511 25.2159 19.8216 25.3454C19.6921 25.4749 19.5165 25.5476 19.3334 25.5476Z"
			fill="#BFC4C9"
		/>
	</svg>
);

export default Icon2103;
