import { Reducer } from "redux";
import placesTypes from "../actions/types/places.types";
import { IPlacesState } from "../interfaces/places.interfaces";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { rootState } from "./state/root.states";

const initState: IPlacesState = {
	stateGetPlaces: { ...rootState },
};

const routesReducer: Reducer<IPlacesState, ISyntheticAction> = (
	state: IPlacesState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case placesTypes.START_GET_WAREHOUSES: {
			const stateReducer: IPlacesState = {
				...state,
				stateGetPlaces: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case placesTypes.GET_PLCAES_SUCCESS: {
			const stateReducer: IPlacesState = {
				...state,
				stateGetPlaces: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case placesTypes.GET_WAREHOUSES_FAILURE: {
			const stateReducer: IPlacesState = {
				...state,
				stateGetPlaces: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/
		default:
			return state;
	}
};

export default routesReducer;
