function SvgIcon00197(props) {
	return (
		<svg
			width={25}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
			x={0.5}
			y={0.5}
			width={24}
			height={24}
			rx={4.5}
			fill={props.fillbg || "#fff"}
			stroke="#808A94"
			/>
			<g clipPath="url(#a)" fill={props.fill || "#001529"}>
			<path d="M12.501 5a3.905 3.905 0 0 0-3.895 3.895 3.905 3.905 0 0 0 3.895 3.894 3.905 3.905 0 0 0 3.895-3.894A3.905 3.905 0 0 0 12.5 5ZM19.185 15.901a3.632 3.632 0 0 0-.391-.714 4.84 4.84 0 0 0-3.35-2.109.717.717 0 0 0-.494.12c-.714.527-1.565.799-2.449.799a4.097 4.097 0 0 1-2.449-.8.638.638 0 0 0-.493-.119 4.806 4.806 0 0 0-3.35 2.11c-.154.22-.29.475-.392.713-.05.102-.034.221.017.323.136.239.306.477.46.68.238.324.493.613.782.885.238.238.51.46.782.68A7.705 7.705 0 0 0 12.484 20a7.705 7.705 0 0 0 4.626-1.53c.272-.205.544-.443.782-.68a7.853 7.853 0 0 0 1.242-1.566c.085-.102.102-.22.05-.323Z" />
			</g>
			<defs>
			<clipPath id="a">
				<path fill={props.fillbg || "#fff"} transform="translate(5 5)" d="M0 0h15v15H0z" />
			</clipPath>
			</defs>
		</svg>
	);
}

export default SvgIcon00197;
