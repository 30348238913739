import { Link } from "react-router-dom";
import { renderIcon } from "./renderIcon";
import { typeIconUI } from "./typesIcon";

interface IIconCustom {
  icon: typeIconUI;
  link?: string;
  fill?: string;
  fillbg?: string,
  className?: string;
  onClick?: (value: any) => void;
}

const DIcon = (props: IIconCustom) => {
  const { link, icon, fill, fillbg, className, onClick } = props;
  
  let _className = className ? className : '';
  _className = onClick ? `cursor-pointer ${_className}` : _className;

  const render = link ? 
  <Link to={link} >
    <div className={_className}>
      {renderIcon(icon, fill, fillbg)}
    </div>
  </Link> :
  <div onClick={onClick} className={_className}>
    {renderIcon(icon, fill, fillbg)}
  </div>

  return render;
};

export default DIcon;
