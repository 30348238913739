function SvgIconLocationVehicle(props) {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.45 4.80001L16.475 4.45001H16.35V3.85001C16.475 3.50001 16.225 2.40001 16.225 2.40001C16.225 1.80001 15.625 1.42501 14.9 1.20001H14.775C14.425 1.07501 14.05 1.07501 13.8 1.07501H11.15C10.9 1.07501 10.55 1.20001 10.175 1.20001H10.05C9.325 1.45001 8.725 1.80001 8.725 2.40001C8.725 2.40001 8.475 3.47501 8.6 3.85001V4.32501H8.475C8.475 4.32501 7.625 4.57501 7.5 4.67501V5.02501L8.575 4.77501C8.575 5.25001 8.575 5.85001 8.7 6.10001V6.45001V8.25001C8.7 8.50001 9.05 8.60001 9.05 8.60001V8.85001H8.075C8.075 8.85001 7.825 8.97501 7.825 9.10001V23.45C7.825 23.45 7.95 23.7 8.075 23.7H16.75C16.75 23.7 17 23.575 17 23.45V9.02501C17 9.02501 16.875 8.77501 16.75 8.77501H15.775V8.52501C15.775 8.52501 16.125 8.27501 16.125 8.17501V5.52501V4.67501L17.325 5.02501V4.77501L17.45 4.80001Z" fill="url(#paint0_linear_17579_156834)"/>
<path d="M8.77502 6.85004L8.65002 7.57504C8.65002 7.57504 8.65002 7.70004 8.77502 7.70004V6.85004Z" fill="url(#paint1_linear_17579_156834)"/>
<path d="M8.64999 3.82499C8.52499 3.47499 8.77499 2.37499 8.77499 2.37499C8.77499 1.39999 10.475 0.924988 11.425 0.924988H13.825C14.8 0.924988 16.475 1.39999 16.475 2.37499C16.475 2.37499 16.6 3.44999 16.6 3.82499C16.475 4.17499 16.475 6.12499 16.475 6.12499H8.87499C8.87499 6.12499 8.62499 4.19999 8.62499 3.82499H8.64999Z" fill="url(#paint2_radial_17579_156834)"/>
<path d="M13.7 0.924988H11.3C10.325 0.924988 8.64996 1.39999 8.64996 2.37499V2.49999C8.64996 2.14999 8.77496 1.89999 8.99996 1.77499C9.34996 1.42499 10.075 1.17499 10.925 1.17499H14.05C14.9 1.17499 15.5 1.42499 15.975 1.77499C16.225 1.89999 16.325 2.24999 16.325 2.49999V2.37499C16.325 1.39999 14.625 0.924988 13.675 0.924988H13.7Z" fill="url(#paint3_linear_17579_156834)"/>
<path d="M16 2.62496C16 2.37496 15.875 2.27496 15.75 2.14996V2.02496H15.875C15.875 2.02496 16 2.37496 16 2.62496Z" fill="#F7941E"/>
<path d="M9.12497 2.62496C9.12497 2.37496 9.24997 2.27496 9.37497 2.14996V2.02496H9.24997C9.24997 2.02496 8.99997 2.37496 9.12497 2.62496Z" fill="#F7941E"/>
<path d="M14.9 1.42501C15.025 1.30001 15.5 1.42501 15.75 1.67501C16 1.92501 15.75 2.02501 15.75 2.02501L14.9 1.42501Z" fill="url(#paint4_linear_17579_156834)"/>
<path d="M10.2 1.42499C10.2 1.42499 9.6 1.42499 9.35 1.67499C9.1 2.02499 9.35 2.02499 9.35 2.02499L10.2 1.42499Z" fill="url(#paint5_linear_17579_156834)"/>
<path d="M8.77496 3.69999C8.77496 3.34999 9.24996 2.37499 9.24996 2.37499C9.59996 1.39999 10.575 1.29999 11.05 1.29999H14.175C14.65 1.29999 15.625 1.42499 15.875 2.37499C15.875 2.37499 16.35 3.34999 16.35 3.69999C16.35 4.04999 16.1 5.14999 16.1 5.14999H9.37496C9.37496 5.14999 8.77496 4.07499 8.77496 3.69999Z" fill="url(#paint6_radial_17579_156834)"/>
<path d="M9.12497 8.52499H16C16 8.52499 16.35 8.27499 16.35 8.17499V6.12499C16.35 5.64999 16.1 5.14999 16.1 5.14999C16.1 5.14999 15.375 4.67499 12.6 4.67499C10.675 4.67499 9.57497 4.92499 9.09997 5.14999C8.84997 5.27499 8.84997 5.62499 8.84997 5.99999V8.04999C8.72497 8.52499 9.09997 8.52499 9.09997 8.52499H9.12497Z" fill="url(#paint7_radial_17579_156834)"/>
<path d="M16 3.95001C16 3.95001 16.35 4.07501 16.35 4.42501C16.35 4.77501 15.875 5.40001 15.875 5.02501C16 4.55001 16 4.17501 16 3.95001Z" fill="url(#paint8_linear_17579_156834)"/>
<path d="M17.45 4.80001L16.475 4.45001C16.35 4.45001 16.225 4.57501 16.225 4.70001L17.425 5.05001V4.80001H17.45Z" fill="url(#paint9_linear_17579_156834)"/>
<path d="M16.225 4.54999V4.67499L17.425 4.92499V4.79999L16.225 4.54999Z" fill="url(#paint10_linear_17579_156834)"/>
<path d="M8.99996 3.95001C8.99996 3.95001 8.64996 4.07501 8.64996 4.42501C8.64996 4.77501 9.12496 5.40001 9.12496 5.02501C8.99996 4.55001 8.99996 4.17501 8.99996 3.95001Z" fill="url(#paint11_linear_17579_156834)"/>
<path d="M7.54999 4.80001L8.52499 4.45001C8.64999 4.45001 8.77499 4.57501 8.77499 4.70001L7.57499 5.05001V4.80001H7.54999Z" fill="url(#paint12_linear_17579_156834)"/>
<path d="M8.77501 4.54999V4.67499L7.57501 4.92499V4.79999L8.77501 4.54999Z" fill="url(#paint13_linear_17579_156834)"/>
<path d="M13.95 1.42499H11.175L11.3 1.29999H13.7L13.95 1.42499Z" fill="url(#paint14_linear_17579_156834)"/>
<path d="M16 8.17496L16.35 7.82496V5.64996L16 5.99996C16 5.99996 16.125 6.97496 16 8.17496Z" fill="url(#paint15_linear_17579_156834)"/>
<path d="M16.35 7.79998V6.47498C16.35 6.47498 16.1 6.59998 16.1 6.72498C16.1 7.07498 16.1 7.69998 15.975 8.29998C15.975 8.17498 16.325 7.82498 16.325 7.82498L16.35 7.79998Z" fill="url(#paint16_linear_17579_156834)"/>
<path d="M9.12496 8.175C8.99996 6.975 9.12496 5.875 9.12496 5.875L8.77496 5.625V7.8L9.12496 8.15V8.175Z" fill="url(#paint17_linear_17579_156834)"/>
<path d="M8.77496 7.79998V6.47498C8.77496 6.47498 9.02496 6.59998 9.02496 6.72498C9.02496 7.07498 9.02496 7.69998 9.14996 8.29998L8.79996 7.82498L8.77496 7.79998Z" fill="url(#paint18_linear_17579_156834)"/>
<path d="M16 8.52496H9.125V8.77496H16V8.52496Z" fill="url(#paint19_linear_17579_156834)"/>
<path d="M15.4 3.69998C14.425 3.34998 13.35 3.09998 12.15 3.09998V2.97498C13.35 2.97498 14.45 3.22498 15.4 3.57498C15.4 3.57498 15.4 3.61664 15.4 3.69998Z" fill="black"/>
<path d="M13.225 3.09999C13.225 3.09999 12.875 3.22499 12.375 3.22499C11.875 3.22499 11.525 3.09999 11.525 3.09999C11.525 3.09999 11.875 2.97499 12.375 2.97499C12.85 2.84999 13.225 2.97499 13.225 3.09999Z" fill="black"/>
<path d="M16.35 7.57498L16.475 6.84998C16.475 6.84998 16.475 6.72498 16.35 6.72498V7.57498Z" fill="#E3E3E3"/>
<path d="M8.77496 7.57498L8.64996 6.84998C8.64996 6.84998 8.64996 6.72498 8.77496 6.72498V7.57498Z" fill="#C9C9C9"/>
<path d="M12.5 4.79998C11.425 4.79998 9.375 5.14998 9.375 5.14998C9.375 5.14998 9.125 3.82498 9.125 3.57498C10.325 3.22498 11.425 2.97498 12.625 2.97498C13.6 2.97498 14.675 3.09998 15.875 3.32498C15.875 4.04998 15.625 5.02498 15.625 5.02498C15.625 5.02498 13.575 4.77498 12.5 4.77498V4.79998Z" fill="black"/>
<path d="M15.75 5.02501C13.575 4.55001 11.525 4.55001 9.47498 5.02501C9.34998 4.42501 9.22498 4.05001 9.22498 3.57501C11.4 2.85001 13.45 2.85001 15.975 3.45001C15.85 4.05001 15.725 5.02501 15.725 5.02501H15.75Z" fill="url(#paint20_linear_17579_156834)"/>
<path d="M10.575 4.79998C11.9 4.67498 13.35 4.67498 14.675 4.79998L14.925 3.22498C13.35 2.97498 11.8 2.97498 10.225 3.22498L10.575 4.79998Z" fill="url(#paint21_linear_17579_156834)"/>
<path d="M8.14996 8.77496H16.825C16.825 8.77496 17.075 8.89996 17.075 9.02496V23.375C17.075 23.375 16.95 23.625 16.825 23.625H8.14996C8.14996 23.625 7.89996 23.5 7.89996 23.375V9.02496C7.89996 9.02496 8.02496 8.77496 8.14996 8.77496Z" fill="url(#paint22_linear_17579_156834)"/>
<path d="M8.64996 9.125H16.475C16.475 9.125 16.725 9.25 16.725 9.375V23.125C16.725 23.125 16.6 23.375 16.475 23.375H8.64996C8.64996 23.375 8.39996 23.25 8.39996 23.125V9.375C8.39996 9.375 8.52496 9.125 8.64996 9.125Z" fill="url(#paint23_linear_17579_156834)"/>
<defs>
<linearGradient id="paint0_linear_17579_156834" x1="7.825" y1="12.4" x2="17.975" y2="12.4" gradientUnits="userSpaceOnUse">
<stop stop-color="#787878"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint1_linear_17579_156834" x1="8.67502" y1="7.27504" x2="8.75002" y2="7.27504" gradientUnits="userSpaceOnUse">
<stop stop-color="#787878"/>
<stop offset="1"/>
</linearGradient>
<radialGradient id="paint2_radial_17579_156834" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.525 3.52499) scale(3.325)">
<stop stop-color="#9CFF00"/>
<stop offset="1" stop-color="#63A300"/>
</radialGradient>
<linearGradient id="paint3_linear_17579_156834" x1="8.69996" y1="1.72499" x2="16.35" y2="1.72499" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#0C0D0D"/>
</linearGradient>
<linearGradient id="paint4_linear_17579_156834" x1="14.875" y1="1.70001" x2="15.8" y2="1.70001" gradientUnits="userSpaceOnUse">
<stop stop-color="#F7F7F7"/>
<stop offset="1" stop-color="#DBDBDB"/>
</linearGradient>
<linearGradient id="paint5_linear_17579_156834" x1="9.65" y1="1.69999" x2="10.125" y2="1.69999" gradientUnits="userSpaceOnUse">
<stop stop-color="#F7F7F7"/>
<stop offset="1" stop-color="#DBDBDB"/>
</linearGradient>
<radialGradient id="paint6_radial_17579_156834" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.525 3.24999) scale(3.025)">
<stop stop-color="#83D600"/>
<stop offset="0.26" stop-color="#83D600"/>
<stop offset="0.84" stop-color="#83D600"/>
</radialGradient>
<radialGradient id="paint7_radial_17579_156834" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.525 6.64999) scale(3)">
<stop stop-color="#83D600"/>
<stop offset="0.26" stop-color="#83D600"/>
<stop offset="0.84" stop-color="#83D600"/>
</radialGradient>
<linearGradient id="paint8_linear_17579_156834" x1="15.8651" y1="4.19031" x2="16.6995" y2="5.9106" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#1B1C1C"/>
</linearGradient>
<linearGradient id="paint9_linear_17579_156834" x1="16.301" y1="4.72501" x2="17.497" y2="4.72501" gradientUnits="userSpaceOnUse">
<stop stop-color="#BCBEC0"/>
<stop offset="1" stop-color="#414042"/>
</linearGradient>
<linearGradient id="paint10_linear_17579_156834" x1="16.3" y1="4.72499" x2="17.5" y2="4.72499" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#1B1C1C"/>
</linearGradient>
<linearGradient id="paint11_linear_17579_156834" x1="9.21014" y1="4.17758" x2="8.37574" y2="5.89786" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#1B1C1C"/>
</linearGradient>
<linearGradient id="paint12_linear_17579_156834" x1="8.75899" y1="4.72501" x2="7.56299" y2="4.72501" gradientUnits="userSpaceOnUse">
<stop stop-color="#BCBEC0"/>
<stop offset="1" stop-color="#414042"/>
</linearGradient>
<linearGradient id="paint13_linear_17579_156834" x1="8.75001" y1="4.72499" x2="7.55001" y2="4.72499" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#1B1C1C"/>
</linearGradient>
<linearGradient id="paint14_linear_17579_156834" x1="11.125" y1="1.34999" x2="13.925" y2="1.34999" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#0C0D0D"/>
</linearGradient>
<linearGradient id="paint15_linear_17579_156834" x1="15.75" y1="6.09996" x2="17.525" y2="9.49996" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#1B1C1C"/>
</linearGradient>
<linearGradient id="paint16_linear_17579_156834" x1="15.875" y1="6.79998" x2="17.025" y2="9.02498" gradientUnits="userSpaceOnUse">
<stop stop-color="#3B3B3B"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint17_linear_17579_156834" x1="8.49996" y1="5.975" x2="10.5" y2="9.875" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#1B1C1C"/>
</linearGradient>
<linearGradient id="paint18_linear_17579_156834" x1="8.12496" y1="6.77498" x2="9.32496" y2="7.67498" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#3B3B3B"/>
</linearGradient>
<linearGradient id="paint19_linear_17579_156834" x1="9.1" y1="8.64996" x2="15.95" y2="8.64996" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#0C0D0D"/>
</linearGradient>
<linearGradient id="paint20_linear_17579_156834" x1="9.17498" y1="4.02501" x2="15.875" y2="4.02501" gradientUnits="userSpaceOnUse">
<stop stop-color="#363B39"/>
<stop offset="1" stop-color="#0C0D0D"/>
</linearGradient>
<linearGradient id="paint21_linear_17579_156834" x1="10.25" y1="3.92498" x2="14.9" y2="3.92498" gradientUnits="userSpaceOnUse">
<stop stop-color="#3B3B3B"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint22_linear_17579_156834" x1="7.92496" y1="16.2" x2="17.125" y2="16.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#9CFF00"/>
<stop offset="0.05" stop-color="#92EF00"/>
<stop offset="0.89" stop-color="#68AC00"/>
<stop offset="1" stop-color="#558C00"/>
</linearGradient>
<linearGradient id="paint23_linear_17579_156834" x1="8.39996" y1="16.25" x2="16.65" y2="16.25" gradientUnits="userSpaceOnUse">
<stop stop-color="#A7E24B"/>
<stop offset="1" stop-color="#83D600"/>
</linearGradient>
</defs>
</svg>

	);
}

export default SvgIconLocationVehicle;
