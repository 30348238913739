import { call, put, takeLatest } from 'redux-saga/effects';
import { _apiNotSaga } from 'src/services/api/apiNotSaga';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces'; //eslint-disable-line
import { actionFailure, actionSuccess } from '../root.actions';
import routesTypes from '../types/routes.types';

function* getRoutes(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return _apiNotSaga.getRoutes(payload?.params)
    });
    yield put(actionSuccess(routesTypes.GET_ROUTES_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(routesTypes.GET_ROUTES_FAILURE, err));
  }
};

// function* getDriverDetail(action: IAction) {
//   try {
//     // yield delay(500, true);
//     const { payload } = action;
//     const res: IRootResponse = yield call(() => {
//       return _apiNotSaga.getDriverDetail(payload?.id)
//     });
//     yield put(actionSuccess(driversTypes.GET_DRIVER_DETAIL_SUCCESS, res));
//   } catch (err: ICatchError | any) {
//     yield put(actionFailure(driversTypes.GET_DRIVER_DETAIL_FAILURE, err));
//   }
// };

const routesSagas = [
    takeLatest(routesTypes.START_GET_ROUTES, getRoutes),
  //   takeLatest(driversTypes.START_GET_DRIVER_DETAIL, getDriverDetail),
  ]

export default routesSagas

