import { Space, Tag } from "antd";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import { renderIcon } from "../icons/renderIcon";
import { typeIconUI } from "../icons/typesIcon";
import { STATUS_BILL_OBJ, STATUS_SEAL } from "src/utils/helpers/functions/data";

interface ITemplateCard {
	status: any;
}
const TagStatusSeal = (props: ITemplateCard) => {
	const { status } = props;
	return <Tag style={{borderRadius: 5}} color={STATUS_SEAL[status]?.color} >{STATUS_SEAL[status]?.bs_name}</Tag>;
};

export default TagStatusSeal;
