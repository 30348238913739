import { Avatar, Button, Dropdown, Menu, Popconfirm, Popover, Progress, Switch, Tag } from "antd";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgIcon00060 from "src/assets/svg/SvgIcon00060";
import SvgIcon00061 from "src/assets/svg/SvgIcon00061";
import SvgIcon00139 from "src/assets/svg/SvgIcon00139";
import SvgIcon00178 from "src/assets/svg/SvgIcon00178";
import { renderCell } from "src/components/commonRender/renderData";
import DIcon from "src/components/icons/DIcon";
import { TICKET_URL } from "src/services/api/config";
import colors from "src/utils/colors";
import { numberWithCommas } from "src/utils/helpers/functions/textUtils";
import "./style.scss";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SvgPen from "src/assets/svg/SvgPen";
import moment from "moment";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsDataField = ({
	setOpenModalLoadReport,
	setItems,
	onEdit,
	onCancel,
	getDetail,
	pushUpApproval,
	onCreateTicket,
	user
}: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "ID",
			align: "left",
			width: '10%',
			render: (record: any, index: number) => {
				return (
					<div onClick={() => getDetail(record?.id)} className="flex flex-col cursor-pointer justify-start items-start">
						{/* <Link to={`/list-email/${record?.id}`}> */}
							<p className="text-sky-500 text-[13px] font-normal  underline leading-snug">
								{record?.id}
							</p>
						{/* </Link> */}
					</div>
				);
			}
		},
		{
			title: "Key",
			width: "30%",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.key}</div>
					</div>
				);
			}
		},
		{
			title: "value",
			width: "30%",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.value}</div>
					</div>
				);
			}
		},
		// {
		// 	title: "Người tạo",
		// 	key: "user_type_name",
		// 	width: 150,
		// 	render: (record: any, index: number) => {
		// 		return (
		// 			<div className="flex flex-col justify-start items-start">
		// 				<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
		// 					{record?.create_user}
		// 				</div>
		// 				<div>
		// 					<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày tạo:</span>
		// 					<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
		// 						{" "}
		// 						{record?.create_at}
		// 					</span>
		// 				</div>
		// 				<div>
		// 					<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày cập nhật:</span>
		// 					<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
		// 						{" "}
		// 						{record?.update_at}
		// 					</span>
		// 				</div>
		// 			</div>
		// 		);
		// 	}
		// },
		// {
		// 	title: "TT hoạt động",
		// 	dataIndex: "status",
		// 	key: "status",
		// 	align: "center",
		// 	width: 150,
		// 	render: (v: string, r: any) => (
		// 		<div className="flex justify-center">
		// 			<Switch checked={v === "A"} />
		// 		</div>
		// 	)
		// },
		{
			title: "Thao tác",
			align: "center",
			fixed: "right",
			width: "13%",
			render: (text: string, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter">
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
}

export const columnsData = ({
	setOpenModalLoadReport,
	setItems,
	onEdit,
	onCancel,
	pushUpApproval,
	getDetail,
	updateStatus,
	onCreateTicket,
	user
}: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "ID",
			align: "left",
			width: '10%',
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<Link to={`/list-email/details/${record?.id}`}>
							<p className="text-sky-500 text-[13px] font-normal  underline leading-snug">
								{record?.id}
							</p>
						</Link>
					</div>
				);
			}
		},
		{
			title: "Tên mẫu",
			width: "30%",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.email_template_code}</div>
					</div>
				);
			}
		},
		{
			title: "Tiêu đề email",
			width: "20%",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.email_template_name}</div>
					</div>
				);
			}
		},
		{
			title: "Người tạo",
			key: "user_type_name",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
							{record?.created_by}
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày tạo:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.created_at).format('DD/MM/YYYY')}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày cập nhật:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.updated_at).format('DD/MM/YYYY')}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "TT hoạt động",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 150,
			render: (v: string, r: any) => (
				<div className="flex justify-center">
					<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status, "status")} />
				</div>
			)
		},
		{
			title: "Thao tác",
			align: "center",
			fixed: "right",
			width: "13%",
			render: (text: string, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter">
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
export const columnsTransportTripExpenseData = ({ setOpenModalLoadReport, setItems }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.transport_trip_code}</div>;
			}
		},
		{
			title: "Tuyến",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.routing_road?.routing_road_name}</div>;
			}
		},
		{
			title: "Biển số xe",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.vehicle?.license_plate_short}</div>;
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Ngày bắt đầu",
						content: record?.transport_trip?.start_at || "",
						type: "dateTime"
					},
					{
						title: "Ngày kết thúc",
						content: record?.transport_trip?.end_at || "",
						type: "dateTime"
					}
				]);
			}
		},
		{
			title: "Tài xế phụ trách",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Tài xế chính",
						content: record?.transport_trip?.main_user?.profile?.full_name || ""
					},
					{
						title: "Tài xế phụ",
						content: record?.transport_trip?.sub_user?.profile?.full_name || ""
					}
				]);
			}
		},
		// {
		// 	title: "Lương chuyến (Vnđ)",
		// 	width: "10%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				content: record?.transport_trip_cost || 0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		// {
		// 	title: "Chi phí thưởng / phạt (Vnđ)",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				title: "Thưởng",
		// 				content: record?.transport_bobus || 0,
		// 				type: "money"
		// 			},
		// 			{
		// 				title: "Phạt",
		// 				content: record?.transport_penalty || 0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		{
			title: "Tổng chi phí phát sinh (Vnđ)",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.costs_incurred || 0,
						type: "money"
					}
				]);
			}
		}
	];
};

export const columnsDataRow = ({ setOpenModalLoadReport, setItems, _handleImgBillClick }: any) => {
	return [
		{
			title: "Số HĐ/PT",
			align: "center",
			render: (record: any, index: number) => {
				return <div>{record?.invoice_number || "-"}</div>;
			}
		},
		{
			title: "MST NCC",
			render: (record: any, index: number) => {
				return <div>{record?.tax_code || "-"}</div>;
			}
		},
		{
			title: "Nhóm phí",
			render: (record: any, index: number) => {
				return <div>{record?.cost_category_name}</div>;
			}
		},
		{
			title: "Loại phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Type?.cost_type_name}</div>;
			}
		},
		{
			title: "Chi phí (Vnđ)",
			align: "center",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.amount || 0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Hình ảnh hóa đơn",

			render: (record: any, index: number) => {
				return (
					<div className="grid-container-add">
						<u
							style={{ color: "#2F80ED", cursor: "pointer" }}
							onClick={() => {
								_handleImgBillClick(record?.imgs);
							}}
						>
							Xem ảnh
						</u>
						{/* {record?.imgs &&
							record?.imgs.length > 0 &&
							record?.imgs.map((img: any) => (
								<Image
									src={`${CND_URL}/${img.image_url}`}
									style={{ cursor: "pointer", height: "100px", width: "100px" }}
								/>
							))} */}
					</div>
				);
			}
		}
	];
};
export const columnsDataDetail = () => {
	return [
		{
			title: "Nhân viên",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal ">
								{record?.mvn} {record?.name}
								<br />
							</span>
							<span className="text-black text-opacity-25 text-[13px] font-normal ">Chức vụ:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal ">
								{" "}
								{record?.chucvu}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return <div>{record?.time}</div>;
			}
		},

		{
			title: "Nội dung cập nhật",
			render: (record: any, index: number) => {
				return <div>{record?.noidung}</div>;
			}
		}
	];
};
export const columnsDataDetailEdit = ({ setOpenModalLoadReport, setItems, onDelete }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.transport_trip_code}</div>;
			}
		},
		{
			title: "Tuyến",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.routing_road?.routing_road_name}</div>;
			}
		},
		{
			title: "Biển số xe",
			width: "7%",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.vehicle?.license_plate_short}</div>;
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Ngày bắt đầu",
						content: record?.transportTrip?.start_at || "",
						type: "dateTime"
					},
					{
						title: "Ngày kết thúc",
						content: record?.transportTrip?.end_at || "",
						type: "dateTime"
					}
				]);
			}
		},
		{
			title: "Tài xế phụ trách",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Tài xế chính",
						content: record?.transportTrip?.main_user?.profile?.full_name || ""
					},
					{
						title: "Tài xế phụ",
						content: record?.transportTrip?.sub_user?.profile?.full_name || ""
					}
				]);
			}
		},
		// {
		// 	title: "Lương chuyến (Vnđ)",
		// 	width: "10%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_trip_cost) ||
		// 					0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		// {
		// 	title: "Chi phí thưởng / phạt (Vnđ)",
		// 	width: "13%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				title: "Thưởng",
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_bobus) ||
		// 					0,
		// 				type: "money"
		// 			},
		// 			{
		// 				title: "Phạt",
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense?.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_penalty) ||
		// 					0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		{
			title: "Tổng chi phí phát sinh (Vnđ)",
			width: "13%",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content:
							(record?.transportTrip?.expense &&
								record?.transportTrip?.expense?.length > 0 &&
								record?.transportTrip?.expense[0]?.costs_incurred) ||
							0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Thao tác",
			dataIndex: "tt",
			key: "action",
			align: "center",
			fixed: "right",
			width: 120,
			render: (text: string, record: any, index: number) => {
				return (
					<div>
						<Popconfirm
							title="Bạn có muốn xóa hay không?"
							style={{ borderRadius: "10px" }}
							okButtonProps={{
								style: {
									background: "black",
									color: "#fdd800"
								}
							}}
							cancelButtonProps={{
								style: {
									background: "#dfe2e4",
									color: "black"
								}
							}}
							onConfirm={() => {
								onDelete(record.id);
							}}
							okText="Đồng ý"
							cancelText="Hủy"
						>
							<DIcon
								// onClick={() => {
								// 	onDelete(record.id);
								// }}
								icon="recycle_bin"
								className="flex align-center justify-center"
							/>
						</Popconfirm>
					</div>
				);
			}
		}
	];
};
export const columnsCostIncurred = ({ setOpenModalLoadReport, setItems, _handleImgBillClick }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.Transport_Trip?.transport_trip_code || "-"}</div>;
			}
		},
		{
			title: "Chi phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Category?.cost_name || "-"}</div>;
			}
		},
		{
			title: "Loại chi phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Type?.cost_type_name || "-"}</div>;
			}
		},
		{
			title: "Mã số thuế",
			render: (record: any, index: number) => {
				return <div>{record?.tax_code || "-"}</div>;
			}
		},
		{
			title: "Số hóa đơn",
			render: (record: any, index: number) => {
				return <div>{record?.invoice_number || "-"}</div>;
			}
		},
		{
			title: "Chi phí phát sinh (Vnđ)",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.amount || 0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Hình ảnh hóa đơn",
			render: (record: any, index: number) => {
				return (
					<div className="grid-container">
						<u
							style={{ color: "#2F80ED", cursor: "pointer" }}
							onClick={() => {
								_handleImgBillClick(record?.imgs);
							}}
						>
							Xem ảnh
						</u>

						{/* {record?.imgs &&
							record?.imgs.length > 0 &&
							record?.imgs.map((img: any) => (
								<Image
									src={`${CND_URL}/${img.image_url}`}
									style={{ cursor: "pointer", height: "100px", width: "100px" }}
								/>
							))} */}
					</div>
				);
			}
		}
	];
};
export const _paramsFilter: any = {
	q: undefined,
	status: null,
	created_at_start: undefined,
	created_at_end: undefined,
	page: 1,
	limit: 10
};
