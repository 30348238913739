import * as React from "react"
import { SVGProps } from "react"
const SvgBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#212E3B"
      d="M22.4 19h-1.462v-8.125h-4.063V2.75h-9.75v8.125H3.063V19H1.6v1.625h1.463v1.625h1.625v-1.625h14.625v1.625h1.625v-1.625H22.4V19Zm-3.087 0h-6.5v-6.5h2.437v1.625h1.625V12.5h2.438V19ZM8.75 4.375h2.438V6h1.624V4.375h2.438v6.5h-6.5v-6.5ZM4.688 12.5h2.437v1.625H8.75V12.5h2.438V19h-6.5v-6.5Z"
    />
  </svg>
)
export default SvgBag
