const moduleFunctTypes = {
    START_GET_LIST_MODULE_FUNCT: "START_GET_LIST_MODULE_FUNCT",
    GET_LIST_MODULE_FUNCT_SUCCESS: "GET_LIST_MODULE_FUNCT_SUCCESS",
    GET_LIST_MODULE_FUNCT_FAILURE: "GET_LIST_MODULE_FUNCT_FAILURE",

    START_GET_MODULE_FUNCT_BY_ID: "START_GET_MODULE_FUNCT_BY_ID",
    GET_MODULE_FUNCT_BY_ID_SUCCESS: "GET_MODULE_FUNCT_BY_ID_SUCCESS",
    GET_MODULE_FUNCT_BY_ID_FAILURE: "GET_MODULE_FUNCT_BY_ID_FAILURE",

    START_GET_MODULE_FUNCT_BY_ID_CHILDREN: "START_GET_MODULE_FUNCT_BY_ID_CHILDREN",
    GET_MODULE_FUNCT_BY_ID_CHILDREN_SUCCESS: "GET_MODULE_FUNCT_BY_ID_CHILDREN_SUCCESS",
    GET_MODULE_FUNCT_BY_ID_CHILDREN_FAILURE: "GET_MODULE_FUNCT_BY_ID_CHILDREN_FAILURE",
  }
  
  export default moduleFunctTypes;