import { Reducer } from "redux";

import userTypes from "../actions/types/user.types";
import { IUserState } from "../interfaces/user.interfaces";
import { rootState } from "./state/root.states";
import { ISyntheticAction } from "../interfaces/root.interfaces";

const initState: IUserState = {
	stateListUser: { ...rootState },
};

const userReducer: Reducer<IUserState, ISyntheticAction> = (
	state: IUserState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                  GET LIST USER SYSTEM                   */

		case userTypes.START_GET_USERS: {
			const stateReducer: IUserState = {
				...state,
				stateListUser: {
					...rootState,
					isLoading: true
				}
			};
			return stateReducer;
		}
		case userTypes.GET_USERS_SUCCESS: {
			const stateReducer: IUserState = {
				...state,
				stateListUser: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case userTypes.GET_USERS_FAILURE: {
			const stateReducer: IUserState = {
				...state,
				stateListUser: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/

		default:
			return state;
	}
};

export default userReducer;
