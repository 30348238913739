import { Reducer } from "redux";
import orgTypes from "../actions/types/org.types";
import { IOrgState } from "../interfaces/org.interfaces";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { rootState } from "./state/root.states";

const initState: IOrgState = {
stateGetOrgs: { ...rootState },
};

const orgReducer: Reducer<IOrgState, ISyntheticAction> = (
	state: IOrgState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/

		case orgTypes.START_ORG: {
			const stateReducer: IOrgState = {
				...state,
				stateGetOrgs: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case orgTypes.ORG_SUCCESS: {
			const stateReducer: IOrgState = {
				...state,
				stateGetOrgs: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case orgTypes.ORG_FAILURE: {
			const stateReducer: IOrgState = {
				...state,
				stateGetOrgs: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		default:
			return state;
	}
};

export default orgReducer;
