import * as React from "react";

const SvgIconDown = (props) => (
	<svg width={14} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7 7.876a.95.95 0 0 0 .673-.279l5.848-5.847A.952.952 0 1 0 12.174.403L7 5.577 1.826.403A.952.952 0 0 0 .479 1.75l5.848 5.848A.95.95 0 0 0 7 7.876Z"
			fill="#414141"
		/>
	</svg>
);

export default SvgIconDown;
