function SvgIcon00198(props) {
	return (
		<svg
			width={25}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
			x={0.5}
			y={0.5}
			width={24}
			height={24}
			rx={12}
			fill={props.fillbg || "#fff"}
			stroke="#808A94"
			/>
			<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m12.916 16.03 2.89 2.89a.722.722 0 0 0 1.021 0l2.89-2.89a.722.722 0 1 0-1.022-1.021l-1.656 1.656V6.85a.722.722 0 1 0-1.445 0v9.814l-1.656-1.656a.722.722 0 1 0-1.022 1.021ZM9.122 6.34a.722.722 0 0 0-1.021 0L5.21 9.23a.722.722 0 0 0 1.022 1.021L7.89 8.595v9.814a.722.722 0 0 0 1.445 0V8.595l1.656 1.656a.722.722 0 1 0 1.022-1.021l-2.89-2.89Z"
			fill={props.fill || "#000"}
			/>
		</svg>
	);
}

export default SvgIcon00198;
